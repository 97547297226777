import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export const options = {
    responsive: true,
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    stacked: false,
    plugins: {
        title: {
            display: false,
        },
    },
    scales: {
        y: {
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
        },
    },
}

const dummyChartLabelList = ['2022.07.20', '2022.07.21', '2022.07.22', '2022.07.23', '2022.07.24', '2022.07.25', '2022.07.26'];

const dummyChartData = {
    labels: dummyChartLabelList,
    datasets: [
        {
            label: '마이프로틴',
            data: dummyChartLabelList.map(() => Math.random() * (345000 - 34000) + 34000),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            yAxisID: 'y',
        },
        {
            label: '아이허브',
            data: dummyChartLabelList.map(() => Math.random() * (500000 - 50000) + 50000),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            yAxisID: 'y',
        },
        {
            label: '쿠팡',
            data: dummyChartLabelList.map(() => Math.random() * (200000 - 0) + 0),
            borderColor: 'rgb(153, 162, 135)',
            backgroundColor: 'rgba(153, 162, 135, 0.5)',
            yAxisID: 'y',
        },
    ],
}

const drawerWidth = 300;
const isMobileDevice = isMobile()

const termOptionList = [
    '하루',
    '일주일',
    '1개월',
    '3개월',
    '6개월',
    '1년',
];

const SBBGRevenue = () => {
    const history = useHistory()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [selectedIndex, setSelectedIndex] = useState<null | number>(1)
    const [selectedOption, setSelectedOption] = useState<string>("")
    const [selectedServiceList, setSelectedServiceList] = useState<any>(["마이프로틴", "아이허브", "쿠팡"])

    const [chartLabelList, setChartLabelList] = useState<any>([])
    const [chartData, setChartData] = useState<any>(null)

    const open = Boolean(anchorEl)

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleSelectedServiceListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let curSelectedServiceList = selectedServiceList

        if (isSelectedService(event.target.name))
            curSelectedServiceList = selectedServiceList.filter((v: any) => v != event.target.name)
        else
            curSelectedServiceList.push(event.target.name)

        setSelectedServiceList([...curSelectedServiceList])
    }

    const isSelectedService = (name: string) => {
        let isSelected = false

        for (let i = 0; i < selectedServiceList.length; i++)
            if (selectedServiceList[i] === name)
                isSelected = true

        return isSelected
    }

    useEffect(() => {
        if (selectedIndex === null || selectedIndex < 0 || selectedIndex > termOptionList.length) return

        setSelectedOption(termOptionList[selectedIndex])
    }, [JSON.stringify(selectedIndex)])

    useEffect(() => {
        alert("준비중인 기능입니다.")
        history.goBack()
        setChartLabelList(dummyChartLabelList)
        setChartData(dummyChartData)
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>수익</BoxTitle>
                        <BoxSubTitle>{"SBBG > 수익"}</BoxSubTitle>
                    </BoxDiv>
                    <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>기간 내 총 수익: <BoxTitleSpan>254,367원</BoxTitleSpan></BoxTitleKey>
                        </BoxTitleFlexDiv>
                        <BoxDivider />
                        <BoxTitleFlexDiv>
                            <FlexStartDiv>
                                <TermSupportText>지난</TermSupportText>
                                <TermSelectDiv
                                    onClick={handleClickListItem}
                                >
                                    <TermSelect>{selectedOption}</TermSelect>
                                    <ArrowDownDiv>
                                        <KeyboardArrowDownRoundedIcon color="inherit" fontSize="inherit" />
                                    </ArrowDownDiv>
                                </TermSelectDiv>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleMenuClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {termOptionList.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                                <TermSupportText>동안</TermSupportText>
                            </FlexStartDiv>
                            {/* <ServiceCheckListDiv>
                                <ServiceCheckDiv>asdasdasda</ServiceCheckDiv>
                                <ServiceCheckDiv>asdasdasda</ServiceCheckDiv>
                                <ServiceCheckDiv>asdasdasda</ServiceCheckDiv>
                            </ServiceCheckListDiv> */}
                            <FormControl>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={isSelectedService("마이프로틴")} onChange={handleSelectedServiceListChange} name="마이프로틴" />
                                        }
                                        label="마이프로틴"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={isSelectedService("아이허브")} onChange={handleSelectedServiceListChange} name="아이허브" />
                                        }
                                        label="아이허브"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={isSelectedService("쿠팡")} onChange={handleSelectedServiceListChange} name="쿠팡" />
                                        }
                                        label="쿠팡"
                                    />
                                </FormGroup>
                            </FormControl>
                        </BoxTitleFlexDiv>
                        <BoxDivider />
                        <FlexEndDiv>
                            <UpdateTimeDiv>
                                <UpdateTime>{"마지막 갱신 시간:"}</UpdateTime>
                                <UpdateTime>{"2022.07.26 23:78"}</UpdateTime>
                            </UpdateTimeDiv>
                        </FlexEndDiv>
                        {
                            chartData !== null &&
                            <BoxTitleFlexDiv>
                                <Line options={options} data={chartData} />
                                {/* <TempDiagram>그래프 준비중</TempDiagram> */}
                            </BoxTitleFlexDiv>
                        }
                    </BoxDiv>
                </WrapDiv>
            </Box>
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleSpan = Styled.span`
    box-shadow: rgb(180 255 185) 0px -5px 0px inset;
`
const TermSupportText = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #666666;
`
const TermSelectDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    border-bottom: solid 2px #327BFF;
    padding: 5px 7px;
`
const TermSelect = Styled.div`
    width: fit-content;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #327BFF;
`
const ArrowDownDiv = Styled.div`
    width: fit-content;
    font-size: 16px;
    height: 16px;
    color: #327BFF;
`
const FlexStartDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
`
const FlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const ServiceCheckListDiv = Styled.div`
    text-align: left;
`
const ServiceCheckDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const UpdateTimeDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
`
const UpdateTime = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #666666;
`
const TempDiagram = Styled.div`
    width: 100%;
    font-size: 45px;
    text-align: center;
    padding: 200px 0px;
    border: 3px dashed #C4C4C4;
    color: #EBEBEB;
`

export default SBBGRevenue;