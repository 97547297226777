import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Dashboard from 'page/pv_cms/Dashboard';

import SignIn from 'page/pv_cms/SignIn';
import SignUp from 'page/pv_cms/SignUp';
// import FindID from 'page/pv_cms/FindID';
// import FindPW from 'page/pv_cms/FindPW';
import UserManagement from 'page/pv_cms/UserManagement';

import DSBReview from 'page/dsb/DSBReview';
import DSBUser from 'page/dsb/DSBUser';
import DSBProductUpsert from 'page/dsb/product/DSBProductUpsert';
import DSBProductRead from 'page/dsb/product/DSBProductRead';
import DSBChildProductUpdate from 'page/dsb/product/DSBChildProductUpdate';
import DSBUnit from 'page/dsb/productComponent/DSBUnit';
import DSBFlavor from 'page/dsb/productComponent/DSBFlavor';
import DSBBrand from 'page/dsb/productComponent/DSBBrand';
import DSBAnalysis from 'page/dsb/productComponent/DSBAnalysis';
import DSBProductCategory from 'page/dsb/productComponent/DSBProductCategory';
// import DSBIngredient from 'page/dsb/productComponent/DSBIngredient';
// import DSBIngredientCategory from 'page/dsb/productComponent/DSBIngredientCategory';
import DSBManufacturer from 'page/dsb/productComponent/DSBManufacturer';
import DSBAllergy from 'page/dsb/productComponent/DSBAllergy';
import DSBDistributor from 'page/dsb/productComponent/DSBDistributor';
import DSBReviewTag from 'page/dsb/productComponent/DSBReviewTag';
// import DSBPrivacyPolicy from 'page/dsb/commonComponent/DSBPrivacyPolicy';
// import DSBNotice from 'page/dsb/commonComponent/DSBNotice';
// import DSBLogo from 'page/dsb/commonComponent/DSBLogo';
import DSBPick from 'page/dsb/commonComponent/DSBPick';
import DSBMagazine from 'page/dsb/commonComponent/DSBMagazine';
import DSBMagazineType from 'page/dsb/commonComponent/DSBMagazineType';
import DSBBanner from 'page/dsb/commonComponent/DSBBanner';
import DSBEvent from 'page/dsb/commonComponent/DSBEvent';
import DSBEventType from 'page/dsb/commonComponent/DSBEventType';
// import DSBTerms from 'page/dsb/commonComponent/DSBTerms';
// import DSBFavicon from 'page/dsb/commonComponent/DSBFavicon';

import SBBGRevenue from 'page/sbbg/SBBGRevenue';
import SBBGMPFavorite from 'page/sbbg/SBBGMPFavorite';
import SBBGMPDCode from 'page/sbbg/SBBGMPDCode';

import Box from '@mui/material/Box';

const Root: React.FC = () => {

  return (
    <Box sx={{ display: 'flex' }}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/signIn" exact component={SignIn} />
          <Route path="/signUp" exact component={SignUp} />
          {/* <Route path="/findID" exact component={FindID} />
          <Route path="/findPW" exact component={FindPW} /> */}
          <Route path="/userManagement" exact component={UserManagement} />
          <Route path="/dsb/product/upsert" exact component={DSBProductUpsert} />
          <Route path="/dsb/product/read" exact component={DSBProductRead} />
          <Route path="/dsb/product/CPUpdate" exact component={DSBChildProductUpdate} />
          <Route path="/dsb/productComponent/unit" exact component={DSBUnit} />
          <Route path="/dsb/productComponent/flavor" exact component={DSBFlavor} />
          <Route path="/dsb/productComponent/brand" exact component={DSBBrand} />
          <Route path="/dsb/productComponent/analysis" exact component={DSBAnalysis} />
          <Route path="/dsb/productComponent/productCategory" exact component={DSBProductCategory} />
          {/* <Route path="/dsb/productComponent/ingredient" exact component={DSBIngredient} />
          <Route path="/dsb/productComponent/ingredientCategory" exact component={DSBIngredientCategory} /> */}
          <Route path="/dsb/productComponent/manufacturer" exact component={DSBManufacturer} />
          <Route path="/dsb/productComponent/allergy" exact component={DSBAllergy} />
          <Route path="/dsb/productComponent/distributor" exact component={DSBDistributor} />
          <Route path="/dsb/productComponent/reviewTag" exact component={DSBReviewTag} />
          {/* <Route path="/dsb/commonComponent/privacyPolicy" exact component={DSBPrivacyPolicy} />
          <Route path="/dsb/commonComponent/notice" exact component={DSBNotice} />
          <Route path="/dsb/commonComponent/logo" exact component={DSBLogo} /> */}
          <Route path="/dsb/commonComponent/dsbPick" exact component={DSBPick} />
          <Route path="/dsb/commonComponent/magazine" exact component={DSBMagazine} />
          <Route path="/dsb/commonComponent/magazineType" exact component={DSBMagazineType} />
          <Route path="/dsb/commonComponent/banner" exact component={DSBBanner} />
          <Route path="/dsb/commonComponent/event" exact component={DSBEvent} />
          <Route path="/dsb/commonComponent/eventType" exact component={DSBEventType} />
          {/* <Route path="/dsb/commonComponent/terms" exact component={DSBTerms} />
          <Route path="/dsb/commonComponent/favicon" exact component={DSBFavicon} /> */}
          <Route path="/dsb/review" exact component={DSBReview} />
          <Route path="/dsb/user" exact component={DSBUser} />
          <Route path="/sbbg/revenue" exact component={SBBGRevenue} />
          <Route path="/sbbg/crawlingComponent/MPFavorite" exact component={SBBGMPFavorite} />
          <Route path="/sbbg/crawlingComponent/MPDCode" exact component={SBBGMPDCode} />
          <Redirect path="*" to="/" />
        </Switch>
      </BrowserRouter>
    </Box>
  )
};

export default Root;