import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'

import Button from '@mui/material/Button';

interface Props {
    disabled?: boolean,
    value: string,
    size: "small" | "medium" | "large",
    color: "primary" | "error" | "success" | "secondary",
    onClickEvent?: any,
    variant?: 'contained' | 'outlined' | 'text'
}

const PrimaryButton = ({ disabled, value, size, color, onClickEvent, variant }: Props) => {

    return (
        <>
        {
            disabled === true ?
            <Button
                disabled
                variant={variant !== undefined ? variant : "contained"}
                size={size}
                color={color}
            >{value}</Button>
            :
            <Button
                variant={variant !== undefined ? variant : "contained"}
                size={size}
                color={color}
                onClick={onClickEvent !== undefined ? onClickEvent : undefined}
            >{value}</Button>
        }
            
        </>
    );
};

export default PrimaryButton;