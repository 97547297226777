import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import { getToken } from 'utils/token';
import { isPositiveInteger } from 'utils/validator';
import { getRequest, deleteRequestWithToken } from 'utils/APIRequest';

import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import PrimaryButton from 'component/button/PrimaryButton';
import FlavorCreationPopup from 'component/dialog/FlavorCreationPopup';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

const drawerWidth = 300;
const rowsPerPage = 10
const isMobileDevice = isMobile()

const DSBFlavor = () => {
    const history = useHistory()

    const [flavorList, setFlavorList] = useState<any>([])
    const [flavorListCount, setFlavorListCount] = useState<number>(0)
    const [tablePage, setTablePage] = useState<number>(0)

    const [insertingQuery, setInsertingQuery] = useState<string>("")
    const [searchingQuery, setSearchingQuery] = useState<string>("")
    const [selectedFlavorList, setSelectedFlavorList] = useState<any>([])

    const [flavorForUpdate, setFlavorForUpdate] = useState<any>("")

    const [flavorCreationPopupOpen, setFlavorCreationPopupOpen] = useState<boolean>(false)
    const [flavorUpdatePopupOpen, setFlavorUpdatePopupOpen] = useState<boolean>(false)

    const handleTablePageChange = (event: unknown, newPage: number) => {
        setTablePage(newPage);
    }

    const handleFlavorCreationPopupOpen = () => {
        setFlavorCreationPopupOpen(true)
    }

    const handleFlavorCreationPopupClose = () => {
        setFlavorCreationPopupOpen(false)
    }

    const handleFlavorUpdatePopupOpen = (curFlavor: any) => {
        setFlavorForUpdate(curFlavor)
    }

    const handleFlavorUpdatePopupClose = () => {
        setFlavorForUpdate("")
        setFlavorUpdatePopupOpen(false)
    }

    const handleInsertingQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInsertingQuery(event.target.value)
    }

    const handleFlavorListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const curID = Number(event.target.id)
        let curList = selectedFlavorList

        if (isSelectedFlavor(curID))
            curList = selectedFlavorList.filter((v: any) => v !== curID)
        else
            curList.push(curID)

        setSelectedFlavorList([...curList])
    }

    const isSelectedFlavor = (flavorId: number) => {
        let isSelected = false

        for (let i = 0; i < selectedFlavorList.length; i++)
            if (selectedFlavorList[i] === flavorId)
                isSelected = true

        return isSelected
    }

    const deleteFlavorList = async () => {
        if (selectedFlavorList === undefined || selectedFlavorList.length === 0) {
            alert("선택된 Flavor이 없습니다.")
            return
        }

        const curToken = getToken()
        if (curToken === '') return

        const flavorIdListInfo = {
            flavorIdList: selectedFlavorList
        }

        const res = await deleteRequestWithToken(
            '/flavors',
            flavorIdListInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                getFlavorList(tablePage, searchingQuery)
                setSelectedFlavorList([])
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
            } else if (res.status === 204) {
                setSelectedFlavorList([])
                return
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getFlavorList = async (page: number, query: string) => {
        const pathname = "/flavors?page=" + page + (query === "" ? "" : ("&query=" + query))
        const res = await getRequest(pathname)
        if (res !== null) {
            if (res.status === 200) {
                if (res.data.count !== undefined)
                    setFlavorListCount(res.data.count)
                if (res.data.flavorList !== undefined)
                    setFlavorList([...res.data.flavorList])
            } else if (res.status === 204) {
                setFlavorList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const initTableConfig = () => {
        setTablePage(0)
        setFlavorListCount(0)
    }

    useEffect(() => {
        if (!isPositiveInteger(tablePage)) return
        getFlavorList(tablePage, searchingQuery)
    }, [tablePage])

    useEffect(() => {
        if (flavorCreationPopupOpen)
            return

        getFlavorList(tablePage, searchingQuery)
    }, [flavorCreationPopupOpen])

    useEffect(() => {
        if (flavorUpdatePopupOpen)
            return

        getFlavorList(tablePage, searchingQuery)
    }, [flavorUpdatePopupOpen])

    useEffect(() => {
        if (flavorForUpdate == undefined || flavorForUpdate == "")
            return

        setFlavorUpdatePopupOpen(true)
    }, [flavorForUpdate])

    useEffect(() => {
        getFlavorList(tablePage, searchingQuery)
        initTableConfig()
    }, [searchingQuery])

    useEffect(() => {
        getFlavorList(0, "")
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>맛</BoxTitle>
                        <BoxSubTitle>{"단성비 > 상품 컴포넌트 > 맛"}</BoxSubTitle>
                    </BoxDiv>
                    <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>맛 목록</BoxTitleKey>
                            <BoxTitleValue>
                                <TextField
                                    id="cc-search-textfield"
                                    size="small"
                                    label="맛 검색"
                                    fullWidth
                                    value={insertingQuery}
                                    onChange={handleInsertingQueryChange}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                            setSearchingQuery(insertingQuery)
                                            ev.preventDefault()
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={() => setSearchingQuery(insertingQuery)}
                                >검색</Button>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxDivider />
                        <BoxTitleFlexDiv>
                            <TableKeyDiv>총 {flavorListCount !== undefined ? flavorListCount : 0}개</TableKeyDiv>
                            <TableValueDiv>
                                <FlexEndDiv>
                                    <PrimaryButton
                                        value="맛 추가"
                                        size="medium"
                                        color="primary"
                                        onClickEvent={() => handleFlavorCreationPopupOpen()}
                                    />
                                    <BoxVirticalDivider />
                                    {
                                        selectedFlavorList == undefined || selectedFlavorList.length == 0 ?
                                            <PrimaryButton disabled={true} value="선택 삭제" size="medium" color="error" />
                                            :
                                            <PrimaryButton value="선택 삭제" size="medium" color="error" onClickEvent={() => deleteFlavorList()} />
                                    }
                                </FlexEndDiv>
                            </TableValueDiv>
                        </BoxTitleFlexDiv>
                        {
                            flavorList !== undefined && flavorList.length !== 0 &&
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="flavorList table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>맛 이름</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {flavorList.map((row: any) => (
                                                <TableRow
                                                    key={row.flavorId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Checkbox
                                                            id={row.flavorId}
                                                            checked={isSelectedFlavor(row.flavorId)}
                                                            onChange={handleFlavorListChange}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">{row.name} </TableCell>
                                                    <TableCell align="right">
                                                        <PrimaryButton
                                                            value="수정"
                                                            size="small"
                                                            color="success"
                                                            onClickEvent={() => {
                                                                handleFlavorUpdatePopupOpen(row)
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[rowsPerPage]}
                                    component="div"
                                    count={flavorListCount}
                                    rowsPerPage={rowsPerPage}
                                    page={tablePage}
                                    onPageChange={handleTablePageChange}
                                />
                            </Paper>
                        }
                    </BoxDiv>
                </WrapDiv>
            </Box>
            {
                <>
                    <FlavorCreationPopup
                        open={flavorCreationPopupOpen}
                        purpose="추가"
                        onClickClose={handleFlavorCreationPopupClose}
                        btnTextFirst="취소"
                        btnTextSecond="추가"
                    />
                </>
            }
            {
                <>
                    <FlavorCreationPopup
                        open={flavorUpdatePopupOpen}
                        flavorForUpdate={flavorForUpdate}
                        purpose="수정"
                        onClickClose={handleFlavorUpdatePopupClose}
                        btnTextFirst="취소"
                        btnTextSecond="수정"
                    />
                </>
            }
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const FlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const BoxVirticalDivider = Styled.div`
    width: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`

export default DSBFlavor;