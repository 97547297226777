import React, { useEffect, useState } from 'react';
import QueryString from 'query-string'
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import { removeToken } from 'utils/token';
import { signCheck } from 'utils/APIRequest';
import { getSchema, postRequest } from 'utils/APIRequest';
import OneBtnPopup from 'component/dialog/OneBtnPopup';

import { makeStyles, Theme, createStyles, withStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        textField: {
            borderRadius: 4,
            marginBottom: 16,
        }
    }),
);

const SignUp = () => {
    const history = useHistory()
    const classes = useStyles()

    const [isSignUpCompletePopupOpen, setIsSignUpCompletePopupOpen] = useState<boolean>(false)
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false)

    const [userName, setUserName] = useState<string>("");
    const [userID, setUserID] = useState<string>("");
    const [userPW, setUserPW] = useState<string>("");
    const [userPWC, setUserPWC] = useState<string>("");
    const [userBirthDate, setUserBirthDate] = useState<string>("");
    const [userPN, setUserPN] = useState<string>("");

    const [errTextWithName, setErrTextWithName] = useState<string>("");
    const [errTextWithID, setErrTextWithID] = useState<string>("");
    const [errTextWithPW, setErrTextWithPW] = useState<string>("");
    const [errTextWithPWC, setErrTextWithPWC] = useState<string>("");
    const [errTextWithPN, setErrTextWithPN] = useState<string>("");
    const [errTextWithBirthDate, setErrTextWithBirthDate] = useState<string>("");

    const handleSignUpCompletePopupOpen = () => {
        setIsSignUpCompletePopupOpen(true)
    }

    const handleSignUpCompletePopupClose = () => {
        setIsSignUpCompletePopupOpen(false)
        if (userID !== undefined && userID !== "") {
            history.push("/signIn?id=" + encodeURI(userID))
        } else {
            history.push("/signIn")
        }
    }

    const handleNameChange = (event: { target: any; }) => {
        if (errTextWithName !== "" && event.target.value.length > 0) setErrTextWithName("")
        setUserName(event.target.value)
    }

    const handleIDChange = (event: { target: any; }) => {
        if (errTextWithID !== "" && event.target.value.length > 0) setErrTextWithID("")
        setUserID(event.target.value)
    }

    const handlePWChange = (event: { target: any; }) => {
        if (errTextWithPW !== "" && event.target.value.length > 0) setErrTextWithPW("")
        setUserPW(event.target.value)
    }

    const handlePWChangeForConfirm = (event: { target: any; }) => {
        if (errTextWithPWC !== "" && event.target.value.length > 0) setErrTextWithPWC("")
        setUserPWC(event.target.value)
    }

    const handlePNChange = (event: { target: any; }) => {
        const onlyNumber = event.target.value.replace(/[^0-9]/g, '');
        if (errTextWithPN !== "" && onlyNumber.length > 0) setErrTextWithPN("")
        setUserPN(onlyNumber)
    }

    const handleClickShowPassword = () => {
        setIsShowPassword(!isShowPassword)
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    const handleBirthDateChange = (event: { target: any; }) => {
        const onlyNumber = event.target.value.replace(/[^0-9]/g, '')
        if (errTextWithBirthDate !== "" && onlyNumber.length > 0) setErrTextWithBirthDate("")
        setUserBirthDate(onlyNumber)
    }

    const isAllFilled = () => {
        if (userName === "" || userID === "" || userPW === "" || userBirthDate === "" || userPN === "") return false
        if (userName.length < 0 || userName.length > 20) return false
        if (userID.length < 0 || userID.length > 50) return false
        if (userPW.length < 6 || userPW.length > 20) return false
        if (userPWC !== userPW) return false
        if (userBirthDate.length !== 8) return false
        if (userPN.length !== 11) return false

        return true
    }

    const validateErrHandle = (errMessage: any) => {
        const errInfo = validateErrParse(errMessage)
        if (errInfo.from === '"name"') {
            setErrTextWithName(errInfo.message)
        } else if (errInfo.from === '"email"') {
            setErrTextWithID(errInfo.message)
        } else if (errInfo.from === '"password"') {
            setErrTextWithPW(errInfo.message)
        } else if (errInfo.from === '"phoneNumber"') {
            setErrTextWithPN(errInfo.message)
        } else if (errInfo.from === '"birthDate"') {
            setErrTextWithBirthDate(errInfo.message)
        } else {
            setErrTextWithName("ValidationError")
            setErrTextWithID("ValidationError")
            setErrTextWithPW("ValidationError")
            setErrTextWithPN("ValidationError")
            setErrTextWithBirthDate("ValidationError")
        }
    }

    const validateErrParse = (errMessage: any) => {
        let errInfo = {
            from: "",
            message: "",
        }

        const errSplitList = errMessage.split(' ')
        const errSplitListLen = errSplitList.length

        if (errSplitListLen > 0) {
            errInfo.from = errSplitList[0]
            errInfo.message = errSplitList.slice(1).join(' ')
        }

        return errInfo
    }


    const summitSignUpInfo = async () => {
        if (!isAllFilled()) return
        const signUpInfo = {
            name: userName,
            email: userID,
            password: userPW,
            phoneNumber: userPN,
            birthDate: userBirthDate
        }
        const curSchema = getSchema('/auth/signUp', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(signUpInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                validateErrHandle(err.message)
                return
            }
        } else {
            return
        }

        const res = await postRequest(
            '/auth/signUp',
            signUpInfo
        )
        if (res !== null) {
            if (res.status === 201) {
                handleSignUpCompletePopupOpen()
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                validateErrHandle(res.data.message)
            } else if (res.status === 409 && res.data.name === "SequelizeUniqueConstraintError") {
                setErrTextWithID("이미 가입된 계정이 있습니다. 다른 ID를 사용해주세요!")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    useEffect(() => {
        if (userPWC !== userPW)
            setErrTextWithPWC("비밀번호가 일치하지 않습니다.")
        else
            setErrTextWithPWC("")
    }, [userPWC])

    useEffect(() => {
        signCheck().then((isSignIn) => {
            if (isSignIn) {
                if (window.confirm("이미 로그인 되어있습니다. 로그아웃하고 새로운 계정을 생성하시겠습니까?")) {
                    removeToken()
                } else {
                    history.push("/")
                    window.location.reload()
                }
            }
        });

    }, [])

    return (
        <>
            <WrapDiv>
                <ElevatedCard>
                    <CardTitle>회원가입</CardTitle>
                    <CardSubTitle>PortValue에 오신걸 환영합니다🎉</CardSubTitle>
                    <TextField
                        className={classes.textField}
                        error={errTextWithName !== "" ? true : false}
                        fullWidth
                        label="이름"
                        color="primary"
                        variant="outlined"
                        helperText={errTextWithName}
                        value={userName}
                        onChange={handleNameChange}
                        inputProps={{
                            maxLength: 10,
                        }}
                    />
                    <TextField
                        className={classes.textField}
                        error={errTextWithID !== "" ? true : false}
                        fullWidth
                        label="이메일 혹은 아이디"
                        color="primary"
                        variant="outlined"
                        helperText={errTextWithID}
                        value={userID}
                        onChange={handleIDChange}
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                    <TextField
                        className={classes.textField}
                        error={errTextWithPW !== "" ? true : false}
                        type={isShowPassword ? 'text' : 'password'}
                        fullWidth
                        label="비밀번호"
                        color="primary"
                        variant="outlined"
                        helperText={errTextWithPW}
                        value={userPW}
                        onChange={handlePWChange}
                        inputProps={{
                            maxLength: 20,
                        }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isShowPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>,
                        }}
                    />
                    <TextField
                        className={classes.textField}
                        error={errTextWithPWC !== "" ? true : false}
                        type={isShowPassword ? 'text' : 'password'}
                        fullWidth
                        label="비밀번호 확인"
                        color="primary"
                        variant="outlined"
                        helperText={errTextWithPWC}
                        value={userPWC}
                        onChange={handlePWChangeForConfirm}
                        inputProps={{
                            maxLength: 20,
                        }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {isShowPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>,
                        }}
                    />
                    <TextField
                        className={classes.textField}
                        error={errTextWithBirthDate !== "" ? true : false}
                        fullWidth
                        label="생년월일(8자리)"
                        color="primary"
                        variant="outlined"
                        helperText={errTextWithBirthDate}
                        value={userBirthDate}
                        onChange={handleBirthDateChange}
                        inputProps={{
                            maxLength: 8,
                        }}
                    />
                    <TextField
                        className={classes.textField}
                        error={errTextWithPN !== "" ? true : false}
                        fullWidth
                        label="핸드폰 번호(숫자 11자리)"
                        color="primary"
                        variant="outlined"
                        helperText={errTextWithPN}
                        value={userPN}
                        onChange={handlePNChange}
                        inputProps={{
                            maxLength: 11,
                        }}
                    />
                    <SummitBtn
                        active={isAllFilled()}
                        onClick={() => { if (isAllFilled()) summitSignUpInfo() }}
                    >회원가입</SummitBtn>
                </ElevatedCard>
            </WrapDiv>

            {
                <>
                    <OneBtnPopup
                        open={isSignUpCompletePopupOpen}
                        onClickEventFirst={handleSignUpCompletePopupClose}
                        btnTextFirst="확인"
                        content="회원가입이 완료되었습니다. 가입하신 계정으로 로그인 해주세요 :)"
                    />
                </>
            }
        </>
    )
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`
const ElevatedCard = Styled.div`
    width: 100%;
    max-width: 376px;
    position: fixed;
    left: 50%;
    top: 50%; 
    transform: translate(-50%,-50%);
    background: #FFFFFF;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 16px;
`
const CardTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 133.4%;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
`
const CardSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
    text-align: left;
`
const SummitBtn = Styled.div`
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    background: ${(props: { active: any; }) => (props.active ? "#1976D2" : "rgba(0, 0, 0, 0.12)")};
    color: ${(props: { active: any; }) => (props.active ? "#FFFFFF" : "rgba(0, 0, 0, 0.26)")};
`

export default SignUp;