import React from 'react';
import './asset/Styles/global.css'
import { ThemeProvider } from 'styled-components'
import { theme } from './asset/Styles/theme'
import Routers from './router/index';
import Styled from 'styled-components'

function App() {


  return (
    <ThemeProvider theme={theme}>
      <AppBodyContainer>
        <Routers /> {/*  src/Router/Routers.tsx 에 라우터 정의  */}
      </AppBodyContainer>
    </ThemeProvider>
  );
}


const AppBodyContainer = Styled.div`
  min-width: 320px;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: #FDFDFD;
  margin-right: constant(safe-area-inset-right);
  margin-left: constant(safe-area-inset-left);
  margin-bottom: constant(safe-area-inset-bottom);
  margin-top: constant(safe-area-inset-top);
  margin-right: env(safe-area-inset-right);
  margin-left: env(safe-area-inset-left);
  margin-bottom: env(safe-area-inset-bottom);
  margin-top: env(safe-area-inset-top);
`

export default App;
