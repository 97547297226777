import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import { getToken } from 'utils/token';
import { getRequest, deleteRequestWithToken } from 'utils/APIRequest';

import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import PrimaryButton from 'component/button/PrimaryButton';
import BannerCreationPopup from 'component/dialog/BannerCreationPopup';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

const drawerWidth = 300;
const isMobileDevice = isMobile()
const today = new Date();

const DSBBanner = () => {
    const history = useHistory()

    const [mainBannerList, setMainBannerList] = useState<any>([])
    const [mainBannerListInTable, setMainBannerListInTable] = useState<any>([])

    const [insertingQuery, setInsertingQuery] = useState<string>("")
    const [selectedMainBannerList, setSelectedMainBannerList] = useState<any>([])

    const [mainBannerForUpdate, setMainBannerForUpdate] = useState<any>("")

    const [mainBannerCreationPopupOpen, setBannerCreationPopupOpen] = useState<boolean>(false)
    const [mainBannerUpdatePopupOpen, setBannerUpdatePopup] = useState<boolean>(false)

    const handleBannerCreationPopupOpen = () => {
        setBannerCreationPopupOpen(true)
    }

    const handleBannerCreationPopupClose = () => {
        setBannerCreationPopupOpen(false)
    }

    const handleBannerUpdatePopup = (mainBanner: any) => {
        const curMainBanner = {
            mainBannerId: mainBanner.mainBannerId,
            name: mainBanner.name,
            thumbnailImagePath: mainBanner.thumbnailImagePath,
            contentImagePath: mainBanner.contentImagePath,
            startTime: mainBanner.startTime,
            endTime: mainBanner.endTime,
            buttonName: mainBanner.buttonName,
            buttonConnectionURL: mainBanner.buttonConnectionURL,
            order: mainBanner.order,
            exposureStatus: mainBanner.exposureStatus,
        }
        setMainBannerForUpdate(curMainBanner)
    }

    const handleMainBannerUpdatePopupClose = () => {
        setMainBannerForUpdate("")
        setBannerUpdatePopup(false)
    }

    const handleInsertingQueryChange = (mainBanner: React.ChangeEvent<HTMLInputElement>) => {
        setInsertingQuery(mainBanner.target.value)
    }

    const handleMainBannerListChange = (mainBanner: React.ChangeEvent<HTMLInputElement>) => {
        const curID = Number(mainBanner.target.id)
        let curList = selectedMainBannerList

        if (isSelectedMainBanner(curID))
            curList = selectedMainBannerList.filter((v: any) => v !== curID)
        else
            curList.push(curID)

        setSelectedMainBannerList([...curList])
    }

    const isSelectedMainBanner = (mainBannerId: number) => {
        let isSelected = false

        for (let i = 0; i < selectedMainBannerList.length; i++)
            if (selectedMainBannerList[i] === mainBannerId)
                isSelected = true

        return isSelected
    }

    const deleteMainBannerList = async () => {
        if (selectedMainBannerList === undefined || selectedMainBannerList.length === 0) {
            alert("선택된 MainBanner이 없습니다.")
            return
        }

        const curToken = getToken()
        if (curToken === '') return

        const mainBannerIdListInfo = {
            mainBannerIdList: selectedMainBannerList
        }

        const res = await deleteRequestWithToken(
            '/mainBanners',
            mainBannerIdListInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                getMainBannerList()
                setSelectedMainBannerList([])
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
            } else if (res.status === 204) {
                setSelectedMainBannerList([])
                return
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getMainBannerList = async () => {
        const res = await getRequest('/mainBanners')
        if (res !== null) {
            if (res.status === 200) {
                setMainBannerList([...res.data])
            } else if (res.status === 204) {
                setMainBannerList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    useEffect(() => {
        if (mainBannerCreationPopupOpen)
            return

        getMainBannerList()
    }, [mainBannerCreationPopupOpen])

    useEffect(() => {
        if (mainBannerUpdatePopupOpen)
            return

        getMainBannerList()
    }, [mainBannerUpdatePopupOpen])

    useEffect(() => {
        if (mainBannerForUpdate == undefined || mainBannerForUpdate == "")
            return

        setBannerUpdatePopup(true)
    }, [mainBannerForUpdate])

    useEffect(() => {
        if (mainBannerList === undefined || mainBannerList.length === 0) {
            setMainBannerListInTable([])
            return
        }

        let curMainBannerList: any[] = mainBannerList

        curMainBannerList = curMainBannerList.filter((v: any) => {
            if (v.name.includes(insertingQuery))
                return v
        })
        setMainBannerListInTable([...curMainBannerList])
    }, [JSON.stringify(mainBannerList), insertingQuery])

    useEffect(() => {
        getMainBannerList()
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>배너</BoxTitle>
                        <BoxSubTitle>{"단성비 > 공통 컴포넌트 > 배너"}</BoxSubTitle>
                    </BoxDiv>
                    <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>배너 목록</BoxTitleKey>
                            <BoxTitleValue>
                                <TextField
                                    id="cc-search-textfield"
                                    size="small"
                                    label="배너명으로 검색"
                                    fullWidth
                                    value={insertingQuery}
                                    onChange={handleInsertingQueryChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxDivider />
                        <BoxTitleFlexDiv>
                            <TableKeyDiv>총 {mainBannerListInTable !== undefined ? mainBannerListInTable.length : 0}개</TableKeyDiv>
                            <TableValueDiv>
                                <FlexEndDiv>
                                    <PrimaryButton
                                        value="배너 추가"
                                        size="medium"
                                        color="primary"
                                        onClickEvent={() => handleBannerCreationPopupOpen()}
                                    />
                                    <BoxVirticalDivider />
                                    {
                                        selectedMainBannerList == undefined || selectedMainBannerList.length == 0 ?
                                            <PrimaryButton disabled={true} value="선택 삭제" size="medium" color="error" />
                                            :
                                            <PrimaryButton value="선택 삭제" size="medium" color="error" onClickEvent={() => deleteMainBannerList()} />
                                    }
                                </FlexEndDiv>
                            </TableValueDiv>
                        </BoxTitleFlexDiv>
                        {
                            mainBannerListInTable !== undefined && mainBannerListInTable.length !== 0 &&
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="mainBannerListInTable table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>배너 썸네일</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>배너명</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>배너 기간</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>정렬(오름차순)</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>공개 상태</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {mainBannerListInTable.map((row: any) => {
                                                const curEndTime = new Date(row.endTime !== undefined ? row.endTime : "1900-01-01")
                                                return (
                                                    <TableRow
                                                        key={row.mainBannerId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <Checkbox
                                                                id={row.mainBannerId}
                                                                checked={isSelectedMainBanner(row.mainBannerId)}
                                                                onChange={handleMainBannerListChange}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TableProfileImg src={row.thumbnailImagePath} alt={row.thumbnailImagePath} />
                                                        </TableCell>
                                                        <TableCell align="left">{row.name} </TableCell>
                                                        <TableCell align="left">{today > curEndTime ? "종료된 배너" : (row.startTime + ' TO ' + row.endTime)}</TableCell>
                                                        <TableCell align="left">{row.order}</TableCell>
                                                        <TableCell align="left">{row.exposureStatus}</TableCell>
                                                        <TableCell align="right">
                                                            <PrimaryButton
                                                                value="수정"
                                                                size="small"
                                                                color="success"
                                                                onClickEvent={() => {
                                                                    handleBannerUpdatePopup(row)
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        }
                    </BoxDiv>
                </WrapDiv>
            </Box>
            {
                <>
                    <BannerCreationPopup
                        open={mainBannerCreationPopupOpen}
                        purpose="추가"
                        onClickClose={handleBannerCreationPopupClose}
                        btnTextFirst="취소"
                        btnTextSecond="추가"
                    />
                </>
            }
            {
                <>
                    <BannerCreationPopup
                        open={mainBannerUpdatePopupOpen}
                        mainBannerForUpdate={mainBannerForUpdate}
                        purpose="수정"
                        onClickClose={handleMainBannerUpdatePopupClose}
                        btnTextFirst="취소"
                        btnTextSecond="수정"
                    />
                </>
            }
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const FlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const BoxVirticalDivider = Styled.div`
    width: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`
const TableProfileImg = Styled.img`
    width: 100px;
    height: 100px;
`
const FormGroupTitle = Styled.div`
    font-size: 16px;
    color: #666666;
    margin-top: 9px;
    margin-right: 20px;
`
const TableCellHighlightText = Styled.div`
    font-weight: 600;
    font-size: 18px;
    color: red;
`

export default DSBBanner;