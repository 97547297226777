import React, { useEffect, useState, useCallback } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { getToken } from 'utils/token';
import { getSchema, getRequest, postRequestWithToken, putRequestWithToken, postImageRequestWithToken } from 'utils/APIRequest';
import { isMobile } from 'utils/common';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from "dayjs";

interface EventClassificationOptionType {
    inputValue?: string;
    eventClassificationId: number;
    name: string;
}

const filterEventClassificationOption = createFilterOptions<EventClassificationOptionType>();

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

const today = new Date();
const isMobileDevice = isMobile()

interface Props {
    open?: any,
    eventForUpdate?: any,
    purpose?: any,
    onClickClose?: any,
    btnTextFirst?: any,
    btnTextSecond?: any,
}

const EventCreationPopup = ({ open, eventForUpdate, purpose, onClickClose, btnTextFirst, btnTextSecond }: Props) => {
    const classes = useStyles();
    const history = useHistory()

    const [popupPurpose, setPopupPurpose] = useState<any>("추가")
    const [eventClassificationList, setEventClassificationList] = useState<any>([])

    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [event, setEvent] = useState<any>(null)
    const [eventId, setEventId] = useState<number>(-1)
    const [eventClassification, setEventClassification] = useState<any>(null);
    const [eventClassificationId, setEventClassificationId] = useState<number>(-1)
    const [eventName, setEventName] = useState<string | null>("")
    const [thumbnailImagePath, setThumbnailImagePath] = useState<any>("")
    const [contentImagePath, setContentImagePath] = useState<any>("")
    const [startTime, setStartTime] = useState<any>("")
    const [endTime, setEndTime] = useState<any>("")
    const [buttonName, setButtonName] = useState<any>("")
    const [buttonConnectionURL, setButtonConnectionURL] = useState<any>("")
    const [order, setOrder] = useState<number>(1)
    const [exposureStatus, setExposureStatus] = useState<string>("HOLD")

    const {
        getRootProps: getRootThumbnailImagePathProps,
        getInputProps: getInputThumbnailImagePathProps,
        isDragActive: isThumbnailImagePathDragActive,
        isDragReject: isThumbnailImagePathDragReject,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 100 * 1024,
        multiple: false,
        onDropRejected: (rejectedFiles) => {
            if (rejectedFiles[0] !== undefined)
                alert(JSON.stringify(rejectedFiles[0].errors))
        },
        onDropAccepted: (acceptedFile) => {
            let formData = new FormData()
            formData.append('image', acceptedFile[0])
            postImage(formData, "thumbnailImagePath")
        },
    });

    const {
        getRootProps: getRootContentImagePathProps,
        getInputProps: getInputContentImagePathProps,
        isDragActive: isContentImagePathDragActive,
        isDragReject: isContentImagePathDragReject,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 1024 * 1024,
        multiple: false,
        onDropRejected: (rejectedFiles) => {
            if (rejectedFiles[0] !== undefined)
                alert(JSON.stringify(rejectedFiles[0].errors))
        },
        onDropAccepted: (acceptedFile) => {
            let formData = new FormData()
            formData.append('image', acceptedFile[0])
            postImage(formData, "contentImagePath")
        },
    });

    const postImage = async (formData: any, from: string) => {
        if (formData === undefined || !formData) return
        const curToken = getToken()
        if (curToken === '') return

        const res = await postImageRequestWithToken(
            formData,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                if (res.data.location === undefined) {
                    alert("이미지 경로를 찾을 수 없습니다. \n\n" + JSON.stringify(res.data))
                    return
                }
                if (from === "thumbnailImagePath") {
                    setThumbnailImagePath(res.data.location)
                } else {
                    setContentImagePath(res.data.location)
                }
            } else if (res.status === 409) {
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const createEvent = async () => {
        const curToken = getToken()
        if (curToken === '') return

        const eventInfo = {
            eventClassificationId: event.eventClassificationId,
            name: event.name,
            thumbnailImagePath: event.thumbnailImagePath,
            contentImagePath: event.contentImagePath,
            startTime: event.startTime,
            endTime: event.endTime,
            buttonName: event.buttonName,
            buttonConnectionURL: event.buttonConnectionURL,
            order: event.order,
            exposureStatus: event.exposureStatus,
        }
        const curSchema = getSchema('/events', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(eventInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/events',
            eventInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                clearInputData()
                onClickClose()
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateEvent = async () => {
        if (event.eventId === undefined || event.eventId === "" || event.eventId === -1) return
        const curToken = getToken()
        if (curToken === '') return

        const eventInfo = {
            eventClassificationId: event.eventClassificationId,
            name: event.name,
            thumbnailImagePath: event.thumbnailImagePath,
            contentImagePath: event.contentImagePath,
            startTime: event.startTime,
            endTime: event.endTime,
            buttonName: event.buttonName,
            buttonConnectionURL: event.buttonConnectionURL,
            order: event.order,
            exposureStatus: event.exposureStatus,
        }
        const curSchema = getSchema('/events', 'PUT')

        if (curSchema) {
            try {
                await curSchema.validateAsync(eventInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            `/events/${event.eventId}`,
            eventInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                clearInputData()
                onClickClose()
            } else if (res.status === 204) {
                alert("event ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const clearInputData = () => {
        setEvent(null)
        setEventId(-1)
        setEventClassification(null)
        setEventName("")
        setThumbnailImagePath("")
        setContentImagePath("")
        setStartTime("")
        setEndTime("")
        setButtonName("")
        setButtonConnectionURL("")
        setOrder(1)
        setExposureStatus("HOLD")
    }

    const handleEventNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEventName(event.target.value)
    }

    const handleButtonNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setButtonName(event.target.value)
    }

    const handleButtonConnectionURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setButtonConnectionURL(event.target.value)
    }

    const handleOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrder(Number(event.target.value))
    }

    const handleExposureStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isAllFilled) setExposureStatus(event.target.value)
        else alert("공개 상태를 변경하기 위해서는 필수항목이 채워져있어야 합니다 :)")
    }

    const getEventClassificationList = async () => {
        const res = await getRequest('/eventClassifications')
        if (res !== null) {
            if (res.status === 200) {
                setEventClassificationList([...res.data])
            } else if (res.status === 204) {
                setEventClassificationList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addEventClassification = async (eventClassification: EventClassificationOptionType) => {
        if (eventClassification.name === undefined || eventClassification.name === "") return
        if (eventClassification.eventClassificationId === undefined || eventClassification.eventClassificationId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const eventClassificationInfo = { name: eventClassification.name }
        const curSchema = getSchema('/eventClassifications', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(eventClassificationInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/eventClassifications',
            eventClassificationInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getEventClassificationList()
                const createdEventClassification = res.data
                setEventClassification({
                    eventClassificationId: createdEventClassification.eventClassificationId,
                    name: createdEventClassification.name
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getEventClassificationName = (curId: number) => {
        for (let i = 0; i < eventClassificationList.length; i++) {
            if (eventClassificationList[i] === undefined) continue
            if (eventClassificationList[i].eventClassificationId === curId) return eventClassificationList[i].name
        }
        return "알수없는 ID"
    }

    useEffect(() => {
        if (!eventClassification)
            return
        if (eventClassification.eventClassificationId !== undefined && eventClassification.eventClassificationId === -1)
            addEventClassification(eventClassification)

        setEventClassificationId(eventClassification.eventClassificationId)
    }, [JSON.stringify(eventClassification)])

    useEffect(() => {
        if (eventName === undefined || eventName === "") {
            setIsAllFilled(false)
            return
        }
        if (eventClassificationId === undefined || eventClassificationId <= 0) {
            setIsAllFilled(false)
            return
        }
        if (thumbnailImagePath === undefined || thumbnailImagePath === "") {
            setIsAllFilled(false)
            return
        }
        if (contentImagePath === undefined || contentImagePath === "") {
            setIsAllFilled(false)
            return
        }
        if (startTime === undefined || startTime === "" || startTime === "Invalid Date") {
            setIsAllFilled(false)
            return
        }
        if (endTime === undefined || endTime === "" || endTime === "Invalid Date") {
            setIsAllFilled(false)
            return
        }
        const startTimeDate = new Date(startTime)
        const endTimeDate = new Date(endTime)
        if (endTimeDate < startTimeDate) {
            alert("이벤트 시작 시각이 종료 시각보다 늦습니다.")
            setIsAllFilled(false)
            return
        }
        if (endTimeDate < today) {
            alert("이벤트 종료 시각이 현재 시각보다 빠릅니다.")
            setIsAllFilled(false)
            return
        }
        const curEvent = {
            eventId: eventId,
            eventClassificationId: eventClassificationId,
            name: eventName,
            thumbnailImagePath: thumbnailImagePath,
            contentImagePath: contentImagePath,
            startTime: startTime,
            endTime: endTime,
            buttonName: buttonName,
            buttonConnectionURL: buttonConnectionURL,
            order: order,
            exposureStatus: exposureStatus,
        }

        if (eventForUpdate !== undefined && JSON.stringify(curEvent) === JSON.stringify(eventForUpdate)) {
            setIsAllFilled(false)
            return
        }

        setEvent(curEvent)
        setIsAllFilled(true)

    }, [eventName, eventClassificationId, thumbnailImagePath, contentImagePath, startTime, endTime, buttonName, buttonConnectionURL, order, exposureStatus])

    useEffect(() => {
        if (open) return
        clearInputData()
    }, [open])

    useEffect(() => {
        if (eventForUpdate !== undefined && eventForUpdate !== "") {
            setEventId(eventForUpdate.eventId)
            if (eventForUpdate.eventClassificationId !== undefined && eventForUpdate.eventClassificationId !== null) {
                setEventClassification({
                    eventClassificationId: eventForUpdate.eventClassificationId,
                    name: getEventClassificationName(eventForUpdate.eventClassificationId)
                })
            }
            setEventName(eventForUpdate.name)
            setThumbnailImagePath(eventForUpdate.thumbnailImagePath)
            setContentImagePath(eventForUpdate.contentImagePath)
            setStartTime(eventForUpdate.startTime)
            setEndTime(eventForUpdate.endTime)
            setButtonName(eventForUpdate.buttonName)
            setButtonConnectionURL(eventForUpdate.buttonConnectionURL)
            setOrder(eventForUpdate.order)
            setExposureStatus(eventForUpdate.exposureStatus)
        }
    }, [JSON.stringify(eventForUpdate)])

    useEffect(() => {
        getEventClassificationList()
        if (purpose !== undefined)
            setPopupPurpose(purpose)
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>이벤트 {popupPurpose}</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>이벤트 종류 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <Autocomplete
                                                id="eventClassification"
                                                value={eventClassification}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        setEventClassification({
                                                            eventClassificationId: -1,
                                                            name: newValue.inputValue,
                                                        });
                                                    } else {
                                                        setEventClassification(newValue);
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filterEventClassificationOption(options, params);

                                                    const { inputValue } = params;
                                                    const isExisting = options.some((option) => inputValue === option.name);
                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            name: `"${inputValue}" 추가!`,
                                                            eventClassificationId: -1
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                options={eventClassificationList}
                                                getOptionLabel={(option) => {
                                                    return option.name;
                                                }}
                                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        size="small"
                                                        fullWidth
                                                        label='이벤트 종류 선택'
                                                    />
                                                )}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>이벤트명 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="eventName"
                                                size="small"
                                                fullWidth
                                                label='이벤트명'
                                                value={eventName}
                                                onChange={handleEventNameChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>썸네일 이미지 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            {
                                                thumbnailImagePath !== undefined && thumbnailImagePath !== null && thumbnailImagePath !== "" ?
                                                    <DropzonePreviewDiv>
                                                        <ProfileImageDiv>
                                                            <ProfileImage src={thumbnailImagePath} alt={thumbnailImagePath} />
                                                            <ImageCancleBtn onClick={() => setThumbnailImagePath("")}>삭제</ImageCancleBtn>
                                                        </ProfileImageDiv>
                                                    </DropzonePreviewDiv>
                                                    :
                                                    <DropzoneDiv {...getRootThumbnailImagePathProps()}>
                                                        <input {...getInputThumbnailImagePathProps()} />
                                                        {
                                                            isThumbnailImagePathDragReject ?
                                                                <DropzoneRejectDiv>
                                                                    <div>1. File Size는 100KB 이하</div>
                                                                    <div>2. 이미지 업로드는 한장만 허용</div>
                                                                    <div>3. 이미지 파일 타입 에러는 개발자에게 문의 바람</div>
                                                                </DropzoneRejectDiv>
                                                                :
                                                                <>
                                                                    {
                                                                        isThumbnailImagePathDragActive ?
                                                                            <DropzoneHelpDiv>추가할 이미지를 여기로 드랍해주세요 :)</DropzoneHelpDiv> :
                                                                            <DropzoneHelpDiv>
                                                                                <div>이미지를 드래그 후 드랍하거나 여기를 클릭해서 추가해주세요 :)</div>
                                                                            </DropzoneHelpDiv>
                                                                    }
                                                                </>
                                                        }
                                                    </DropzoneDiv>
                                            }
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>내용 이미지 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            {
                                                contentImagePath !== undefined && contentImagePath !== null && contentImagePath !== "" ?
                                                    <DropzonePreviewDiv>
                                                        <ProfileImageDiv>
                                                            <ProfileImage src={contentImagePath} alt={contentImagePath} />
                                                            <ImageCancleBtn onClick={() => setContentImagePath("")}>삭제</ImageCancleBtn>
                                                        </ProfileImageDiv>
                                                    </DropzonePreviewDiv>
                                                    :
                                                    <DropzoneDiv {...getRootContentImagePathProps()}>
                                                        <input {...getInputContentImagePathProps()} />
                                                        {
                                                            isContentImagePathDragReject ?
                                                                <DropzoneRejectDiv>
                                                                    <div>1. File Size는 1MB 이하</div>
                                                                    <div>2. 이미지 업로드는 한장만 허용</div>
                                                                    <div>3. 이미지 파일 타입 에러는 개발자에게 문의 바람</div>
                                                                </DropzoneRejectDiv>
                                                                :
                                                                <>
                                                                    {
                                                                        isContentImagePathDragActive ?
                                                                            <DropzoneHelpDiv>추가할 이미지를 여기로 드랍해주세요 :)</DropzoneHelpDiv> :
                                                                            <DropzoneHelpDiv>
                                                                                <div>이미지를 드래그 후 드랍하거나 여기를 클릭해서 추가해주세요 :)</div>
                                                                            </DropzoneHelpDiv>
                                                                    }
                                                                </>
                                                        }
                                                    </DropzoneDiv>
                                            }
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>이벤트 기간 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TimeRangeDiv>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="이벤트 시작"
                                                        value={startTime}
                                                        onChange={(newValue: any) => {
                                                            setStartTime(dayjs(newValue).format("YYYY-MM-DD"));
                                                        }}
                                                        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                <ToDiv>TO</ToDiv>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="이벤트 종료"
                                                        value={endTime}
                                                        onChange={(newValue: any) => {
                                                            setEndTime(dayjs(newValue).format("YYYY-MM-DD"));
                                                        }}
                                                        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </TimeRangeDiv>
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>순서</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="order"
                                                size="small"
                                                type="number"
                                                fullWidth
                                                label='이벤트 순서(오름차순, 1이상 정수 입력 필수)'
                                                value={order}
                                                onChange={handleOrderChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>'바로가기' 버튼명</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="buttonName"
                                                size="small"
                                                fullWidth
                                                label='버튼 가운데 표시되는 이름'
                                                value={buttonName}
                                                onChange={handleButtonNameChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>'바로가기' 연결 URL</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="buttonConnectionURL"
                                                size="small"
                                                fullWidth
                                                label='버튼 클릭시 이동하는 URL'
                                                value={buttonConnectionURL}
                                                onChange={handleButtonConnectionURLChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                        <BoxContentDiv>
                            <BoxContentFlexDiv>
                                <BoxContentInputFlexDiv width="100%">
                                    <BoxContentInputKeyDiv>공개 상태</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <RadioGroup
                                            row
                                            name="exposureStatus"
                                            value={exposureStatus}
                                            onChange={handleExposureStatusChange}
                                        >
                                            <FormControlLabel value="OPEN" control={<Radio />} label="공개" />
                                            {
                                                !isMobileDevice &&
                                                <FormControlLabelDivider />
                                            }
                                            <FormControlLabel value="CLOSE" control={<Radio />} label="비공개" />
                                            {
                                                !isMobileDevice &&
                                                <FormControlLabelDivider />
                                            }
                                            <FormControlLabel value="HOLD" control={<Radio />} label="보류" />
                                        </RadioGroup>
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexDiv>
                        </BoxContentDiv>
                        <BoxMarginDivider />
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton
                            variant="outlined"
                            value={btnTextFirst}
                            size="large"
                            color="primary"
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                        <VerticalDivider />
                        {
                            !isAllFilled ?
                                <PrimaryButton
                                    disabled={true}
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                />
                                :
                                <PrimaryButton
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                    onClickEvent={() => {
                                        if (purpose === "추가")
                                            createEvent()
                                        else
                                            updateEvent()
                                    }}
                                />
                        }
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
`
const BoxContentProfileDiv = Styled.div`
    width: 100%;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 140px;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 140px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const VerticalDivider = Styled.div`
    width: 20px;
`
const BoxFlexStartDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`
const DropzonePreviewDiv = Styled.div`
    width: 100%;
    height: 230px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #EBEBEB;
    border-radius: 20px;
`
const DropzoneDiv = Styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 3px #8fb7ff;
    border-radius: 20px;
`
const DropzoneHelpDiv = Styled.div`
    font-size: 25px;
    color: #8fb7ff;
`
const DropzoneRejectDiv = Styled.div`
    font-size: 25px;
    color: #fc5d6b;
`
const ProfileImageDiv = Styled.div`
    width: fit-content;
    height: 230px;
`
const ProfileImage = Styled.img`
    height: 200px;
    width: auto;
`
const ImageCancleBtn = Styled.div`
    width: 100%;
    color: #ffffff;
    background: #fc5d6b;
    border-radius: 10px;
    padding: 5px 0px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`
const FormControlLabelDivider = Styled.div`
    width: 15%;
`
const TimeRangeDiv = Styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const ToDiv = Styled.div`
    font-size: 20px;
    padding-right: 20px;
    padding-left: 20px;
`

export default EventCreationPopup