import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    basicWidth: '320px',
    color: {
        blue: '#327bff', 
        blue_2: '#327bff', 
        gray: '#c9c9c9',
        white: '#f2f2f2',
        red: '#ff5243',
        black: '#333333',
        gray_2: '#7b7b7b',
        yellow: '#f7931e'
    }
};

export { theme };