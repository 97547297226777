import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import { getToken } from 'utils/token';
import { isPositiveInteger } from 'utils/validator';
import { getRequest, deleteRequestWithToken } from 'utils/APIRequest';

import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import PrimaryButton from 'component/button/PrimaryButton';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

const drawerWidth = 300
const rowsPerPage = 10
const isMobileDevice = isMobile()

const DSBProductRead = () => {
    const history = useHistory()

    const [productList, setProductList] = useState<any>([])
    const [productListCount, setProductListCount] = useState<number>(0)
    const [productTablePage, setProductTablePage] = useState<number>(0)

    const [insertingQuery, setInsertingQuery] = useState<string>("")
    const [productType, setProductType] = useState<string>("PARENT")
    const [infoStatusList, setInfoStatusList] = useState<any>(["미흡", "충족"])
    const [exposureStatusList, setExposureStatusList] = useState<any>(["OPEN", "CLOSE", "HOLD"])

    const [isParentProduct, setIsParentProduct] = useState<boolean>(true)
    const [selectedProductList, setSelectedProductList] = useState<any>([])

    const handleProductTablePageChange = (event: unknown, newPage: number) => {
        setProductTablePage(newPage);
    }

    const handleInsertingQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInsertingQuery(event.target.value)
    }

    const handleProductTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProductType(event.target.value)
    }

    const handleInfoStatusListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let curInfoStatusList = infoStatusList

        if (isSelectedInfoStatus(event.target.name))
            curInfoStatusList = infoStatusList.filter((v: any) => v != event.target.name)
        else
            curInfoStatusList.push(event.target.name)

        setInfoStatusList([...curInfoStatusList])
    }

    const isSelectedInfoStatus = (name: string) => {
        let isSelected = false

        for (let i = 0; i < infoStatusList.length; i++)
            if (infoStatusList[i] === name)
                isSelected = true

        return isSelected
    }

    const handleExposureStatusListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let curExposureStatusList = exposureStatusList

        if (isSelectedExposureStatus(event.target.name))
            curExposureStatusList = exposureStatusList.filter((v: any) => v !== event.target.name)
        else
            curExposureStatusList.push(event.target.name)

        setExposureStatusList([...curExposureStatusList])
    }

    const isSelectedExposureStatus = (name: string) => {
        let isSelected = false

        for (let i = 0; i < exposureStatusList.length; i++)
            if (exposureStatusList[i] === name)
                isSelected = true

        return isSelected
    }

    const deleteProductList = async () => {
        if (selectedProductList === undefined || selectedProductList.length === 0) {
            alert("선택된 Product이 없습니다.")
            return
        }

        const curToken = getToken()
        if (curToken === '') return

        let pathname = "/products"
        let productIdListInfo = {}

        if (isParentProduct) {
            productIdListInfo = {
                parentProductIdList: selectedProductList
            }
        } else {
            pathname = pathname + "/childs"
            productIdListInfo = {
                childProductIdList: selectedProductList
            }
        }

        const res = await deleteRequestWithToken(
            pathname,
            productIdListInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                getProductList()
                setSelectedProductList([])
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
            } else if (res.status === 204) {
                setSelectedProductList([])
                return
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getProductList = async (page?: any) => {
        if (productTablePage === undefined || productTablePage === null) return
        if (productType === undefined) return
        if (insertingQuery === undefined) return
        if (infoStatusList === undefined) return
        if (exposureStatusList === undefined) return

        const curPage = page === undefined ? productTablePage : page
        if (page === undefined) {
            setProductListCount(0)
            setProductTablePage(0)
        }
        const isParentProductReq = productType === "PARENT" ? true : false
        let pathname = (isParentProductReq ? "/products?page=" : "/products/childs?page=") + curPage
        if (insertingQuery.length > 0)
            pathname = pathname + "&query=" + insertingQuery

        if (infoStatusList.length > 0) {
            pathname = pathname + "&infoStatusList=" + encodeURIComponent(JSON.stringify(infoStatusList))
        } else {
            setIsParentProduct(isParentProductReq)
            setProductListCount(0)
            setProductList([])
            return
        }

        if (exposureStatusList.length > 0) {
            pathname = pathname + "&exposureStatusList=" + encodeURIComponent(JSON.stringify(exposureStatusList))
        } else {
            setIsParentProduct(isParentProductReq)
            setProductListCount(0)
            setProductList([])
            return
        }

        const res = await getRequest(pathname)
        if (res !== null) {
            if (res.status === 200) {
                if (res.data.count !== undefined)
                    setProductListCount(res.data.count)
                if (res.data.productList !== undefined)
                    setProductList([...res.data.productList])
                setIsParentProduct(isParentProductReq)
                setSelectedProductList([])
            } else if (res.status === 204) {
                setProductList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const handleProductListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const curID = Number(event.target.id)
        let curList = selectedProductList

        if (isSelectedProduct(curID))
            curList = selectedProductList.filter((v: any) => v !== curID)
        else
            curList.push(curID)

        setSelectedProductList([...curList])
    }

    const isSelectedProduct = (productId: number) => {
        let isSelected = false

        for (let i = 0; i < selectedProductList.length; i++)
            if (selectedProductList[i] === productId)
                isSelected = true

        return isSelected
    }

    useEffect(() => {
        if (!isPositiveInteger(productTablePage)) return
        getProductList(productTablePage)
    }, [String(productTablePage)])

    useEffect(() => {
        getProductList()
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>상품 목록/수정</BoxTitle>
                        <BoxSubTitle>{"단성비 > 상품 관리 > 상품 목록/수정"}</BoxSubTitle>
                    </BoxDiv>
                    <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>상품 목록</BoxTitleKey>
                        </BoxTitleFlexDiv>
                        <BoxDivider />
                        <BoxFilterDiv
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    getProductList()
                                }
                            }}
                        >
                            <BoxFilterKeyDiv>
                                <BoxTitleFlexDiv>
                                    <FormControl>
                                        <FormGroup row>
                                            <FormGroupTitle>공개 상태</FormGroupTitle>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={isSelectedExposureStatus("OPEN")} onChange={handleExposureStatusListChange} name="OPEN" />
                                                }
                                                label="공개"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={isSelectedExposureStatus("CLOSE")} onChange={handleExposureStatusListChange} name="CLOSE" />
                                                }
                                                label="비공개"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={isSelectedExposureStatus("HOLD")} onChange={handleExposureStatusListChange} name="HOLD" />
                                                }
                                                label="보류"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            name="row-radio-buttons-group"
                                            value={productType}
                                            onChange={handleProductTypeChange}
                                        >
                                            <FormControlLabel value="PARENT" control={<Radio />} label="Parent 제품" />
                                            <FormControlLabel value="CHILD" control={<Radio />} label="Child 제품" />
                                        </RadioGroup>
                                    </FormControl>
                                </BoxTitleFlexDiv>
                                <BoxTitleFlexDiv>
                                    <FormControl>
                                        <FormGroup row>
                                            <FormGroupTitle>필수정보 상태</FormGroupTitle>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={isSelectedInfoStatus("충족")} onChange={handleInfoStatusListChange} name="충족" />
                                                }
                                                label="충족"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={isSelectedInfoStatus("미흡")} onChange={handleInfoStatusListChange} name="미흡" />
                                                }
                                                label="미흡"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <BoxTitleValue>
                                        <TextField
                                            id="cc-search-textfield"
                                            size="small"
                                            label="상품코드, 이름, 브랜드, 맛, 용량으로 상품 검색"
                                            fullWidth
                                            value={insertingQuery}
                                            onChange={handleInsertingQueryChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </BoxTitleValue>
                                </BoxTitleFlexDiv>
                            </BoxFilterKeyDiv>
                            <BoxFilterValueDiv onClick={(ev) => {
                                getProductList()
                                ev.preventDefault()
                            }
                            }>
                                {"필터링 적용"}
                            </BoxFilterValueDiv>
                        </BoxFilterDiv>
                        <BoxDivider />
                        <BoxTitleFlexDiv>
                            <TableKeyDiv>필터링된 {isParentProduct ? "Parent 상품: " : "Child 상품: "} 총 {productListCount !== undefined ? productListCount : 0}개</TableKeyDiv>
                            <TableValueDiv>
                                <FlexEndDiv>
                                    {
                                        selectedProductList === undefined || selectedProductList.length === 0 ?
                                            <PrimaryButton disabled={true} value="선택 삭제" size="medium" color="error" />
                                            :
                                            <PrimaryButton value="선택 삭제" size="medium" color="error" onClickEvent={() => deleteProductList()} />
                                    }
                                </FlexEndDiv>
                            </TableValueDiv>
                        </BoxTitleFlexDiv>
                        {
                            productList !== undefined && productList.length !== 0 &&
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="productList table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>상품코드</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>{isParentProduct ? "대표 이미지" : "이미지"}</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>브랜드</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>상품명</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>{isParentProduct ? "대표 '맛 / 용량'" : "맛 / 용량"}</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>공개 상태</TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {productList.map((row: any) => {
                                                let background = null
                                                if (row.isFilled === false)
                                                    background = "rgb(255 77 77 / 10%)"
                                                return (
                                                    <TableRow
                                                        key={isParentProduct ? row.parentProductId : row.childProductId}
                                                        sx={{ background, '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <Checkbox
                                                                id={isParentProduct ? row.parentProductId : row.childProductId}
                                                                checked={isSelectedProduct(isParentProduct ? row.parentProductId : row.childProductId)}
                                                                onChange={handleProductListChange}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">{row.productSerialNumber} </TableCell>
                                                        <TableCell align="left">
                                                            <TableProfileImg src={row.imagePath} alt={row.imagePath} />
                                                        </TableCell>
                                                        <TableCell align="left">{isParentProduct ? row.brandStr : row.parentProductBrand}</TableCell>
                                                        <TableCell align="left">{isParentProduct ? row.name : row.parentProductName}</TableCell>
                                                        <TableCell align="left">{`${row.flavorStr} / ${row.capacityStr}`}</TableCell>
                                                        <TableCell align="left">{row.exposureStatus}</TableCell>
                                                        <TableCell align="center">
                                                            <PrimaryButton
                                                                value="수정"
                                                                size="small"
                                                                color="success"
                                                                onClickEvent={() => {
                                                                    if (isParentProduct) {
                                                                        history.push("/dsb/product/upsert?parentProductId=" + row.parentProductId)
                                                                        window.location.reload()
                                                                    } else {
                                                                        history.push("/dsb/product/CPUpdate?childProductId=" + row.childProductId)
                                                                        window.location.reload()
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[rowsPerPage]}
                                    component="div"
                                    count={productListCount}
                                    rowsPerPage={rowsPerPage}
                                    page={productTablePage}
                                    onPageChange={handleProductTablePageChange}
                                />
                            </Paper>
                        }
                    </BoxDiv>
                </WrapDiv>
            </Box>
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const FlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`
const TableProfileImg = Styled.img`
    width: 100px;
    height: 100px;
`
const FormGroupTitle = Styled.div`
    font-size: 16px;
    color: #666666;
    margin-top: 9px;
    margin-right: 20px;
`
const BoxFilterDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const BoxFilterKeyDiv = Styled.div`
    width: calc(100% - 135px);
`
const BoxFilterValueDiv = Styled.div`
    width: 120px;
    margin-left: 15px;
    background: #327BFF;
    color: #FFFFFF;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin-bottom: 20px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`

export default DSBProductRead;