import React, { useEffect, useState } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { getSchema, getRequest } from 'utils/APIRequest';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

interface Props {
    open?: any,
    PPId: number,
    CPId: number,
    setCloningId?: any,
    onClickClose?: any,
}

const ChildProductClonePopup = ({ open, PPId, CPId, setCloningId, onClickClose }: Props) => {
    const classes = useStyles();
    const history = useHistory()

    const [CPList, setCPList] = useState<any>([])

    const getCPList = async (id: number) => {
        if (id === undefined || id === -1) return

        const res = await getRequest(`/products/${id}/childs`)
        if (res !== null) {
            if (res.status === 200) {
                setCPList([...res.data])
            } else if (res.status === 204) {
                setCPList([])
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    useEffect(() => {
        if (PPId === undefined || PPId <= 0) return
        getCPList(PPId)
    }, [String(PPId)])

    useEffect(() => {
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>Child Product 내용 복제 (프로필 정보 제외)</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxDivider />
                            <BoxTitleFlexDiv>
                                <TableKeyDiv>총 {CPList !== undefined ? CPList.length : 0}개</TableKeyDiv>
                                <TableValueDiv>
                                </TableValueDiv>
                            </BoxTitleFlexDiv>
                            {
                                CPList !== undefined && CPList.length !== 0 &&
                                <Paper sx={{ width: '100%', mb: 2 }}>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="CPList table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>상품 시리얼 넘버</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>이미지</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>맛</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>용량</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>공개 상태</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>필수정보</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {CPList.map((row: any, index: number) => (
                                                    <TableRow
                                                        key={row.productSerialNumber}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="left">{row.productSerialNumber} </TableCell>
                                                        <TableCell align="left">
                                                            <TableProfileImg src={row.imagePath} alt={row.imagePath} />
                                                        </TableCell>
                                                        <TableCell align="left">{row.flavorStr}</TableCell>
                                                        <TableCell align="left">{row.capacityStr}</TableCell>
                                                        <TableCell align="left">{row.exposureStatus}</TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                row.isFilled === false ?
                                                                    <TableCellHighlightText>{"미흡"}</TableCellHighlightText>
                                                                    :
                                                                    <>{"충족"}</>
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <PrimaryButtonListFlexDiv>
                                                                {
                                                                    CPId === row.childProductId ?
                                                                    <PrimaryButton
                                                                        value="자신 선택 불가능"
                                                                        size="small"
                                                                        color="success"
                                                                        disabled={true}
                                                                    />
                                                                    :
                                                                    <PrimaryButton
                                                                        value="복제 대상으로 선택"
                                                                        size="small"
                                                                        color="success"
                                                                        onClickEvent={() => {
                                                                            setCloningId(row.childProductId)
                                                                            onClickClose()
                                                                        }} 
                                                                    />
                                                                }
                                                            </PrimaryButtonListFlexDiv>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            }
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton
                            variant="outlined"
                            value={"취소"}
                            size="large"
                            color="primary"
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`
const TableProfileImg = Styled.img`
    width: 100px;
    height: 100px;
`
const TableCellHighlightText = Styled.div`
    font-weight: 600;
    font-size: 18px;
    color: red;
`
const PrimaryButtonListFlexDiv = Styled.div`
    display: flex;
    justify-content: center;
`

export default ChildProductClonePopup