import React, { useEffect, useState, useCallback } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { getToken } from 'utils/token';
import { isPositiveInteger } from 'utils/validator';
import { getSchema, getRequest, postRequestWithToken, putRequestWithToken, postImageRequestWithToken } from 'utils/APIRequest';
import { isMobile } from 'utils/common';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import InputAdornment from '@mui/material/InputAdornment';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1500px",
        }
    }
});

const isMobileDevice = isMobile()
const rowsPerPage = 10

interface Props {
    open?: any,
    recvDansungbeePickId?: any,
    purpose?: any,
    onClickClose?: any,
    btnTextFirst?: any,
    btnTextSecond?: any,
}

const DansungbeePickCreationPopup = ({ open, recvDansungbeePickId, purpose, onClickClose, btnTextFirst, btnTextSecond }: Props) => {
    const classes = useStyles();
    const history = useHistory()

    const [popupPurpose, setPopupPurpose] = useState<any>("추가")

    const [productList, setProductList] = useState<any>([])
    const [productListCount, setProductListCount] = useState<number>(0)
    const [productTablePage, setProductTablePage] = useState<number>(0)

    const [insertingQuery, setInsertingQuery] = useState<string>("")

    const [dansungbeePickForUpdate, setDansungbeePickForUpdate] = useState<any>(null)

    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [dansungbeePick, setDansungbeePick] = useState<any>(null)
    const [dansungbeePickId, setDansungbeePickId] = useState<number>(-1)
    const [dansungbeePickTitle, setDansungbeePickTitle] = useState<string | null>("")
    const [dansungbeePickDescription, setDansungbeePickDescription] = useState<string | null>("")
    const [imagePath, setImagePath] = useState<any>("")
    const [recvRegisteredChildProductList, setRecvRegisteredChildProductList] = useState<any>([])
    const [registeredChildProductList, setRegisteredChildProductList] = useState<any>([])

    const [exposureStatus, setExposureStatus] = useState<string>("HOLD")

    const {
        getRootProps: getRootImagePathProps,
        getInputProps: getInputImagePathProps,
        isDragActive: isImagePathDragActive,
        isDragReject: isImagePathDragReject,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 100 * 1024,
        multiple: false,
        onDropRejected: (rejectedFiles) => {
            if (rejectedFiles[0] !== undefined)
                alert(JSON.stringify(rejectedFiles[0].errors))
        },
        onDropAccepted: (acceptedFile) => {
            let formData = new FormData()
            formData.append('image', acceptedFile[0])
            postImage(formData)
        },
    });

    const postImage = async (formData: any) => {
        if (formData === undefined || !formData) return
        const curToken = getToken()
        if (curToken === '') return

        const res = await postImageRequestWithToken(
            formData,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                if (res.data.location === undefined) {
                    alert("이미지 경로를 찾을 수 없습니다. \n\n" + JSON.stringify(res.data))
                    return
                }
                setImagePath(res.data.location)
            } else if (res.status === 409) {
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getDansungbeePick = async (id: number) => {
        if(id === undefined || id <= 0) return

        const res = await getRequest(`/dansungbeePicks/${id}`)
        if (res !== null) {
            if (res.status === 200) {
                const curDP = res.data
                setDansungbeePickForUpdate({
                    dansungbeePickId: curDP.dansungbeePickId,
                    title: curDP.title,
                    description: curDP.description,
                    imagePath: curDP.imagePath,
                    childProductIdList: curDP.childProductIdList,
                    exposureStatus: curDP.exposureStatus,
                })
            } else if (res.status === 204) {
                setDansungbeePickForUpdate(null)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const createDansungbeePick = async () => {
        const curToken = getToken()
        if (curToken === '') return

        const dansungbeePickInfo = {
            title: dansungbeePick.title,
            description: dansungbeePick.description,
            imagePath: dansungbeePick.imagePath,
            childProductIdList: dansungbeePick.childProductIdList,
            exposureStatus: dansungbeePick.exposureStatus,
        }
        const curSchema = getSchema('/dansungbeePicks', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(dansungbeePickInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/dansungbeePicks',
            dansungbeePickInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                clearInputData()
                onClickClose()
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateDansungbeePick = async () => {
        if (dansungbeePick.dansungbeePickId === undefined || dansungbeePick.dansungbeePickId === "" || dansungbeePick.dansungbeePickId === -1) return
        const curToken = getToken()
        if (curToken === '') return

        const dansungbeePickInfo = {
            title: dansungbeePick.title,
            description: dansungbeePick.description,
            imagePath: dansungbeePick.imagePath,
            childProductIdList: dansungbeePick.childProductIdList,
            exposureStatus: dansungbeePick.exposureStatus,
        }
        const curSchema = getSchema('/dansungbeePicks', 'PUT')

        if (curSchema) {
            try {
                await curSchema.validateAsync(dansungbeePickInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            `/dansungbeePicks/${dansungbeePick.dansungbeePickId}`,
            dansungbeePickInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                clearInputData()
                onClickClose()
            } else if (res.status === 204) {
                alert("dansungbeePick ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const clearInputData = () => {
        setProductList([])
        setProductListCount(0)
        setProductTablePage(0)

        setDansungbeePickForUpdate(null)
        setDansungbeePick(null)
        setDansungbeePickId(-1)
        setDansungbeePickTitle("")
        setDansungbeePickDescription("")
        setImagePath("")
        setRegisteredChildProductList([])
        setRecvRegisteredChildProductList([])
        setExposureStatus("HOLD")
    }

    const handleDansungbeePickTitleChange = (dansungbeePick: React.ChangeEvent<HTMLInputElement>) => {
        setDansungbeePickTitle(dansungbeePick.target.value)
    }

    const handleDansungbeePickDescriptionChange = (dansungbeePick: React.ChangeEvent<HTMLInputElement>) => {
        setDansungbeePickDescription(dansungbeePick.target.value)
    }

    const handleExposureStatusChange = (dansungbeePick: React.ChangeEvent<HTMLInputElement>) => {
        if (isAllFilled) setExposureStatus(dansungbeePick.target.value)
        else alert("공개 상태를 변경하기 위해서는 필수항목이 채워져있어야 합니다 :)")
    }

    const handleInsertingQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInsertingQuery(event.target.value)
    }

    const handleProductTablePageChange = (event: unknown, newPage: number) => {
        setProductTablePage(newPage);
    }

    const getProductList = async (page?: any) => {
        if (productTablePage === undefined || productTablePage === null) return
        if (insertingQuery === undefined) return

        const curPage = page === undefined ? productTablePage : page
        if (page === undefined) {
            setProductListCount(0)
            setProductTablePage(0)
        }
        let pathname = "/products/childs?page=" + curPage
        if (insertingQuery.length > 0)
            pathname = pathname + "&query=" + insertingQuery

        const res = await getRequest(pathname)
        if (res !== null) {
            if (res.status === 200) {
                if (res.data.count !== undefined)
                    setProductListCount(res.data.count)
                if (res.data.productList !== undefined)
                    setProductList([...res.data.productList])
            } else if (res.status === 204) {
                setProductList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const registerChildProduct = (product: any) => {
        if (product === undefined || product === null) return
        let curList = registeredChildProductList

        for (let i = 0; i < curList.length; i++) {
            if (curList[i].childProductId === product.childProductId) {
                alert("입력하신 항목을 이미 등록했습니다.")
                return
            }
        }
        curList.push(product)
        setRegisteredChildProductList([...curList])
    }

    const unRegisterChildProduct = (product: any) => {
        if (product === undefined || product === null) return
        let curList = []
        curList = registeredChildProductList.filter((v: any) => v.childProductId !== product.childProductId)
        setRegisteredChildProductList([...curList])
    }

    const getIdList = (productList: any) => {
        let curList = []
        curList = productList.map((v: any) => v.childProductId)
        return curList
    }

    const getProductListwith = async (idList: any) => {
        if (idList === undefined || idList === null) return
        let pathname = "/products/childs/byIdList?childProductIdList=" + encodeURIComponent(JSON.stringify(idList))
        const res = await getRequest(pathname)
        if (res !== null) {
            if (res.status === 200) {
                setRegisteredChildProductList([...res.data])
            } else if (res.status === 204) {
                setRegisteredChildProductList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    useEffect(() => {
        if (dansungbeePickTitle === undefined || dansungbeePickTitle === "") {
            setIsAllFilled(false)
            return
        }
        if (dansungbeePickDescription === undefined || dansungbeePickDescription === "") {
            setIsAllFilled(false)
            return
        }
        if (registeredChildProductList === undefined || registeredChildProductList.length <= 0) {
            setIsAllFilled(false)
            return
        }
        if (imagePath === undefined || imagePath === "") {
            setIsAllFilled(false)
            return
        }

        const curDansungbeePick = {
            dansungbeePickId: dansungbeePickId,
            title: dansungbeePickTitle,
            description: dansungbeePickDescription,
            imagePath: imagePath,
            childProductIdList: getIdList(registeredChildProductList),
            exposureStatus: exposureStatus,
        }

        if (dansungbeePickForUpdate !== undefined && JSON.stringify(curDansungbeePick) === JSON.stringify(dansungbeePickForUpdate)) {
            setIsAllFilled(false)
            return
        }

        setDansungbeePick(curDansungbeePick)
        setIsAllFilled(true)

    }, [dansungbeePickTitle, dansungbeePickDescription, JSON.stringify(registeredChildProductList), imagePath, exposureStatus])

    useEffect(() => {
        if (open) return
        clearInputData()
    }, [open])

    useEffect(() => {
        if (dansungbeePickForUpdate !== undefined && dansungbeePickForUpdate !== null) {
            setDansungbeePickId(dansungbeePickForUpdate.dansungbeePickId)
            setDansungbeePickTitle(dansungbeePickForUpdate.title)
            setDansungbeePickDescription(dansungbeePickForUpdate.description)
            setImagePath(dansungbeePickForUpdate.imagePath)
            if (dansungbeePickForUpdate.childProductIdList !== undefined && dansungbeePickForUpdate.childProductIdList.length > 0) {
                setRecvRegisteredChildProductList([...dansungbeePickForUpdate.childProductIdList])
            }
            setExposureStatus(dansungbeePickForUpdate.exposureStatus)
        }
    }, [JSON.stringify(dansungbeePickForUpdate)])

    useEffect(() => {
        if (recvDansungbeePickId === undefined || recvDansungbeePickId === -1) return

        getDansungbeePick(recvDansungbeePickId)
    }, [String(recvDansungbeePickId)])

    useEffect(() => {
        if (recvRegisteredChildProductList === undefined || recvRegisteredChildProductList.length <= 0) return 
        getProductListwith(recvRegisteredChildProductList)
    }, [JSON.stringify(recvRegisteredChildProductList)])

    useEffect(() => {
        if (!isPositiveInteger(productTablePage)) return
        getProductList(productTablePage)
    }, [String(productTablePage)])


    useEffect(() => {
        getProductList()
        if (purpose !== undefined)
            setPopupPurpose(purpose)
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>단성비 Pick {popupPurpose}</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>단성비 Pick 타이틀 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="dansungbeePickTitle"
                                                size="small"
                                                fullWidth
                                                label='단성비 Pick 타이틀'
                                                value={dansungbeePickTitle}
                                                onChange={handleDansungbeePickTitleChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>단성비 Pick 설명 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="dansungbeePickDescription"
                                                size="small"
                                                fullWidth
                                                label='단성비 Pick 설명'
                                                value={dansungbeePickDescription}
                                                onChange={handleDansungbeePickDescriptionChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>단성비 Pick 이미지 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            {
                                                imagePath !== undefined && imagePath !== null && imagePath !== "" ?
                                                    <DropzonePreviewDiv>
                                                        <ProfileImageDiv>
                                                            <ProfileImage src={imagePath} alt={imagePath} />
                                                            <ImageCancleBtn onClick={() => setImagePath("")}>삭제</ImageCancleBtn>
                                                        </ProfileImageDiv>
                                                    </DropzonePreviewDiv>
                                                    :
                                                    <DropzoneDiv {...getRootImagePathProps()}>
                                                        <input {...getInputImagePathProps()} />
                                                        {
                                                            isImagePathDragReject ?
                                                                <DropzoneRejectDiv>
                                                                    <div>1. File Size는 100KB 이하</div>
                                                                    <div>2. 이미지 업로드는 한장만 허용</div>
                                                                    <div>3. 이미지 파일 타입 에러는 개발자에게 문의 바람</div>
                                                                </DropzoneRejectDiv>
                                                                :
                                                                <>
                                                                    {
                                                                        isImagePathDragActive ?
                                                                            <DropzoneHelpDiv>추가할 이미지를 여기로 드랍해주세요 :)</DropzoneHelpDiv> :
                                                                            <DropzoneHelpDiv>
                                                                                <div>이미지를 드래그 후 드랍하거나 여기를 클릭해서 추가해주세요 :)</div>
                                                                            </DropzoneHelpDiv>
                                                                    }
                                                                </>
                                                        }
                                                    </DropzoneDiv>
                                            }
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxMarginDivider />
                                <BoxContentFlexDiv>
                                    <BoxFlexStartDiv>
                                        <CPSelectionInputDiv>
                                        <BoxTitleFlexDiv>
                                            <BoxTitleKey>상품 목록</BoxTitleKey>
                                        </BoxTitleFlexDiv>
                                            <BoxDivider />
                                            <BoxFilterDiv
                                                onKeyPress={(ev) => {
                                                    if (ev.key === 'Enter') {
                                                        getProductList()
                                                    }
                                                }}
                                            >
                                                <BoxFilterKeyDiv>
                                                    <BoxTitleValue>
                                                        <TextField
                                                            id="cc-search-textfield"
                                                            size="small"
                                                            label="상품코드, 이름, 브랜드, 맛, 용량으로 상품 검색"
                                                            fullWidth
                                                            value={insertingQuery}
                                                            onChange={handleInsertingQueryChange}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </BoxTitleValue>
                                                </BoxFilterKeyDiv>
                                                <BoxFilterValueDiv onClick={(ev) => {
                                                    getProductList()
                                                    ev.preventDefault()
                                                }
                                                }>
                                                    {"필터링 적용"}
                                                </BoxFilterValueDiv>
                                            </BoxFilterDiv>
                                            <BoxDivider />
                                            <BoxTitleFlexDiv>
                                                <TableKeyDiv>필터링된 Child 상품: 총 {productListCount !== undefined ? productListCount : 0}개</TableKeyDiv>
                                                <TableValueDiv>
                                                </TableValueDiv>
                                            </BoxTitleFlexDiv>
                                            {
                                                productList !== undefined && productList.length !== 0 &&
                                                <Paper sx={{ width: '100%', mb: 2 }}>
                                                    <TableContainer>
                                                        <Table aria-label="productList table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>{"이미지"}</TableCell>
                                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>브랜드</TableCell>
                                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>상품명</TableCell>
                                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>{"맛/용량"}</TableCell>
                                                                    <TableCell align="center"></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {productList.map((row: any) => {
                                                                    let background = null
                                                                    if (row.isFilled === false)
                                                                        background = "rgb(255 77 77 / 10%)"
                                                                    return (
                                                                        <TableRow
                                                                            key={row.childProductId}
                                                                            sx={{ background, '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell align="left">
                                                                                <TableProfileImg src={row.imagePath} alt={row.imagePath} />
                                                                            </TableCell>
                                                                            <TableCell align="left">{row.parentProductBrand}</TableCell>
                                                                            <TableCell align="left">{row.parentProductName}</TableCell>
                                                                            <TableCell align="left">{`${row.flavorStr}/${row.capacityStr}`}</TableCell>
                                                                            <TableCell align="center">
                                                                                <PrimaryButton
                                                                                    value="등록"
                                                                                    size="small"
                                                                                    color="success"
                                                                                    onClickEvent={() => {
                                                                                        registerChildProduct(row)
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination
                                                        rowsPerPageOptions={[rowsPerPage]}
                                                        component="div"
                                                        count={productListCount}
                                                        rowsPerPage={rowsPerPage}
                                                        page={productTablePage}
                                                        onPageChange={handleProductTablePageChange}
                                                    />
                                                </Paper>
                                            }
                                        </CPSelectionInputDiv>
                                        <CPSelectionArrowDiv>
                                            <ArrowForwardDiv>
                                                <ArrowForwardRoundedIcon fontSize="inherit" />
                                            </ArrowForwardDiv>
                                        </CPSelectionArrowDiv>
                                        <CPSelectionOutputDiv>
                                            <BoxTitleFlexDiv>
                                                <BoxTitleKey>등록된 상품 정보</BoxTitleKey>
                                            </BoxTitleFlexDiv>
                                            <Paper sx={{ width: '100%', mb: 2 }}>
                                                <TableContainer>
                                                    <Table aria-label="registeredChildProductList table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>{"이미지"}</TableCell>
                                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>브랜드</TableCell>
                                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>상품명</TableCell>
                                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>{"맛/용량"}</TableCell>
                                                                <TableCell align="center"></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {registeredChildProductList.map((row: any) => {
                                                                let background = null
                                                                if (row.isFilled === false)
                                                                    background = "rgb(255 77 77 / 10%)"
                                                                return (
                                                                    <TableRow
                                                                        key={row.childProductId}
                                                                        sx={{ background, '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">
                                                                            <TableProfileImg src={row.imagePath} alt={row.imagePath} />
                                                                        </TableCell>
                                                                        <TableCell align="left">{row.parentProductBrand}</TableCell>
                                                                        <TableCell align="left">{row.parentProductName}</TableCell>
                                                                        <TableCell align="left">{`${row.flavorStr}/${row.capacityStr}`}</TableCell>
                                                                        <TableCell align="center">
                                                                            <PrimaryButton
                                                                                value="삭제"
                                                                                size="small"
                                                                                color="error"
                                                                                onClickEvent={() => {
                                                                                    unRegisterChildProduct(row)
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </CPSelectionOutputDiv>
                                    </BoxFlexStartDiv>
                                </BoxContentFlexDiv>
                                <BoxMarginDivider />
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                        <BoxMarginDivider />
                        <BoxContentDiv>
                            <BoxContentFlexDiv>
                                <BoxContentInputFlexDiv width="100%">
                                    <BoxContentInputKeyDiv>공개 상태</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <RadioGroup
                                            row
                                            name="exposureStatus"
                                            value={exposureStatus}
                                            onChange={handleExposureStatusChange}
                                        >
                                            <FormControlLabel value="OPEN" control={<Radio />} label="공개" />
                                            {
                                                !isMobileDevice &&
                                                <FormControlLabelDivider />
                                            }
                                            <FormControlLabel value="CLOSE" control={<Radio />} label="비공개" />
                                            {
                                                !isMobileDevice &&
                                                <FormControlLabelDivider />
                                            }
                                            <FormControlLabel value="HOLD" control={<Radio />} label="보류" />
                                        </RadioGroup>
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexDiv>
                        </BoxContentDiv>
                        <BoxMarginDivider />
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton
                            variant="outlined"
                            value={btnTextFirst}
                            size="large"
                            color="primary"
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                        <VerticalDivider />
                        {
                            !isAllFilled ?
                                <PrimaryButton
                                    disabled={true}
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                />
                                :
                                <PrimaryButton
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                    onClickEvent={() => {
                                        if (purpose === "추가")
                                            createDansungbeePick()
                                        else
                                            updateDansungbeePick()
                                    }}
                                />
                        }
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
`
const BoxContentProfileDiv = Styled.div`
    width: 100%;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 140px;
    text-align: left;
    margin-top: 9px;
`
const DansungbeePickContentTitleDiv = Styled.div`
    width: 100%;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 140px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const VerticalDivider = Styled.div`
    width: 20px;
`
const BoxFlexStartDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`
const DropzonePreviewDiv = Styled.div`
    width: 100%;
    height: 230px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #EBEBEB;
    border-radius: 20px;
`
const DropzoneDiv = Styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 3px #8fb7ff;
    border-radius: 20px;
`
const DropzoneHelpDiv = Styled.div`
    font-size: 25px;
    color: #8fb7ff;
`
const DropzoneRejectDiv = Styled.div`
    font-size: 25px;
    color: #fc5d6b;
`
const ProfileImageDiv = Styled.div`
    width: fit-content;
    height: 230px;
`
const ProfileImage = Styled.img`
    height: 200px;
    width: auto;
`
const ImageCancleBtn = Styled.div`
    width: 100%;
    color: #ffffff;
    background: #fc5d6b;
    border-radius: 10px;
    padding: 5px 0px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`
const FormControlLabelDivider = Styled.div`
    width: 15%;
`
const CPSelectionInputDiv = Styled.div`
    width: 47%;
`
const CPSelectionArrowDiv = Styled.div`
    width: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const ArrowForwardDiv = Styled.div`
    font-size: 25px;
`
const CPSelectionOutputDiv = Styled.div`
    width: 47%;
`
const TableProfileImg = Styled.img`
    width: 100px;
    height: 100px;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`
const BoxFilterDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;
`
const BoxFilterKeyDiv = Styled.div`
    width: 100%;
`
const BoxFilterValueDiv = Styled.div`
    width: 120px;
    margin-left: 15px;
    background: #327BFF;
    color: #FFFFFF;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`

export default DansungbeePickCreationPopup