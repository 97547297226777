export const httpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const userStatus = {
  ACTIVE: 'ACTIVE',
  EXIT: 'EXIT',
};

export const exposureStatus = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  HOLD: 'HOLD',
};

export const saleStatus = {
  OPEN: 'OPEN',
  STOP: 'STOP',
  SOLDOUT: 'SOLDOUT',
};

export const unitType = {
  CAPACITY: 'CAPACITY',
  FORM: 'FORM',
};

export const nameLangType = {
  KOR: 'KOR',
  ENG: 'ENG',
};

export const priorityType = {
  MAIN: 'MAIN',
  SUB: 'SUB',
};
