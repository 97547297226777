import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom';

import { getToken } from 'utils/token';
import { isPositiveInteger } from 'utils/validator';
import { getSchema, getRequest, postRequestWithToken, putRequestWithToken, deleteRequestWithToken } from 'utils/APIRequest';

import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import PrimaryButton from 'component/button/PrimaryButton';
import CPCreationPopup from 'component/dialog/CPCreationPopup';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

const drawerWidth = 300
const isMobileDevice = isMobile()

interface BrandOptionType {
    inputValue?: string;
    brandId: number;
    name: string;
}

interface ManufacturerOptionType {
    inputValue?: string;
    manufacturerId: number;
    name: string;
}

const filterBrandOption = createFilterOptions<BrandOptionType>();
const filterManufacturerOption = createFilterOptions<ManufacturerOptionType>();

const DSBProductUpsert = () => {
    const history = useHistory()
    const location = useLocation()

    const [brandNameList, setBrandNameList] = useState<any>([])
    const [manufacturerList, setManufacturerList] = useState<any>([])
    const [productCategoryList, setProductCategoryList] = useState<any>([])
    const [structuredProductCategoryList, setStructuredProductCategoryList] = useState<any>([])

    const [CPList, setCPList] = useState<any>([])
    const [CPListInTable, setCPListInTable] = useState<any>([])

    const [recvProductCategoryId, setRecvProductCategoryId] = useState<number | null>(null)
    const [recvBrandId, setRecvBrandId] = useState<number | null>(null)
    const [recvManufacturerId, setRecvManufacturerId] = useState<number | null>(null)
    const [recvRepChildProductId, setRecvRepChildProductId] = useState<number | null>(null)


    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [PP, setPP] = useState<any>(null)
    const [PPId, setPPId] = useState<number>(-1)

    const [PPName, setPPName] = useState<string>("")
    const [PPBrand, setPPBrand] = useState<any>(null);
    const [PPBrandId, setPPBrandId] = useState<number | null>(null);
    const [PPManufacturer, setPPManufacturer] = useState<any>(null);
    const [PPManufacturerId, setPPManufacturerId] = useState<number | null>(null);
    const [PPFirstLevelCategoryId, setPPFirstLevelCategoryId] = useState<number>(-1)
    const [PPSecondLevelCategoryId, setPPSecondLevelCategoryId] = useState<number>(-1)
    const [PPThirdLevelCategoryId, setPPThirdLevelCategoryId] = useState<number>(-1)

    const [secondLCategoryList, setSecondLCategoryList] = useState<any>([])
    const [thirdLCategoryList, setThirdLCategoryList] = useState<any>([])

    const [PPYoutubeIframePath, setPPYoutubeIframePath] = useState<string>("")
    const [exposureStatus, setExposureStatus] = useState<string>("HOLD")

    const [insertingQuery, setInsertingQuery] = useState<string>("")
    const [repChildProductId, setRepChildProductId] = useState<number>(-1)
    const [infoStatus, setInfoStatus] = useState<any>(["미흡", "충족"])
    const [exposureStatusList, setExposureStatusList] = useState<any>(["OPEN", "CLOSE", "HOLD"])

    const [CPCreationPopupOpen, setCPCreationPopupOpen] = useState<boolean>(false)
    const [showChildProductList, setShowChildProductList] = useState<boolean>(false)

    const handleCPCreationPopupOpen = () => {
        setCPCreationPopupOpen(true)
    }

    const handleCPCreationPopupClose = () => {
        setCPCreationPopupOpen(false)
    }

    const handlePPNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPPName(event.target.value)
    }

    const handleFirstLevelCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === null) return
        setPPFirstLevelCategoryId(Number(event.target.value))
        setPPSecondLevelCategoryId(-1)
        setPPThirdLevelCategoryId(-1)
    }

    const handleSecondLevelCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === null) return
        setPPSecondLevelCategoryId(Number(event.target.value))
        setPPThirdLevelCategoryId(-1)
    }

    const handleThirdLevelCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === null) return
        setPPThirdLevelCategoryId(Number(event.target.value))
    }

    const handlePPYoutubeIframePathChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPPYoutubeIframePath(event.target.value)
    }

    const handleExposureStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isAllFilled) setExposureStatus(event.target.value)
        else alert("공개 상태를 변경하기 위해서는 필수항목이 채워져있어야 합니다 :)")
    }

    const handleInsertingQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInsertingQuery(event.target.value)
    }

    const handleRepChildProductIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRepChildProductId(Number(event.target.value))
    }

    const handleInfoStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let curInfoStatus = infoStatus

        if (isSelectedInfoStatus(event.target.name))
            curInfoStatus = infoStatus.filter((v: any) => v != event.target.name)
        else
            curInfoStatus.push(event.target.name)

        setInfoStatus([...curInfoStatus])
    }

    const isSelectedInfoStatus = (name: string) => {
        let isSelected = false

        for (let i = 0; i < infoStatus.length; i++)
            if (infoStatus[i] === name)
                isSelected = true

        return isSelected
    }

    const handleExposureStatusListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let curExposureStatusList = exposureStatusList

        if (isSelectedExposureStatus(event.target.name))
            curExposureStatusList = exposureStatusList.filter((v: any) => v != event.target.name)
        else
            curExposureStatusList.push(event.target.name)

        setExposureStatusList([...curExposureStatusList])
    }

    const isSelectedExposureStatus = (name: string) => {
        let isSelected = false

        for (let i = 0; i < exposureStatusList.length; i++)
            if (exposureStatusList[i] === name)
                isSelected = true

        return isSelected
    }

    const getBrandNameList = async () => {
        const res = await getRequest('/brands/name')
        if (res !== null) {
            if (res.status === 200) {
                setBrandNameList([...res.data])
            } else if (res.status === 204) {
                setBrandNameList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addBrand = async (brand: BrandOptionType) => {
        if (brand.name === undefined || brand.name === "") return
        if (brand.brandId === undefined || brand.brandId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const brandInfo = { name: brand.name }
        const curSchema = getSchema('/brands/onlyName', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(brandInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/brands/onlyName',
            brandInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getBrandNameList()
                const createdBrand = res.data
                setPPBrand({
                    brandId: createdBrand.brandId,
                    name: createdBrand.name,
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getManufacturerList = async () => {
        const res = await getRequest('/manufacturers')
        if (res !== null) {
            if (res.status === 200) {
                setManufacturerList([...res.data])
            } else if (res.status === 204) {
                setManufacturerList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addManufacturer = async (manufacturer: ManufacturerOptionType) => {
        if (manufacturer.name === undefined || manufacturer.name === "") return
        if (manufacturer.manufacturerId === undefined || manufacturer.manufacturerId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const manufacturerInfo = { name: manufacturer.name }
        const curSchema = getSchema('/manufacturers', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(manufacturerInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/manufacturers',
            manufacturerInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getManufacturerList()
                const createdManufacturer = res.data
                setPPManufacturer({
                    manufacturerId: createdManufacturer.manufacturerId,
                    name: createdManufacturer.name,
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getProductCategoryList = async () => {
        const res = await getRequest('/productClassifications')
        if (res !== null) {
            if (res.status === 200) {
                setProductCategoryList([...res.data])
            } else if (res.status === 204) {
                setProductCategoryList([])
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getStructuredProductCategoryList = async () => {
        const res = await getRequest('/productClassifications/structured')
        if (res !== null) {
            if (res.status === 200) {
                setStructuredProductCategoryList([...res.data])
            } else if (res.status === 204) {
                setStructuredProductCategoryList([])
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getParentProduct = async (id: number) => {
        if (id === undefined || id <= 0) return
        if (id === undefined || id <= 0) return
        const res = await getRequest('/products/' + id)
        if (res !== null) {
            if (res.status === 200) {
                const curPP = res.data
                parsePP(curPP)
            } else if (res.status === 204) {
                alert("알 수 없는 product id입니다. 다시 시도해주세요")
                history.goBack()
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const createParentProduct = async () => {
        if (PP === undefined || PP === null) return
        const curToken = getToken()
        if (curToken === '') return

        const productInfo = PP
        const curSchema = getSchema('/products', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(productInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/products',
            productInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                alert("Parent Product를 생성했습니다 :)")
                const createdPPId = res.data.parentProductId
                setPPId(createdPPId)
                history.replace(`/dsb/product/upsert?parentProductId=${createdPPId}`)
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateParentProduct = async () => {
        if (PP === undefined || PP === null) return
        if (PPId === undefined || PPId === -1) return
        const curToken = getToken()
        if (curToken === '') return

        const productInfo = PP
        const curSchema = getSchema('/products', 'PUT')

        if (curSchema) {
            try {
                await curSchema.validateAsync(productInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            '/products/' + PPId,
            productInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                alert("Parent Product가 수정됐습니다 :)")
                return
            } else if (res.status === 204) {
                alert("product ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const parsePP = (recvPP: any,) => {
        if (recvPP === undefined || recvPP === null) return

        if (recvPP.name !== undefined && recvPP.name !== null)
            setPPName(recvPP.name)

        if (recvPP.repChildProductId !== undefined && recvPP.repChildProductId !== null)
            setRecvRepChildProductId(recvPP.repChildProductId)

        if (recvPP.brandId !== undefined && recvPP.brandId !== null)
            setRecvBrandId(recvPP.brandId)

        if (recvPP.manufacturerId !== undefined && recvPP.manufacturerId !== null)
            setRecvManufacturerId(recvPP.manufacturerId)

        if (recvPP.productClassificationId !== undefined && recvPP.productClassificationId !== null)
            setRecvProductCategoryId(recvPP.productClassificationId)

        if (recvPP.youtubeIframePath !== undefined && recvPP.youtubeIframePath !== null)
            setPPYoutubeIframePath(recvPP.youtubeIframePath)

        if (recvPP.exposureStatus !== undefined && recvPP.exposureStatus !== null)
            setExposureStatus(recvPP.exposureStatus)


        return
    }

    const updateRepChildProduct = async (parentProductId: number, childProductId: number) => {
        if (parentProductId === undefined || parentProductId === -1) return
        if (childProductId === undefined || childProductId === -1) return
        const curToken = getToken()
        if (curToken === '') return

        const productInfo = { repChildProductId: childProductId }
        const curSchema = getSchema('/products/{parentProductId}/repChildProduct', 'PUT')

        if (curSchema) {
            try {
                await curSchema.validateAsync(productInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            `/products/${parentProductId}/repChildProduct`,
            productInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                alert("대표상품이 변경되었습니다 :)")
                return
            } else if (res.status === 204) {
                alert("product ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 대표상품입니다.")
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getCPList = async (id: number) => {
        if (id === undefined || id === -1) return

        const res = await getRequest(`/products/${id}/childs`)
        if (res !== null) {
            if (res.status === 200) {
                setCPList([...res.data])
            } else if (res.status === 204) {
                setCPList([])
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const deleteCP = async (id: number) => {
        if (id === undefined || id === -1) {
            alert("선택된 Child Product가 없습니다.")
            return
        }

        const curToken = getToken()
        if (curToken === '') return

        const childProductId = id

        const res = await deleteRequestWithToken(
            `/products/childs/${childProductId}`,
            {},
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                alert("Child Product가 삭제됐습니다 :)")
                if (id === repChildProductId) {
                    const curCPList = CPList.filter((v: any) => v.childProductId !== id)
                    if (curCPList.length > 0)
                        setRepChildProductId(curCPList[0].childProductId)
                }
                getCPList(PPId)
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 204) {
                return
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const parseQS = (curLocation: any) => {
        const params = new URLSearchParams(curLocation.search)
        const recvParentProductId = params.get("parentProductId")

        if (recvParentProductId && recvParentProductId !== "") {
            const curId = decodeURI(recvParentProductId)
            if (!isPositiveInteger(curId)) return
            setPPId(Number(curId))
        }

    }

    useEffect(() => {
        if (PPName === undefined || PPName === "") {
            setIsAllFilled(false)
            return
        }
        if (PPBrandId === undefined || PPBrandId === null || PPBrandId <= 0) {
            setIsAllFilled(false)
            return
        }

        let curProductClassificationId = -1
        if (PPFirstLevelCategoryId !== undefined && PPFirstLevelCategoryId !== null && PPFirstLevelCategoryId > 0) {
            if (PPSecondLevelCategoryId !== undefined && PPSecondLevelCategoryId !== null && PPSecondLevelCategoryId > 0) {
                if (PPThirdLevelCategoryId !== undefined && PPThirdLevelCategoryId !== null && PPThirdLevelCategoryId > 0) {
                    curProductClassificationId = PPThirdLevelCategoryId
                } else {
                    curProductClassificationId = PPSecondLevelCategoryId
                }
            } else {
                curProductClassificationId = PPFirstLevelCategoryId
            }
        }

        if (curProductClassificationId === undefined || curProductClassificationId === null || curProductClassificationId <= 0) {
            setIsAllFilled(false)
            return
        }

        const curPP = {
            name: PPName,
            brandId: PPBrandId,
            manufacturerId: PPManufacturerId,
            productClassificationId: curProductClassificationId,
            youtubeIframePath: PPYoutubeIframePath,
            exposureStatus: exposureStatus
        }

        setPP(curPP)
        setIsAllFilled(true)
    }, [PPName, String(PPBrandId), String(PPManufacturerId), String(PPFirstLevelCategoryId), String(PPSecondLevelCategoryId), String(PPThirdLevelCategoryId), PPYoutubeIframePath, exposureStatus])

    useEffect(() => {
        if (!PPBrand)
            return
        if (PPBrand.brandId !== undefined && PPBrand.brandId === -1) {
            const nameExpectEng = PPBrand.name.replace(/[a-zA-Z]/g, '')
            if (PPBrand.name !== nameExpectEng) {
                alert("추가할 브랜드에 알파벳이 포함되어있습니다. \n영어이름의 브랜드를 등록하시려면 아래 과정을 참고해주세요 \n 1. 한글이름으로 등록\n 2. '상품 컴포넌트 > 브랜드' 페이지에서 영어이름 등록 및 대표언어 ENG로 설정")
                setPPBrand(null)
                return
            }
            addBrand(PPBrand)
        }

        setPPBrandId(PPBrand.brandId)
    }, [JSON.stringify(PPBrand)])

    useEffect(() => {
        if (!PPManufacturer)
            return
        if (PPManufacturer.manufacturerId !== undefined && PPManufacturer.manufacturerId === -1)
            addManufacturer(PPManufacturer)

        setPPManufacturerId(PPManufacturer.manufacturerId)
    }, [JSON.stringify(PPManufacturer)])

    useEffect(() => {
        if (PPId === undefined || PPId === -1 || PPId === null) return
        if (repChildProductId === undefined || repChildProductId === -1 || repChildProductId === null) return

        if (repChildProductId !== recvRepChildProductId) {
            updateRepChildProduct(PPId, repChildProductId)
            setRecvRepChildProductId(null)
        }
    }, [String(repChildProductId), String(recvRepChildProductId)])

    useEffect(() => {
        if (recvRepChildProductId === undefined || recvRepChildProductId === -1 || recvRepChildProductId === null) return

        setRepChildProductId(recvRepChildProductId)
    }, [String(recvRepChildProductId)])

    useEffect(() => {
        if (CPList === undefined || CPList.length === 0) {
            setRepChildProductId(-1)
            return
        }
        if (CPList.length === 1) {
            setRepChildProductId(CPList[0].childProductId)
        }
    }, [JSON.stringify(CPList)])

    useEffect(() => {
        if (PPFirstLevelCategoryId === undefined || PPFirstLevelCategoryId === null) return
        if (PPFirstLevelCategoryId !== -1) {
            let curIdx = -1
            const curCategoryList = structuredProductCategoryList
            for (let i = 0; i < curCategoryList.length; i++) {
                if (curCategoryList[i].productClassificationId === PPFirstLevelCategoryId) {
                    curIdx = i
                    break
                }
            }
            if (curCategoryList[curIdx].childList === undefined || curCategoryList[curIdx].childList.length === 0) {
                setSecondLCategoryList([])
                return
            }
            setSecondLCategoryList([...curCategoryList[curIdx].childList])
        } else {
            setSecondLCategoryList([])
            setPPSecondLevelCategoryId(-1)
            setPPThirdLevelCategoryId(-1)
        }
    }, [String(PPFirstLevelCategoryId)])

    useEffect(() => {
        if (PPSecondLevelCategoryId === undefined || PPSecondLevelCategoryId === null) return
        if (secondLCategoryList === undefined || secondLCategoryList.length <= 0) return
        if (PPSecondLevelCategoryId !== -1) {
            let curIdx = -1
            const curCategoryList = secondLCategoryList
            for (let i = 0; i < curCategoryList.length; i++) {
                if (curCategoryList[i].productClassificationId === PPSecondLevelCategoryId) {
                    curIdx = i
                    break
                }
            }
            if (curCategoryList[curIdx].childList === undefined || curCategoryList[curIdx].childList.length === 0) {
                setThirdLCategoryList([])
                return
            }
            setThirdLCategoryList([...curCategoryList[curIdx].childList])
        } else {
            setThirdLCategoryList([])
            setPPThirdLevelCategoryId(-1)
        }
    }, [String(PPSecondLevelCategoryId), JSON.stringify(secondLCategoryList)])

    useEffect(() => {
        if (CPList === undefined || CPList.length === 0) {
            setCPListInTable([])
            return
        }
        if (infoStatus.length === 0) {
            setCPListInTable([])
            return
        }
        if (exposureStatusList.length === 0) {
            setCPListInTable([])
            return
        }

        let curCPList: any[] = CPList
        if (infoStatus.length === 1) {
            curCPList = curCPList.filter((v: any) => {
                if (infoStatus[0] === "미흡" && !v.isFilled) return v
                if (infoStatus[0] === "충족" && v.isFilled) return v
            })
        }

        if (exposureStatusList.length === 1) {
            curCPList = curCPList.filter((v: any) => {
                if (exposureStatusList[0] === v.exposureStatus) return v
            })
        } else if (exposureStatusList.length === 2) {
            curCPList = curCPList.filter((v: any) => {
                if (exposureStatusList[0] === v.exposureStatus || exposureStatusList[1] === v.exposureStatus) return v
            })
        }

        curCPList = curCPList.filter((v: any) => {
            if (v.productSerialNumber.includes(insertingQuery) || v.flavorStr.includes(insertingQuery) || v.capacityStr.includes(insertingQuery))
                return v
        })
        setCPListInTable([...curCPList])
    }, [JSON.stringify(CPList), JSON.stringify(infoStatus), JSON.stringify(exposureStatusList), insertingQuery])

    useEffect(() => {
        if (CPCreationPopupOpen)
            return

        getCPList(PPId)
    }, [CPCreationPopupOpen])

    useEffect(() => {
        if (recvProductCategoryId === undefined || recvProductCategoryId === null || recvProductCategoryId <= 0) return
        if (productCategoryList === undefined || productCategoryList.length < 0) return

        const curList = productCategoryList
        let curId = recvProductCategoryId
        let parentId = -1
        let grandParentId = -1

        for (let i = 0; i < curList.length; i++) {
            if (curList[i].productClassificationId === curId) {
                if (curList[i].parentProductClassificationId !== null && curList[i].parentProductClassificationId > 0) {
                    parentId = curList[i].parentProductClassificationId
                } else {
                    setPPFirstLevelCategoryId(curId)
                    return
                }
            }
        }

        for (let i = 0; i < curList.length; i++) {
            if (curList[i].productClassificationId === parentId) {
                if (curList[i].parentProductClassificationId !== null && curList[i].parentProductClassificationId > 0) {
                    grandParentId = curList[i].parentProductClassificationId
                } else {
                    setPPFirstLevelCategoryId(parentId)
                    setPPSecondLevelCategoryId(curId)
                    return
                }
            }
        }

        setPPFirstLevelCategoryId(grandParentId)
        setPPSecondLevelCategoryId(parentId)
        setPPThirdLevelCategoryId(curId)
    }, [String(recvProductCategoryId), JSON.stringify(productCategoryList)])

    useEffect(() => {
        if (recvBrandId === undefined || recvBrandId === null || recvBrandId <= 0) return
        if (brandNameList === undefined || brandNameList.length < 0) return

        for (let i = 0; i < brandNameList.length; i++) {
            if (brandNameList[i].brandId === recvBrandId) {
                setPPBrand({
                    name: brandNameList[i].name,
                    brandId: recvBrandId
                })
            }
        }
    }, [String(recvBrandId), JSON.stringify(brandNameList)])

    useEffect(() => {
        if (recvManufacturerId === undefined || recvManufacturerId === null || recvManufacturerId <= 0) return
        if (manufacturerList === undefined || manufacturerList.length < 0) return

        for (let i = 0; i < manufacturerList.length; i++) {
            if (manufacturerList[i].manufacturerId === recvManufacturerId) {
                setPPManufacturer({
                    name: manufacturerList[i].name,
                    manufacturerId: recvManufacturerId
                })
            }
        }
    }, [String(recvManufacturerId), JSON.stringify(manufacturerList)])

    useEffect(() => {
        if (PPId === undefined || PPId <= 0) return

        getParentProduct(PPId)
        getCPList(PPId)
        setShowChildProductList(true)
    }, [String(PPId)])

    useEffect(() => {
        parseQS(location)
        getBrandNameList()
        getManufacturerList()
        getProductCategoryList()
        getStructuredProductCategoryList()
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>상품 등록</BoxTitle>
                        <BoxSubTitle>{"단성비 > 상품 관리 > 상품 등록"}</BoxSubTitle>
                    </BoxDiv>
                    {
                        structuredProductCategoryList.length !== 0 ?
                            <BoxDiv>
                                <BoxTitle>Parent 상품 프로필</BoxTitle>
                                <BoxContentFlexDiv>
                                    <BoxContentKeyDiv>상품명 *</BoxContentKeyDiv>
                                    <BoxContentValueDiv>
                                        <TextField
                                            id="PPName"
                                            size="small"
                                            fullWidth
                                            value={PPName}
                                            onChange={handlePPNameChange}
                                        />
                                    </BoxContentValueDiv>
                                </BoxContentFlexDiv>
                                {
                                    brandNameList !== undefined && brandNameList.length !== 0 &&
                                    <BoxContentFlexDiv>
                                        <BoxContentKeyDiv>브랜드 *</BoxContentKeyDiv>
                                        <BoxContentValueDiv>
                                            <Autocomplete
                                                id="PPBrand"
                                                value={PPBrand}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        setPPBrand({
                                                            brandId: -1,
                                                            name: newValue.inputValue,
                                                        });
                                                    } else {
                                                        setPPBrand(newValue);
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filterBrandOption(options, params);

                                                    const { inputValue } = params;
                                                    const isExisting = options.some((option) => inputValue === option.name);
                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            name: `"${inputValue}" 추가! (영어이름 불가능)`,
                                                            brandId: -1
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                options={brandNameList}
                                                getOptionLabel={(option) => {
                                                    return option.name;
                                                }}
                                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        size="small"
                                                        fullWidth
                                                        label='브랜드명'
                                                    />
                                                )}
                                            />
                                        </BoxContentValueDiv>
                                    </BoxContentFlexDiv>
                                }
                                {
                                    manufacturerList !== undefined && manufacturerList.length !== 0 &&
                                    <BoxContentFlexDiv>
                                        <BoxContentKeyDiv>제조사</BoxContentKeyDiv>
                                        <BoxContentValueDiv>
                                            <Autocomplete
                                                id="PPManufacturer"
                                                value={PPManufacturer}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        setPPManufacturer({
                                                            manufacturerId: -1,
                                                            name: newValue.inputValue,
                                                        });
                                                    } else {
                                                        setPPManufacturer(newValue);
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filterManufacturerOption(options, params);

                                                    const { inputValue } = params;
                                                    const isExisting = options.some((option) => inputValue === option.name);
                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            name: `"${inputValue}" 추가!`,
                                                            manufacturerId: -1
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                options={manufacturerList}
                                                getOptionLabel={(option) => {
                                                    return option.name;
                                                }}
                                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        size="small"
                                                        fullWidth
                                                        label='제조사명'
                                                    />
                                                )}
                                            />
                                        </BoxContentValueDiv>
                                    </BoxContentFlexDiv>
                                }
                                <BoxContentFlexDiv>
                                    <BoxContentKeyDiv>카테고리 *</BoxContentKeyDiv>
                                    <BoxContentValueDiv>
                                        <TextField
                                            id="PPFirstLevelCategoryId"
                                            size="small"
                                            select
                                            fullWidth
                                            label="대분류"
                                            defaultValue=""
                                            value={PPFirstLevelCategoryId}
                                            onChange={handleFirstLevelCategoryChange}
                                        >
                                            {structuredProductCategoryList.map((v: any) => (
                                                <MenuItem key={v.productClassificationId} value={v.productClassificationId}>
                                                    {v.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </BoxContentValueDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentKeyDiv></BoxContentKeyDiv>
                                    <BoxContentValueDiv>
                                        {
                                            PPFirstLevelCategoryId !== -1 && secondLCategoryList !== undefined && secondLCategoryList.length > 0 &&
                                            <TextField
                                                id="PPSecondLevelCategoryId"
                                                size="small"
                                                select
                                                fullWidth
                                                label="중분류"
                                                defaultValue=""
                                                value={PPSecondLevelCategoryId}
                                                onChange={handleSecondLevelCategoryChange}
                                            >
                                                {
                                                    secondLCategoryList.map((v: any) => (
                                                        <MenuItem key={v.productClassificationId} value={v.productClassificationId}>
                                                            {v.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        }
                                    </BoxContentValueDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentKeyDiv></BoxContentKeyDiv>
                                    <BoxContentValueDiv>
                                        {
                                            PPSecondLevelCategoryId !== -1 && thirdLCategoryList !== undefined && thirdLCategoryList.length > 0 &&
                                            <TextField
                                                id="PPThirdLevelCategoryId"
                                                size="small"
                                                select
                                                fullWidth
                                                label="소분류"
                                                defaultValue=""
                                                value={PPThirdLevelCategoryId}
                                                onChange={handleThirdLevelCategoryChange}
                                            >
                                                {
                                                    thirdLCategoryList.map((v: any) => (
                                                        <MenuItem key={v.productClassificationId} value={v.productClassificationId}>
                                                            {v.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        }
                                    </BoxContentValueDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentKeyDiv>
                                        {"유튜브 iframe 링크 "}
                                        <BoxContentKeyA href="https://github.com/PortValue/pv_cms_reactjs/issues/85" title="유튜브 iframe 링크 확인 방법">
                                            (확인 방법)
                                        </BoxContentKeyA>
                                    </BoxContentKeyDiv>
                                    <BoxContentValueDiv>
                                        <TextField
                                            id="PPYoutubeIframePath"
                                            size="small"
                                            fullWidth
                                            value={PPYoutubeIframePath}
                                            label="iframe src"
                                            onChange={handlePPYoutubeIframePathChange}
                                        />
                                    </BoxContentValueDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentKeyDiv>공개 상태 *</BoxContentKeyDiv>
                                    <BoxContentValueDiv>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={exposureStatus}
                                            onChange={handleExposureStatusChange}
                                        >
                                            <FormControlLabel value="OPEN" control={<Radio />} label="공개" />
                                            {
                                                !isMobileDevice &&
                                                <FormControlLabelDivider />
                                            }
                                            <FormControlLabel value="CLOSE" control={<Radio />} label="비공개" />
                                            {
                                                !isMobileDevice &&
                                                <FormControlLabelDivider />
                                            }
                                            <FormControlLabel value="HOLD" control={<Radio />} label="보류" />
                                        </RadioGroup>
                                    </BoxContentValueDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentKeyDiv></BoxContentKeyDiv>
                                    {
                                        isAllFilled ?
                                            <>
                                                {
                                                    showChildProductList ?
                                                        <PrimaryButton value="상품 수정" size="large" color="primary" onClickEvent={() => updateParentProduct()} />
                                                        :
                                                        <PrimaryButton value="상품 등록" size="large" color="primary" onClickEvent={() => createParentProduct()} />
                                                }
                                            </>
                                            :
                                            <PrimaryButton disabled={true} value="저장" size="large" color="primary" />
                                    }
                                </BoxContentFlexDiv>
                            </BoxDiv>
                            :
                            <BoxDiv>
                                <BoxTitle>Parent 상품 프로필</BoxTitle>
                                <BoxSubTitle>{"상품을 등록하기 위해서는 상품 카테고리가 하나이상 등록이 되어있어야 합니다!!"}</BoxSubTitle>
                                <BoxDivider />
                                <BoxContentFlexDiv>
                                    <BoxContentKeyDiv>
                                        {"상품 카테고리 수정 "}
                                        <BoxContentKeyA onClick={() => history.push("/dsb/productComponent/productCategory")}>
                                            (바로 가기)
                                        </BoxContentKeyA>
                                    </BoxContentKeyDiv>
                                </BoxContentFlexDiv>
                            </BoxDiv>
                    }
                    {
                        showChildProductList &&
                        <BoxDiv>
                            <BoxTitleFlexDiv>
                                <BoxTitleKey>Child 상품 목록</BoxTitleKey>
                                <BoxTitleValue>
                                    <TextField
                                        id="cc-search-textfield"
                                        size="small"
                                        label="상품코드, 맛, 용량으로 상품 검색"
                                        fullWidth
                                        value={insertingQuery}
                                        onChange={handleInsertingQueryChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </BoxTitleValue>
                            </BoxTitleFlexDiv>
                            <BoxDivider />
                            <BoxTitleFlexDiv>
                                <div></div>
                                <FormControl>
                                    <FormGroup row>
                                        <FormGroupTitle>공개 상태</FormGroupTitle>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={isSelectedExposureStatus("OPEN")} onChange={handleExposureStatusListChange} name="OPEN" />
                                            }
                                            label="공개"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={isSelectedExposureStatus("CLOSE")} onChange={handleExposureStatusListChange} name="CLOSE" />
                                            }
                                            label="비공개"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={isSelectedExposureStatus("HOLD")} onChange={handleExposureStatusListChange} name="HOLD" />
                                            }
                                            label="보류"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </BoxTitleFlexDiv>
                            <BoxTitleFlexDiv>
                                <div></div>
                                <FormControl>
                                    <FormGroup row>
                                        <FormGroupTitle>필수정보 상태</FormGroupTitle>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={isSelectedInfoStatus("충족")} onChange={handleInfoStatusChange} name="충족" />
                                            }
                                            label="충족"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={isSelectedInfoStatus("미흡")} onChange={handleInfoStatusChange} name="미흡" />
                                            }
                                            label="미흡"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </BoxTitleFlexDiv>
                            <BoxDivider />
                            <BoxTitleFlexDiv>
                                <TableKeyDiv>총 {CPListInTable !== undefined ? CPListInTable.length : 0}개</TableKeyDiv>
                                <TableValueDiv>
                                    <FlexEndDiv>
                                        <PrimaryButton value="Child 제품 추가" size="medium" color="primary" onClickEvent={() => handleCPCreationPopupOpen()} />
                                    </FlexEndDiv>
                                </TableValueDiv>
                            </BoxTitleFlexDiv>
                            {
                                CPListInTable !== undefined && CPListInTable.length !== 0 &&
                                <Paper sx={{ width: '100%', mb: 2 }}>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="CPListInTable table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold', fontSize: 15 }}>대표 제품</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>상품 시리얼 넘버</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>이미지</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>맛</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>용량</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>공개 상태</TableCell>
                                                    <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>필수정보</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {CPListInTable.map((row: any) => (
                                                    <TableRow
                                                        key={row.productSerialNumber}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <Radio
                                                                checked={repChildProductId === row.childProductId}
                                                                onChange={handleRepChildProductIdChange}
                                                                color="secondary"
                                                                value={row.childProductId}
                                                                name="CP-radio-buttons"
                                                                inputProps={{ 'aria-label': 'A' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">{row.productSerialNumber} </TableCell>
                                                        <TableCell align="left">
                                                            <TableProfileImg src={row.imagePath} alt={row.imagePath} />
                                                        </TableCell>
                                                        <TableCell align="left">{row.flavorStr}</TableCell>
                                                        <TableCell align="left">{row.capacityStr}</TableCell>
                                                        <TableCell align="left">{row.exposureStatus}</TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                row.isFilled === false ?
                                                                    <TableCellHighlightText>{"미흡"}</TableCellHighlightText>
                                                                    :
                                                                    <>{"충족"}</>
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <PrimaryButtonListFlexDiv>
                                                                <PrimaryButton
                                                                    value="수정"
                                                                    size="small"
                                                                    color="success"
                                                                    onClickEvent={() => {
                                                                        window.scrollTo(0, 0)
                                                                        history.push("/dsb/product/CPUpdate?childProductId=" + row.childProductId)
                                                                    }} />
                                                                <PrimaryButtonDivider />
                                                                <PrimaryButton
                                                                    value="삭제"
                                                                    size="small"
                                                                    color="error"
                                                                    onClickEvent={() => {
                                                                        if (window.confirm(`Child Product(${row.productSerialNumber})를 삭제 하시겠습니까?`)) {
                                                                            deleteCP(row.childProductId)
                                                                        }
                                                                    }}
                                                                />
                                                            </PrimaryButtonListFlexDiv>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            }
                        </BoxDiv>
                    }
                </WrapDiv>
            </Box>
            {
                <>
                    <CPCreationPopup
                        open={CPCreationPopupOpen}
                        onClickClose={handleCPCreationPopupClose}
                        PPId={PPId}
                        btnTextFirst="취소"
                        btnTextSecond="추가"
                    />
                </>
            }
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxContentFlexDiv = Styled.div`
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 6px;
`
const BoxContentKeyA = Styled.a`
    color: #327bff;
    text-decoration: underline;
`
const BoxContentValueDiv = Styled.div`
    width: 70%;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const FlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const FormControlLabelDivider = Styled.div`
    width: 15%;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`
const TableProfileImg = Styled.img`
    width: 100px;
    height: 100px;
`
const TableCellHighlightText = Styled.div`
    font-weight: 600;
    font-size: 18px;
    color: red;
`
const PrimaryButtonListFlexDiv = Styled.div`
    display: flex;
    justify-content: center;
`
const PrimaryButtonDivider = Styled.div`
    width: 15px;
`
const FormGroupTitle = Styled.div`
    font-size: 16px;
    color: #666666;
    margin-top: 9px;
    margin-right: 20px;
`

export default DSBProductUpsert;