import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import { getToken } from 'utils/token';
import { getRequest, deleteRequestWithToken } from 'utils/APIRequest';

import DSBMainDrawer from 'component/drawer/DSBMainDrawer'; 
import PrimaryButton from 'component/button/PrimaryButton';
import MagazineClassificationCreationPopup from 'component/dialog/MagazineClassificationCreationPopup';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

const drawerWidth = 300;
const isMobileDevice = isMobile()

const DSBMagazineType = () => {
    const history = useHistory()

    const [magazineClassificationList, setMagazineClassificationList] = useState<any>([])
    const [magazineClassificationListInTable, setMagazineClassificationListInTable] = useState<any>([])

    const [insertingQuery, setInsertingQuery] = useState<string>("")
    const [selectedMagazineClassificationList, setSelectedMagazineClassificationList] = useState<any>([])

    const [magazineClassificationForUpdate, setMagazineClassificationForUpdate] = useState<any>("")

    const [magazineClassificationCreationPopupOpen, setMagazineClassificationCreationPopupOpen] = useState<boolean>(false)
    const [magazineClassificationUpdatePopupOpen, setMagazineClassificationUpdatePopupOpen] = useState<boolean>(false)

    const handleMagazineClassificationCreationPopupOpen = () => {
        setMagazineClassificationCreationPopupOpen(true)
    }

    const handleMagazineClassificationCreationPopupClose = () => {
        setMagazineClassificationCreationPopupOpen(false)
    }

    const handleMagazineClassificationUpdatePopupOpen = (curMagazineClassification: any) => {
        setMagazineClassificationForUpdate(curMagazineClassification)
    }

    const handleMagazineClassificationUpdatePopupClose = () => {
        setMagazineClassificationForUpdate("")
        setMagazineClassificationUpdatePopupOpen(false)
    }

    const handleInsertingQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInsertingQuery(event.target.value)
    }

    const handleMagazineClassificationListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const curID = Number(event.target.id)
        let curList = selectedMagazineClassificationList

        if (isSelectedMagazineClassification(curID))
            curList = selectedMagazineClassificationList.filter((v: any) => v !== curID)
        else
            curList.push(curID)

        setSelectedMagazineClassificationList([...curList])
    }

    const isSelectedMagazineClassification = (magazineClassificationId: number) => {
        let isSelected = false

        for (let i = 0; i < selectedMagazineClassificationList.length; i++)
            if (selectedMagazineClassificationList[i] === magazineClassificationId)
                isSelected = true

        return isSelected
    }

    const deleteMagazineClassificationList = async () => {
        if (selectedMagazineClassificationList === undefined || selectedMagazineClassificationList.length === 0) {
            alert("선택된 MagazineClassification이 없습니다.")
            return
        }

        const curToken = getToken()
        if (curToken === '') return

        const magazineClassificationIdListInfo = {
            magazineClassificationIdList: selectedMagazineClassificationList
        }

        const res = await deleteRequestWithToken(
            '/magazineClassifications',
            magazineClassificationIdListInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                getMagazineClassificationList()
                setSelectedMagazineClassificationList([])
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
            } else if (res.status === 204) {
                setSelectedMagazineClassificationList([])
                return
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getMagazineClassificationList = async () => {
        const res = await getRequest('/magazineClassifications')
        if (res !== null) {
            if (res.status === 200) {
                setMagazineClassificationList([...res.data])
            } else if (res.status === 204) {
                setMagazineClassificationList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    useEffect(() => {
        if (magazineClassificationCreationPopupOpen)
            return

        getMagazineClassificationList()
    }, [magazineClassificationCreationPopupOpen])
    
    useEffect(() => {
        if (magazineClassificationUpdatePopupOpen)
            return

        getMagazineClassificationList()
    }, [magazineClassificationUpdatePopupOpen])

    useEffect(() => {
        if (magazineClassificationForUpdate == undefined || magazineClassificationForUpdate == "")
            return

        setMagazineClassificationUpdatePopupOpen(true)
    }, [magazineClassificationForUpdate])

    useEffect(() => {
        if (magazineClassificationList === undefined || magazineClassificationList.length === 0) {
            setMagazineClassificationListInTable([...magazineClassificationList])
            return
        }
        if (insertingQuery === "" || insertingQuery === undefined) {
            setMagazineClassificationListInTable([...magazineClassificationList])
            return
        }
        const curMagazineClassificationList = magazineClassificationList.filter((v: any) => {
            if (v.name.includes(insertingQuery))
                return v
        })
        setMagazineClassificationListInTable([...curMagazineClassificationList])
    }, [JSON.stringify(magazineClassificationList), insertingQuery])

    useEffect(() => {
        getMagazineClassificationList()
    }, [])

    return (
        <>
        <DSBMainDrawer/>
        <Box
            component="main"
            sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice?1:4, marginTop: isMobileDevice?7:8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
            <WrapDiv>
                <BoxDiv>
                    <BoxTitle>매거진 종류</BoxTitle>
                    <BoxSubTitle>{"단성비 > 공통 컴포넌트 > 매거진 종류"}</BoxSubTitle>
                </BoxDiv>
                <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>매거진 종류 목록</BoxTitleKey>
                            <BoxTitleValue>
                                <TextField
                                    id="cc-search-textfield"
                                    size="small"
                                    label="매거진 종류명으로 검색"
                                    fullWidth
                                    value={insertingQuery}
                                    onChange={handleInsertingQueryChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxDivider />
                        <BoxTitleFlexDiv>
                            <TableKeyDiv>총 {magazineClassificationListInTable !== undefined ? magazineClassificationListInTable.length : 0}개</TableKeyDiv>
                            <TableValueDiv>
                                <FlexEndDiv>
                                    <PrimaryButton 
                                        value="매거진 종류 추가" 
                                        size="medium" 
                                        color="primary" 
                                        onClickEvent={() => handleMagazineClassificationCreationPopupOpen()}
                                    />
                                    <BoxVirticalDivider/>
                                    {
                                        selectedMagazineClassificationList == undefined || selectedMagazineClassificationList.length == 0 ?
                                            <PrimaryButton disabled={true} value="선택 삭제" size="medium" color="error" />
                                            :
                                            <PrimaryButton value="선택 삭제" size="medium" color="error" onClickEvent={() => deleteMagazineClassificationList()} />
                                    }
                                </FlexEndDiv>
                            </TableValueDiv>
                        </BoxTitleFlexDiv>
                        {
                            magazineClassificationListInTable !== undefined && magazineClassificationListInTable.length !== 0 &&
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="magazineClassificationListInTable table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="left" sx={{fontWeight: 'bold', fontSize: 15}}>매거진 종류명</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {magazineClassificationListInTable.map((row: any) => (
                                                <TableRow
                                                    key={row.magazineClassificationId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Checkbox
                                                            id={row.magazineClassificationId}
                                                            checked={isSelectedMagazineClassification(row.magazineClassificationId)}
                                                            onChange={handleMagazineClassificationListChange}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">{row.name} </TableCell>
                                                    <TableCell align="right">
                                                        <PrimaryButton 
                                                            value="수정" 
                                                            size="small" 
                                                            color="success" 
                                                            onClickEvent={() => {
                                                                handleMagazineClassificationUpdatePopupOpen(row)
                                                            }} 
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        }
                    </BoxDiv>
            </WrapDiv>
        </Box>
        {
            <>
                <MagazineClassificationCreationPopup
                    open={magazineClassificationCreationPopupOpen}
                    purpose="추가"
                    onClickClose={handleMagazineClassificationCreationPopupClose}
                    btnTextFirst="취소"
                    btnTextSecond="추가"
                />
            </>
        }
        {
            <>
                <MagazineClassificationCreationPopup
                    open={magazineClassificationUpdatePopupOpen}
                    magazineClassificationForUpdate={magazineClassificationForUpdate}
                    purpose="수정"
                    onClickClose={handleMagazineClassificationUpdatePopupClose}
                    btnTextFirst="취소"
                    btnTextSecond="수정"
                />
            </>
        }
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const FlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const BoxVirticalDivider = Styled.div`
    width: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`

export default DSBMagazineType;