import React, { useEffect, useState, useCallback } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import DragableCard from 'component/card/DragableCard'
import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import PrimaryButton from 'component/button/PrimaryButton';
import FavoriteProductCreationPopup from 'component/dialog/FavoriteProductCreationPopup';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

const drawerWidth = 300;
const isMobileDevice = isMobile()

const tempFavoriteProductList = [
    {
        id: 1,
        name: "임팩트 웨이 프로틴 초콜릿 스무스 2.5kg",
        price: "49,500원",
        URL: "www.임팩트 웨이 프로틴 초콜릿 스무스 2.5kg.co.kr",
        serialNum: "12312312",
    },
    {
        id: 2,
        name: "임팩트 웨이 프로틴 무맛 2.5kg",
        price: "39,500원",
        URL: "www.임팩트 웨이 프로틴 무맛 2.5kg.co.kr",
        serialNum: "asdasdasd",
    },
    {
        id: 3,
        name: "임팩트 웨이 아이솔레이트 바닐라 2.5kg",
        price: "29,500원",
        URL: "www.임팩트 웨이 아이솔레이트 바닐라 2.5kg.co.kr",
        serialNum: "123421233",
    },
]

export interface Item {
    id: number
    name: string
    price: string
    URL: string
    serialNum: string
}

const SBBGMPFavorite = () => {
    const history = useHistory()

    const [registeredFavoriteProductList, setRegisteredFavoriteProductList] = useState<any>([])
    const [favoriteProductList, setFavoriteProductList] = useState<any>(tempFavoriteProductList)

    const [insertingQuery, setInsertingQuery] = useState<string>("")
    const [searchingQuery, setSearchingQuery] = useState<string>("")
    const [selectedFavoriteProductList, setSelectedFavoriteProductList] = useState<any>([])

    const [favoriteProductForUpdate, setFavoriteProductForUpdate] = useState<any>("")

    const [favoriteProductCreationPopupOpen, setFavoriteProductCreationPopupOpen] = useState<boolean>(false)
    const [favoriteProductUpdatePopupOpen, setFavoriteProductUpdatePopupOpen] = useState<boolean>(false)

    const handleFavoriteProductCreationPopupOpen = () => {
        setFavoriteProductCreationPopupOpen(true)
    }

    const handleFavoriteProductCreationPopupClose = () => {
        setFavoriteProductCreationPopupOpen(false)
    }

    const handleFavoriteProductUpdatePopupOpen = (curFavoriteProduct: any) => {
        setFavoriteProductForUpdate(curFavoriteProduct)
    }

    const handleFavoriteProductUpdatePopupClose = () => {
        setFavoriteProductForUpdate("")
        setFavoriteProductUpdatePopupOpen(false)
    }

    const handleInsertingQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInsertingQuery(event.target.value)
    }

    const handleFavoriteProductListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let curList = selectedFavoriteProductList

        if (isSelectedFavoriteProduct(event.target.name))
            curList = selectedFavoriteProductList.filter((v: any) => v != event.target.name)
        else
            curList.push(event.target.name)

        setSelectedFavoriteProductList([...curList])
    }

    const isSelectedFavoriteProduct = (name: string) => {
        let isSelected = false

        for (let i = 0; i < selectedFavoriteProductList.length; i++)
            if (selectedFavoriteProductList[i] === name)
                isSelected = true

        return isSelected
    }

    const moveFavoriteProductCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setFavoriteProductList((prevCards: Item[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as Item],
                ],
            }),
        )
    }, [])

    const renderFavoriteProductCard = useCallback(
        (card: { id: number; name: string }, index: number) => {
            return (
                <DragableCard
                    key={card.id}
                    index={index}
                    id={card.id}
                    name={card.name}
                    moveCard={moveFavoriteProductCard}
                />
            )
        },
        [],
    )


    useEffect(() => {
        if (favoriteProductCreationPopupOpen)
            return

        //TODO: favoriteProduct list api calling needed
        console.log("favoriteProduct list api calling needed")

    }, [favoriteProductCreationPopupOpen])

    useEffect(() => {
        if (favoriteProductUpdatePopupOpen)
            return

        //TODO: favoriteProduct list api calling needed
        console.log("favoriteProduct list api calling needed")

    }, [favoriteProductUpdatePopupOpen])

    useEffect(() => {
        if (favoriteProductForUpdate == undefined || favoriteProductForUpdate == "")
            return

        setFavoriteProductUpdatePopupOpen(true)
    }, [favoriteProductForUpdate])

    useEffect(() => {
        if (searchingQuery !== "")
            alert(searchingQuery + "로 검색하는 부분~")
    }, [searchingQuery])

    useEffect(() => {
        alert("준비중인 기능입니다.")
        history.goBack()
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>마이프로틴 인기상품</BoxTitle>
                        <BoxSubTitle>{"SBBG > 크롤링 컴포넌트 > 마이프로틴 인기상품"}</BoxSubTitle>
                    </BoxDiv>
                    <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>상품 목록</BoxTitleKey>
                            <BoxTitleValue>
                                <TextField
                                    id="cc-search-textfield"
                                    size="small"
                                    label="상품 검색"
                                    fullWidth
                                    value={insertingQuery}
                                    onChange={handleInsertingQueryChange}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                            setSearchingQuery(insertingQuery)
                                            ev.preventDefault()
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={() => setSearchingQuery(insertingQuery)}
                                >검색</Button>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxDivider />
                        <BoxTitleFlexDiv>
                            <TableKeyDiv>총 {favoriteProductList !== undefined ? favoriteProductList.length : 0}개</TableKeyDiv>
                            <TableValueDiv>
                                <FlexEndDiv>
                                    <PrimaryButton
                                        value="상품 추가"
                                        size="medium"
                                        color="primary"
                                        onClickEvent={() => handleFavoriteProductCreationPopupOpen()}
                                    />
                                    <BoxVirticalDivider />
                                    {
                                        selectedFavoriteProductList == undefined || selectedFavoriteProductList.length == 0 ?
                                            <PrimaryButton disabled={true} value="선택 삭제" size="medium" color="error" />
                                            :
                                            <PrimaryButton value="선택 삭제" size="medium" color="error" onClickEvent={() => alert(selectedFavoriteProductList.length + "개의 선택한 상품들을 삭제~")} />
                                    }
                                </FlexEndDiv>
                            </TableValueDiv>
                        </BoxTitleFlexDiv>
                        {
                            favoriteProductList !== undefined && favoriteProductList.length !== 0 &&
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="favoriteProductList table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell sx={{ width: '15%', fontWeight: 'bold', fontSize: 15 }}>노출 순서</TableCell>
                                                <TableCell align="left" sx={{ width: '50%', fontWeight: 'bold', fontSize: 15 }}>상품명 (드래그 앤 드랍으로 노출 순서 변경 가능)</TableCell>
                                                <TableCell align="left" sx={{ width: '15%', fontWeight: 'bold', fontSize: 15 }}>가격</TableCell>
                                                <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: 15 }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <DndProvider backend={HTML5Backend}>
                                                {favoriteProductList.map((row: any, index: any) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <Checkbox
                                                                checked={isSelectedFavoriteProduct(row.name)}
                                                                onChange={handleFavoriteProductListChange}
                                                                name={row.name}
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" sx={{ width: '13%' }}>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ width: '50%' }}>{renderFavoriteProductCard(row, index)}</TableCell>
                                                        <TableCell align="left" sx={{ width: '15%' }}>{row.price}</TableCell>
                                                        <TableCell align="right">
                                                            <PrimaryButton
                                                                value="수정"
                                                                size="small"
                                                                color="success"
                                                                onClickEvent={() => {
                                                                    handleFavoriteProductUpdatePopupOpen(row)
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </DndProvider>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        }
                    </BoxDiv>
                </WrapDiv>
            </Box>
            {
                <>
                    <FavoriteProductCreationPopup
                        open={favoriteProductCreationPopupOpen}
                        purpose="추가"
                        onClickClose={handleFavoriteProductCreationPopupClose}
                        btnTextFirst="취소"
                        btnTextSecond="추가"
                    />
                </>
            }
            {
                <>
                    <FavoriteProductCreationPopup
                        open={favoriteProductUpdatePopupOpen}
                        favoriteProductForUpdate={favoriteProductForUpdate}
                        purpose="수정"
                        onClickClose={handleFavoriteProductUpdatePopupClose}
                        btnTextFirst="취소"
                        btnTextSecond="수정"
                    />
                </>
            }
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const FlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const BoxVirticalDivider = Styled.div`
    width: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`

export default SBBGMPFavorite;