import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import { getToken, removeToken } from 'utils/token';
import { signCheck, getSchema, getRequestWithToken, postRequestWithToken, deleteRequestWithToken } from 'utils/APIRequest';

import PV from 'asset/image/pv.png';

import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Collapse from '@mui/material/Collapse';

import MenuIcon from '@mui/icons-material/Menu';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import AccessibilityNewOutlinedIcon from '@mui/icons-material/AccessibilityNewOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

const drawerWidth = 300;
const drawerList = [
    {
        name: "대시보드",
        path: "/",
        subDrawerList: [],
    },
    {
        name: "단성비",
        path: "/dsb",
        subDrawerList: [
            {
                name: "상품 관리",
                path: "/product",
                subDrawerList: [
                    {
                        name: "상품 등록",
                        path: "/upsert",
                        subDrawerList: [],
                    },
                    {
                        name: "상품 목록/수정",
                        path: "/read",
                        subDrawerList: [],
                    },
                ]
            },
            {
                name: "상품 컴포넌트",
                path: "/productComponent",
                subDrawerList: [
                    {
                        name: "단위",
                        path: "/unit",
                        subDrawerList: [],
                    },
                    {
                        name: "리뷰 태그",
                        path: "/reviewTag",
                        subDrawerList: [],
                    },
                    {
                        name: "맛",
                        path: "/flavor",
                        subDrawerList: [],
                    },
                    {
                        name: "브랜드",
                        path: "/brand",
                        subDrawerList: [],
                    },
                    {
                        name: "상품 분석 항목",
                        path: "/analysis",
                        subDrawerList: [],
                    },
                    {
                        name: "상품 카테고리",
                        path: "/productCategory",
                        subDrawerList: [],
                    },
                    {
                        name: "제조사",
                        path: "/manufacturer",
                        subDrawerList: [],
                    },
                    {
                        name: "알레르기",
                        path: "/allergy",
                        subDrawerList: [],
                    },
                    {
                        name: "판매처",
                        path: "/distributor",
                        subDrawerList: [],
                    },
                ]
            },
            {
                name: "공통 컴포넌트",
                path: "/commonComponent",
                subDrawerList: [
                    {
                        name: "단성비 Pick",
                        path: "/dsbPick",
                        subDrawerList: [],
                    },
                    {
                        name: "매거진",
                        path: "/magazine",
                        subDrawerList: [],
                    },
                    {
                        name: "매거진 종류",
                        path: "/magazineType",
                        subDrawerList: [],
                    },
                    {
                        name: "메인 배너",
                        path: "/banner",
                        subDrawerList: [],
                    },
                    {
                        name: "이벤트",
                        path: "/event",
                        subDrawerList: [],
                    },
                    {
                        name: "이벤트 종류",
                        path: "/eventType",
                        subDrawerList: [],
                    },
                ]
            },
            {
                name: "사용자 관리",
                path: "/user",
                subDrawerList: [],
            },
            {
                name: "리뷰 관리",
                path: "/review",
                subDrawerList: [],
            },
        ],
    },
    {
        name: "큐잉",
        path: "/cueing",
        subDrawerList: [],
    },
    {
        name: "SBBG",
        path: "/sbbg",
        subDrawerList: [
            {
                name: "수익",
                path: "/revenue",
                subDrawerList: [],
            },
            {
                name: "크롤링 컴포넌트",
                path: "/crawlingComponent",
                subDrawerList: [
                    {
                        name: "마이프로틴 인기상품",
                        path: "/MPFavorite",
                        subDrawerList: [],
                    },
                    {
                        name: "마이프로틴 할인코드",
                        path: "/MPDCode",
                        subDrawerList: [],
                    },
                ],
            },
        ],
    },
    {
        name: "매니저 관리",
        path: "/userManagement",
        subDrawerList: [],
    },
]

const openFuncListInit = [
    {
        status: false,
        subOpenFuncList: [],
    },
    {
        status: false,
        subOpenFuncList: [
            {
                status: false,
                subOpenFuncList: [
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                ],
            },
            {
                status: false,
                subOpenFuncList: [
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                ],
            },
            {
                status: false,
                subOpenFuncList: [
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                ],
            },
            {
                status: false,
                subOpenFuncList: [],
            },
            {
                status: false,
                subOpenFuncList: [],
            },
        ],
    },
    {
        status: false,
        subOpenFuncList: [],
    },
    {
        status: false,
        subOpenFuncList: [
            {
                status: false,
                subOpenFuncList: [],
            },
            {
                status: false,
                subOpenFuncList: [
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                    {
                        status: false,
                        subOpenFuncList: [],
                    },
                ],
            },
        ],
    },
    {
        status: false,
        subOpenFuncList: [],
    },
]

const DSBMainDrawer = () => {
    const history = useHistory()
    const container = window !== undefined ? () => window.document.body : undefined;
    const [mobileOpen, setMobileOpen] = useState<boolean>(false)
    const [openFuncList, setOpenFuncList] = useState<any>(openFuncListInit);
    const [favoriteDrawerList, setFavoriteDrawerList] = useState<any>([]);
    const [favoriteModifyMode, setFavoriteModifyMode] = useState<boolean>(false);

    const handleFavoriteDrawerList = (openFuncListIndex: any, openFuncListSecondIndex?: any, openFuncListSubThirdIndex?: any) => {
        const curOpenFuncList = openFuncList
        let destPath = ""
        let curName = ""

        if (openFuncListSecondIndex == undefined) {
            if (curOpenFuncList[openFuncListIndex].subOpenFuncList.length == 0) {
                if (drawerList[openFuncListIndex].path !== undefined) {
                    destPath = drawerList[openFuncListIndex].path
                    curName = drawerList[openFuncListIndex].name
                }
            }
        } else {
            if (openFuncListSubThirdIndex == undefined) {
                if (curOpenFuncList[openFuncListIndex].subOpenFuncList[openFuncListSecondIndex].subOpenFuncList.length == 0) {
                    if (drawerList[openFuncListIndex].path !== undefined &&
                        drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].path !== undefined) {
                        destPath = drawerList[openFuncListIndex].path + drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].path
                        curName = drawerList[openFuncListIndex].name + " > " + drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].name
                    }
                }
            } else {
                if (curOpenFuncList[openFuncListIndex].subOpenFuncList[openFuncListSecondIndex].subOpenFuncList[openFuncListSubThirdIndex].subOpenFuncList.length == 0) {
                    if (drawerList[openFuncListIndex].path !== undefined &&
                        drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].path !== undefined &&
                        drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].subDrawerList[openFuncListSubThirdIndex].path !== undefined) {
                        destPath = drawerList[openFuncListIndex].path + drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].path + drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].subDrawerList[openFuncListSubThirdIndex].path
                        curName = drawerList[openFuncListIndex].name + " > .. > " + drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].subDrawerList[openFuncListSubThirdIndex].name
                    }
                }
            }
        }

        addToFavoriteDrawerList(curName, destPath)
    }

    const gotoFavoritePath = (path: string) => {
        if (path === undefined || path === "") return
        history.push(path)
        window.location.reload()
        clearRoutingOpenFuncList()
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    const clearRoutingOpenFuncList = () => {
        let curOpenFuncList = openFuncList

        for (let i = 0; i < curOpenFuncList.length; i++) {
            if (curOpenFuncList[i].subOpenFuncList.length == 0) {
                curOpenFuncList[i].status = false
            } else {
                for (let j = 0; j < curOpenFuncList[i].subOpenFuncList.length; j++) {
                    if (curOpenFuncList[i].subOpenFuncList[j].subOpenFuncList.length == 0) {
                        curOpenFuncList[i].subOpenFuncList[j].status = false
                    } else {
                        for (let k = 0; k < curOpenFuncList[i].subOpenFuncList[j].subOpenFuncList.length; k++) {
                            if (curOpenFuncList[i].subOpenFuncList[j].subOpenFuncList[k].subOpenFuncList.length == 0) {
                                curOpenFuncList[i].subOpenFuncList[j].subOpenFuncList[k].status = false
                            }
                        }
                    }
                }
            }
        }

        setOpenFuncList([...curOpenFuncList])
    }

    const handleOpenFuncList = (openFuncListIndex: any, openFuncListSecondIndex?: any, openFuncListSubThirdIndex?: any) => {
        let curOpenFuncList = openFuncList
        let destPath = ""

        if (openFuncListSecondIndex == undefined) {
            if (curOpenFuncList[openFuncListIndex].subOpenFuncList.length == 0) {
                if (drawerList[openFuncListIndex].path !== undefined) destPath = drawerList[openFuncListIndex].path
                history.push(destPath)
                clearRoutingOpenFuncList()
            }
            curOpenFuncList[openFuncListIndex].status = !curOpenFuncList[openFuncListIndex].status
        } else {
            if (openFuncListSubThirdIndex == undefined) {
                if (curOpenFuncList[openFuncListIndex].subOpenFuncList[openFuncListSecondIndex].subOpenFuncList.length == 0) {
                    if (drawerList[openFuncListIndex].path !== undefined &&
                        drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].path !== undefined)
                        destPath = drawerList[openFuncListIndex].path + drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].path
                    history.push(destPath)
                    clearRoutingOpenFuncList()
                }
                curOpenFuncList[openFuncListIndex].subOpenFuncList[openFuncListSecondIndex].status = !curOpenFuncList[openFuncListIndex].subOpenFuncList[openFuncListSecondIndex].status
            } else {
                if (curOpenFuncList[openFuncListIndex].subOpenFuncList[openFuncListSecondIndex].subOpenFuncList[openFuncListSubThirdIndex].subOpenFuncList.length == 0) {
                    if (drawerList[openFuncListIndex].path !== undefined &&
                        drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].path !== undefined &&
                        drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].subDrawerList[openFuncListSubThirdIndex].path !== undefined)
                        destPath = drawerList[openFuncListIndex].path + drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].path + drawerList[openFuncListIndex].subDrawerList[openFuncListSecondIndex].subDrawerList[openFuncListSubThirdIndex].path
                    history.push(destPath)
                    clearRoutingOpenFuncList()
                }
                curOpenFuncList[openFuncListIndex].subOpenFuncList[openFuncListSecondIndex].subOpenFuncList[openFuncListSubThirdIndex].status = !curOpenFuncList[openFuncListIndex].subOpenFuncList[openFuncListSecondIndex].subOpenFuncList[openFuncListSubThirdIndex].status
            }
        }

        setOpenFuncList([...curOpenFuncList])
    }

    const isOpenFuncList = (openFuncListIndex: any, openFuncListSecondIndex?: any, openFuncListSubThirdIndex?: any) => {
        let curOpenFuncList = openFuncList

        if (openFuncListSecondIndex == undefined) {
            return curOpenFuncList[openFuncListIndex].status
        } else {
            if (openFuncListSubThirdIndex == undefined) {
                return curOpenFuncList[openFuncListIndex].subOpenFuncList[openFuncListSecondIndex].status
            } else {
                return curOpenFuncList[openFuncListIndex].subOpenFuncList[openFuncListSecondIndex].subOpenFuncList[openFuncListSubThirdIndex].status
            }
        }
    }

    const getFavoriteDrawerList = async () => {
        const curToken = getToken()
        if (curToken === '') return

        const res = await getRequestWithToken(
            '/favorites',
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                setFavoriteDrawerList([...res.data])
            } else if (res.status === 204) {
                setFavoriteDrawerList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addToFavoriteDrawerList = async (name: string, path: string) => {
        if (name === undefined || name === "") return
        if (path === undefined || path === "") return
        const curToken = getToken()
        if (curToken === '') return

        const favoriteInfo = { name: name, path: path }
        const curSchema = getSchema('/favorites', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(favoriteInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/favorites',
            favoriteInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getFavoriteDrawerList()
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const deleteFromFavoriteDrawerList = async (name: string, path: string) => {
        if (name === undefined || name === "") return
        if (path === undefined || path === "") return

        const curToken = getToken()
        if (curToken === '') return

        const favoriteInfo = { name: name, path: path }
        const curSchema = getSchema('/favorites', 'DELETE')

        if (curSchema) {
            try {
                await curSchema.validateAsync(favoriteInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await deleteRequestWithToken(
            '/favorites',
            favoriteInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                getFavoriteDrawerList()
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 204) {
                return
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }


    useEffect(() => {
        signCheck().then((isSignIn) => {
            if (!isSignIn)
            history.push("/signIn")
        });

        getFavoriteDrawerList()
    }, [])

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    bgcolor: "#FDFDFD",
                    color: "rgba(0, 0, 0, 0.87)",
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` },
                }}
                elevation={0}
            >
                <Toolbar>
                    <AppBarMenuButtonDiv>
                        <IconButton
                            size="large"
                            aria-label="MenuIcon"
                            onClick={handleDrawerToggle}
                            color="inherit"
                            sx={{ display: { md: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </AppBarMenuButtonDiv>
                    <AppBarDiv>
                        <AppBarButtonDiv>
                            <IconButton
                                size="large"
                                aria-label="AccountCircleIcon"
                                color="inherit"
                                onClick={()=>{
                                    if (window.confirm("로그아웃 하시겠습니까?")) {
                                        removeToken()
                                        history.push("/signIn")
                                    }
                                }}
                            >
                                <AccountCircleIcon />
                            </IconButton>
                        </AppBarButtonDiv>
                        <AppBarButtonDiv>
                            <IconButton
                                size="large"
                                aria-label="ContactSupportIcon"
                                color="inherit"
                                onClick={()=>window.open("https://pf.kakao.com/_hcsqxj")}
                            >
                                <ContactSupportIcon />
                            </IconButton>
                        </AppBarButtonDiv>
                    </AppBarDiv>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {
                    openFuncList !== "" &&
                    <>
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                // display: { xs: 'block', sm: 'none' },
                                display: { xs: 'block', sm: 'block', md: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}
                        >
                            <PVProfileDiv onClick={() => history.push("/")}>
                                <PVProfileImage src={PV} alt="PV" />
                                <PVProfileDetailDiv>
                                    <PVProfileTitle>PortValue</PVProfileTitle>
                                    <PVProfileSubTitle>Content Management System</PVProfileSubTitle>
                                </PVProfileDetailDiv>
                            </PVProfileDiv>
                            <Divider />
                            {
                                favoriteDrawerList !== undefined && favoriteDrawerList.length !== 0 &&
                                <>
                                    <DrawerDescFlexDiv>
                                        <DrawerDescDiv>즐겨찾기</DrawerDescDiv>
                                        <ModifyButton
                                            onClick={() =>
                                                setFavoriteModifyMode(!favoriteModifyMode)
                                            }
                                        >수정</ModifyButton>
                                    </DrawerDescFlexDiv>
                                    <List>
                                        {favoriteDrawerList.map((v: any, i: number) => (
                                            <ListItem
                                                button
                                                key={v.name}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    gotoFavoritePath(v.path)
                                                }}
                                            >
                                                <ListItemStarIconDiv>
                                                    <StarOutlineRoundedIcon color="inherit" fontSize="inherit" />
                                                </ListItemStarIconDiv>
                                                <ListItemText primary={v.name} />
                                                {
                                                    favoriteModifyMode &&
                                                    <DeleteForeverRoundedIcon
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            deleteFromFavoriteDrawerList(v.name, v.path)
                                                        }}
                                                    />
                                                }
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Divider />
                                </>
                            }
                            <DrawerDescFlexDiv>
                                <DrawerDescDiv>워크스페이스</DrawerDescDiv>
                            </DrawerDescFlexDiv>
                            <List>
                                {drawerList.map((v, index) => (
                                    <>
                                        <ListItem
                                            button
                                            key={v.name}
                                            onClick={() => handleOpenFuncList(index)}
                                        >
                                            <ListItemIconDiv>
                                                {index === 0 && <InsertChartOutlinedIcon color="inherit" fontSize="inherit" />}
                                                {index === 1 && <Inventory2OutlinedIcon color="inherit" fontSize="inherit" />}
                                                {index === 2 && <WidgetsOutlinedIcon color="inherit" fontSize="inherit" />}
                                                {index === 3 && <FastfoodOutlinedIcon color="inherit" fontSize="inherit" />}
                                                {index === 4 && <AccessibilityNewOutlinedIcon color="inherit" fontSize="inherit" />}
                                            </ListItemIconDiv>
                                            <ListItemText primary={v.name} />
                                            {
                                                v.subDrawerList.length !== 0 &&
                                                <ListItemArrowIconDiv>
                                                    {isOpenFuncList(index) ? <ExpandLessRoundedIcon color="inherit" fontSize="inherit" /> : <ExpandMoreRoundedIcon color="inherit" fontSize="inherit" />}
                                                </ListItemArrowIconDiv>
                                            }
                                        </ListItem>
                                        {
                                            v.subDrawerList.length !== 0 &&
                                            <Collapse in={isOpenFuncList(index)} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {
                                                        v.subDrawerList.map((v2, index2) => (
                                                            <>
                                                                <ListItem
                                                                    button
                                                                    key={v2.name}
                                                                    onClick={() => handleOpenFuncList(index, index2)}
                                                                >
                                                                    <ListItemDivider />
                                                                    <ListItemText primary={v2.name} />
                                                                    {
                                                                        v2.subDrawerList.length !== 0 &&
                                                                        <SubListItemIconDiv>
                                                                            {isOpenFuncList(index, index2) ? <ExpandLessRoundedIcon color="inherit" fontSize="inherit" /> : <ExpandMoreRoundedIcon color="inherit" fontSize="inherit" />}
                                                                        </SubListItemIconDiv>
                                                                    }
                                                                </ListItem>
                                                                {
                                                                    v2.subDrawerList.length !== 0 &&
                                                                    <Collapse in={isOpenFuncList(index, index2)} timeout="auto" unmountOnExit>
                                                                        <List component="div" disablePadding>
                                                                            {
                                                                                v2.subDrawerList.map((v3, index3) => (
                                                                                    <>
                                                                                        <ListItem
                                                                                            button
                                                                                            key={v3.name}
                                                                                            selected={v3.subDrawerList.length === 0 && isOpenFuncList(index, index2, index3)}
                                                                                            onClick={() => handleOpenFuncList(index, index2, index3)}
                                                                                            sx={{
                                                                                                borderRadius: 2,
                                                                                            }}
                                                                                        >
                                                                                            <ListItemDivider />
                                                                                            <ListItemDivider />
                                                                                            <ListItemTextDiv isSelected={v3.subDrawerList.length === 0 && isOpenFuncList(index, index2, index3)}>{v3.name}</ListItemTextDiv>
                                                                                        </ListItem>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </List>
                                                                    </Collapse>
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </List>
                                                <CollapseDivider />
                                            </Collapse>
                                        }
                                    </>
                                ))}
                            </List>
                            <Divider />
                        </Drawer>
                        <Drawer
                            variant="permanent"
                            sx={{
                                // display: { xs: 'none', sm: 'block' },
                                display: { xs: 'none', sm: 'none', md: 'block' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}
                            open
                        >
                            <PVProfileDiv onClick={() => history.push("/")}>
                                <PVProfileImage src={PV} alt="PV" />
                                <PVProfileDetailDiv>
                                    <PVProfileTitle>PortValue</PVProfileTitle>
                                    <PVProfileSubTitle>Content Management System</PVProfileSubTitle>
                                </PVProfileDetailDiv>
                            </PVProfileDiv>
                            <Divider />
                            {
                                favoriteDrawerList !== undefined && favoriteDrawerList.length !== 0 &&
                                <>
                                    <DrawerDescFlexDiv>
                                        <DrawerDescDiv>즐겨찾기</DrawerDescDiv>
                                        <ModifyButton
                                            onClick={() =>
                                                setFavoriteModifyMode(!favoriteModifyMode)
                                            }
                                        >수정</ModifyButton>
                                    </DrawerDescFlexDiv>
                                    <List>
                                        {favoriteDrawerList.map((v: any, i: number) => (
                                            <ListItem
                                                button
                                                key={v.name}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    gotoFavoritePath(v.path)
                                                }}
                                            >
                                                <ListItemStarIconDiv>
                                                    <StarOutlineRoundedIcon color="inherit" fontSize="inherit" />
                                                </ListItemStarIconDiv>
                                                <ListItemText primary={v.name} />
                                                {
                                                    favoriteModifyMode &&
                                                    <DeleteForeverRoundedIcon
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            deleteFromFavoriteDrawerList(v.name, v.path)
                                                        }}
                                                    />
                                                }
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Divider />
                                </>
                            }
                            <DrawerDescFlexDiv>
                                <DrawerDescDiv>워크스페이스</DrawerDescDiv>
                            </DrawerDescFlexDiv>
                            <List>
                                {drawerList.map((v, index) => (
                                    <>
                                        <ListItem
                                            button
                                            key={v.name}
                                            onClick={() => handleOpenFuncList(index)}
                                            selected={v.subDrawerList.length === 0 && isOpenFuncList(index)}
                                            sx={{
                                                borderRadius: 2,
                                            }}
                                        >
                                            <ListItemIconDiv>
                                                {index === 0 && <InsertChartOutlinedIcon color="inherit" fontSize="inherit" />}
                                                {index === 1 && <Inventory2OutlinedIcon color="inherit" fontSize="inherit" />}
                                                {index === 2 && <WidgetsOutlinedIcon color="inherit" fontSize="inherit" />}
                                                {index === 3 && <FastfoodOutlinedIcon color="inherit" fontSize="inherit" />}
                                                {index === 4 && <AccessibilityNewOutlinedIcon color="inherit" fontSize="inherit" />}
                                            </ListItemIconDiv>
                                            <ListItemTextDiv isSelected={v.subDrawerList.length === 0 && isOpenFuncList(index)}>{v.name}</ListItemTextDiv>
                                            {
                                                v.subDrawerList.length !== 0 &&
                                                <ListItemArrowIconDiv>
                                                    {isOpenFuncList(index) ? <ExpandLessRoundedIcon color="inherit" fontSize="inherit" /> : <ExpandMoreRoundedIcon color="inherit" fontSize="inherit" />}
                                                </ListItemArrowIconDiv>
                                            }
                                            {
                                                v.subDrawerList.length === 0 && isOpenFuncList(index) &&
                                                <SubListItemIconDiv
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleFavoriteDrawerList(index)
                                                    }}
                                                >
                                                    <StarOutlineRoundedIcon color="inherit" fontSize="inherit" />
                                                </SubListItemIconDiv>
                                            }
                                        </ListItem>
                                        {
                                            v.subDrawerList.length !== 0 &&
                                            <Collapse in={isOpenFuncList(index)} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {
                                                        v.subDrawerList.map((v2, index2) => (
                                                            <>
                                                                <ListItem
                                                                    button
                                                                    key={v2.name}
                                                                    onClick={() => handleOpenFuncList(index, index2)}
                                                                    selected={v2.subDrawerList.length === 0 && isOpenFuncList(index, index2)}
                                                                    sx={{
                                                                        borderRadius: 2,
                                                                    }}
                                                                >
                                                                    <ListItemDivider />
                                                                    <ListItemTextDiv isSelected={v2.subDrawerList.length === 0 && isOpenFuncList(index, index2)}>{v2.name}</ListItemTextDiv>
                                                                    {
                                                                        v2.subDrawerList.length !== 0 &&
                                                                        <SubListItemIconDiv>
                                                                            {isOpenFuncList(index, index2) ? <ExpandLessRoundedIcon color="inherit" fontSize="inherit" /> : <ExpandMoreRoundedIcon color="inherit" fontSize="inherit" />}
                                                                        </SubListItemIconDiv>
                                                                    }
                                                                    {
                                                                        v2.subDrawerList.length === 0 && isOpenFuncList(index, index2) &&
                                                                        <SubListItemIconDiv
                                                                            onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                handleFavoriteDrawerList(index, index2)
                                                                            }}
                                                                        >
                                                                            <StarOutlineRoundedIcon color="inherit" fontSize="inherit" />
                                                                        </SubListItemIconDiv>
                                                                    }
                                                                </ListItem>
                                                                {
                                                                    v2.subDrawerList.length !== 0 &&
                                                                    <Collapse in={isOpenFuncList(index, index2)} timeout="auto" unmountOnExit>
                                                                        <List component="div" disablePadding>
                                                                            {
                                                                                v2.subDrawerList.map((v3, index3) => (
                                                                                    <>
                                                                                        <ListItem
                                                                                            button
                                                                                            key={v3.name}
                                                                                            onClick={() => handleOpenFuncList(index, index2, index3)}
                                                                                            selected={v3.subDrawerList.length === 0 && isOpenFuncList(index, index2, index3)}
                                                                                            sx={{
                                                                                                borderRadius: 2,
                                                                                            }}
                                                                                        >
                                                                                            <ListItemDivider />
                                                                                            <ListItemDivider />
                                                                                            <ListItemTextDiv isSelected={v3.subDrawerList.length === 0 && isOpenFuncList(index, index2, index3)}>{v3.name}</ListItemTextDiv>
                                                                                            {
                                                                                                v3.subDrawerList.length === 0 && isOpenFuncList(index, index2, index3) &&
                                                                                                <SubListItemIconDiv
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation()
                                                                                                        handleFavoriteDrawerList(index, index2, index3)
                                                                                                    }}
                                                                                                >
                                                                                                    <StarOutlineRoundedIcon color="inherit" fontSize="inherit" />
                                                                                                </SubListItemIconDiv>
                                                                                            }
                                                                                        </ListItem>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </List>
                                                                    </Collapse>
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </List>
                                                <CollapseDivider />
                                            </Collapse>
                                        }
                                    </>
                                ))}
                            </List>
                            <Divider />
                        </Drawer>
                    </>
                }
            </Box>
        </>
    );
};

const AppBarMenuButtonDiv = Styled.div`
    box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%);
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.01);
    color: #327BFF;
`
const AppBarButtonDiv = Styled.div`
    margin-left: 20px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%);
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.01);
    color: #327BFF;
`
const AppBarDiv = Styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
const PVProfileDiv = Styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
`
const PVProfileDetailDiv = Styled.div`
`
const PVProfileTitle = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
`
const PVProfileSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const PVProfileImage = Styled.img`
    width: 44px;
    height: 44px;
    margin-right: 15px;
`
const DrawerDescFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 0px;
`
const ModifyButton = Styled.div`
    text-align: right;
    font-size: 12px;
    color: #327BFF;
`
const DrawerDescDiv = Styled.div`
    text-align: left;
    font-size: 15px;
    color: #666666;
`
const CollapseDivider = Styled.div`
    height: 20px;
`
const ListItemDivider = Styled.div`
    width: 15%;
`
const SubListItemIconDiv = Styled.div`
    font-size: 18px;
    color: #327BFF;
`
const ListItemIconDiv = Styled.div`
    width: 15%;
    font-size: 24px;
    color: #327BFF;
`
const ListItemArrowIconDiv = Styled.div`
    font-size: 24px;
    color: #327BFF;
`
const ListItemStarIconDiv = Styled.div`
    width: 15%;
    font-size: 24px;
    color: #FFD400;
`
const ListItemTextDiv = Styled.div`
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    ${(props: { isSelected: any; }) => (props.isSelected ? "font-weight: 600;color: #327BFF;" : "font-weight: inherit;color: inherit;")}
`

export default DSBMainDrawer;