import React, { useEffect, useState, useCallback } from 'react';
import Styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { getToken } from 'utils/token';
import { isMobile } from 'utils/common';
import { isPositiveInteger } from 'utils/validator';
import { getSchema, getRequest, postRequestWithToken, putRequestWithToken, postImageRequestWithToken } from 'utils/APIRequest';

import ChildProductClonePopup from 'component/dialog/ChildProductClonePopup';
import ImagePreviewPopup from 'component/dialog/ImagePreviewPopup';
import DragableCard from 'component/card/DragableCard'
import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import PrimaryButton from 'component/button/PrimaryButton';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { green, grey } from '@mui/material/colors';

import Fab from '@mui/material/Fab';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

interface UnitOptionType {
    inputValue?: string;
    unitId: number;
    name: string;
    type?: string;
}

interface FlavorOptionType {
    inputValue?: string;
    flavorId: number;
    name: string;
}

interface DistributorOptionType {
    inputValue?: string;
    distributorId: number;
    name: string;
}

const filterUnitOption = createFilterOptions<UnitOptionType>();
const filterFlavorOption = createFilterOptions<FlavorOptionType>();
const filterDistributorOption = createFilterOptions<DistributorOptionType>();

const drawerWidth = 300
const isMobileDevice = isMobile()

export interface Item {
    id: number
    name: string
}

export interface ContainerState {
    cards: Item[]
}

const DSBChildProductUpdate = () => {
    const history = useHistory()
    const location = useLocation()

    const [flavorList, setFlavorList] = useState<any>([])
    const [capacityUnitList, setCapacityUnitList] = useState<any>([])
    const [formUnitList, setFormUnitList] = useState<any>([])
    const [allergyList, setAllergyList] = useState<any>([])
    const [distributorList, setDistributorList] = useState<any>([])

    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [isProfileFilled, setIsProfileFilled] = useState<boolean>(false)
    const [isNutrientFilled, setIsNutrientFilled] = useState<boolean>(false)
    const [isIngredientFilled, setIsIngredientFilled] = useState<boolean>(false)
    const [isDistributorFilled, setIsDistributorFilled] = useState<boolean>(false)

    const [ChildProductClonePopupOpen, setChildProductClonePopupOpen] = useState<boolean>(false)
    const [ImagePreviewPopupOpen, setImagePreviewPopupOpen] = useState<boolean>(false)

    const handleChildProductClonePopupOpen = () => {
        setChildProductClonePopupOpen(true)
    }

    const handleChildProductClonePopupClose = () => {
        setChildProductClonePopupOpen(false)
    }

    const handleImagePreviewPopupOpen = () => {
        setImagePreviewPopupOpen(true)
    }

    const handleImagePreviewPopupClose = () => {
        setImagePreviewPopupOpen(false)
    }

    const [PPId, setPPId] = useState<number>(-1)
    const [CPId, setCPId] = useState<number>(-1)
    const [cloningCPId, setCloningId] = useState<number>(-1)
    const [parentProductName, setParentProductName] = useState<string>("정보 없음");
    const [CPImagePath, setCPImagePath] = useState<any>("")
    const [CPFlavor, setCPFlavor] = useState<any>(null);
    const [CPFlavorId, setCPFlavorId] = useState<number | null>(null);
    const [CPCapacity, setCPCapacity] = useState<number | null>(null);
    const [CPCapacityUnit, setCPCapacityUnit] = useState<any>(null);
    const [CPCapacityUnitId, setCPCapacityUnitId] = useState<number | null>(null);
    const [CPItemCode, setCPItemCode] = useState<string>("");
    const [CPLicenseNo, setCPLicenseNo] = useState<string>("");

    const [recvNutrient, setRecvNutrient] = useState<any>(null)
    const [CPNutrient, setCPNutrient] = useState<any>({
        capacity: null,
        capacityUnitId: -1,
        form: "",
        formUnitId: -1,
        calory: null,
        sodium: null,
        carbohydrate: null,
        sugars: null,
        dietaryFiber: null,
        fat: null,
        transFat: null,
        saturatedFat: null,
        cholesterol: null,
        protein: null,
    });
    const [nutrientCapacityUnit, setNutrientCapacityUnit] = useState<any>(null);
    const [nutrientFormUnit, setNutrientFormUnit] = useState<any>(null);

    const [CPVitamin, setCPVitamin] = useState<any>({
        vitaminA: null,
        vitaminD: null,
        vitaminE: null,
        vitaminK: null,
        vitaminC: null,
        thiamine: null,
        riboflavin: null,
        niacin: null,
        vitaminB6: null,
        folicAcide: null,
        vitaminB12: null,
        pantothenicAcid: null,
        biotin: null,
    });
    const [CPMineral, setCPMineral] = useState<any>({
        calcium: null,
        phosphorus: null,
        chlorine: null,
        potassium: null,
        magnesium: null,
        iron: null,
        zinc: null,
        copper: null,
        fluoride: null,
        manganese: null,
        iodine: null,
        selenium: null,
        molybdenium: null,
        chrominum: null,
    });


    const [recvNutrientList, setRecvNutrientList] = useState<any>([])
    const [ETCNutrientList, setETCNutrientList] = useState<any>([])
    const [recvAllergyIdList, setRecvAllergyIdList] = useState<any>([])
    const [allergyIdList, setAllergyIdList] = useState<any>([])
    const [distributorMapList, setDistributorMapList] = useState<any>([])

    const [CPIngredient, setCPIngredient] = useState<string>("")

    const [ETCNutrientName, setETCNutrientName] = useState<string>("")
    const [ETCCapacity, setETCCapacity] = useState<number | null>(0)
    const [ETCCapacityUnit, setETCCapacityUnit] = useState<any>(null);
    const [ETCCapacityUnitId, setETCCapacityUnitId] = useState<number | null>(null);
    const [readyToAddETCNutrient, setReadyToAddETCNutrient] = useState<boolean>(false)

    const [registeredAllergyList, setRegisteredAllergyList] = useState<any>([])
    const [allergyQuery, setAllergyQuery] = useState<string>("")
    const [allergyListInTable, setAllergyListInTable] = useState<any>([])

    const [PDDistributorName, setPDDistributorName] = useState<any>(null);
    const [PDDistributorNameId, setPDDistributorNameId] = useState<number | null>(null);
    const [PDProductLink, setPDProductLink] = useState<string>("")
    const [PDParentProductSerialNo, setPDParentProductSerialNo] = useState<string>("")
    const [PDAmount, setPDAmount] = useState<number>(0)
    const [PDProductSerialNo, setPDProductSerialNo] = useState<string>("")
    const [PDPrice, setPDPrice] = useState<number>(0)
    const [readyToAddPDInfo, setReadyToAddPDInfo] = useState<boolean>(false)

    const [registeredManufacturerImageList, setRegisteredManufacturerImageList] = useState<any>([])

    const [CPExposureStatus, setCPExposureStatus] = useState<string>("HOLD")

    const {
        getRootProps: getRootCPImagePathProps,
        getInputProps: getInputCPImagePathProps,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 10 * 10 * 1024,
        multiple: false,
        onDropRejected: (rejectedFiles) => {
            if (rejectedFiles[0] !== undefined)
                alert(JSON.stringify(rejectedFiles[0].errors))
        },
        onDropAccepted: (acceptedFile) => {
            let formData = new FormData()
            formData.append('image', acceptedFile[0])
            postImage(formData, "CPImagePath")
        },
    });

    const {
        getRootProps: getRootManufacturerImageListProps,
        getInputProps: getInputManufacturerImageListProps,
        isDragActive: isManufacturerImageListDragActive,
        isDragReject: isManufacturerImageListDragReject,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 1024 * 1024,
        maxFiles: 20,
        onDropRejected: (rejectedFiles) => {
            alert("파일 업로드에 실패했습니다! \n 아래 에러사항을 확인해주세요.\n\n" + JSON.stringify(rejectedFiles))
        },
        onDropAccepted: (acceptedFiles) => {
            const fileLen = acceptedFiles.length
            if (fileLen + registeredManufacturerImageList.length > 20) {
                alert("최대 20장까지 업로드 가능합니다! \n 추가 등록은 개발자에게 문의 바랍니다.")
                return
            }
            for (let i = 0; i < fileLen; i++) {
                let formData = new FormData()
                formData.append('image', acceptedFiles[i])
                postImage(formData, "ManufacturerImageList")
            }
        },
    });

    const postImage = async (formData: any, from: string) => {
        if (formData === undefined || !formData) return
        const curToken = getToken()
        if (curToken === '') return

        const res = await postImageRequestWithToken(
            formData,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                if (res.data.location === undefined) {
                    alert("이미지 경로를 찾을 수 없습니다. \n\n" + JSON.stringify(res.data))
                    return
                }
                if (from === "CPImagePath") {
                    setCPImagePath(res.data.location)
                } else {
                    let curManufacturerImageList = registeredManufacturerImageList
                    curManufacturerImageList.push({
                        id: curManufacturerImageList.length + 1,
                        name: res.data.location,
                    })
                    setRegisteredManufacturerImageList([...curManufacturerImageList])
                }
            } else if (res.status === 409) {
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const handleCPItemCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCPItemCode(event.target.value)
    }

    const handleCPLicenseNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCPLicenseNo(event.target.value)
    }

    const handleCPIngredientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCPIngredient(event.target.value)
    }

    const handleETCNutrientNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setETCNutrientName(event.target.value)
    }

    const handleAllergyQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllergyQuery(event.target.value)
    }

    const handlePDProductLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPDProductLink(event.target.value)
    }

    const handlePDParentProductSerialNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPDParentProductSerialNo(event.target.value)
    }

    const handlePDAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const onlyNumber = event.target.value.replace(/[^0-9]/g, '')
        setPDAmount(Number(onlyNumber))
    }

    const handlePDPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const onlyNumber = event.target.value.replace(/[^0-9]/g, '')
        setPDPrice(Number(onlyNumber))
    }

    const handlePDProductSerialNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPDProductSerialNo(event.target.value)
    }

    const handleCPExposureStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isAllFilled) setCPExposureStatus(event.target.value)
        else alert("공개 상태를 변경하기 위해서는 필수항목이 채워져있어야 합니다 :)")
    }

    const getCP = async (id: number, isCloning: boolean) => {
        if (id === undefined || id <= 0) return
        const res = await getRequest("/products/childs/" + id)
        if (res !== null) {
            if (res.status === 200) {
                const curCP = res.data
                parseCP(curCP, isCloning)
            } else if (res.status === 204) {
                alert("URL에 설정된 product의 id에 대한 정보가 없습니다.")
                history.goBack()
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateCP = async () => {
        if (!isAllFilled) return
        if (CPId === undefined || CPId <= 0) return

        const curToken = getToken()
        if (curToken === '') return

        const CPInfoBase = {
            imagePath: CPImagePath,
            flavorId: CPFlavorId,
            capacity: CPCapacity,
            capacityUnitId: CPCapacityUnitId,
            itemCode: CPItemCode,
            licenseNo: CPLicenseNo,
            ingredient: CPIngredient,
            exposureStatus: CPExposureStatus,
        }

        const nutrient = CPNutrient
        const vitamin = CPVitamin
        const mineral = CPMineral

        const CPInfo = {
            ...CPInfoBase,
            nutrient,
            vitamin,
            mineral,
            nutrientEtcList: ETCNutrientList,
            allergyIdList: allergyIdList,
            distributorMapList: distributorMapList,
            manufacturerImagePathList: deleteIdFrom(registeredManufacturerImageList),
        }

        const curSchema = getSchema('/products/childs/{childProductId}', 'PUT')
        if (curSchema) {
            try {
                await curSchema.validateAsync(CPInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            '/products/childs/' + CPId,
            CPInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                alert("Child Product가 수정됐습니다 :)")
                return
            } else if (res.status === 204) {
                alert("product ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateTempCP = async () => {
        if (!isProfileFilled) return
        //TODO: api needed
        alert("임시 저장 기능은 준비중입니다 :)")
        return
    }

    const parseCP = (recvCP: any, isCloning: boolean) => {
        if (recvCP === undefined || recvCP === null) return

        if (!isCloning) {
            if (recvCP.parentProductId !== undefined && recvCP.parentProductId !== null)
                setPPId(recvCP.parentProductId)
            if (recvCP.parentProduct.name !== undefined && recvCP.parentProduct.name !== null)
                setParentProductName(recvCP.parentProduct.name)

            if (recvCP.imagePath !== undefined && recvCP.imagePath !== null)
                setCPImagePath(recvCP.imagePath)

            if (recvCP.flavor !== undefined && recvCP.flavor !== null)
                setCPFlavor(recvCP.flavor)

            if (recvCP.capacity !== undefined && recvCP.capacity !== null)
                setCPCapacity(recvCP.capacity)

            if (recvCP.capacityUnit !== undefined && recvCP.capacityUnit !== null)
                setCPCapacityUnit(recvCP.capacityUnit)

            if (recvCP.itemCode !== undefined && recvCP.itemCode !== null)
                setCPItemCode(recvCP.itemCode)

            if (recvCP.licenseNo !== undefined && recvCP.licenseNo !== null)
                setCPLicenseNo(recvCP.licenseNo)
        }

        if (recvCP.nutrient !== undefined && recvCP.nutrient !== null)
            setRecvNutrient(recvCP.nutrient)

        if (recvCP.vitamin !== undefined && recvCP.vitamin !== null)
            setCPVitamin(recvCP.vitamin)

        if (recvCP.mineral !== undefined && recvCP.mineral !== null)
            setCPMineral(recvCP.mineral)

        if (recvCP.nutrientEtcList !== undefined && recvCP.nutrientEtcList !== null)
            setRecvNutrientList([...recvCP.nutrientEtcList])

        if (recvCP.ingredient !== undefined && recvCP.ingredient !== null)
            setCPIngredient(recvCP.ingredient)

        if (recvCP.allergyIdList !== undefined && recvCP.allergyIdList !== null)
            setRecvAllergyIdList([...recvCP.allergyIdList])

        if (recvCP.distributorMapList !== undefined && recvCP.distributorMapList !== null)
            setDistributorMapList(recvCP.distributorMapList)

        if (recvCP.manufacturerImagePathList !== undefined && recvCP.manufacturerImagePathList !== null)
            setRegisteredManufacturerImageList([...addIdTo(recvCP.manufacturerImagePathList)])

        if (recvCP.exposureStatus !== undefined && recvCP.exposureStatus !== null)
            setCPExposureStatus(recvCP.exposureStatus)

        return
    }

    const getFlavorList = async () => {
        const res = await getRequest("/flavors")
        if (res !== null) {
            if (res.status === 200) {
                if (res.data.flavorList !== undefined)
                    setFlavorList([...res.data.flavorList])
            } else if (res.status === 204) {
                setFlavorList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addFlavor = async (flavor: FlavorOptionType) => {
        if (flavor.name === undefined || flavor.name === "") return
        if (flavor.flavorId === undefined || flavor.flavorId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const flavorInfo = { name: flavor.name }
        const curSchema = getSchema('/flavors', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(flavorInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/flavors',
            flavorInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getFlavorList()
                const createdFlavor = res.data
                setCPFlavor({
                    flavorId: createdFlavor.flavorId,
                    name: createdFlavor.name,
                    type: createdFlavor.type
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getUnitList = async (type: string) => {
        if (type === undefined || type === "") return
        const res = await getRequest('/units?type=' + type)
        if (res !== null) {
            if (res.status === 200) {
                if (type === "CAPACITY")
                    setCapacityUnitList([...res.data])
                else
                    setFormUnitList([...res.data])
            } else if (res.status === 204) {
                if (type === "CAPACITY")
                    setCapacityUnitList([])
                else
                    setFormUnitList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getUnitName = (curId: number, type: string) => {
        let curUnitList = []
        if (type === "FORM")
            curUnitList = formUnitList
        else
            curUnitList = capacityUnitList
        for (let i = 0; i < curUnitList.length; i++) {
            if (curUnitList[i] === undefined) continue
            if (curUnitList[i].unitId === curId) return curUnitList[i].name
        }
        return "알수없는 ID"
    }

    const addUnit = async (unit: UnitOptionType, type: string, from: string) => {
        if (unit.name === undefined || unit.name === "") return
        if (unit.unitId === undefined || unit.unitId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const unitInfo = { name: unit.name, type: type }
        const curSchema = getSchema('/units', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(unitInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/units',
            unitInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getUnitList(type)
                const createdUnit = res.data

                if (from === "CPCapacityUnit") {
                    setCPCapacityUnit({
                        unitId: createdUnit.unitId,
                        name: createdUnit.name,
                        type: createdUnit.type
                    })
                } else if (from === "nutrientCapacityUnit") {
                    setNutrientCapacityUnit({
                        unitId: createdUnit.unitId,
                        name: createdUnit.name,
                        type: createdUnit.type
                    })
                } else if (from === "nutrientFormUnit") {
                    setNutrientFormUnit({
                        unitId: createdUnit.unitId,
                        name: createdUnit.name,
                        type: createdUnit.type
                    })
                } else if (from === "ETCCapacityUnit") {
                    setETCCapacityUnit({
                        unitId: createdUnit.unitId,
                        name: createdUnit.name,
                        type: createdUnit.type
                    })
                } else {
                    alert("제대로 추가되지 않았습니다. from: " + from)
                }
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getAllergyList = async () => {
        const res = await getRequest('/allergies')
        if (res !== null) {
            if (res.status === 200) {
                setAllergyList([...res.data])
            } else if (res.status === 204) {
                setAllergyList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getAllergyName = (curId: number) => {
        for (let i = 0; i < allergyList.length; i++) {
            if (allergyList[i] === undefined) continue
            if (allergyList[i].allergyId === curId) return allergyList[i].name
        }
        return "알수없는 ID"
    }

    const getDistributorList = async () => {
        const res = await getRequest('/distributors')
        if (res !== null) {
            if (res.status === 200) {
                setDistributorList([...res.data])
            } else if (res.status === 204) {
                setDistributorList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addDistributor = async (distributor: DistributorOptionType) => {
        if (distributor.name === undefined || distributor.name === "") return
        if (distributor.distributorId === undefined || distributor.distributorId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const distributorInfo = { name: distributor.name }
        const curSchema = getSchema('/distributors/onlyName', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(distributorInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/distributors/onlyName',
            distributorInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getDistributorList()
                const createdDistributor = res.data
                setPDDistributorName({
                    distributorId: createdDistributor.distributorId,
                    name: createdDistributor.name,
                    type: createdDistributor.type
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getDistributorName = (curId: number) => {
        for (let i = 0; i < distributorList.length; i++) {
            if (distributorList[i] === undefined) continue
            if (distributorList[i].distributorId === curId) return distributorList[i].name
        }
        return "알수없는 ID"
    }

    const moveAllergyCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setRegisteredAllergyList((prevCards: Item[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as Item],
                ],
            }),
        )
    }, [])

    const renderAllergyCard = useCallback(
        (card: { allergyId: number; name: string }, index: number) => {
            return (
                <DragableCard
                    key={card.allergyId}
                    index={index}
                    id={card.allergyId}
                    name={card.name}
                    moveCard={moveAllergyCard}
                />
            )
        },
        [],
    )

    const registerAllergy = (allergy: any) => {
        const curAllergy = {
            allergyId: allergy.allergyId,
            name: allergy.name,
        }
        let curRegisteredAllergyList = registeredAllergyList
        for (let i = 0; i < curRegisteredAllergyList.length; i++) {
            if (curRegisteredAllergyList[i].allergyId === curAllergy.allergyId) {
                alert("입력하신 항목을 이미 등록했습니다.")
                return
            }
        }
        curRegisteredAllergyList.push(curAllergy)
        setRegisteredAllergyList([...curRegisteredAllergyList])
    }

    const unregisterAllergy = (allergy: any) => {
        const curAllergy = {
            allergyId: allergy.allergyId,
            name: allergy.name,
        }
        let curRegisteredAllergyList = registeredAllergyList
        curRegisteredAllergyList = registeredAllergyList.filter((v: any) => v.allergyId !== curAllergy.allergyId)
        setRegisteredAllergyList([...curRegisteredAllergyList])
    }

    const moveManufacturerImagePathCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setRegisteredManufacturerImageList((prevCards: Item[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as Item],
                ],
            }),
        )
    }, [])

    const renderManufacturerImagePathCard = useCallback(
        (card: { id: number; name: string }, index: number) => {
            const imageURL = new URL(card.name)
            const imageName = imageURL.pathname
            return (
                <DragableCard
                    key={card.id}
                    index={index}
                    id={card.id}
                    name={imageName}
                    moveCard={moveManufacturerImagePathCard}
                />
            )
        },
        [],
    )

    const unregisterManufacturerImagePath = (imagePath: any) => {
        let curImagePathList = registeredManufacturerImageList
        curImagePathList = registeredManufacturerImageList.filter((v: any) => v !== imagePath)
        setRegisteredManufacturerImageList([...curImagePathList])
    }

    const initPDInfo = () => {
        setPDDistributorName(null)
        setPDDistributorNameId(-1)
        setPDProductLink("")
        setPDParentProductSerialNo("")
        setPDAmount(0)
        setPDPrice(0)
        setPDProductSerialNo("")
    }

    const addPDInfo = () => {
        if (!readyToAddPDInfo) return
        let curPD = {
            distributorId: PDDistributorNameId,
            productLink: PDProductLink,
            parentProductSerialNo: PDParentProductSerialNo,
            amount: PDAmount,
            price: PDPrice,
            productSerialNo: PDProductSerialNo,
        }
        let curDistributorMapList = distributorMapList

        for (let i = 0; i < curDistributorMapList.length; i++) {
            if (JSON.stringify(curDistributorMapList[i]) === JSON.stringify(curPD)) {
                alert("입력하신 판매처를 이미 등록했습니다.")
                return
            }
        }

        curDistributorMapList.push(curPD)
        setDistributorMapList([...curDistributorMapList])
        initPDInfo()
    }

    const deletePDInfo = (curPD: any) => {
        let curDistributorMapList = distributorMapList

        curDistributorMapList = distributorMapList.filter((v: any) => v !== curPD)
        setDistributorMapList([...curDistributorMapList])
    }

    const clickToCopy = async (url: string) => {
        try {
            await navigator.clipboard.writeText(url)
            alert('클립보드에 복사됐습니다 :)')
        } catch (error) {
            alert('url 복사에 실패했습니다. \n\n' + 'url: ' + url)
        }
    }

    const initETCNutrient = () => {
        setETCNutrientName("")
        setETCCapacity(0)
        setETCCapacityUnit(null)
        setETCCapacityUnitId(-1)
    }

    const addETCNutrient = () => {
        if (!readyToAddETCNutrient) return
        let curETCNutrient = {
            name: ETCNutrientName,
            capacity: ETCCapacity,
            capacityUnitId: ETCCapacityUnitId,
        }
        let curETCNutrientList = ETCNutrientList

        for (let i = 0; i < curETCNutrientList.length; i++) {
            if (JSON.stringify(curETCNutrientList[i]) === JSON.stringify(curETCNutrient)) {
                alert("입력하신 영양성분을 이미 등록했습니다.")
                return
            }
        }

        curETCNutrientList.push(curETCNutrient)
        setETCNutrientList([...curETCNutrientList])
        initETCNutrient()
    }

    const deleteETCNutrient = (curETCNutrient: any) => {
        let curETCNutrientList = ETCNutrientList

        curETCNutrientList = ETCNutrientList.filter((v: any) => v !== curETCNutrient)
        setETCNutrientList([...curETCNutrientList])
    }


    const addIdTo = (imageList: any) => {
        let curImageList: any = []
        if (imageList === undefined || imageList.length === 0) return curImageList

        for (let i = 0; i < imageList.length; i++) {
            const curOption = {
                id: i + 1,
                name: imageList[i],
            }
            curImageList.push(curOption)
        }

        return curImageList
    }

    const deleteIdFrom = (imageList: any) => {
        let curImageList: any = []
        if (imageList === undefined || imageList.length === 0) return curImageList

        curImageList = imageList.map((option: any) => option.name)

        return curImageList
    }

    const parseQS = (curLocation: any) => {
        const params = new URLSearchParams(curLocation.search)
        const recvChildProductId = params.get("childProductId")

        if (recvChildProductId && recvChildProductId !== "") {
            const curId = decodeURI(recvChildProductId)
            if (!isPositiveInteger(curId)) return
            setCPId(Number(curId))
        }
    }

    useEffect(() => {
        if (CPImagePath === undefined || CPImagePath.length <= 0) {
            setIsProfileFilled(false)
            return
        }
        if (CPFlavorId === undefined || CPFlavorId === null || CPFlavorId <= 0) {
            setIsProfileFilled(false)
            return
        }
        if (CPCapacity === undefined || CPCapacity === null || CPCapacity <= 0) {
            setIsProfileFilled(false)
            return
        }
        if (CPCapacityUnitId === undefined || CPCapacityUnitId === null || CPCapacityUnitId <= 0) {
            setIsProfileFilled(false)
            return
        }
        setIsProfileFilled(true)
    }, [CPImagePath, CPFlavorId, CPCapacity, CPCapacityUnitId])

    useEffect(() => {
        if (CPNutrient.capacity === undefined || CPNutrient.capacity === null || CPNutrient.capacity <= 0) {
            setIsNutrientFilled(false)
            return
        }
        if (CPNutrient.capacityUnitId === undefined || CPNutrient.capacityUnitId === null || CPNutrient.capacityUnitId <= 0) {
            setIsNutrientFilled(false)
            return
        }
        if (CPNutrient.form === undefined || CPNutrient.form === null || CPNutrient.form <= 0) {
            setIsNutrientFilled(false)
            return
        }
        if (CPNutrient.formUnitId === undefined || CPNutrient.formUnitId === null || CPNutrient.formUnitId <= 0) {
            setIsNutrientFilled(false)
            return
        }
        setIsNutrientFilled(true)
    }, [JSON.stringify(CPNutrient)])

    useEffect(() => {
        if (CPIngredient === undefined || CPIngredient.length <= 0) {
            setIsIngredientFilled(false)
            return
        }
        setIsIngredientFilled(true)
    }, [CPIngredient])

    useEffect(() => {
        if (distributorMapList === undefined || distributorMapList.length <= 0) {
            setIsDistributorFilled(false)
            return
        }
        setIsDistributorFilled(true)
    }, [JSON.stringify(distributorMapList)])

    useEffect(() => {
        if (!isProfileFilled || !isNutrientFilled || !isIngredientFilled || !isDistributorFilled) {
            setIsAllFilled(false)
            return
        }
        setIsAllFilled(true)
    }, [isProfileFilled, isNutrientFilled, isIngredientFilled, isDistributorFilled])

    useEffect(() => {
        if (!CPFlavor)
            return
        if (CPFlavor.flavorId !== undefined && CPFlavor.flavorId === -1)
            addFlavor(CPFlavor)

        setCPFlavorId(CPFlavor.flavorId)
    }, [JSON.stringify(CPFlavor)])

    useEffect(() => {
        if (!CPCapacityUnit)
            return
        if (CPCapacityUnit.unitId !== undefined && CPCapacityUnit.unitId === -1)
            addUnit(CPCapacityUnit, "CAPACITY", "CPCapacityUnit")

        setCPCapacityUnitId(CPCapacityUnit.unitId)
    }, [JSON.stringify(CPCapacityUnit)])

    useEffect(() => {
        if (!nutrientCapacityUnit)
            return
        if (nutrientCapacityUnit.unitId !== undefined && nutrientCapacityUnit.unitId === -1)
            addUnit(nutrientCapacityUnit, "CAPACITY", "nutrientCapacityUnit")

        setCPNutrient((prevState: any) => ({
            ...prevState,
            capacityUnitId: nutrientCapacityUnit.unitId
        }))
    }, [JSON.stringify(nutrientCapacityUnit)])

    useEffect(() => {
        if (!nutrientFormUnit)
            return
        if (nutrientFormUnit.unitId !== undefined && nutrientFormUnit.unitId === -1)
            addUnit(nutrientFormUnit, "FORM", "nutrientFormUnit")

        setCPNutrient((prevState: any) => ({
            ...prevState,
            formUnitId: nutrientFormUnit.unitId
        }))
    }, [JSON.stringify(nutrientFormUnit)])

    useEffect(() => {
        if (!ETCCapacityUnit)
            return
        if (ETCCapacityUnit.unitId !== undefined && ETCCapacityUnit.unitId === -1)
            addUnit(ETCCapacityUnit, "CAPACITY", "ETCCapacityUnit")

        setETCCapacityUnitId(ETCCapacityUnit.unitId)
    }, [JSON.stringify(ETCCapacityUnit)])

    useEffect(() => {
        if (!PDDistributorName)
            return
        if (PDDistributorName.distributorId !== undefined && PDDistributorName.distributorId === -1)
            addDistributor(PDDistributorName)

        setPDDistributorNameId(PDDistributorName.distributorId)
    }, [JSON.stringify(PDDistributorName)])

    useEffect(() => {
        if (ETCNutrientName === undefined || ETCNutrientName === "") {
            setReadyToAddETCNutrient(false)
            return
        }
        if (ETCCapacity === undefined || ETCCapacity === null || ETCCapacity < 0) {
            setReadyToAddETCNutrient(false)
            return
        }
        if (ETCCapacityUnitId === undefined || ETCCapacityUnitId === null || ETCCapacityUnitId === -1) {
            setReadyToAddETCNutrient(false)
            return
        }
        setReadyToAddETCNutrient(true)
    }, [ETCNutrientName, ETCCapacity, ETCCapacityUnitId])

    useEffect(() => {
        if (PDDistributorNameId === undefined || PDDistributorNameId === -1) {
            setReadyToAddPDInfo(false)
            return
        }
        if (PDProductLink === undefined || PDProductLink === "") {
            setReadyToAddPDInfo(false)
            return
        }
        if (PDParentProductSerialNo === undefined || PDParentProductSerialNo === "") {
            setReadyToAddPDInfo(false)
            return
        }
        if (PDAmount === undefined || PDAmount === 0) {
            setReadyToAddPDInfo(false)
            return
        }
        if (PDPrice === undefined || PDPrice === 0) {
            setReadyToAddPDInfo(false)
            return
        }
        if (PDProductSerialNo === undefined) {
            setReadyToAddPDInfo(false)
            return
        }

        setReadyToAddPDInfo(true)
    }, [PDDistributorNameId, PDProductLink, PDParentProductSerialNo, PDAmount, PDPrice, PDProductSerialNo])

    useEffect(() => {
        if (allergyList === undefined || allergyList.length === 0) {
            setAllergyListInTable([])
            return
        }

        let curAllergyList: any[] = allergyList

        curAllergyList = curAllergyList.filter((v: any) => {
            if (v.name.includes(allergyQuery))
                return v
        })
        setAllergyListInTable([...curAllergyList])
    }, [JSON.stringify(allergyList), allergyQuery])

    useEffect(() => {
        if (allergyList === undefined || allergyList.length === 0) return
        if (recvAllergyIdList === undefined || recvAllergyIdList.length === 0) return

        let curRegisteredAllergyList = []
        for (let i = 0; i < recvAllergyIdList.length; i++) {
            curRegisteredAllergyList.push({
                name: getAllergyName(recvAllergyIdList[i]),
                allergyId: recvAllergyIdList[i],
            })
        }
        setRegisteredAllergyList([...curRegisteredAllergyList])
    }, [JSON.stringify(allergyList), JSON.stringify(recvAllergyIdList)])

    useEffect(() => {
        if (capacityUnitList === undefined || capacityUnitList.length === 0) return
        if (formUnitList === undefined || formUnitList.length === 0) return
        if (recvNutrient === undefined || recvNutrient === null) return
        setCPNutrient(recvNutrient)

        const curCapacityUnitId = recvNutrient.capacityUnitId
        const curFormUnitId = recvNutrient.formUnitId
        if (curCapacityUnitId <= 0) return
        if (curFormUnitId <= 0) return

        setNutrientCapacityUnit({
            unitId: curCapacityUnitId,
            name: getUnitName(curCapacityUnitId, "CAPACITY"),
        })
        setNutrientFormUnit({
            unitId: curFormUnitId,
            name: getUnitName(curFormUnitId, "FORM"),
        })
    }, [JSON.stringify(capacityUnitList), JSON.stringify(formUnitList), JSON.stringify(recvNutrient)])


    useEffect(() => {
        if (recvNutrientList === undefined || recvNutrientList.length === 0) return

        let curNutrientList = []
        for (let i = 0; i < recvNutrientList.length; i++) {
            curNutrientList.push({
                name: recvNutrientList[i].name,
                capacity: recvNutrientList[i].capacity,
                capacityUnitId: recvNutrientList[i].capacityUnitId,
            })
        }
        setETCNutrientList([...curNutrientList])
    }, [JSON.stringify(recvNutrientList)])

    useEffect(() => {
        if (cloningCPId === undefined || cloningCPId <= 0) return
        getCP(cloningCPId, true)
    }, [cloningCPId])

    useEffect(() => {
        if (registeredAllergyList === undefined || registeredAllergyList.length === 0) {
            setAllergyIdList([])
            return
        }

        let curAllergyList: any[] = registeredAllergyList
        const curAllergyIdList = curAllergyList.map((v: any) => {
            return v.allergyId
        })

        setAllergyIdList([...curAllergyIdList])
    }, [JSON.stringify(registeredAllergyList), JSON.stringify(recvAllergyIdList)])

    useEffect(() => {
        if (CPId === undefined || CPId <= 0) return

        getCP(CPId, false)
    }, [String(CPId)])

    useEffect(() => {
        parseQS(location)
    }, [location])

    useEffect(() => {
        getFlavorList()
        getUnitList("CAPACITY")
        getUnitList("FORM")
        getAllergyList()
        getDistributorList()
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>Child 상품 수정</BoxTitle>
                        <BoxSubTitle>{"단성비 > 상품 관리 > 상품 등록 > Child 상품 수정"}</BoxSubTitle>
                    </BoxDiv>
                    <BoxDiv bgColor={!isProfileFilled ? "rgba(255 0 0 / .05)" : "#FFFFFF"}>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>Child 상품 프로필</BoxTitleKey>
                            <BoxTitleValue>
                                <PrimaryButton value="다른 Child 상품 불러오기" size="medium" color="primary" onClickEvent={() => handleChildProductClonePopupOpen()} />
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxContentFlexStartDiv>
                            {
                                CPImagePath !== undefined && CPImagePath !== null && CPImagePath !== "" ?
                                    <DropzonePreviewDiv>
                                        <ProfileImage src={CPImagePath} alt={CPImagePath} />
                                        <ProfileImageCancleDiv onClick={() => setCPImagePath("")}>
                                            <EditOutlinedIcon fontSize="inherit" color="inherit" />
                                            <ProfileImageEditText>삭제</ProfileImageEditText>
                                        </ProfileImageCancleDiv>
                                    </DropzonePreviewDiv>
                                    :
                                    <BoxContentProfileKeyDiv {...getRootCPImagePathProps()}>
                                        <input {...getInputCPImagePathProps()} />
                                        <BoxContentProfileKeyPlusDiv>
                                            <AddIcon />
                                        </BoxContentProfileKeyPlusDiv>
                                        <ProfileImageEditDiv>
                                            <EditOutlinedIcon fontSize="inherit" color="inherit" />
                                            <ProfileImageEditText>수정하기</ProfileImageEditText>
                                        </ProfileImageEditDiv>
                                    </BoxContentProfileKeyDiv>
                            }
                            <BoxContentProfileValueDiv>
                                <BoxContentFlexStartDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>Parent 상품이름</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <ParentProductNameDiv>{parentProductName}</ParentProductNameDiv>
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexStartDiv>
                                <BoxContentFlexStartDiv>
                                    <BoxContentInputFlexDiv width="50%">
                                        <BoxContentInputKeyDiv>맛 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <Autocomplete
                                                id="CPFlavor"
                                                value={CPFlavor}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        setCPFlavor({
                                                            flavorId: -1,
                                                            name: newValue.inputValue,
                                                        });
                                                    } else {
                                                        setCPFlavor(newValue);
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filterFlavorOption(options, params);

                                                    const { inputValue } = params;
                                                    const isExisting = options.some((option) => inputValue === option.name);
                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            name: `"${inputValue}" 추가!`,
                                                            flavorId: -1
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                options={flavorList}
                                                getOptionLabel={(option) => {
                                                    return option.name;
                                                }}
                                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        size="small"
                                                        fullWidth
                                                        label='맛'
                                                    />
                                                )}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                    <BoxContentInputFlexDiv width="50%">
                                        <BoxContentInputKeyDiv>총 용량 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueFlexDiv>
                                            <BoxFiftyPercentDiv>
                                                <TextField
                                                    id="CPCapacity"
                                                    size="small"
                                                    fullWidth
                                                    label='용량'
                                                    value={CPCapacity}
                                                    type="number"
                                                    onChange={event => {
                                                        const valueNum = Number(event.target.value)
                                                        if (valueNum < 0) return
                                                        setCPCapacity(valueNum)
                                                    }}
                                                />
                                            </BoxFiftyPercentDiv>
                                            <BoxFiftyPercentDiv>
                                                <Autocomplete
                                                    id="CPCapacityUnit"
                                                    value={CPCapacityUnit}
                                                    onChange={(event, newValue) => {
                                                        if (newValue && newValue.inputValue) {
                                                            setCPCapacityUnit({
                                                                unitId: -1,
                                                                name: newValue.inputValue,
                                                            });
                                                        } else {
                                                            setCPCapacityUnit(newValue);
                                                        }
                                                    }}
                                                    filterOptions={(options, params) => {
                                                        const filtered = filterUnitOption(options, params);

                                                        const { inputValue } = params;
                                                        const isExisting = options.some((option) => inputValue === option.name);
                                                        if (inputValue !== '' && !isExisting) {
                                                            filtered.push({
                                                                inputValue,
                                                                name: `"${inputValue}" 추가!`,
                                                                unitId: -1
                                                            });
                                                        }

                                                        return filtered;
                                                    }}
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                    options={capacityUnitList}
                                                    getOptionLabel={(option) => {
                                                        return option.name;
                                                    }}
                                                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                    freeSolo
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            size="small"
                                                            fullWidth
                                                            label='단위(용량)'
                                                        />
                                                    )}
                                                />
                                            </BoxFiftyPercentDiv>
                                        </BoxContentInputValueFlexDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexStartDiv>
                                <BoxContentFlexStartDiv>
                                    <BoxContentInputFlexDiv width="50%">
                                        <BoxContentInputKeyDiv>식품코드</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="CPItemCode"
                                                size="small"
                                                fullWidth
                                                value={CPItemCode}
                                                onChange={handleCPItemCodeChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                    <BoxContentInputFlexDiv width="50%">
                                        <BoxContentInputKeyDiv>품목보고번호</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="CPLicenseNo"
                                                size="small"
                                                fullWidth
                                                value={CPLicenseNo}
                                                onChange={handleCPLicenseNoChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexStartDiv>
                            </BoxContentProfileValueDiv>
                        </BoxContentFlexStartDiv>
                    </BoxDiv>
                    {
                        CPNutrient !== undefined &&
                        <BoxDiv bgColor={!isNutrientFilled ? "rgba(255 0 0 / .05)" : "#FFFFFF"}>
                            <BoxTitleFlexDiv>
                                <BoxTitleKey>영양성분 (1회 제공량 기준)</BoxTitleKey>
                            </BoxTitleFlexDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="50%">
                                    <BoxContentInputKeyDiv>용량 *</BoxContentInputKeyDiv>
                                    <BoxContentInputValueFlexDiv>
                                        <BoxFiftyPercentDiv>
                                            <TextField
                                                id="CPNutrient.capacity"
                                                size="small"
                                                fullWidth
                                                label='용량'
                                                value={CPNutrient.capacity}
                                                type="number"
                                                onChange={event => {
                                                    const valueNum = Number(event.target.value)
                                                    if (valueNum < 0) return
                                                    setCPNutrient((prevState: any) => ({
                                                        ...prevState,
                                                        capacity: valueNum
                                                    }))
                                                }}
                                            />
                                        </BoxFiftyPercentDiv>
                                        <BoxFiftyPercentDiv>
                                            <Autocomplete
                                                id="nutrientCapacityUnit"
                                                value={nutrientCapacityUnit}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        setNutrientCapacityUnit({
                                                            unitId: -1,
                                                            name: newValue.inputValue,
                                                        });
                                                    } else {
                                                        setNutrientCapacityUnit(newValue);
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filterUnitOption(options, params);

                                                    const { inputValue } = params;
                                                    const isExisting = options.some((option) => inputValue === option.name);
                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            name: `"${inputValue}" 추가!`,
                                                            unitId: -1
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                options={capacityUnitList}
                                                getOptionLabel={(option) => {
                                                    return option.name;
                                                }}
                                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        size="small"
                                                        fullWidth
                                                        label='단위(용량)'
                                                    />
                                                )}
                                            />
                                        </BoxFiftyPercentDiv>
                                    </BoxContentInputValueFlexDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="50%">
                                    <BoxContentInputKeyDiv>제품형태 *</BoxContentInputKeyDiv>
                                    <BoxContentInputValueFlexDiv>
                                        <BoxFiftyPercentDiv>
                                            <TextField
                                                id="CPNutrient.form"
                                                size="small"
                                                fullWidth
                                                label='제품형태'
                                                value={CPNutrient.form}
                                                type="number"
                                                onChange={event => {
                                                    const valueNum = Number(event.target.value)
                                                    if (valueNum < 0) return
                                                    setCPNutrient((prevState: any) => ({
                                                        ...prevState,
                                                        form: valueNum
                                                    }))
                                                }}
                                            />
                                        </BoxFiftyPercentDiv>
                                        <BoxFiftyPercentDiv>
                                            <Autocomplete
                                                id="nutrientFormUnit"
                                                value={nutrientFormUnit}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        setNutrientFormUnit({
                                                            unitId: -1,
                                                            name: newValue.inputValue,
                                                        });
                                                    } else {
                                                        setNutrientFormUnit(newValue);
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filterUnitOption(options, params);

                                                    const { inputValue } = params;
                                                    const isExisting = options.some((option) => inputValue === option.name);
                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            name: `"${inputValue}" 추가!`,
                                                            unitId: -1
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                options={formUnitList}
                                                getOptionLabel={(option) => {
                                                    return option.name;
                                                }}
                                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        size="small"
                                                        fullWidth
                                                        label='단위(형태)'
                                                    />
                                                )}
                                            />
                                        </BoxFiftyPercentDiv>
                                    </BoxContentInputValueFlexDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>칼로리</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPNutrient.calory"
                                            size="small"
                                            fullWidth
                                            label='kcal'
                                            value={CPNutrient.calory}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPNutrient((prevState: any) => ({
                                                    ...prevState,
                                                    calory: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>나트륨</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPNutrient.sodium"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPNutrient.sodium}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPNutrient((prevState: any) => ({
                                                    ...prevState,
                                                    sodium: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>탄수화물</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPNutrient.carbohydrate"
                                            size="small"
                                            fullWidth
                                            label='g'
                                            value={CPNutrient.carbohydrate}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPNutrient((prevState: any) => ({
                                                    ...prevState,
                                                    carbohydrate: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>당류</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPNutrient.sugars"
                                            size="small"
                                            fullWidth
                                            label='g'
                                            value={CPNutrient.sugars}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPNutrient((prevState: any) => ({
                                                    ...prevState,
                                                    sugars: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>식이섬유</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPNutrient.dietaryFiber"
                                            size="small"
                                            fullWidth
                                            label='g'
                                            value={CPNutrient.dietaryFiber}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPNutrient((prevState: any) => ({
                                                    ...prevState,
                                                    dietaryFiber: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>지방</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPNutrient.fat"
                                            size="small"
                                            fullWidth
                                            label='g'
                                            value={CPNutrient.fat}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPNutrient((prevState: any) => ({
                                                    ...prevState,
                                                    fat: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>트랜스지방</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPNutrient.transFat"
                                            size="small"
                                            fullWidth
                                            label='g'
                                            value={CPNutrient.transFat}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPNutrient((prevState: any) => ({
                                                    ...prevState,
                                                    transFat: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>포화지방</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPNutrient.saturatedFat"
                                            size="small"
                                            fullWidth
                                            label='g'
                                            value={CPNutrient.saturatedFat}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPNutrient((prevState: any) => ({
                                                    ...prevState,
                                                    saturatedFat: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>콜레스테롤</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPNutrient.cholesterol"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPNutrient.cholesterol}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPNutrient((prevState: any) => ({
                                                    ...prevState,
                                                    cholesterol: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>단백질</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPNutrient.protein"
                                            size="small"
                                            fullWidth
                                            label='g'
                                            value={CPNutrient.protein}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPNutrient((prevState: any) => ({
                                                    ...prevState,
                                                    protein: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                        </BoxDiv>
                    }
                    {
                        CPVitamin !== undefined &&
                        <BoxDiv>
                            <BoxTitleFlexDiv>
                                <BoxTitleKey>비타민</BoxTitleKey>
                            </BoxTitleFlexDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>비타민A</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.vitaminA"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPVitamin.vitaminA}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    vitaminA: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>비타민D</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.vitaminD"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPVitamin.vitaminD}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    vitaminD: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>비타민E</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.vitaminE"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPVitamin.vitaminE}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    vitaminE: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>비타민K</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.vitaminK"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPVitamin.vitaminK}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    vitaminK: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>비타민C</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.vitaminC"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPVitamin.vitaminC}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    vitaminC: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>티아민</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.thiamine"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPVitamin.thiamine}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    thiamine: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>리보플라빈</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.riboflavin"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPVitamin.riboflavin}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    riboflavin: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>니아신</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.niacin"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPVitamin.niacin}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    niacin: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>비타민B6</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.vitaminB6"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPVitamin.vitaminB6}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    vitaminB6: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>엽산</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.folicAcide"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPVitamin.folicAcide}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    folicAcide: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>비타민B12</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.vitaminB12"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPVitamin.vitaminB12}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    vitaminB12: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>판토텐산</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.pantothenicAcid"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPVitamin.pantothenicAcid}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    pantothenicAcid: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>비오틴</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPVitamin.biotin"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPVitamin.biotin}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPVitamin((prevState: any) => ({
                                                    ...prevState,
                                                    biotin: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                        </BoxDiv>
                    }
                    {
                        CPMineral !== undefined &&
                        <BoxDiv>
                            <BoxTitleFlexDiv>
                                <BoxTitleKey>무기질</BoxTitleKey>
                            </BoxTitleFlexDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>칼슘</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.calcium"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPMineral.calcium}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    calcium: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>인</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.phosphorus"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPMineral.phosphorus}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    phosphorus: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>염소</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.chlorine"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPMineral.chlorine}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    chlorine: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>칼륨</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.potassium"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPMineral.potassium}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    potassium: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>마그네슘</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.magnesium"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPMineral.magnesium}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    magnesium: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>철분</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.iron"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPMineral.iron}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    iron: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>아연</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.zinc"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPMineral.zinc}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    zinc: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>구리</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.copper"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPMineral.copper}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    copper: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>불소</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.fluoride"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPMineral.fluoride}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    fluoride: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>망간</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.manganese"
                                            size="small"
                                            fullWidth
                                            label='mg'
                                            value={CPMineral.manganese}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    manganese: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>요오드</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.iodine"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPMineral.iodine}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    iodine: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>셀레늄</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.selenium"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPMineral.selenium}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    selenium: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                            <BoxContentFlexStartDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>몰리브데넘</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.molybdenium"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPMineral.molybdenium}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    molybdenium: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                                <BoxContentInputFlexDiv width="25%">
                                    <BoxContentInputKeyDiv>크롬</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <TextField
                                            id="CPMineral.chrominum"
                                            size="small"
                                            fullWidth
                                            label='mcg'
                                            value={CPMineral.chrominum}
                                            type="number"
                                            onChange={event => {
                                                const valueNum = Number(event.target.value)
                                                if (valueNum < 0) return
                                                setCPMineral((prevState: any) => ({
                                                    ...prevState,
                                                    chrominum: valueNum
                                                }))
                                            }}
                                        />
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexStartDiv>
                        </BoxDiv>
                    }
                    <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>기타 영양성분</BoxTitleKey>
                        </BoxTitleFlexDiv>
                        <BoxContentFlexDiv
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    if (readyToAddETCNutrient) {
                                        addETCNutrient()
                                    } else {
                                        alert("필수 정보를 채워주세요 :)")
                                    }
                                }
                            }}
                        >
                            <BoxFlexStartDiv>
                                <BoxContentInputDiv width="33%">
                                    <TextField
                                        id="ETCNutrientName"
                                        size="small"
                                        fullWidth
                                        label='영양성분명 *'
                                        value={ETCNutrientName}
                                        onChange={handleETCNutrientNameChange}
                                    />
                                </BoxContentInputDiv>
                                <BoxContentInputDiv width="33%">
                                    <TextField
                                        id="ETCCapacity"
                                        size="small"
                                        fullWidth
                                        label='내용량 *'
                                        value={ETCCapacity}
                                        type="number"
                                        onChange={event => {
                                            const valueNum = Number(event.target.value)
                                            if (valueNum < 0) return
                                            setETCCapacity(valueNum)
                                        }}
                                    />
                                </BoxContentInputDiv>
                                <BoxContentInputDiv width="33%">
                                    <Autocomplete
                                        id="ETCCapacityUnit"
                                        value={ETCCapacityUnit}
                                        onChange={(event, newValue) => {
                                            if (newValue && newValue.inputValue) {
                                                setETCCapacityUnit({
                                                    unitId: -1,
                                                    name: newValue.inputValue,
                                                });
                                            } else {
                                                setETCCapacityUnit(newValue);
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filterUnitOption(options, params);

                                            const { inputValue } = params;
                                            const isExisting = options.some((option) => inputValue === option.name);
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.push({
                                                    inputValue,
                                                    name: `"${inputValue}" 추가!`,
                                                    unitId: -1
                                                });
                                            }

                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        options={capacityUnitList}
                                        getOptionLabel={(option) => {
                                            return option.name;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                size="small"
                                                fullWidth
                                                label='단위(용량) *'
                                            />
                                        )}
                                    />
                                </BoxContentInputDiv>
                            </BoxFlexStartDiv>
                            <BoxFlexEndDiv>
                                {
                                    readyToAddETCNutrient ?
                                        <PrimaryButton value="등록" size="small" color="primary" onClickEvent={(ev: { preventDefault: () => void; }) => {
                                            addETCNutrient()
                                            ev.preventDefault()
                                        }
                                        } />
                                        :
                                        <PrimaryButton disabled={true} value="등록" size="small" color="primary" />
                                }
                            </BoxFlexEndDiv>
                        </BoxContentFlexDiv>
                        <BoxDownArrowDiv>
                            <ArrowDownwardRoundedIcon fontSize="inherit" />
                        </BoxDownArrowDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>등록된 기타 영양성분들</BoxTitleKey>
                        </BoxTitleFlexDiv>
                        {
                            ETCNutrientList.length !== 0 &&
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="ETCNutrientList table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>영양성분명</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>내용량</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>단위(용량)</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ETCNutrientList.map((row: any) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="left">{row.capacity}</TableCell>
                                                    <TableCell align="left">{getUnitName(row.capacityUnitId, "CAPACITY")}</TableCell>
                                                    <TableCell align="right">
                                                        <PrimaryButton value="삭제" size="small" color="error" onClickEvent={() => deleteETCNutrient(row)} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        }
                    </BoxDiv>
                    <BoxDiv bgColor={!isIngredientFilled ? "rgba(255 0 0 / .05)" : "#FFFFFF"}>
                        <BoxContentDiv>
                            <BoxTitleFlexDiv>
                                <BoxTitleKey>원료 *</BoxTitleKey>
                            </BoxTitleFlexDiv>
                            <BoxSubTitle>{"원재료명을 꼭 순서대로 입력해주세요."}</BoxSubTitle>
                            <BoxMarginDivider />
                            <TextField
                                id="CPIngredient"
                                size="small"
                                fullWidth
                                label='원재료명, 띄어쓰기, 특수문자, 오타없이 입력'
                                value={CPIngredient}
                                onChange={handleCPIngredientChange}
                            />
                        </BoxContentDiv>
                    </BoxDiv>
                    <BoxDiv>
                        <BoxContentDiv>
                            <BoxTitleFlexDiv>
                                <BoxTitleKey>알레르기</BoxTitleKey>
                            </BoxTitleFlexDiv>
                            <TextField
                                id="cc-search-textfield"
                                size="small"
                                label="알레르기명 검색"
                                fullWidth
                                value={allergyQuery}
                                onChange={handleAllergyQueryChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <BoxMarginDivider />
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="allergyListInTable table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>알레르기명</TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allergyListInTable.map((row: any) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            row.isRegistered ?
                                                                <PrimaryButton disabled={true} value="등록" size="small" color="primary" />
                                                                :
                                                                <PrimaryButton value="등록" size="small" color="primary" onClickEvent={() => registerAllergy(row)} />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </BoxContentDiv>
                        <BoxDownArrowDiv>
                            <ArrowDownwardRoundedIcon fontSize="inherit" />
                        </BoxDownArrowDiv>
                        <BoxContentDiv>
                            <BoxTitleFlexDiv>
                                <BoxTitleKey>등록된 알레르기</BoxTitleKey>
                            </BoxTitleFlexDiv>
                            <BoxSubTitle>{"드래그 앤 드랍으로 노출 순서 변경 가능 (모바일 지원X)"}</BoxSubTitle>
                            <BoxMarginDivider />
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="registeredAllergyList table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: '15%', fontWeight: 'bold', fontSize: 15 }}>노출 순서</TableCell>
                                                <TableCell align="left" sx={{ width: '70%', fontWeight: 'bold', fontSize: 15 }}>알레르기명</TableCell>
                                                <TableCell align="center" sx={{ width: '15%', fontWeight: 'bold', fontSize: 15 }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <DndProvider backend={HTML5Backend}>
                                                {
                                                    registeredAllergyList.map((row: any, index: any) => (
                                                        <TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" sx={{ width: '13%' }}>
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ width: '70%' }}>{renderAllergyCard(row, index)}</TableCell>
                                                            <TableCell align="right" sx={{ width: '15%' }}>
                                                                <PrimaryButton value="삭제" size="small" color="error" onClickEvent={() => unregisterAllergy(row)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </DndProvider>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </BoxContentDiv>
                    </BoxDiv>
                    <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>제조사 상품 정보 (최대 10개, 노출 순서 확인)</BoxTitleKey>
                        </BoxTitleFlexDiv>
                        <BoxFlexStartDiv>
                            <DropzoneInputDiv>
                                {
                                    registeredManufacturerImageList.length >= 20 ?
                                        <DropzoneDiv>
                                            <DropzoneRejectDiv>
                                                <div>이미지 업로드는 20장까지 허용됩니다!</div>
                                                <div>추가하고 싶은 경우 개발자에게 문의 바람</div>
                                            </DropzoneRejectDiv>
                                        </DropzoneDiv>
                                        :
                                        <DropzoneDiv {...getRootManufacturerImageListProps()}>
                                            <input {...getInputManufacturerImageListProps()} />
                                            {
                                                isManufacturerImageListDragReject ?
                                                    <DropzoneRejectDiv>
                                                        <div>1. File Size는 1MB 이하</div>
                                                        <div>2. 이미지 업로드는 20장까지 허용</div>
                                                        <div>3. 이미지 파일 타입 에러는 개발자에게 문의 바람</div>
                                                    </DropzoneRejectDiv>
                                                    :
                                                    <>
                                                        {
                                                            isManufacturerImageListDragActive ?
                                                                <DropzoneHelpDiv>추가할 이미지들을 여기로 드랍해주세요 :)</DropzoneHelpDiv> :
                                                                <DropzoneHelpDiv>
                                                                    <div>이미지들을 드래그 후 드랍하거나</div>
                                                                    <div>여기를 클릭해서 추가해주세요 :)</div>
                                                                </DropzoneHelpDiv>
                                                        }
                                                    </>
                                            }
                                        </DropzoneDiv>
                                }
                            </DropzoneInputDiv>
                            <DropzoneArrowDiv>
                                <ArrowForwardDiv>
                                    <ArrowForwardRoundedIcon fontSize="inherit" />
                                </ArrowForwardDiv>
                            </DropzoneArrowDiv>
                            <DropzoneOutputDiv>
                                <BoxTitleFlexDiv>
                                    <BoxTitleKey>등록된 제조사 상품 정보</BoxTitleKey>
                                </BoxTitleFlexDiv>
                                <BoxSubTitle>{"드래그 앤 드랍으로 노출 순서 변경 가능 (모바일 지원X)"}</BoxSubTitle>
                                <BoxMarginDivider />
                                <Paper sx={{ width: '100%', mb: 2 }}>
                                    <TableContainer>
                                        <Table aria-label="registeredManufacturerImageList table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: '20%', fontWeight: 'bold', fontSize: 15 }}>노출 순서</TableCell>
                                                    <TableCell align="left" sx={{ width: '60%', fontWeight: 'bold', fontSize: 15 }}>이미지 이름</TableCell>
                                                    <TableCell align="center" sx={{ width: '20%', fontWeight: 'bold', fontSize: 15 }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <DndProvider backend={HTML5Backend}>
                                                    {
                                                        registeredManufacturerImageList.map((row: any, index: any) => (
                                                            <TableRow
                                                                key={row.name}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row" sx={{ width: '20%' }}>
                                                                    {index + 1}
                                                                </TableCell>
                                                                <TableCell align="left" sx={{ width: '60%' }}>{renderManufacturerImagePathCard(row, index)}</TableCell>
                                                                <TableCell align="right" sx={{ width: '20%' }}>
                                                                    <PrimaryButton value="삭제" size="small" color="error" onClickEvent={() => unregisterManufacturerImagePath(row)} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </DndProvider>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </DropzoneOutputDiv>
                        </BoxFlexStartDiv>
                        <BoxMarginDivider />
                        <BoxFlexEndDiv>
                            {
                                registeredManufacturerImageList !== undefined && registeredManufacturerImageList.length > 0 ?
                                    <PrimaryButton value="미리보기" size="medium" color="secondary" onClickEvent={() => handleImagePreviewPopupOpen()} />
                                    :
                                    <PrimaryButton value="미리보기" size="medium" color="secondary" disabled={true} />
                            }
                        </BoxFlexEndDiv>
                    </BoxDiv>
                    <BoxDiv bgColor={!isDistributorFilled ? "rgba(255 0 0 / .05)" : "#FFFFFF"}>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>판매처</BoxTitleKey>
                        </BoxTitleFlexDiv>
                        <BoxContentFlexDiv
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    if (readyToAddPDInfo) {
                                        addPDInfo()
                                    } else {
                                        alert("필수 정보를 채워주세요 :)")
                                    }
                                }
                            }}
                        >
                            <BoxFlexStartDiv>
                                <ProductDistributorInputDiv width="16%">
                                    <Autocomplete
                                        id="PDDistributorName"
                                        value={PDDistributorName}
                                        onChange={(event, newValue) => {
                                            if (newValue && newValue.inputValue) {
                                                setPDDistributorName({
                                                    distributorId: -1,
                                                    name: newValue.inputValue,
                                                });
                                            } else {
                                                setPDDistributorName(newValue);
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filterDistributorOption(options, params);

                                            const { inputValue } = params;
                                            const isExisting = options.some((option) => inputValue === option.name);
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.push({
                                                    inputValue,
                                                    name: `"${inputValue}" 추가!`,
                                                    distributorId: -1
                                                });
                                            }

                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        options={distributorList}
                                        getOptionLabel={(option) => {
                                            return option.name;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                size="small"
                                                fullWidth
                                                label='판매처명 *'
                                                InputLabelProps={{ style: { fontSize: 13 } }}
                                            />
                                        )}
                                    />
                                </ProductDistributorInputDiv>
                                <ProductDistributorInputDiv width="18%">
                                    <TextField
                                        id="PDProductLink"
                                        size="small"
                                        fullWidth
                                        label='판매처 상품 URL *'
                                        value={PDProductLink}
                                        onChange={handlePDProductLinkChange}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                    />
                                </ProductDistributorInputDiv>
                                <ProductDistributorInputDiv width="22%">
                                    <TextField
                                        id="PDParentProductSerialNo"
                                        size="small"
                                        fullWidth
                                        label='판매처 Parent 상품코드 *'
                                        value={PDParentProductSerialNo}
                                        onChange={handlePDParentProductSerialNoChange}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                    />
                                </ProductDistributorInputDiv>
                                <ProductDistributorInputDiv width="12%">
                                    <TextField
                                        id="PDAmount"
                                        size="small"
                                        fullWidth
                                        label='총 수량(개) *'
                                        value={PDAmount}
                                        onChange={handlePDAmountChange}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                    />
                                </ProductDistributorInputDiv>
                                <ProductDistributorInputDiv width="16%">
                                    <TextField
                                        id="PDPrice"
                                        size="small"
                                        fullWidth
                                        label='가격 *'
                                        value={PDPrice}
                                        onChange={handlePDPriceChange}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                    />
                                </ProductDistributorInputDiv>
                                <ProductDistributorInputDiv width="16%">
                                    <TextField
                                        id="PDProductSerialNo"
                                        size="small"
                                        fullWidth
                                        label='판매처 상품코드'
                                        value={PDProductSerialNo}
                                        onChange={handlePDProductSerialNoChange}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                    />
                                </ProductDistributorInputDiv>
                            </BoxFlexStartDiv>
                            <BoxFlexEndDiv>
                                {
                                    readyToAddPDInfo ?
                                        <PrimaryButton value="등록" size="small" color="primary" onClickEvent={(ev: { preventDefault: () => void; }) => {
                                            addPDInfo()
                                            ev.preventDefault()
                                        }
                                        } />
                                        :
                                        <PrimaryButton disabled={true} value="등록" size="small" color="primary" />
                                }
                            </BoxFlexEndDiv>
                        </BoxContentFlexDiv>
                        <BoxDownArrowDiv>
                            <ArrowDownwardRoundedIcon fontSize="inherit" />
                        </BoxDownArrowDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>등록된 판매처들 *</BoxTitleKey>
                        </BoxTitleFlexDiv>

                        {
                            distributorMapList.length !== 0 &&
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="distributorMapList table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>판매처명</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>판매처 상품 URL (클릭시 복사)</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>판매처 Parent 상품코드</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>총 수량(개)</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>가격</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>판매처 상품코드</TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {distributorMapList.map((row: any) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {getDistributorName(row.distributorId)}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div onClick={() => clickToCopy(row.productLink)}>
                                                            {
                                                                row.productLink.length > 40 ?
                                                                    <>
                                                                        {row.productLink.substr(0, 40)}...
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {row.productLink}
                                                                    </>
                                                            }
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">{row.parentProductSerialNo}</TableCell>
                                                    <TableCell align="left">{row.amount}개</TableCell>
                                                    <TableCell align="left">{row.price === 0 ? "정보 없음" : row.price.toLocaleString() + "원"} </TableCell>
                                                    <TableCell align="left">{row.productSerialNo !== undefined && row.productSerialNo !== "" ? row.productSerialNo : "없음"}</TableCell>
                                                    <TableCell align="center">
                                                        <PrimaryButton value="삭제" size="small" color="error" onClickEvent={() => deletePDInfo(row)} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        }
                    </BoxDiv>
                    <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>공개 상태</BoxTitleKey>
                        </BoxTitleFlexDiv>
                        <>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={CPExposureStatus}
                                onChange={handleCPExposureStatusChange}
                            >
                                <FormControlLabel value="OPEN" control={<Radio />} label="공개" />
                                {
                                    !isMobileDevice &&
                                    <FormControlLabelDivider />
                                }
                                <FormControlLabel value="CLOSE" control={<Radio />} label="비공개" />
                                {
                                    !isMobileDevice &&
                                    <FormControlLabelDivider />
                                }
                                <FormControlLabel value="HOLD" control={<Radio />} label="보류" />
                            </RadioGroup>
                        </>
                    </BoxDiv>
                    <BoxFooterDiv />
                </WrapDiv>
                {
                    <FloatingButtonDiv isMobile={isMobileDevice}>
                        <FloatingButtonContentDiv onClick={() => history.goBack()}>
                            <FloatingButtonContentKeyDiv>뒤로가기</FloatingButtonContentKeyDiv>
                            <FloatingButtonContentValueDiv>
                                <Fab size="small" sx={{ color: 'common.white', bgcolor: grey[500] }} aria-label="ArrowBackIosRoundedIcon">
                                    <ArrowBackIosRoundedIcon />
                                </Fab>
                            </FloatingButtonContentValueDiv>
                        </FloatingButtonContentDiv>
                        {/* <FloatingButtonContentDiv onClick={() => {
                            if (!isProfileFilled) {
                                alert("프로필 정보는 채워야 임시 저장할 수 있습니다 :)")
                                return
                            }
                            updateTempCP()
                        }}>
                            <FloatingButtonContentKeyDiv>임시저장</FloatingButtonContentKeyDiv>
                            <FloatingButtonContentValueDiv>
                                <Fab size="small" sx={{ color: 'common.white', bgcolor: green[500] }} aria-label="SaveOutlinedIcon">
                                    <SaveOutlinedIcon />
                                </Fab>
                            </FloatingButtonContentValueDiv>
                        </FloatingButtonContentDiv> */}
                        <FloatingButtonContentDiv onClick={() => {
                            if (!isAllFilled) {
                                alert("필수정보(빨간색 확인)를 채우고 저장할 수 있습니다 :)")
                                return
                            }
                            updateCP()
                        }}>
                            <FloatingButtonContentKeyDiv isLarge={true}>저장</FloatingButtonContentKeyDiv>
                            <FloatingButtonContentValueDiv>
                                <Fab size="large" color="primary" aria-label="CheckRoundedIcon">
                                    <CheckRoundedIcon />
                                </Fab>
                            </FloatingButtonContentValueDiv>
                        </FloatingButtonContentDiv>
                    </FloatingButtonDiv>
                }
            </Box>
            {
                <>
                    <ChildProductClonePopup
                        open={ChildProductClonePopupOpen}
                        PPId={PPId}
                        CPId={CPId}
                        setCloningId={setCloningId}
                        onClickClose={handleChildProductClonePopupClose}
                    />
                </>
            }
            {
                <>
                    <ImagePreviewPopup
                        open={ImagePreviewPopupOpen}
                        imagePathList={deleteIdFrom(registeredManufacturerImageList)}
                        onClickClose={handleImagePreviewPopupClose}
                    />
                </>
            }
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const FloatingButtonDiv = Styled.div`
    position: fixed;
    bottom: ${(props: { isMobile?: any; }) => props.isMobile === true ? "20px" : "50px"};
    right: ${(props: { isMobile?: any; }) => props.isMobile === true ? "20px" : "50px"};
    z-index: 20;
`
const FloatingButtonContentDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
`
const FloatingButtonContentKeyDiv = Styled.div`
    margin-right: 10px;
    margin-top: ${(props: { isLarge?: any; }) => props.isLarge === true ? "12px" : "5px"};
    font-size: 15px;
    color: #ffffff;
    background-color: #919191;
    padding: 5px 10px;
    border-radius: 5px;
    height: 21px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
`
const FloatingButtonContentValueDiv = Styled.div`
`
const BoxFooterDiv = Styled.div`
    height: 450px;
`
const BoxDiv = Styled.div`
    background: ${(props: { bgColor?: any; }) => props.bgColor !== undefined ? props.bgColor : "#FFFFFF"};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxContentDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentFlexStartDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-start;
`
const BoxContentProfileKeyDiv = Styled.div`
    width: 160px;
    height: 160px;
    background: #EBEBEB;
    margin-right: 20px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`
const BoxContentProfileKeyPlusDiv = Styled.div`
    margin-top: 70px;
`
const ProfileImageEditDiv = Styled.div`
    color: #666666;
    background: #FFFFFF;
    border: solid 1px #666666;
    border-radius: 5px;
    font-size: 19px;
    display: flex;
    justify-content: center;
    padding: 3px;
    width: 80px;
    margin-top: 63px;
    margin-left: 87px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`
const ProfileImageCancleDiv = Styled.div`
    color: #FFFFFF;
    background: #fc5d6b;
    border-radius: 5px;
    font-size: 19px;
    display: flex;
    justify-content: center;
    padding: 3px;
    width: 80px;
    margin-left: 87px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`
const ProfileImageEditText = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: inherit;
    margin-left: 2px;
    margin-top: -2px; 
`
const BoxContentProfileValueDiv = Styled.div`
    width: calc(100% - 160px);
`
const ParentProductNameDiv = Styled.div`
    width: fit-content;
    height: fit-content;
    margin-top: 3px;
    margin-left: 8px;
    font-size: 20px;
    color: #000000;
    box-shadow: rgb(150 100 250 / 40%) 0px -7px 0px inset;
`
const BoxContentInputFlexDiv = Styled.div`
    width: calc(${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"} - 30px);
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
`
const BoxContentInputDiv = Styled.div`
    width: calc(${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"} - 30px);
    padding: 0px 15px;
    font-size: 10px;
`
const ProductDistributorInputDiv = Styled.div`
    width: calc(${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"} - 10px);
    padding: 0px 5px;
    font-size: 10px;
`
const BoxFiftyPercentDiv = Styled.div`
    width: 50%;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 110px;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 110px);
`
const BoxContentInputValueFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    width: calc(100% - 110px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxFlexStartDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`
const BoxFlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const FormControlLabelDivider = Styled.div`
    width: 15%;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`
const BoxDownArrowDiv = Styled.div`
    font-size: 30px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
`
const DropzonePreviewDiv = Styled.div`
    width: 160px;
    height: 160px;
    margin-right: 20px;
`
const DropzoneDiv = Styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 3px #8fb7ff;
    border-radius: 20px;
`
const DropzoneHelpDiv = Styled.div`
    font-size: 20px;
    color: #8fb7ff;
`
const DropzoneRejectDiv = Styled.div`
    font-size: 25px;
    color: #fc5d6b;
`
const ProfileImageDiv = Styled.div`
    width: 160px;
    height: 160px;
`
const ProfileImage = Styled.img`
    width: 160px;
    height: 160px;
`
const ImageCancleBtn = Styled.div`
    width: 100%;
    color: #ffffff;
    background: #fc5d6b;
    border-radius: 10px;
    padding: 5px 0px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`
const DropzoneInputDiv = Styled.div`
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const DropzoneArrowDiv = Styled.div`
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const ArrowForwardDiv = Styled.div`
    font-size: 25px;
`
const DropzoneOutputDiv = Styled.div`
    width: 45%;
`

export default DSBChildProductUpdate;