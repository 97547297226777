import Axios from "axios";
import { AxiosResponse, AxiosError } from 'axios'
import { schemaMap } from 'pv_validation/service/pv_cms/schema';
import { getToken } from 'utils/token';

export const API_PREFIX = 'https://cms-api.portvalue.co.kr'
// export const API_PREFIX = 'http://192.168.1.131:3110'

export const getSchema = (path: string, method: string) => {
  for (let i = 0; i < schemaMap.length; i++) {
    if (schemaMap[i].path !== path) continue
    for (let j = 0; j < schemaMap[i].method.length; j++) {
      if (schemaMap[i].method[j] === method)
        return schemaMap[i].schema;
    }
  }

  return null;
}

/**
 * getParameter()
 * ?key= 뒤의 파라매터 값을 가져오는 함수 
 * @param {string} key 키값
 * @returns 파라매터 키에 매칭되는 value값
 */
export const getParameter = (key: string) => {
  key = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + key + "=([^&#]*)"),
    results = regex.exec(window.location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

/**
 * postRequest()
 * : axios post 요청 + 토큰 제외
 * @param {string} urlPath 요청 주소
 * @param {object} data json 혹은 form 데이터 
 * @returns 리턴데이터
 */
export const postRequest = async (urlPath: string, data: object): Promise<any> => {
  const returnValue = await Axios.post(API_PREFIX + urlPath, data)
    .then(function (response: AxiosResponse) {
      return response
    })
    .catch(function (error: AxiosError) {
      if (error.response === undefined) {
        alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + JSON.stringify(error))
        return null
      }
      return error.response
    })

  return returnValue
}

/**
 * deleteRequest()
 * : axios delete 요청 + 토큰 제외
 * @param {string} urlPath 요청 주소
 * @param {object} data json 혹은 form 데이터 
 * @returns 리턴데이터
 */
export const deleteRequest = async (urlPath: string, data: object): Promise<any> => {
  const returnValue = await Axios.delete(API_PREFIX + urlPath, data)
    .then(function (response: AxiosResponse) {
      return response
    })
    .catch(function (error: AxiosError) {
      if (error.response === undefined) {
        alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + JSON.stringify(error))
        return null
      }
      return error.response
    })

  return returnValue
}

/**
 * getRequest()
 * axios get 요청 + 토큰 제외
 * @param {string} urlPath 요청 주소
 * @returns 리턴데이터
 */
export const getRequest = async (urlPath: string) => {
  const returnValue = await Axios.get(API_PREFIX + urlPath)
    .then(function (response: AxiosResponse) {
      return response
    })
    .catch(function (error: AxiosError) {
      if (error.response === undefined) {
        alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + JSON.stringify(error))
        return null
      }
      return error.response
    })

  return returnValue
}

/**
 * postRequestWithToken()
 * : axios post 요청 + 토큰 포함
 * @param {string} urlPath 요청 주소
 * @param {object} data json 혹은 form 데이터 
 * @param {string} token 토큰값
 * @returns 리턴데이터
 */
export const postRequestWithToken = async (urlPath: string, data: object, token: string): Promise<any> => {
  const returnValue = await Axios.post(API_PREFIX + urlPath,
    data,
    { 'headers': { 'Authorization': "Bearer " + token } }
  )
    .then(function (response: AxiosResponse) {
      return response
    })
    .catch(function (error: AxiosError) {
      if (error.response === undefined) {
        alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + JSON.stringify(error))
        return null
      }
      return error.response
    })

  return returnValue
}

/**
 * putRequestWithToken()
 * : axios put 요청 + 토큰 포함
 * @param {string} urlPath 요청 주소
 * @param {object} data json 혹은 form 데이터 
 * @param {string} token 토큰값
 * @returns 리턴데이터
 */
export const putRequestWithToken = async (urlPath: string, data: object, token: string): Promise<any> => {
  const returnValue = await Axios.put(API_PREFIX + urlPath,
    data,
    { 'headers': { 'Authorization': "Bearer " + token } }
  )
    .then(function (response: AxiosResponse) {
      return response
    })
    .catch(function (error: AxiosError) {
      if (error.response === undefined) {
        alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + JSON.stringify(error))
        return null
      }
      return error.response
    })

  return returnValue
}

/**
 * deleteRequestWithToken()
 * : axios delete 요청 + 토큰 포함
 * @param {string} urlPath 요청 주소
 * @param {object} data json 혹은 form 데이터 
 * @param {string} token 토큰값
 * @returns 리턴데이터
 */
export const deleteRequestWithToken = async (urlPath: string, data: object, token: string): Promise<any> => {
  const returnValue = await Axios.delete(API_PREFIX + urlPath,
    {
      headers: {
        Authorization: "Bearer " + token
      },
      data,
    }
  )
    .then(function (response: AxiosResponse) {
      return response
    })
    .catch(function (error: AxiosError) {
      if (error.response === undefined) {
        alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + JSON.stringify(error))
        return null
      }
      return error.response
    })

  return returnValue
}

/**
 * getRequestWithToken()
 * : axios get 요청 + 토큰 포함
 * @param {string} urlPath 요청 주소
 * @param {string} token 토큰값
 * @returns 리턴데이터
 */
export const getRequestWithToken = async (urlPath: string, token: string): Promise<any> => {

  const returnValue = await Axios.get(API_PREFIX + urlPath, { 'headers': { 'Authorization': "Bearer " + token } })
    .then(function (response: AxiosResponse) {
      return response
    })
    .catch(function (error: AxiosError) {
      if (error.response === undefined) {
        alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + JSON.stringify(error))
        return null
      }
      return error.response
    })

  return returnValue
}

/**
 * signCheck()
 * : 토큰으로 올바른 계정인지 확인
 * @returns true, false 
 */
export const signCheck = async () => {
  const curToken = getToken()
  if (curToken === '') return false;

  const returnValue = await Axios.get(API_PREFIX + '/auth/me', { 'headers': { 'Authorization': "Bearer " + curToken } })
    .then(function (response: AxiosResponse) {
      if (response.status === 200) {
        if (response.data.isAdmin !== true) {
          alert("권한이 없는 계정입니다. 권한은 아래 메일을 통해서 요청할 수 있습니다. \n\n cs@portvalue.co.kr")
          return false;
        }
        return true;
      }
      return false;
    })
    .catch(function (error: AxiosError) {
      if (error.response === undefined) {
        alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + JSON.stringify(error))
      }
      return false
    })

  return returnValue;
}


/**
 * postImageRequestWithToken()
 * : axios post 요청 + image 업로드 + 토큰 포함
 * @param {object} formData json 혹은 form 데이터
 * @param {string} token 토큰값
 * @returns 리턴데이터
 */
export const postImageRequestWithToken = async (formData: object, token: string): Promise<any> => {
  const urlPath = "/images"
  const returnValue = await Axios.post(API_PREFIX + urlPath,
    formData,
    {
      'headers': {
        'Authorization': "Bearer " + token
      }
    }
  )
    .then(function (response: AxiosResponse) {
      return response
    })
    .catch(function (error: AxiosError) {
      if (error.response === undefined) {
        alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + JSON.stringify(error))
        return null
      }
      return error.response
    })

  return returnValue
}