import Joi from 'joi';
import {
  exposureStatus,
  httpMethod,
  nameLangType,
  saleStatus,
  unitType,
} from './enum';

const signInSchema = Joi.object({
  email: Joi.string().min(10).max(50).required(),
  password: Joi.string().min(6).max(20).required(),
});

const userSchema = Joi.object({
  name: Joi.string().max(20).required(),
  email: Joi.string().min(10).max(50).required(),
  password: Joi.string().min(6).max(20).required(),
  birthDate: Joi.date().min('1-1-1900').max('now').required(),
  phoneNumber: Joi.string().alphanum().length(11).required(),
});

const favoriteSchema = Joi.object({
  name: Joi.string().max(50).required(),
  path: Joi.string().max(50).required(),
});

const postChildProductSchema = Joi.object({
  imagePath: Joi.string().allow('').max(400),
  flavorId: Joi.number().integer().required(),
  capacity: Joi.number().required(),
  capacityUnitId: Joi.number().integer().required(),
  itemCode: Joi.string().allow('').max(50),
  licenseNo: Joi.string().allow('').max(50),
});

const putChildProductSchema = Joi.object({
  imagePath: Joi.string().allow('').max(400),
  flavorId: Joi.number().integer().required(),
  capacity: Joi.number().required(),
  capacityUnitId: Joi.number().integer().required(),
  itemCode: Joi.string().allow('').max(50),
  licenseNo: Joi.string().allow('').max(50),
  nutrient: Joi.object({
    capacity: Joi.number().required(),
    capacityUnitId: Joi.number().required(),
    form: Joi.number().required(),
    formUnitId: Joi.number().required(),
    calory: Joi.number().allow(null),
    sodium: Joi.number().allow(null),
    carbohydrate: Joi.number().allow(null),
    sugars: Joi.number().allow(null),
    dietaryFiber: Joi.number().allow(null),
    fat: Joi.number().allow(null),
    transFat: Joi.number().allow(null),
    saturatedFat: Joi.number().allow(null),
    cholesterol: Joi.number().allow(null),
    protein: Joi.number().allow(null),
  }),
  vitamin: Joi.object({
    vitaminA: Joi.number().allow(null),
    vitaminD: Joi.number().allow(null),
    vitaminE: Joi.number().allow(null),
    vitaminK: Joi.number().allow(null),
    vitaminC: Joi.number().allow(null),
    thiamine: Joi.number().allow(null),
    riboflavin: Joi.number().allow(null),
    niacin: Joi.number().allow(null),
    vitaminB6: Joi.number().allow(null),
    folicAcide: Joi.number().allow(null),
    vitaminB12: Joi.number().allow(null),
    pantothenicAcid: Joi.number().allow(null),
    biotin: Joi.number().allow(null),
  }),
  mineral: Joi.object({
    calcium: Joi.number().allow(null),
    phosphorus: Joi.number().allow(null),
    chlorine: Joi.number().allow(null),
    potassium: Joi.number().allow(null),
    magnesium: Joi.number().allow(null),
    iron: Joi.number().allow(null),
    zinc: Joi.number().allow(null),
    copper: Joi.number().allow(null),
    fluoride: Joi.number().allow(null),
    manganese: Joi.number().allow(null),
    iodine: Joi.number().allow(null),
    selenium: Joi.number().allow(null),
    molybdenium: Joi.number().allow(null),
    chrominum: Joi.number().allow(null),
  }),
  nutrientEtcList: Joi.array()
    .items(
      Joi.object({
        name: Joi.string().max(20),
        capacity: Joi.number(),
        capacityUnitId: Joi.number(),
      }),
    )
    .required(),
  ingredient: Joi.string().max(1000).required(),
  allergyIdList: Joi.array().items(Joi.number()).required(),
  manufacturerImagePathList: Joi.array()
    .items(Joi.string().max(400))
    .required(),
  distributorMapList: Joi.array()
    .items(
      Joi.object({
        distributorId: Joi.number().required(),
        productLink: Joi.string().max(750).required(),
        parentProductSerialNo: Joi.string().max(50).required(),
        productSerialNo: Joi.string().allow('').max(50),
        amount: Joi.number().required(),
        price: Joi.number().required(),
      }),
    )
    .required(),
  exposureStatus: Joi.string().valid(
    exposureStatus.OPEN,
    exposureStatus.CLOSE,
    exposureStatus.HOLD,
  ),
});

const parentProductSchema = Joi.object({
  name: Joi.string().max(50).required(),
  repChildProductId: Joi.number().integer(),
  brandId: Joi.number().integer().required(),
  productClassificationId: Joi.number().integer().required(),
  youtubeIframePath: Joi.string().allow('').max(200),
  exposureStatus: Joi.string().valid(
    exposureStatus.OPEN,
    exposureStatus.CLOSE,
    exposureStatus.HOLD,
  ),
});

const repChildProductSchema = Joi.object({
  repChildProductId: Joi.number().integer().required(),
});

const distributorMapSchema = Joi.object({
  distributorId: Joi.number().integer().required(),
  productLink: Joi.string().uri().max(750).required(),
  encodedProductLink: Joi.string().uri().max(1500).required(),
  productSerial: Joi.string().max(30).required(),
  subProductSerial: Joi.string().max(30),
  amount: Joi.number().integer().required().required(),
  price: Joi.number().integer().required().required(),
  saleStatus: Joi.string().valid(
    saleStatus.OPEN,
    saleStatus.SOLDOUT,
    saleStatus.STOP,
  ),
});

const reviewTagSchema = Joi.object({
  name: Joi.string().max(30).required(),
  description: Joi.string().max(100).required(),
  optionList: Joi.array().items(Joi.string().max(20)),
});

const onlyNameReviewTagSchema = Joi.object({
  name: Joi.string().max(30).required(),
});

const unitSchema = Joi.object({
  name: Joi.string().max(20).required(),
  type: Joi.string().valid(unitType.CAPACITY, unitType.FORM).required(),
});

const onlyNameUnitSchema = Joi.object({
  name: Joi.string().max(20).required(),
});

const flavorSchema = Joi.object({
  name: Joi.string().max(50).required(),
});

const brandSchema = Joi.object({
  name: Joi.string().max(40).required(),
  nameEng: Joi.string().max(60).required(),
  nameLangType: Joi.string()
    .valid(nameLangType.KOR, nameLangType.ENG)
    .required(),
  imagePath: Joi.string().allow('').max(400),
  description: Joi.string().allow('').max(500),
});

const onlyNameBrandSchema = Joi.object({
  name: Joi.string().max(40).required(),
});

const manufacturerSchema = Joi.object({
  name: Joi.string().max(20).required(),
});

const magazineClassificationSchema = Joi.object({
  name: Joi.string().max(20).required(),
});

const dansungbeePickSchema = Joi.object({
  title: Joi.string().max(50).required(),
  description: Joi.string().max(50).required(),
  imagePath: Joi.string().allow('').max(400).required(),
  childProductIdList: Joi.array().items(Joi.number().integer()).required(),
  exposureStatus: Joi.string().valid(
    exposureStatus.OPEN,
    exposureStatus.CLOSE,
    exposureStatus.HOLD,
  ),
});

const magazineSchema = Joi.object({
  title: Joi.string().max(50).required(),
  subTitle: Joi.string().max(50).required(),
  imagePath: Joi.string().allow('').max(400).required(),
  content: Joi.string().allow('').max(5000),
  exposureStatus: Joi.string().valid(
    exposureStatus.OPEN,
    exposureStatus.CLOSE,
    exposureStatus.HOLD,
  ),
});

const mainBannerSchema = Joi.object({
  name: Joi.string().max(50).required(),
  thumbnailImagePath: Joi.string().allow('').max(400).required(),
  contentImagePath: Joi.string().allow('').max(400).required(),
  startTime: Joi.date().min('1-1-1900').required(),
  endTime: Joi.date().min('1-1-1900').required(),
  buttonName: Joi.string().allow('').max(50),
  buttonConnectionURL: Joi.string().allow('').max(500),
  exposureStatus: Joi.string().valid(
    exposureStatus.OPEN,
    exposureStatus.CLOSE,
    exposureStatus.HOLD,
  ),
});

const eventClassificationSchema = Joi.object({
  name: Joi.string().max(20).required(),
});

const eventSchema = Joi.object({
  name: Joi.string().max(50).required(),
  thumbnailImagePath: Joi.string().allow('').max(400).required(),
  contentImagePath: Joi.string().allow('').max(400).required(),
  startTime: Joi.date().min('1-1-1900').required(),
  endTime: Joi.date().min('1-1-1900').required(),
  buttonName: Joi.string().allow('').max(50),
  buttonConnectionURL: Joi.string().allow('').max(500),
  exposureStatus: Joi.string().valid(
    exposureStatus.OPEN,
    exposureStatus.CLOSE,
    exposureStatus.HOLD,
  ),
});

const distributorSchema = Joi.object({
  name: Joi.string().max(20).required(),
  deliveryFee: Joi.number().integer().required(),
  freeDeliveryCondition: Joi.number().integer(),
  minimumOrderPrice: Joi.number().integer(),
});

const onlyNameDistributorSchema = Joi.object({
  name: Joi.string().max(20).required(),
});

const analysisSchema = Joi.object({
  name: Joi.string().max(20).required(),
});

const allergySchema = Joi.object({
  name: Joi.string().max(40).required(),
  imagePath: Joi.string().max(400).required(),
  description: Joi.string().allow('').max(200),
});

const onlyNameAllergySchema = Joi.object({
  name: Joi.string().max(40).required(),
});

const precautionSchema = Joi.object({
  name: Joi.string().max(10).required(),
  imagePath: Joi.string().allow('').max(200),
  description: Joi.string().allow('').max(100),
});

const postProductClassificationSchema = Joi.object({
  name: Joi.string().max(20).required(),
  parentProductClassificationId: Joi.number().integer(),
});

const putProductClassificationSchema = Joi.object({
  name: Joi.string().max(20).required(),
  imagePath: Joi.string().max(200).allow('').required(),
  descriptionImagePath: Joi.string().allow('').max(200),
  descriptionURL: Joi.string().allow('').max(200),
  firstProductOption: Joi.string().max(20).required(),
  secondProductOption: Joi.string().max(20).required(),
  perAmount: Joi.number().integer().required(),
  perAmountUnitId: Joi.number().integer().required(),
  reviewTagList: Joi.array().items(Joi.number().integer()).required(),
  analysisList: Joi.array().items(Joi.number().integer()).required(),
  exposureStatus: Joi.string().valid(
    exposureStatus.OPEN,
    exposureStatus.CLOSE,
    exposureStatus.HOLD,
  ),
});

const ingredientSchema = Joi.object({
  name: Joi.string().max(10).required(),
  nameEng: Joi.string().max(50),
  nameAlias: Joi.string().max(50),
  includedMaterial: Joi.string().max(200),
  imagePath: Joi.string().allow('').max(200),
  classificationList: Joi.array().items(Joi.number()),
  precautionList: Joi.array().items(Joi.number()),
  functionList: Joi.array().items(Joi.string().max(20)),
});

export const schemaMap = [
  {
    path: '/auth/signIn',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: signInSchema,
  },
  {
    path: '/auth/signUp',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: userSchema,
  },
  {
    path: '/users',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: userSchema,
  },
  {
    path: '/favorites',
    method: [httpMethod.POST, httpMethod.PUT, httpMethod.DELETE],
    schema: favoriteSchema,
  },
  {
    path: '/products',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: parentProductSchema,
  },
  {
    path: '/products/{parentProductId}/repChildProduct',
    method: [httpMethod.PUT],
    schema: repChildProductSchema,
  },
  {
    path: '/products/{parentProductId}/childs',
    method: [httpMethod.POST],
    schema: postChildProductSchema,
  },
  {
    path: '/products/childs/{childProductId}',
    method: [httpMethod.PUT],
    schema: putChildProductSchema,
  },
  {
    path: '/distributorMaps/childProducts/{childProductId}',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: distributorMapSchema,
  },
  {
    path: '/reviewTags',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: reviewTagSchema,
  },
  {
    path: '/reviewTags/onlyName',
    method: [httpMethod.POST],
    schema: onlyNameReviewTagSchema,
  },
  {
    path: '/units',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: unitSchema,
  },
  {
    path: '/units/onlyName',
    method: [httpMethod.POST],
    schema: onlyNameUnitSchema,
  },
  {
    path: '/flavors',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: flavorSchema,
  },
  {
    path: '/brands',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: brandSchema,
  },
  {
    path: '/brands/onlyName',
    method: [httpMethod.POST],
    schema: onlyNameBrandSchema,
  },
  {
    path: '/manufacturers',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: manufacturerSchema,
  },
  {
    path: '/dansungbeePicks',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: dansungbeePickSchema,
  },
  {
    path: '/magazines',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: magazineSchema,
  },
  {
    path: '/magazineClassifications',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: magazineClassificationSchema,
  },
  {
    path: '/mainBanners',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: mainBannerSchema,
  },
  {
    path: '/events',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: eventSchema,
  },
  {
    path: '/eventClassifications',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: eventClassificationSchema,
  },
  {
    path: '/distributors',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: distributorSchema,
  },
  {
    path: '/distributors/onlyName',
    method: [httpMethod.POST],
    schema: onlyNameDistributorSchema,
  },
  {
    path: '/analysises',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: analysisSchema,
  },
  {
    path: '/allergies',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: allergySchema,
  },
  {
    path: '/allergies/onlyName',
    method: [httpMethod.POST],
    schema: onlyNameAllergySchema,
  },
  {
    path: '/precautions',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: precautionSchema,
  },
  {
    path: '/productClassifications',
    method: [httpMethod.POST],
    schema: postProductClassificationSchema,
  },
  {
    path: '/productClassifications',
    method: [httpMethod.PUT],
    schema: putProductClassificationSchema,
  },
  {
    path: '/ingredients',
    method: [httpMethod.POST, httpMethod.PUT],
    schema: ingredientSchema,
  },
];
