/**
 * isNumber()
 * : 숫자인지 확인
 * @returns true, false 
 */
 export const isNumber = (number: string|number) => {
    return !isNaN(Number(number))
}

/**
 * isPositiveInteger()
 * : 정수인지 확인
 * @returns true, false 
 */
export const isPositiveInteger = ( inputNum: string | number | undefined ): boolean => {
    if (inputNum === '') return false
    const num = Number(inputNum)
    return !isNaN(num) && Number.isInteger(num) && num >= 0
};