import React, { useEffect, useState, useCallback } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';

import { getToken } from 'utils/token';
import { getSchema, getRequest, postRequestWithToken, putRequestWithToken, postImageRequestWithToken } from 'utils/APIRequest';
import { isMobile } from 'utils/common';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

interface MagazineClassificationOptionType {
    inputValue?: string;
    magazineClassificationId: number;
    name: string;
}

const filterMagazineClassificationOption = createFilterOptions<MagazineClassificationOptionType>();

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

const isMobileDevice = isMobile()

interface Props {
    open?: any,
    magazineForUpdate?: any,
    purpose?: any,
    onClickClose?: any,
    btnTextFirst?: any,
    btnTextSecond?: any,
}

const MagazineCreationPopup = ({ open, magazineForUpdate, purpose, onClickClose, btnTextFirst, btnTextSecond }: Props) => {
    const classes = useStyles();
    const history = useHistory()

    const [popupPurpose, setPopupPurpose] = useState<any>("추가")
    const [magazineClassificationList, setMagazineClassificationList] = useState<any>([])

    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [magazine, setMagazine] = useState<any>(null)
    const [magazineId, setMagazineId] = useState<number>(-1)
    const [magazineClassification, setMagazineClassification] = useState<any>(null);
    const [magazineClassificationId, setMagazineClassificationId] = useState<number>(-1)
    const [magazineTitle, setMagazineTitle] = useState<string | null>("")
    const [magazineSubTitle, setMagazineSubTitle] = useState<string | null>("")
    const [imagePath, setImagePath] = useState<any>("")
    const [magazineContent, setMagazineContent] = useState<string>("")
    const [exposureStatus, setExposureStatus] = useState<string>("HOLD")

    const [MDImagePath, setMDImagePath] = useState<any>("")

    const {
        getRootProps: getRootImagePathProps,
        getInputProps: getInputImagePathProps,
        isDragActive: isImagePathDragActive,
        isDragReject: isImagePathDragReject,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 100 * 1024,
        multiple: false,
        onDropRejected: (rejectedFiles) => {
            if (rejectedFiles[0] !== undefined)
                alert(JSON.stringify(rejectedFiles[0].errors))
        },
        onDropAccepted: (acceptedFile) => {
            let formData = new FormData()
            formData.append('image', acceptedFile[0])
            postImage(formData, "imagePath")
        },
    });

    const {
        getRootProps: getRootMDImagePathProps,
        getInputProps: getInputMDImagePathProps,
        isDragActive: isMDImagePathDragActive,
        isDragReject: isMDImagePathDragReject,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 1024 * 1024,
        multiple: false,
        onDropRejected: (rejectedFiles) => {
            if (rejectedFiles[0] !== undefined)
                alert(JSON.stringify(rejectedFiles[0].errors))
        },
        onDropAccepted: (acceptedFile) => {
            let formData = new FormData()
            formData.append('image', acceptedFile[0])
            postImage(formData, "MDImagePath")
        },
    });

    const postImage = async (formData: any, from: string) => {
        if (formData === undefined || !formData) return
        const curToken = getToken()
        if (curToken === '') return

        const res = await postImageRequestWithToken(
            formData,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                if (res.data.location === undefined) {
                    alert("이미지 경로를 찾을 수 없습니다. \n\n" + JSON.stringify(res.data))
                    return
                }
                if (from === "imagePath") {
                    setImagePath(res.data.location)
                } else {
                    setMDImagePath(res.data.location)
                }
            } else if (res.status === 409) {
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const createMagazine = async () => {
        const curToken = getToken()
        if (curToken === '') return

        const magazineInfo = {
            magazineClassificationId: magazine.magazineClassificationId,
            title: magazine.title,
            subTitle: magazine.subTitle,
            imagePath: magazine.imagePath,
            content: magazine.content,
            exposureStatus: magazine.exposureStatus,
        }
        const curSchema = getSchema('/magazines', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(magazineInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/magazines',
            magazineInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                clearInputData()
                onClickClose()
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateMagazine = async () => {
        if (magazine.magazineId === undefined || magazine.magazineId === "" || magazine.magazineId === -1) return
        const curToken = getToken()
        if (curToken === '') return

        const magazineInfo = {
            magazineClassificationId: magazine.magazineClassificationId,
            title: magazine.title,
            subTitle: magazine.subTitle,
            imagePath: magazine.imagePath,
            content: magazine.content,
            exposureStatus: magazine.exposureStatus,
        }
        const curSchema = getSchema('/magazines', 'PUT')

        if (curSchema) {
            try {
                await curSchema.validateAsync(magazineInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            `/magazines/${magazine.magazineId}`,
            magazineInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                clearInputData()
                onClickClose()
            } else if (res.status === 204) {
                alert("magazine ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const clearInputData = () => {
        setMagazine(null)
        setMagazineId(-1)
        setMagazineClassification(null)
        setMagazineTitle("")
        setMagazineSubTitle("")
        setImagePath("")
        setMagazineContent("")
        setExposureStatus("HOLD")
    }

    const handleMagazineTitleChange = (magazine: React.ChangeEvent<HTMLInputElement>) => {
        setMagazineTitle(magazine.target.value)
    }

    const handleMagazineSubTitleChange = (magazine: React.ChangeEvent<HTMLInputElement>) => {
        setMagazineSubTitle(magazine.target.value)
    }

    const handleMagazineContentChange = (magazine: React.ChangeEvent<HTMLInputElement>) => {
        setMagazineContent(magazine.target.value)
    }

    const handleExposureStatusChange = (magazine: React.ChangeEvent<HTMLInputElement>) => {
        if (isAllFilled) setExposureStatus(magazine.target.value)
        else alert("공개 상태를 변경하기 위해서는 필수항목이 채워져있어야 합니다 :)")
    }

    const getMagazineClassificationList = async () => {
        const res = await getRequest('/magazineClassifications')
        if (res !== null) {
            if (res.status === 200) {
                setMagazineClassificationList([...res.data])
            } else if (res.status === 204) {
                setMagazineClassificationList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addMagazineClassification = async (magazineClassification: MagazineClassificationOptionType) => {
        if (magazineClassification.name === undefined || magazineClassification.name === "") return
        if (magazineClassification.magazineClassificationId === undefined || magazineClassification.magazineClassificationId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const magazineClassificationInfo = { name: magazineClassification.name }
        const curSchema = getSchema('/magazineClassifications', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(magazineClassificationInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/magazineClassifications',
            magazineClassificationInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getMagazineClassificationList()
                const createdMagazineClassification = res.data
                setMagazineClassification({
                    magazineClassificationId: createdMagazineClassification.magazineClassificationId,
                    name: createdMagazineClassification.name
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getMagazineClassificationName = (curId: number) => {
        for (let i = 0; i < magazineClassificationList.length; i++) {
            if (magazineClassificationList[i] === undefined) continue
            if (magazineClassificationList[i].magazineClassificationId === curId) return magazineClassificationList[i].name
        }
        return "알수없는 ID"
    }


    useEffect(() => {
        if (MDImagePath === undefined || MDImagePath === "") return

        let curContent = magazineContent
        const imagePathMD = `\n![image](${MDImagePath})`
        setMagazineContent(curContent + imagePathMD)
        setMDImagePath("")
    }, [MDImagePath])

    useEffect(() => {
        if (!magazineClassification)
            return
        if (magazineClassification.magazineClassificationId !== undefined && magazineClassification.magazineClassificationId === -1)
            addMagazineClassification(magazineClassification)

        setMagazineClassificationId(magazineClassification.magazineClassificationId)
    }, [JSON.stringify(magazineClassification)])

    useEffect(() => {
        if (magazineTitle === undefined || magazineTitle === "") {
            setIsAllFilled(false)
            return
        }
        if (magazineSubTitle === undefined || magazineSubTitle === "") {
            setIsAllFilled(false)
            return
        }
        if (magazineContent === undefined || magazineContent === "") {
            setIsAllFilled(false)
            return
        }
        if (magazineClassificationId === undefined || magazineClassificationId <= 0) {
            setIsAllFilled(false)
            return
        }
        if (imagePath === undefined || imagePath === "") {
            setIsAllFilled(false)
            return
        }

        const curMagazine = {
            magazineId: magazineId,
            magazineClassificationId: magazineClassificationId,
            title: magazineTitle,
            subTitle: magazineSubTitle,
            imagePath: imagePath,
            content: magazineContent,
            exposureStatus: exposureStatus,
        }

        if (magazineForUpdate !== undefined && JSON.stringify(curMagazine) === JSON.stringify(magazineForUpdate)) {
            setIsAllFilled(false)
            return
        }

        setMagazine(curMagazine)
        setIsAllFilled(true)

    }, [magazineTitle, magazineSubTitle, magazineContent, magazineClassificationId, imagePath, exposureStatus])

    useEffect(() => {
        if (open) return
        clearInputData()
    }, [open])

    useEffect(() => {
        if (magazineForUpdate !== undefined && magazineForUpdate !== "") {
            setMagazineId(magazineForUpdate.magazineId)
            if (magazineForUpdate.magazineClassificationId !== undefined && magazineForUpdate.magazineClassificationId !== null) {
                setMagazineClassification({
                    magazineClassificationId: magazineForUpdate.magazineClassificationId,
                    name: getMagazineClassificationName(magazineForUpdate.magazineClassificationId)
                })
            }
            setMagazineTitle(magazineForUpdate.title)
            setMagazineSubTitle(magazineForUpdate.subTitle)
            setMagazineContent(magazineForUpdate.content)
            setImagePath(magazineForUpdate.imagePath)
            setExposureStatus(magazineForUpdate.exposureStatus)
        }
    }, [JSON.stringify(magazineForUpdate)])

    useEffect(() => {
        getMagazineClassificationList()
        if (purpose !== undefined)
            setPopupPurpose(purpose)
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>매거진 {popupPurpose}</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>매거진 종류 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <Autocomplete
                                                id="magazineClassification"
                                                value={magazineClassification}
                                                onChange={(magazine, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        setMagazineClassification({
                                                            magazineClassificationId: -1,
                                                            name: newValue.inputValue,
                                                        });
                                                    } else {
                                                        setMagazineClassification(newValue);
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filterMagazineClassificationOption(options, params);

                                                    const { inputValue } = params;
                                                    const isExisting = options.some((option) => inputValue === option.name);
                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            name: `"${inputValue}" 추가!`,
                                                            magazineClassificationId: -1
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                options={magazineClassificationList}
                                                getOptionLabel={(option) => {
                                                    return option.name;
                                                }}
                                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        size="small"
                                                        fullWidth
                                                        label='매거진 종류 선택'
                                                    />
                                                )}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>매거진 타이틀 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="magazineTitle"
                                                size="small"
                                                fullWidth
                                                label='매거진 타이틀'
                                                value={magazineTitle}
                                                onChange={handleMagazineTitleChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>매거진 서브타이틀 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="magazineSubTitle"
                                                size="small"
                                                fullWidth
                                                label='매거진 서브타이틀'
                                                value={magazineSubTitle}
                                                onChange={handleMagazineSubTitleChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>매거진 이미지 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            {
                                                imagePath !== undefined && imagePath !== null && imagePath !== "" ?
                                                    <DropzonePreviewDiv>
                                                        <ProfileImageDiv>
                                                            <ProfileImage src={imagePath} alt={imagePath} />
                                                            <ImageCancleBtn onClick={() => setImagePath("")}>삭제</ImageCancleBtn>
                                                        </ProfileImageDiv>
                                                    </DropzonePreviewDiv>
                                                    :
                                                    <DropzoneDiv {...getRootImagePathProps()}>
                                                        <input {...getInputImagePathProps()} />
                                                        {
                                                            isImagePathDragReject ?
                                                                <DropzoneRejectDiv>
                                                                    <div>1. File Size는 100KB 이하</div>
                                                                    <div>2. 이미지 업로드는 한장만 허용</div>
                                                                    <div>3. 이미지 파일 타입 에러는 개발자에게 문의 바람</div>
                                                                </DropzoneRejectDiv>
                                                                :
                                                                <>
                                                                    {
                                                                        isImagePathDragActive ?
                                                                            <DropzoneHelpDiv>추가할 이미지를 여기로 드랍해주세요 :)</DropzoneHelpDiv> :
                                                                            <DropzoneHelpDiv>
                                                                                <div>이미지를 드래그 후 드랍하거나 여기를 클릭해서 추가해주세요 :)</div>
                                                                            </DropzoneHelpDiv>
                                                                    }
                                                                </>
                                                        }
                                                    </DropzoneDiv>
                                            }
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <MagazineContentTitleDiv>매거진 내용을 마크다운 형식으로 아래 작성해주세요. (필수)</MagazineContentTitleDiv>
                                    </BoxContentInputFlexDiv>
                                    <BoxMarginDivider />
                                    <MagazineContentDiv>
                                        <MagazineContentInputDiv>
                                            <TextField
                                                id="magazineContent"
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={40}
                                                value={magazineContent}
                                                onChange={handleMagazineContentChange}
                                            />
                                        </MagazineContentInputDiv>
                                        <MagazineContentPreviewDiv {...getRootMDImagePathProps()}>
                                            <input {...getInputMDImagePathProps()} />
                                            <MDDiv>
                                                <ReactMarkdown
                                                    children={magazineContent}
                                                    components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} alt="" /> }}
                                                    remarkPlugins={[remarkGfm]}
                                                />
                                            </MDDiv>
                                        </MagazineContentPreviewDiv>
                                    </MagazineContentDiv>
                                </BoxContentDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                        <BoxContentDiv>
                            <BoxContentFlexDiv>
                                <BoxContentInputFlexDiv width="100%">
                                    <BoxContentInputKeyDiv>공개 상태</BoxContentInputKeyDiv>
                                    <BoxContentInputValueDiv>
                                        <RadioGroup
                                            row
                                            name="exposureStatus"
                                            value={exposureStatus}
                                            onChange={handleExposureStatusChange}
                                        >
                                            <FormControlLabel value="OPEN" control={<Radio />} label="공개" />
                                            {
                                                !isMobileDevice &&
                                                <FormControlLabelDivider />
                                            }
                                            <FormControlLabel value="CLOSE" control={<Radio />} label="비공개" />
                                            {
                                                !isMobileDevice &&
                                                <FormControlLabelDivider />
                                            }
                                            <FormControlLabel value="HOLD" control={<Radio />} label="보류" />
                                        </RadioGroup>
                                    </BoxContentInputValueDiv>
                                </BoxContentInputFlexDiv>
                            </BoxContentFlexDiv>
                        </BoxContentDiv>
                        <BoxMarginDivider />
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton
                            variant="outlined"
                            value={btnTextFirst}
                            size="large"
                            color="primary"
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                        <VerticalDivider />
                        {
                            !isAllFilled ?
                                <PrimaryButton
                                    disabled={true}
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                />
                                :
                                <PrimaryButton
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                    onClickEvent={() => {
                                        if (purpose === "추가")
                                            createMagazine()
                                        else
                                            updateMagazine()
                                    }}
                                />
                        }
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
`
const BoxContentProfileDiv = Styled.div`
    width: 100%;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 140px;
    text-align: left;
    margin-top: 9px;
`
const MagazineContentTitleDiv = Styled.div`
    width: 100%;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 140px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const VerticalDivider = Styled.div`
    width: 20px;
`
const BoxFlexStartDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`
const DropzonePreviewDiv = Styled.div`
    width: 100%;
    height: 230px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #EBEBEB;
    border-radius: 20px;
`
const DropzoneDiv = Styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 3px #8fb7ff;
    border-radius: 20px;
`
const DropzoneHelpDiv = Styled.div`
    font-size: 25px;
    color: #8fb7ff;
`
const DropzoneRejectDiv = Styled.div`
    font-size: 25px;
    color: #fc5d6b;
`
const ProfileImageDiv = Styled.div`
    width: fit-content;
    height: 230px;
`
const ProfileImage = Styled.img`
    height: 200px;
    width: auto;
`
const ImageCancleBtn = Styled.div`
    width: 100%;
    color: #ffffff;
    background: #fc5d6b;
    border-radius: 10px;
    padding: 5px 0px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`
const FormControlLabelDivider = Styled.div`
    width: 15%;
`
const MagazineContentDiv = Styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
`
const MagazineContentInputDiv = Styled.div`
    width: 100%;
`
const MagazineContentPreviewDiv = Styled.div`
    width: 375px;
    border: solid 1px #327BFF;
    border-radius: 15px;
`
const MDDiv = Styled.div`
    overflow-x: hidden;
    width: calc(100% - 30px);
    padding: 20px 15px 80px;
    box-sizing: border-box;
    min-width: 200px;
    max-width: 980px;
    margin: 0 auto;
    line-height: 28px;
    text-align: left;
    tr {
        border-top: 1px solid #c6cbd1;
        background: #fff;
    }
    th, td {
        padding: 6px 13px;
        border: 1px solid #dfe2e5;
    }
    table tr:nth-child(2n) {
        background: #f6f8fa;
    }
    blockquote {
        padding: 0 1em;
        color: #79aafc;
        border-left: 0.25em solid #b1ccfc;
        margin-inline-start: 10px;
        margin-inline-end: 10px;
        p {
            color: inherit;
        }
    }
    p {
        margin-top: 15px;
        margin-bottom: 15px;
        color: #555555;
        word-break: keep-all;
    }
    strong {
        color: #555555;
    }
    a {
        color: #327Bff;
    }
    h1, h2, h3, h4, h5, h6 {
        color: #444444;
    }
    del {
        color: #666666;
    }
    menu, ol, ul {
        padding: 13px;
    }
    code {
        background: rgba( 57, 36, 255, 0.13 );
        padding: 2px;
        border-radius: 3px;
    }
`

export default MagazineCreationPopup