import React, { useEffect, useState } from 'react';
import Styled from 'styled-components';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

interface Props {
    open?: any,
    imagePathList: any,
    onClickClose?: any,
}

const ImagePreviewPopup = ({ open, imagePathList, onClickClose }: Props) => {
    const classes = useStyles();

    const [recvImagePathList, setRecvImagePathList] = useState<any>([])

    useEffect(() => {
        if (imagePathList === undefined || imagePathList.length <= 0) return
        setRecvImagePathList(imagePathList)
    }, [JSON.stringify(imagePathList)])

    useEffect(() => {
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>미리보기</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxDivider />
                        <BoxTitleFlexDiv>
                            <TableKeyDiv>총 {recvImagePathList !== undefined ? recvImagePathList.length : 0}장</TableKeyDiv>
                            <TableValueDiv>
                            </TableValueDiv>
                        </BoxTitleFlexDiv>
                        {
                            recvImagePathList !== undefined && recvImagePathList.length > 0 ?
                                <ImageListDiv>
                                    {
                                        recvImagePathList.map((v: any, index: any) => {
                                            const imageURL = new URL(v)
                                            const imageName = imageURL.pathname
                                            return (
                                                <EachImageDiv>
                                                    <ImageName>{imageName}</ImageName>
                                                    <ImageDiv>
                                                        <Image src={v} alt={v} />
                                                    </ImageDiv>
                                                </EachImageDiv>
                                            )
                                        })
                                    }
                                </ImageListDiv>
                                :
                                <h1>보여줄 이미지가 없습니다..!</h1>
                        }
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton
                            variant="outlined"
                            value={"확인"}
                            size="large"
                            color="primary"
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`
const ImageListDiv = Styled.div`
    text-align: -webkit-center;;
`
const EachImageDiv = Styled.div`
    width: 500px;
    margin-bottom: 20px;
`
const ImageName = Styled.div`
    font-size: 25px;
    font-weight: 600;
    color: #000000;
`
const ImageDiv = Styled.div`
    border: solid 2px #FC5D6B;
`
const Image = Styled.img`
    width: 100%;
`

export default ImagePreviewPopup