import React, { useEffect, useState, useCallback } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { getToken } from 'utils/token';
import { getSchema, postRequestWithToken, putRequestWithToken, getRequest } from 'utils/APIRequest';

import DragableCard from 'component/card/DragableCard'
import PrimaryButton from 'component/button/PrimaryButton';

import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

interface Props {
    open?: any,
    reviewTagForUpdate?: any,
    purpose?: any,
    onClickClose?: any,
    btnTextFirst?: any,
    btnTextSecond?: any,
}

const ReviewTagCreationPopup = ({ open, reviewTagForUpdate, purpose, onClickClose, btnTextFirst, btnTextSecond }: Props) => {
    const classes = useStyles();
    const history = useHistory()

    const [popupPurpose, setPopupPurpose] = useState<any>("추가")

    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [reviewTag, setReviewTag] = useState<any>(null)
    const [reviewTagID, setReviewTagID] = useState<number>(-1)
    const [reviewTagName, setReviewTagName] = useState<string | null>("")
    const [reviewTagDescription, setReviewTagDescription] = useState<string | null>("")

    const [recvReviewTagOptionList, setRecvReviewTagOptionList] = useState<any>([])

    const [reviewTagOptionName, setReviewTagOptionName] = useState<string>("")
    const [reviewTagOption, setReviewTagOption] = useState<any>("")
    const [reviewTagOptionList, setReviewTagOptionList] = useState<any>([])

    const createReviewTag = async () => {
        if (reviewTag.name === undefined || reviewTag.name === "") return
        if (reviewTag.description === undefined || reviewTag.description === "") return
        if (reviewTag.optionList === undefined || reviewTag.optionList.length <= 1) return

        const curToken = getToken()
        if (curToken === '') return

        const reviewTagInfo = {
            name: reviewTag.name,
            description: reviewTag.description,
            optionList: reviewTag.optionList
        }
        const curSchema = getSchema('/reviewTags', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(reviewTagInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/reviewTags',
            reviewTagInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                clearInputData()
                onClickClose()
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateReviewTag = async () => {
        if (reviewTag.name === undefined || reviewTag.name === "") return
        if (reviewTag.description === undefined || reviewTag.description === "") return
        if (reviewTag.optionList === undefined || reviewTag.optionList.length <= 1) return
        if (reviewTag.reviewTagId === undefined || reviewTag.reviewTagId === "" || reviewTag.reviewTagId === -1) return

        const curToken = getToken()
        if (curToken === '') return

        const reviewTagInfo = reviewTag
        const curSchema = getSchema('/reviewTags', 'PUT')

        if (curSchema) {
            try {
                await curSchema.validateAsync(reviewTagInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            `/reviewTags/${reviewTagInfo.reviewTagId}`,
            reviewTagInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                clearInputData()
                onClickClose()
            } else if (res.status === 204) {
                alert("reviewTag ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getReviewTag = async (reviewTagId: number) => {
        const res = await getRequest(
            '/reviewTags/' + reviewTagId
        )
        if (res !== null) {
            if (res.status === 200) {
                setReviewTagName(res.data.name)
                setReviewTagDescription(res.data.description)
                setRecvReviewTagOptionList([...res.data.optionList])
            } else if (res.status === 204) {
                alert("조회할 수 없는 reviewTag 입니다.")
                clearInputData()
                onClickClose()
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addIdTo = (optionList: any) => {
        let curOptionList: any = []
        if (optionList === undefined || optionList.length === 0) return curOptionList

        for (let i = 0; i < optionList.length; i ++) {
            const curOption = {
                id: i + 1,
                name: optionList[i],
            }
            curOptionList.push(curOption)
        }

        return curOptionList
    }

    const deleteIdFrom = (optionList: any) => {
        let curOptionList: any = []
        if (optionList === undefined || optionList.length === 0) return curOptionList

        curOptionList = optionList.map((option: any) => option.name)

        return curOptionList
    }

    const clearInputData = () => {
        setReviewTag(null)
        setReviewTagID(-1)
        setReviewTagName("")
        setReviewTagDescription("")
        setRecvReviewTagOptionList([])
        setReviewTagOptionName("")
        setReviewTagOption("")
        setReviewTagOptionList([])
    }

    const handleReviewTagNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReviewTagName(event.target.value)
    }

    const handleReviewTagDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReviewTagDescription(event.target.value)
    }

    const handleReviewTagOptionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReviewTagOptionName(String(event.target.value))
    }

    const moveReviewTagOptionCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setReviewTagOptionList((prevCards: any[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as any],
                ],
            }),
        )
    }, [])

    const renderReviewTagOptionCard = useCallback(
        (card: { id: number; name: string }, index: number) => {
            return (
                <DragableCard
                    key={card.id}
                    index={index}
                    id={card.id}
                    name={card.name}
                    moveCard={moveReviewTagOptionCard}
                />
            )
        },
        [],
    )

    const addReviewTagOption = () => {
        if (reviewTagOption === undefined || reviewTagOption === "") return

        let curReviewTagOptionList = reviewTagOptionList
        const reviewTagOptionListLen = curReviewTagOptionList.length
        const curReviewTagOption = reviewTagOption

        for (let i = 0; i < reviewTagOptionListLen; i++) {
            if (curReviewTagOptionList[i] === curReviewTagOption) {
                alert("입력하신 항목을 이미 등록했습니다.")
                return
            }
        }

        curReviewTagOptionList.push(curReviewTagOption)
        setReviewTagOptionList([...curReviewTagOptionList])
    }

    const deleteReviewTagOption = (curReviewTagOption: any) => {
        let curReviewTagOptionList = reviewTagOptionList

        curReviewTagOptionList = reviewTagOptionList.filter((v: any) => v !== curReviewTagOption)
        setReviewTagOptionList([...curReviewTagOptionList])
    }

    useEffect(() => {
        if (reviewTagOptionName === undefined || reviewTagOptionName === "") return

        let curReviewTagOption = {
            id: reviewTagOptionList.length + 1,
            name: reviewTagOptionName,
        }

        setReviewTagOption(curReviewTagOption)
    }, [reviewTagOptionName])

    useEffect(() => {
        if (reviewTagName === undefined || reviewTagName === "") {
            setIsAllFilled(false)
            return
        }
        if (reviewTagDescription === undefined || reviewTagDescription === "") {
            setIsAllFilled(false)
            return
        }
        if (reviewTagOptionList === undefined || reviewTagOptionList.length <= 1) {
            setIsAllFilled(false)
            return
        }

        const curReviewTagForUpdate = {
            ...reviewTagForUpdate,
            optionList: recvReviewTagOptionList
        }

        const curReviewTag = {
            reviewTagId: reviewTagID,
            name: reviewTagName,
            description: reviewTagDescription,
            optionList: deleteIdFrom(reviewTagOptionList)
        }

        if (reviewTagForUpdate !== undefined && JSON.stringify(curReviewTag) === JSON.stringify(curReviewTagForUpdate)) {
            setIsAllFilled(false)
            return
        }

        setReviewTag(curReviewTag)
        setIsAllFilled(true)

    }, [reviewTagName, reviewTagDescription, JSON.stringify(reviewTagOptionList)])

    useEffect(() => {
        if (recvReviewTagOptionList === undefined) return
        setReviewTagOptionList([...addIdTo(recvReviewTagOptionList)])

    }, [JSON.stringify(recvReviewTagOptionList)])

    useEffect(() => {
        if (reviewTagID === undefined || reviewTagID === -1) return
        getReviewTag(reviewTagID)

    }, [JSON.stringify(reviewTagID)])

    useEffect(() => {
        if(open) return
        clearInputData()
    }, [open])

    useEffect(() => {
        if (reviewTagForUpdate !== undefined) {
            setReviewTagID(reviewTagForUpdate.reviewTagId)
            setReviewTagName(reviewTagForUpdate.name)
            setReviewTagDescription(reviewTagForUpdate.description)
        }
    }, [JSON.stringify(reviewTagForUpdate)])

    useEffect(() => {
        if (purpose !== undefined)
            setPopupPurpose(purpose)
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>리뷰 태그 프로필 {popupPurpose}</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>리뷰 태그 명(Key) *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="reviewTagName"
                                                size="small"
                                                fullWidth
                                                value={reviewTagName}
                                                onChange={handleReviewTagNameChange}
                                                label={`리뷰 태그 명 예시: "맛 만족도"`}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>리뷰 태그 설명 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="reviewTagDescription"
                                                size="small"
                                                fullWidth
                                                value={reviewTagDescription}
                                                onChange={handleReviewTagDescriptionChange}
                                                label="리뷰 태그 명에 대한 설명 혹은 질문"
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                        <LargeGrayLineDivider />
                        <BoxContentDiv>
                            <BoxTitleFlexDiv>
                                <BoxContentInputKeyDiv>리뷰 태그 선택항목(Value) *</BoxContentInputKeyDiv>
                            </BoxTitleFlexDiv>
                            <BoxSubTitle>{"리뷰 태그 명에 대한 선택지를 추가해주세요(최소 두가지)"}</BoxSubTitle>
                            <BoxMarginDivider />
                            <BoxContentFlexDiv>
                                <BoxFlexStartDiv>
                                    <BoxContentInputDiv>
                                        <TextField
                                            id="reviewTagOptionName"
                                            size="small"
                                            fullWidth
                                            label='리뷰 태그 선택항목 입력'
                                            value={reviewTagOptionName}
                                            onChange={handleReviewTagOptionNameChange}
                                            onKeyPress={(ev) => {
                                                if (ev.key === 'Enter') {
                                                    if (reviewTagOption !== undefined && reviewTagOption !== "") {
                                                        addReviewTagOption()
                                                    }
                                                    ev.preventDefault()
                                                }
                                            }}
                                        />
                                    </BoxContentInputDiv>
                                </BoxFlexStartDiv>
                                <BoxFlexEndDiv>
                                    {
                                        reviewTagOption !== undefined && reviewTagOption !== "" ?
                                            <PrimaryButton value="등록" size="small" color="primary" onClickEvent={() => addReviewTagOption()} />
                                            :
                                            <PrimaryButton disabled={true} value="등록" size="small" color="primary" />
                                    }
                                </BoxFlexEndDiv>
                            </BoxContentFlexDiv>
                            <BoxDownArrowDiv>
                                <ArrowDownwardRoundedIcon fontSize="inherit" />
                            </BoxDownArrowDiv>
                            <BoxTitleFlexDiv>
                                <BoxContentInputKeyDiv>등록된 리뷰 태그 선택항목</BoxContentInputKeyDiv>
                            </BoxTitleFlexDiv>
                            <BoxSubTitle>{"드래그 앤 드랍으로 선택 순서 변경 가능 (모바일 지원X)"}</BoxSubTitle>
                            <BoxMarginDivider />
                            {
                                reviewTagOptionList.length !== 0 &&
                                <Paper sx={{ width: '100%', mb: 2 }}>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="reviewTagOptionList table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: '25%', fontWeight: 'bold', fontSize: 15 }}>선택 순서</TableCell>
                                                    <TableCell align="left" sx={{ width: '60%', fontWeight: 'bold', fontSize: 15 }}>{"항목명"}</TableCell>
                                                    <TableCell align="right" sx={{ width: '15%', fontWeight: 'bold', fontSize: 15 }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <DndProvider backend={HTML5Backend}>
                                                    {reviewTagOptionList.map((row: any, index: any) => (
                                                        <TableRow
                                                            key={row}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" sx={{ width: '13%' }}>
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ width: '70%' }}>{renderReviewTagOptionCard(row, index)}</TableCell>
                                                            <TableCell align="right" sx={{ width: '15%' }}>
                                                                <PrimaryButton value="삭제" size="small" color="error" onClickEvent={() => deleteReviewTagOption(row)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </DndProvider>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            }
                        </BoxContentDiv>
                        <LargeGrayLineDivider />
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton
                            variant="outlined"
                            value={btnTextFirst}
                            size="large"
                            color="primary"
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                        <VerticalDivider />
                        {
                            !isAllFilled ?
                                <PrimaryButton
                                    disabled={true}
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                />
                                :
                                <PrimaryButton
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                    onClickEvent={() => {
                                        if (purpose == "추가")
                                            createReviewTag()
                                        else
                                            updateReviewTag()
                                    }}
                                />
                        }
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentProfileDiv = Styled.div`
    width: 100%;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 230px;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 230px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const VerticalDivider = Styled.div`
    width: 20px;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`
const BoxContentInputDiv = Styled.div`
    width: calc(${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"} - 30px);
    padding: 0px 15px;
    font-size: 10px;
`
const BoxFlexStartDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`
const BoxContentDiv = Styled.div`
    width: calc(${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"} - 40px);
    padding: 0px 20px;
    margin-bottom: 25px;
`
const BoxFlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const BoxDownArrowDiv = Styled.div`
    font-size: 30px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
`
const LargeGrayLineDivider = Styled.div`
    height: 0px;
    border-top: solid 1px rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.12);
    margin-top: 60px;
    margin-bottom: 50px;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`

export default ReviewTagCreationPopup