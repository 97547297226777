import React, { useEffect, useState } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { getToken } from 'utils/token';
import { getSchema, postRequestWithToken, putRequestWithToken } from 'utils/APIRequest';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

interface Props {
    open?: any,
    distributorForUpdate?: any,
    purpose?: any,
    onClickClose?: any,
    btnTextFirst?: any,
    btnTextSecond?: any,
}

const DistributorCreationPopup = ({ open, distributorForUpdate, purpose, onClickClose, btnTextFirst, btnTextSecond }: Props) => {
    const classes = useStyles();
    const history = useHistory()

    const [popupPurpose, setPopupPurpose] = useState<any>("추가")

    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [distributor, setDistributor] = useState<any>(null)
    const [distributorID, setDistributorID] = useState<number>(-1)
    const [distributorName, setDistributorName] = useState<string | null>("")
    const [distributorDeliveryFee, setDistributorDeliveryFee] = useState<number | null>(0)
    const [distributorFreeDeliveryCondition, setDistributorFreeDeliveryCondition] = useState<number | null>(0)
    const [distributorMinimumOrderPrice, setDistributorMinimumOrderPrice] = useState<number | null>(0)

    const createDistributor = async () => {
        if (distributor.name === undefined || distributor.name === "") return
        if (distributor.deliveryFee === 0 && distributor.freeDeliveryCondition === 0 && distributor.minimumOrderPrice === 0) return
        const curToken = getToken()
        if (curToken === '') return

        const distributorInfo = {
            name: distributor.name,
            deliveryFee: distributor.deliveryFee,
            freeDeliveryCondition: distributor.freeDeliveryCondition,
            minimumOrderPrice: distributor.minimumOrderPrice,
        }
        const curSchema = getSchema('/distributors', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(distributorInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/distributors',
            distributorInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                clearInputData()
                onClickClose()
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateDistributor = async () => {
        if (distributor.name === undefined || distributor.name === "") return
        if (distributor.deliveryFee === 0 && distributor.freeDeliveryCondition === 0 && distributor.minimumOrderPrice === 0) return
        if (distributor.distributorId === undefined || distributor.distributorId === "" || distributor.distributorId === -1) return
        const curToken = getToken()
        if (curToken === '') return

        const distributorInfo = {
            name: distributor.name,
            deliveryFee: distributor.deliveryFee,
            freeDeliveryCondition: distributor.freeDeliveryCondition,
            minimumOrderPrice: distributor.minimumOrderPrice,
        }
        const curSchema = getSchema('/distributors', 'PUT')

        if (curSchema) {
            try {
                await curSchema.validateAsync(distributorInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            `/distributors/${distributor.distributorId}`,
            distributorInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                clearInputData()
                onClickClose()
            } else if (res.status === 204) {
                alert("distributor ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const clearInputData = () => {
        setDistributor(null)
        setDistributorID(-1)
        setDistributorName("")
        setDistributorDeliveryFee(0)
        setDistributorFreeDeliveryCondition(0)
        setDistributorMinimumOrderPrice(0)
    }

    const handleDistributorNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDistributorName(event.target.value)
    }

    const handleDistributorDeliveryFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const onlyNumber = event.target.value.replace(/[^0-9]/g, '')
        setDistributorDeliveryFee(Number(onlyNumber))
    }

    const handleDistributorFreeDeliveryConditionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const onlyNumber = event.target.value.replace(/[^0-9]/g, '')
        setDistributorFreeDeliveryCondition(Number(onlyNumber))
    }

    const handleDistributorMinimumOrderPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const onlyNumber = event.target.value.replace(/[^0-9]/g, '')
        setDistributorMinimumOrderPrice(Number(onlyNumber))
    }

    useEffect(() => {
        if (distributorName === undefined || distributorName === "") {
            setIsAllFilled(false)
            return
        }

        if (distributorDeliveryFee === undefined) {
            setIsAllFilled(false)
            return
        }

        if (distributorDeliveryFee === 0 && distributorFreeDeliveryCondition === 0 && distributorMinimumOrderPrice === 0) {
            setIsAllFilled(false)
            return
        }

        const curDistributor = {
            distributorId: distributorID,
            name: distributorName,
            deliveryFee: distributorDeliveryFee,
            freeDeliveryCondition: distributorFreeDeliveryCondition,
            minimumOrderPrice: distributorMinimumOrderPrice,
        }

        if (distributorForUpdate !== undefined && JSON.stringify(curDistributor) == JSON.stringify(distributorForUpdate)) {
            setIsAllFilled(false)
            return
        }

        setDistributor(curDistributor)
        setIsAllFilled(true)

    }, [distributorName, distributorDeliveryFee, distributorFreeDeliveryCondition, distributorMinimumOrderPrice])

    useEffect(() => {
        if(open) return
        clearInputData()
    }, [open])

    useEffect(() => {
        if (distributorForUpdate !== undefined) {
            setDistributorID(distributorForUpdate.distributorId)
            setDistributorName(distributorForUpdate.name)
            setDistributorDeliveryFee(distributorForUpdate.deliveryFee)
            setDistributorFreeDeliveryCondition(distributorForUpdate.freeDeliveryCondition)
            setDistributorMinimumOrderPrice(distributorForUpdate.minimumOrderPrice)
        }
    }, [JSON.stringify(distributorForUpdate)])

    useEffect(() => {
        if (purpose !== undefined)
            setPopupPurpose(purpose)
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>판매처 프로필 {popupPurpose}</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>판매처명 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="distributorName"
                                                size="small"
                                                fullWidth
                                                value={distributorName}
                                                onChange={handleDistributorNameChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>배송료 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="distributorDeliveryFee"
                                                size="small"
                                                fullWidth
                                                label="원"
                                                value={distributorDeliveryFee}
                                                onChange={handleDistributorDeliveryFeeChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>무료배송조건</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="distributorFreeDeliveryCondition"
                                                size="small"
                                                fullWidth
                                                label="{입력한 금액} 이상 구매시 무료배송"
                                                value={distributorFreeDeliveryCondition}
                                                onChange={handleDistributorFreeDeliveryConditionChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>최소주문금액</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="distributorMinimumOrderPrice"
                                                size="small"
                                                fullWidth
                                                label="원"
                                                value={distributorMinimumOrderPrice}
                                                onChange={handleDistributorMinimumOrderPriceChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton
                            variant="outlined"
                            value={btnTextFirst}
                            size="large"
                            color="primary"
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                        <VerticalDivider />
                        {
                            !isAllFilled ?
                                <PrimaryButton
                                    disabled={true}
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                />
                                :
                                <PrimaryButton
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                    onClickEvent={() => {
                                        if (purpose == "추가")
                                            createDistributor()
                                        else
                                            updateDistributor()
                                    }}
                                />
                        }
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentProfileDiv = Styled.div`
    width: 100%;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 110px;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 110px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const VerticalDivider = Styled.div`
    width: 20px;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`

export default DistributorCreationPopup