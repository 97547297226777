import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';

const drawerWidth = 300;
const isMobileDevice = isMobile()

const UserManagement = () => {
    const history = useHistory()

    useEffect(() => {
        alert("준비중인 기능입니다.")
        history.goBack()
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>홈</BoxTitle>
                        <BoxSubTitle>PortValue CMS에 오신것을 환영합니다 :)</BoxSubTitle>
                    </BoxDiv>
                    <BoxDiv>
                        <button onClick={() => { history.push("/signIn") }}>SignIn</button >
                        <button onClick={() => { history.push("/signUp") }}>SignUp</button >
                    </BoxDiv>
                </WrapDiv>
            </Box>
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`

export default UserManagement;