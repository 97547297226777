import React, { useEffect, useState } from 'react';
import Styled from 'styled-components';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

interface Props {
    open?: any,
    DCodeForUpdate?: any,
    purpose?: any,
    onClickClose?: any,
    btnTextFirst?: any,
    btnTextSecond?: any,
}

const DCodeCreationPopup = ({ open, DCodeForUpdate, purpose, onClickClose, btnTextFirst, btnTextSecond }: Props) => {
    const classes = useStyles();
    const [popupPurpose, setPopupPurpose] = useState<any>("추가")

    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [DCode, setDCode] = useState<any>(null)
    const [DCodeID, setDCodeID] = useState<number>(-1)
    const [DCodeName, setDCodeName] = useState<string | null>("")
    const [DCodeRatio, setDCodeRatio] = useState<string | null>("")

    const createDCode = () => {
        console.log("createDCode called")
        console.log(DCode)
        clearInputData()
        onClickClose()
    }

    const updateDCode = () => {
        console.log("updateDCode called")
        console.log(DCode)
        clearInputData()
        onClickClose()
    }

    const clearInputData = () => {
        setDCode(null)
        setDCodeID(-1)
        setDCodeName("")
        setDCodeRatio("")
    }

    const handleDCodeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDCodeName(event.target.value)
    }

    useEffect(() => {
        if (DCodeName == undefined || DCodeName == "") {
            setIsAllFilled(false)
            return
        }
        if (DCodeRatio == undefined || DCodeRatio == "") {
            setIsAllFilled(false)
            return
        }

        const curDCode = {
            id: DCodeID,
            name: DCodeName,
            ratio: DCodeRatio,
        }

        if (DCodeForUpdate !== undefined && JSON.stringify(curDCode) == JSON.stringify(DCodeForUpdate)) {
            setIsAllFilled(false)
            return
        }

        setDCode(curDCode)
        setIsAllFilled(true)

    }, [DCodeName, DCodeRatio])

    useEffect(() => {
        if(open) return
        clearInputData()
    }, [open])

    useEffect(() => {
        if (DCodeForUpdate !== undefined) {
            setDCodeID(DCodeForUpdate.id)
            setDCodeName(DCodeForUpdate.name)
            setDCodeRatio(DCodeForUpdate.ratio)
        }
    }, [JSON.stringify(DCodeForUpdate)])

    useEffect(() => {
        if (purpose !== undefined)
            setPopupPurpose(purpose)
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>할인코드 프로필 {popupPurpose}</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>할인코드명 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="DCodeName"
                                                size="small"
                                                fullWidth
                                                value={DCodeName}
                                                onChange={handleDCodeNameChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton
                            variant="outlined"
                            value={btnTextFirst}
                            size="large"
                            color="primary"
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                        <VerticalDivider />
                        {
                            !isAllFilled ?
                                <PrimaryButton
                                    disabled={true}
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                />
                                :
                                <PrimaryButton
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                    onClickEvent={() => {
                                        if (purpose == "추가")
                                            createDCode()
                                        else
                                            updateDCode()
                                    }}
                                />
                        }
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentProfileDiv = Styled.div`
    width: 100%;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 110px;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 110px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const VerticalDivider = Styled.div`
    width: 20px;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`

export default DCodeCreationPopup