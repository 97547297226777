import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import { getToken } from 'utils/token';
import { getRequest, deleteRequestWithToken } from 'utils/APIRequest';

import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import PrimaryButton from 'component/button/PrimaryButton';
import UnitCreationPopup from 'component/dialog/UnitCreationPopup';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const drawerWidth = 300;
const isMobileDevice = isMobile()

const DSBUnit = () => {
    const history = useHistory()

    const [unitList, setUnitList] = useState<any>([])
    const [unitListInTable, setUnitListInTable] = useState<any>([])
    const [infoStatus, setInfoStatus] = useState<any>(["미흡", "충족"])

    const [insertingQuery, setInsertingQuery] = useState<string>("")
    const [selectedUnitList, setSelectedUnitList] = useState<any>([])

    const [unitForUpdate, setUnitForUpdate] = useState<any>("")

    const [unitCreationPopupOpen, setUnitCreationPopupOpen] = useState<boolean>(false)
    const [unitUpdatePopupOpen, setUnitUpdatePopupOpen] = useState<boolean>(false)

    const handleInfoStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let curInfoStatus = infoStatus

        if (isSelectedInfoStatus(event.target.name))
            curInfoStatus = infoStatus.filter((v: any) => v != event.target.name)
        else
            curInfoStatus.push(event.target.name)

        setInfoStatus([...curInfoStatus])
    }

    const isSelectedInfoStatus = (name: string) => {
        let isSelected = false

        for (let i = 0; i < infoStatus.length; i++)
            if (infoStatus[i] === name)
                isSelected = true

        return isSelected
    }

    const handleUnitCreationPopupOpen = () => {
        setUnitCreationPopupOpen(true)
    }

    const handleUnitCreationPopupClose = () => {
        setUnitCreationPopupOpen(false)
    }

    const handleUnitUpdatePopupOpen = (curUnit: any) => {
        setUnitForUpdate({
            unitId: curUnit.unitId,
            name: curUnit.name,
            type: curUnit.type,
        })
    }

    const handleUnitUpdatePopupClose = () => {
        setUnitForUpdate("")
        setUnitUpdatePopupOpen(false)
    }

    const handleInsertingQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInsertingQuery(event.target.value)
    }

    const handleUnitListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const curID = Number(event.target.id)
        let curList = selectedUnitList

        if (isSelectedUnit(curID))
            curList = selectedUnitList.filter((v: any) => v !== curID)
        else
            curList.push(curID)

        setSelectedUnitList([...curList])
    }

    const isSelectedUnit = (unitId: number) => {
        let isSelected = false

        for (let i = 0; i < selectedUnitList.length; i++)
            if (selectedUnitList[i] === unitId)
                isSelected = true

        return isSelected
    }

    const deleteUnitList = async () => {
        if (selectedUnitList === undefined || selectedUnitList.length === 0) {
            alert("선택된 Unit이 없습니다.")
            return
        }

        const curToken = getToken()
        if (curToken === '') return

        const unitIdListInfo = {
            unitIdList: selectedUnitList
        }

        const res = await deleteRequestWithToken(
            '/units',
            unitIdListInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                getUnitList()
                setSelectedUnitList([])
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
            } else if (res.status === 204) {
                setSelectedUnitList([])
                return
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getUnitList = async () => {
        const res = await getRequest('/units')
        if (res !== null) {
            if (res.status === 200) {
                setUnitList([...res.data])
            } else if (res.status === 204) {
                setUnitList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    useEffect(() => {
        if (unitCreationPopupOpen)
            return

        getUnitList()
    }, [unitCreationPopupOpen])

    useEffect(() => {
        if (unitUpdatePopupOpen)
            return

        getUnitList()
    }, [unitUpdatePopupOpen])

    useEffect(() => {
        if (unitForUpdate == undefined || unitForUpdate == "")
            return

        setUnitUpdatePopupOpen(true)
    }, [unitForUpdate])

    useEffect(() => {
        if (unitList === undefined || unitList.length === 0) {
            setUnitListInTable([...unitList])
            return
        }
        if (infoStatus.length === 0) {
            setUnitListInTable([])
            return
        }
        
        let curUnitList: any[] = unitList
        if (infoStatus.length === 1) {
            curUnitList = curUnitList.filter((v: any) => {
                if(infoStatus[0] === "미흡" && !v.isFilled) return v
                if(infoStatus[0] === "충족" && v.isFilled) return v
            })
        }

        curUnitList = curUnitList.filter((v: any) => {
            if (v.name.includes(insertingQuery) || v.type.includes(insertingQuery))
                return v
        })
        setUnitListInTable([...curUnitList])
    }, [JSON.stringify(unitList), JSON.stringify(infoStatus), insertingQuery])

    useEffect(() => {
        getUnitList()
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>단위</BoxTitle>
                        <BoxSubTitle>{"단성비 > 상품 컴포넌트 > 단위"}</BoxSubTitle>
                    </BoxDiv>
                    <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>단위 목록</BoxTitleKey>
                            <BoxTitleValue>
                                <TextField
                                    id="cc-search-textfield"
                                    size="small"
                                    label="단위명, 종류로 단위 검색"
                                    fullWidth
                                    value={insertingQuery}
                                    onChange={handleInsertingQueryChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxDivider />
                        <BoxTitleFlexDiv>
                            <div></div>
                            <FormControl>
                                <FormGroup row>
                                    <FormGroupTitle>필수정보 상태</FormGroupTitle>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={isSelectedInfoStatus("충족")} onChange={handleInfoStatusChange} name="충족" />
                                        }
                                        label="충족"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={isSelectedInfoStatus("미흡")} onChange={handleInfoStatusChange} name="미흡" />
                                        }
                                        label="미흡"
                                    />
                                </FormGroup>
                            </FormControl>
                        </BoxTitleFlexDiv>
                        <BoxDivider />
                        <BoxTitleFlexDiv>
                            <TableKeyDiv>총 {unitListInTable !== undefined ? unitListInTable.length : 0}개</TableKeyDiv>
                            <TableValueDiv>
                                <FlexEndDiv>
                                    <PrimaryButton
                                        value="단위 추가"
                                        size="medium"
                                        color="primary"
                                        onClickEvent={() => handleUnitCreationPopupOpen()}
                                    />
                                    <BoxVirticalDivider />
                                    {
                                        selectedUnitList == undefined || selectedUnitList.length == 0 ?
                                            <PrimaryButton disabled={true} value="선택 삭제" size="medium" color="error" />
                                            :
                                            <PrimaryButton value="선택 삭제" size="medium" color="error" onClickEvent={() => deleteUnitList()} />
                                    }
                                </FlexEndDiv>
                            </TableValueDiv>
                        </BoxTitleFlexDiv>
                        {
                            unitListInTable !== undefined && unitListInTable.length !== 0 &&
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="unitList table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>단위명</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>종류</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>필수정보</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {unitListInTable.map((v: any) => (
                                                <TableRow
                                                    key={v.unitId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Checkbox
                                                            id={v.unitId}
                                                            checked={isSelectedUnit(v.unitId)}
                                                            onChange={handleUnitListChange}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">{v.name} </TableCell>
                                                    <TableCell align="left">{v.type}</TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            v.isFilled === false ?
                                                                <TableCellHighlightText>{"미흡"}</TableCellHighlightText>
                                                                :
                                                                <>{"충족"}</>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <PrimaryButton
                                                            value="수정"
                                                            size="small"
                                                            color="success"
                                                            onClickEvent={() => {
                                                                handleUnitUpdatePopupOpen(v)
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        }
                    </BoxDiv>
                </WrapDiv>
            </Box>
            {
                <>
                    <UnitCreationPopup
                        open={unitCreationPopupOpen}
                        purpose="추가"
                        onClickClose={handleUnitCreationPopupClose}
                        btnTextFirst="취소"
                        btnTextSecond="추가"
                    />
                </>
            }
            {
                <>
                    <UnitCreationPopup
                        open={unitUpdatePopupOpen}
                        unitForUpdate={unitForUpdate}
                        purpose="수정"
                        onClickClose={handleUnitUpdatePopupClose}
                        btnTextFirst="취소"
                        btnTextSecond="수정"
                    />
                </>
            }
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const FlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const BoxVirticalDivider = Styled.div`
    width: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`
const FormGroupTitle = Styled.div`
    font-size: 16px;
    color: #666666;
    margin-top: 9px;
    margin-right: 20px;
`
const TableCellHighlightText = Styled.div`
    font-weight: 600;
    font-size: 18px;
    color: red;
`

export default DSBUnit;