import React, { useEffect, useState } from 'react';
import Styled from 'styled-components';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

interface Props {
    open?: any,
    favoriteProductForUpdate?: any,
    purpose?: any,
    onClickClose?: any,
    btnTextFirst?: any,
    btnTextSecond?: any,
}

const FavoriteProductCreationPopup = ({ open, favoriteProductForUpdate, purpose, onClickClose, btnTextFirst, btnTextSecond }: Props) => {
    const classes = useStyles();
    const [popupPurpose, setPopupPurpose] = useState<any>("추가")

    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [favoriteProduct, setFavoriteProduct] = useState<any>(null)
    const [favoriteProductID, setFavoriteProductID] = useState<number>(-1)
    const [favoriteProductName, setFavoriteProductName] = useState<string | null>("")
    const [favoriteProductURL, setFavoriteProductURL] = useState<string | null>("")
    const [favoriteProductPrice, setFavoriteProductPrice] = useState<string | null>("")
    const [favoriteProductSerialNum, setFavoriteProductSerialNum] = useState<string | null>("")

    const createFavoriteProduct = () => {
        //TODO: FavoriteProductID가 -1이므로 이에 대한 처리 필요
        console.log("createFavoriteProduct called")
        console.log(favoriteProduct)
        clearInputData()
        onClickClose()
    }

    const updateFavoriteProduct = () => {
        console.log("updateFavoriteProduct called")
        console.log(favoriteProduct)
        clearInputData()
        onClickClose()
    }

    const clearInputData = () => {
        setFavoriteProduct(null)
        setFavoriteProductID(-1)
        setFavoriteProductName("")
        setFavoriteProductURL("")
        setFavoriteProductPrice("")
        setFavoriteProductSerialNum("")
    }

    const handleFavoriteProductNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFavoriteProductName(event.target.value)
    }

    const handleFavoriteProductURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFavoriteProductURL(event.target.value)
    }

    const handleFavoriteProductSerialNumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFavoriteProductSerialNum(event.target.value)
    }

    useEffect(() => {
        if (favoriteProductName == undefined || favoriteProductName == "") {
            setIsAllFilled(false)
            return
        }
        if (favoriteProductPrice == undefined || favoriteProductPrice == "") {
            setIsAllFilled(false)
            return
        }
        if (favoriteProductURL == undefined || favoriteProductURL == "") {
            setIsAllFilled(false)
            return
        }
        if (favoriteProductSerialNum == undefined || favoriteProductSerialNum == "") {
            setIsAllFilled(false)
            return
        }

        const curFavoriteProduct = {
            id: favoriteProductID,
            name: favoriteProductName,
            price: favoriteProductPrice,
            URL: favoriteProductURL,
            serialNum: favoriteProductSerialNum,
        }

        if (favoriteProductForUpdate !== undefined && JSON.stringify(curFavoriteProduct) == JSON.stringify(favoriteProductForUpdate)) {
            setIsAllFilled(false)
            return
        }

        setFavoriteProduct(curFavoriteProduct)
        setIsAllFilled(true)

    }, [favoriteProductName, favoriteProductURL, favoriteProductPrice, favoriteProductSerialNum])

    useEffect(() => {
        if(open) return
        clearInputData()
    }, [open])

    useEffect(() => {
        if (favoriteProductForUpdate !== undefined) {
            setFavoriteProductID(favoriteProductForUpdate.id)
            setFavoriteProductName(favoriteProductForUpdate.name)
            setFavoriteProductURL(favoriteProductForUpdate.URL)
            setFavoriteProductPrice(favoriteProductForUpdate.price)
            setFavoriteProductSerialNum(favoriteProductForUpdate.serialNum)
        }
    }, [JSON.stringify(favoriteProductForUpdate)])

    useEffect(() => {
        if (purpose !== undefined)
            setPopupPurpose(purpose)
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>인기상품 프로필 {popupPurpose}</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>상품명 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="favoriteProductName"
                                                size="small"
                                                fullWidth
                                                value={favoriteProductName}
                                                onChange={handleFavoriteProductNameChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>URL *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="favoriteProductName"
                                                size="small"
                                                fullWidth
                                                value={favoriteProductURL}
                                                onChange={handleFavoriteProductURLChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>시리얼 넘버 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="favoriteProductSerialNum"
                                                size="small"
                                                fullWidth
                                                value={favoriteProductSerialNum}
                                                onChange={handleFavoriteProductSerialNumChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton
                            variant="outlined"
                            value={btnTextFirst}
                            size="large"
                            color="primary"
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                        <VerticalDivider />
                        {
                            !isAllFilled ?
                                <PrimaryButton
                                    disabled={true}
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                />
                                :
                                <PrimaryButton
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                    onClickEvent={() => {
                                        if (purpose == "추가")
                                            createFavoriteProduct()
                                        else
                                            updateFavoriteProduct()
                                    }}
                                />
                        }
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentProfileDiv = Styled.div`
    width: 100%;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 110px;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 110px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const VerticalDivider = Styled.div`
    width: 20px;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`

export default FavoriteProductCreationPopup