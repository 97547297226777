import React, { useEffect, useState } from 'react';
import QueryString from 'query-string'
import Styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom';

import { setToken, removeToken } from 'utils/token';
import { signCheck, getSchema, postRequest } from 'utils/APIRequest';

import { makeStyles, Theme, createStyles, withStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        textField: {
            borderRadius: 4,
            marginBottom: 16,
        }
    }),
);

const SignIn = () => {
    const history = useHistory()
    const location = useLocation()
    
    const classes = useStyles()

    const [isSignMaintain, setIsSignMaintain] = useState<boolean>(false);
    const [userID, setUserID] = useState<string>("");
    const [userPW, setUserPW] = useState<string>("");
    const [errTextWithID, setErrTextWithID] = useState<string>("");
    const [errTextWithPW, setErrTextWithPW] = useState<string>("");

    const handleCheckBoxChange = (event: { target: any; }) => {
        setIsSignMaintain(event.target.checked)
    }

    const handleIDChange = (event: { target: any; }) => {
        if (errTextWithID !== "" && event.target.value.length > 0) setErrTextWithID("")
        setUserID(event.target.value)
    }

    const handlePWChange = (event: { target: any; }) => {
        if (errTextWithPW !== "" && event.target.value.length > 0) setErrTextWithPW("")
        setUserPW(event.target.value)
    }

    const isAllFilled = () => {
        if (userID === "" || userPW === "") return false
        if (userID.length < 0 || userID.length > 50) return false
        if (userPW.length < 6 || userPW.length > 20) return false

        return true
    }

    const validateErrHandle = (errMessage: any) => {
        const errInfo = validateErrParse(errMessage)
        if (errInfo.from === '"email"') {
            setErrTextWithID(errInfo.message)
        } else if (errInfo.from === '"password"') {
            setErrTextWithPW(errInfo.message)
        } else {
            setErrTextWithID("ValidationError")
            setErrTextWithPW("ValidationError")
        }
    }

    const validateErrParse = (errMessage: any) => {
        let errInfo = {
            from: "",
            message: "",
        }

        const errSplitList = errMessage.split(' ')
        const errSplitListLen = errSplitList.length

        if (errSplitListLen > 0) {
            errInfo.from = errSplitList[0]
            errInfo.message = errSplitList.slice(1).join(' ')
        }

        return errInfo
    }

    const summitSignInInfo = async () => {
        if (!isAllFilled()) return
        const signInInfo = { email: userID, password: userPW }
        const curSchema = getSchema('/auth/signIn', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(signInInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                validateErrHandle(err.message)
                return
            }
        } else {
            return
        }

        const res = await postRequest(
            '/auth/signIn',
            signInInfo
        )
        if (res !== null) {
            if (res.status === 200) {
                const curToken = res.data.token
                if (curToken === undefined || curToken === "") {
                    alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "token: " + curToken)
                    return
                } else {
                    setToken(curToken, isSignMaintain)
                    history.push("/")
                    window.location.reload()
                }
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                validateErrHandle(res.data.message)
            } else if (res.status === 204) {
                setErrTextWithID("등록된 계정이 없습니다. ID를 확인해주세요!")
            } else if (res.status === 401) {
                setErrTextWithPW("비밀번호를 확인해주세요!")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const parseQS = (curLocation: any) => {
        const params = new URLSearchParams(curLocation.search)
        const recvUserID = params.get("id")

        if(recvUserID && recvUserID !== "")
            setUserID(decodeURI(recvUserID))
        
    }

    useEffect(() => {
        parseQS(location)
    }, [location])

    useEffect(() => {
        signCheck().then((isSignIn) => {
            if (isSignIn) {
                if (window.confirm("이미 로그인 되어있습니다. 로그아웃하고 다른 계정을 이용하시겠습니까?")) {
                    removeToken()
                } else {
                    history.push("/")
                }
            }
        });

    }, [])

    return (
        <WrapDiv>
            <ElevatedCard>
                <CardTitle>Sign in to PV-CMS</CardTitle>
                <CardSubTitle>PortValue CMS에 오신걸 환영합니다🎉</CardSubTitle>
                <TextField
                    className={classes.textField}
                    error={errTextWithID !== "" ? true : false}
                    fullWidth
                    label="이메일 혹은 아이디"
                    color="primary"
                    variant="outlined"
                    helperText={errTextWithID}
                    value={userID}
                    onChange={handleIDChange}
                    inputProps={{
                        maxLength: 50,
                    }}
                />
                <TextField
                    className={classes.textField}
                    error={errTextWithPW !== "" ? true : false}
                    fullWidth
                    label="비밀번호"
                    color="primary"
                    variant="outlined"
                    helperText={errTextWithPW}
                    value={userPW}
                    type="password"
                    autoComplete="current-password"
                    onChange={handlePWChange}
                    inputProps={{
                        maxLength: 50,
                    }}
                />
                <SummitBtn
                    active={isAllFilled()}
                    onClick={() => { if (isAllFilled()) summitSignInInfo() }}
                >로그인</SummitBtn>
                <CheckboxDiv>
                    <Checkbox
                        checked={isSignMaintain}
                        onChange={handleCheckBoxChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <CheckboxTitle>로그인 상태 유지</CheckboxTitle>
                </CheckboxDiv>
                <SignInDetailDiv>
                    <SignInFindDiv>
                        {/* <BlueLink onClick={() => { history.push("/findID") }}>아이디 찾기</BlueLink>
                        <BlueLink onClick={() => { history.push("/findPW") }}>비밀번호 찾기</BlueLink> */}
                    </SignInFindDiv>
                    <BlueLink onClick={() => { history.push("/signUp") }}>회원가입</BlueLink>
                </SignInDetailDiv>
            </ElevatedCard>
        </WrapDiv>
    )
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`
const ElevatedCard = Styled.div`
    width: 100%;
    max-width: 376px;
    position: fixed;
    left: 50%;
    top: 50%; 
    transform: translate(-50%,-50%);
    background: #FFFFFF;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 16px;
`
const CardTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 133.4%;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-bottom: 46px;
`
const CardSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
    text-align: left;
`
const SummitBtn = Styled.div`
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    background: ${(props: { active: any; }) => (props.active ? "#1976D2" : "rgba(0, 0, 0, 0.12)")};
    color: ${(props: { active: any; }) => (props.active ? "#FFFFFF" : "rgba(0, 0, 0, 0.26)")};
`
const CheckboxDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
`
const CheckboxTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px;
`
const SignInDetailDiv = Styled.div`
    display: flex;
    justify-content: space-between;
`
const SignInFindDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    width: 40%;
`
const BlueLink = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: #1976D2;
    text-decoration: underline;
`
export default SignIn;