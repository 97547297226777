import React, { useEffect, useState, useCallback } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { getToken } from 'utils/token';
import { getSchema, postRequestWithToken, getRequest, postImageRequestWithToken } from 'utils/APIRequest';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

interface UnitOptionType {
    inputValue?: string;
    unitId: number;
    name: string;
    type?: string;
}

interface FlavorOptionType {
    inputValue?: string;
    flavorId: number;
    name: string;
}

const filterUnitOption = createFilterOptions<UnitOptionType>();
const filterFlavorOption = createFilterOptions<FlavorOptionType>();

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

interface Props {
    open?: any,
    PPId?: any,
    onClickClose?: any,
    btnTextFirst?: any,
    btnTextSecond?: any,
}

const CPCreationPopup = ({ open, PPId, onClickClose, btnTextFirst, btnTextSecond }: Props) => {
    const classes = useStyles();
    const history = useHistory()

    const [unitList, setUnitList] = useState<any>([])
    const [flavorList, setFlavorList] = useState<any>([])

    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)

    const [CP, setCP] = useState<any>(null)
    const [CPImagePath, setCPImagePath] = useState<any>("")
    const [CPFlavor, setCPFlavor] = useState<any>(null);
    const [CPFlavorId, setCPFlavorId] = useState<number | null>(null);
    const [CPCapacity, setCPCapacity] = useState<number>(0);
    const [CPUnit, setCPUnit] = useState<any>(null);
    const [CPUnitId, setCPUnitId] = useState<number | null>(null);
    const [CPItemCode, setCPItemCode] = useState<string>("");
    const [CPLicenseNo, setCPLicenseNo] = useState<string>("");

    const onDropRejected = useCallback(rejectedFiles => {
        if (rejectedFiles[0] !== undefined)
            alert(JSON.stringify(rejectedFiles[0].errors))
    }, [])

    const onDropAccepted = useCallback(acceptedFile => {
        let formData = new FormData()
        formData.append('image', acceptedFile[0])
        postImage(formData)
    }, [])

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 100 * 1024,
        multiple: false,
        onDropRejected,
        onDropAccepted,
    })

    const postImage = async (formData: any) => {
        if (formData === undefined || !formData) return
        const curToken = getToken()
        if (curToken === '') return

        const res = await postImageRequestWithToken(
            formData,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                if (res.data.location === undefined) {
                    alert("이미지 경로를 찾을 수 없습니다. \n\n" + JSON.stringify(res.data))
                    return
                }
                setCPImagePath(res.data.location)
            } else if (res.status === 409) {
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const handleCPItemCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCPItemCode(event.target.value)
    }

    const handleCPLicenseNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCPLicenseNo(event.target.value)
    }

    const createCP = async (parentProductId: number) => {
        if (parentProductId === undefined || parentProductId === -1) return

        const curToken = getToken()
        if (curToken === '') return

        const CPInfo = CP
        const curSchema = getSchema('/products/{parentProductId}/childs', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(CPInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            `/products/${parentProductId}/childs`,
            CPInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                clearInputData()
                onClickClose()
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const clearInputData = () => {
        setCP(null)
        setCPImagePath("")
        setCPFlavor(null)
        setCPFlavorId(null)
        setCPCapacity(0)
        setCPUnit(null)
        setCPUnitId(null)
        setCPItemCode("")
        setCPLicenseNo("")
    }

    const getFlavorList = async () => {
        const res = await getRequest("/flavors")
        if (res !== null) {
            if (res.status === 200) {
                if (res.data.flavorList !== undefined)
                    setFlavorList([...res.data.flavorList])
            } else if (res.status === 204) {
                setFlavorList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addFlavor = async (flavor: FlavorOptionType) => {
        if (flavor.name === undefined || flavor.name === "") return
        if (flavor.flavorId === undefined || flavor.flavorId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const flavorInfo = { name: flavor.name }
        const curSchema = getSchema('/flavors', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(flavorInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/flavors',
            flavorInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getFlavorList()
                const createdFlavor = res.data
                setCPFlavor({
                    flavorId: createdFlavor.flavorId,
                    name: createdFlavor.name,
                    type: createdFlavor.type
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getUnitList = async (type: string) => {
        if (type === undefined || type === "") return
        const res = await getRequest('/units?type=' + type)
        if (res !== null) {
            if (res.status === 200) {
                setUnitList([...res.data])
            } else if (res.status === 204) {
                setUnitList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addUnit = async (unit: UnitOptionType) => {
        if (unit.name === undefined || unit.name === "") return
        if (unit.unitId === undefined || unit.unitId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const unitInfo = { name: unit.name, type: "CAPACITY" }
        const curSchema = getSchema('/units', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(unitInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/units',
            unitInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getUnitList("CAPACITY")
                const createdUnit = res.data
                setCPUnit({
                    unitId: createdUnit.unitId,
                    name: createdUnit.name,
                    type: createdUnit.type
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    useEffect(() => {
        if (CPFlavorId === undefined || CPFlavorId === null || CPFlavorId <= 0) {
            setIsAllFilled(false)
            return
        }
        if (CPCapacity === undefined || CPCapacity <= 0) {
            setIsAllFilled(false)
            return
        }
        if (CPUnitId === undefined || CPUnitId === null || CPUnitId <= 0) {
            setIsAllFilled(false)
            return
        }
        if (CPUnitId === undefined || CPUnitId === null || CPUnitId <= 0) {
            setIsAllFilled(false)
            return
        }

        let itemCodeInfo = {}
        let licenseNoInfo = {}
        if (CPItemCode !== undefined && CPItemCode !== "") {
            itemCodeInfo = {
                itemCode: CPItemCode,
            }
        }
        if (CPLicenseNo !== undefined && CPLicenseNo !== "") {
            licenseNoInfo = {
                licenseNo: CPLicenseNo,
            }
        }

        const curCP = {
            imagePath: CPImagePath,
            flavorId: CPFlavorId,
            capacity: CPCapacity,
            capacityUnitId: CPUnitId,
            ...itemCodeInfo,
            ...licenseNoInfo,
        }

        setCP(curCP)
        setIsAllFilled(true)

    }, [CPImagePath, CPUnitId, CPFlavorId, String(CPCapacity), CPItemCode, String(CPLicenseNo)])

    useEffect(() => {
        if (!CPFlavor)
            return
        if (CPFlavor.flavorId !== undefined && CPFlavor.flavorId === -1)
            addFlavor(CPFlavor)

        setCPFlavorId(CPFlavor.flavorId)
    }, [JSON.stringify(CPFlavor)])

    useEffect(() => {
        if (!CPUnit)
            return
        if (CPUnit.unitId !== undefined && CPUnit.unitId === -1)
            addUnit(CPUnit)

        setCPUnitId(CPUnit.unitId)
    }, [JSON.stringify(CPUnit)])

    useEffect(() => {
        if (PPId !== undefined || PPId === -1) {
            return
        }
    }, [String(PPId)])

    useEffect(() => {
        if (PPId !== undefined || PPId === -1) return
        if (!open) {
            clearInputData()
            return
        }
    }, [open])

    useEffect(() => {
        getUnitList("CAPACITY")
        getFlavorList()
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>Child 상품 프로필</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxFlexStartDiv>
                            {
                                CPImagePath !== undefined && CPImagePath !== null && CPImagePath !== "" ?
                                    <DropzonePreviewDiv>
                                        <ProfileImageDiv>
                                            <ProfileImage src={CPImagePath} alt={CPImagePath} />
                                            <ImageCancleBtn onClick={() => setCPImagePath("")}>삭제</ImageCancleBtn>
                                        </ProfileImageDiv>
                                    </DropzonePreviewDiv>
                                    :
                                    <DropzoneDiv {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragReject ?
                                                <DropzoneRejectDiv>
                                                    <div>1. File Size는 100KB 이하</div>
                                                    <div>2. 이미지 업로드는 한장만 허용</div>
                                                    <div>3. 이미지 파일 타입 에러는 개발자에게 문의 바람</div>
                                                </DropzoneRejectDiv>
                                                :
                                                <>
                                                    {
                                                        isDragActive ?
                                                            <DropzoneHelpDiv>추가할 이미지를 여기로 드랍해주세요 :)</DropzoneHelpDiv> :
                                                            <DropzoneHelpDiv>이미지를 드래그 후 드랍하거나 여기를 클릭해서 추가해주세요 :)</DropzoneHelpDiv>
                                                    }
                                                </>
                                        }
                                    </DropzoneDiv>
                            }
                        </BoxFlexStartDiv>
                        <BoxMarginDivider />
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="50%">
                                        <BoxContentInputKeyDiv>맛 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <Autocomplete
                                                id="CPFlavor"
                                                value={CPFlavor}
                                                onChange={(event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        setCPFlavor({
                                                            flavorId: -1,
                                                            name: newValue.inputValue,
                                                        });
                                                    } else {
                                                        setCPFlavor(newValue);
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filterFlavorOption(options, params);

                                                    const { inputValue } = params;
                                                    const isExisting = options.some((option) => inputValue === option.name);
                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            name: `"${inputValue}" 추가!`,
                                                            flavorId: -1
                                                        });
                                                    }

                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                options={flavorList}
                                                getOptionLabel={(option) => {
                                                    return option.name;
                                                }}
                                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        size="small"
                                                        fullWidth
                                                        label='맛'
                                                    />
                                                )}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                    <BoxContentInputFlexDiv width="50%">
                                        <BoxContentInputKeyDiv>용량 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueFlexDiv>
                                            <BoxFiftyPercentDiv>
                                                <TextField
                                                    id="CPCapacity"
                                                    size="small"
                                                    fullWidth
                                                    label='용량'
                                                    value={CPCapacity}
                                                    type="number"
                                                    onChange={event => {
                                                        const valueNum = Number(event.target.value)
                                                        if (valueNum < 0) return
                                                        setCPCapacity(valueNum)
                                                    }}
                                                />
                                            </BoxFiftyPercentDiv>
                                            <BoxFiftyPercentDiv>
                                                <Autocomplete
                                                    id="CPUnit"
                                                    value={CPUnit}
                                                    onChange={(event, newValue) => {
                                                        if (newValue && newValue.inputValue) {
                                                            setCPUnit({
                                                                unitId: -1,
                                                                name: newValue.inputValue,
                                                            });
                                                        } else {
                                                            setCPUnit(newValue);
                                                        }
                                                    }}
                                                    filterOptions={(options, params) => {
                                                        const filtered = filterUnitOption(options, params);

                                                        const { inputValue } = params;
                                                        const isExisting = options.some((option) => inputValue === option.name);
                                                        if (inputValue !== '' && !isExisting) {
                                                            filtered.push({
                                                                inputValue,
                                                                name: `"${inputValue}" 추가!`,
                                                                unitId: -1
                                                            });
                                                        }

                                                        return filtered;
                                                    }}
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                    options={unitList}
                                                    getOptionLabel={(option) => {
                                                        return option.name;
                                                    }}
                                                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                    freeSolo
                                                    renderInput={(params) => (
                                                        <TextField {...params}
                                                            size="small"
                                                            fullWidth
                                                            label='단위(용량)'
                                                        />
                                                    )}
                                                />
                                            </BoxFiftyPercentDiv>
                                        </BoxContentInputValueFlexDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="50%">
                                        <BoxContentInputKeyDiv>식품코드</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="CPItemCode"
                                                size="small"
                                                fullWidth
                                                value={CPItemCode}
                                                onChange={handleCPItemCodeChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                    <BoxContentInputFlexDiv width="50%">
                                        <BoxContentInputKeyDiv>품목보고번호</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="CPLicenseNo"
                                                size="small"
                                                fullWidth
                                                value={CPLicenseNo}
                                                onChange={handleCPLicenseNoChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton
                            variant="outlined"
                            value={btnTextFirst}
                            size="large"
                            color="primary"
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                        <VerticalDivider />
                        {
                            !isAllFilled ?
                                <PrimaryButton
                                    disabled={true}
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                />
                                :
                                <PrimaryButton
                                    value={btnTextSecond}
                                    size="large"
                                    color="primary"
                                    onClickEvent={() => {
                                        createCP(PPId)
                                    }}
                                />
                        }
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentProfileDiv = Styled.div`
    width: 100%;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
`
const BoxFiftyPercentDiv = Styled.div`
    width: 50%;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 110px;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 110px);
`
const BoxContentInputValueFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    width: calc(100% - 110px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const VerticalDivider = Styled.div`
    width: 20px;
`
const BoxFlexStartDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`
const DropzonePreviewDiv = Styled.div`
    width: 100%;
    height: 230px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #EBEBEB;
    border-radius: 20px;
`
const DropzoneDiv = Styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 3px #8fb7ff;
    border-radius: 20px;
`
const DropzoneHelpDiv = Styled.div`
    font-size: 25px;
    color: #8fb7ff;
`
const DropzoneRejectDiv = Styled.div`
    font-size: 25px;
    color: #fc5d6b;
`
const ProfileImageDiv = Styled.div`
    width: fit-content;
    height: 230px;
`
const ProfileImage = Styled.img`
    height: 200px;
    width: auto;
`
const ImageCancleBtn = Styled.div`
    width: 100%;
    color: #ffffff;
    background: #fc5d6b;
    border-radius: 10px;
    padding: 5px 0px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`

export default CPCreationPopup