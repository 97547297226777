import React, { useEffect, useState } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';

import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import PrimaryButton from 'component/button/PrimaryButton';
import DCodeCreationPopup from 'component/dialog/DCodeCreationPopup';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

const drawerWidth = 300;
const isMobileDevice = isMobile()

const tempDCodeList = [
    {
        id: 1,
        name: "CUEING",
        ratio: "97%",
    },
    {
        id: 2,
        name: "FANCAFE",
        ratio: "17%",
    },
    {
        id: 3,
        name: "HORSEKING",
        ratio: "17%",
    },
]

const SBBGMPDCode = () => {
    const history = useHistory()

    const [DCodeList, setDCodeList] = useState<any>(tempDCodeList)

    const [insertingQuery, setInsertingQuery] = useState<string>("")
    const [searchingQuery, setSearchingQuery] = useState<string>("")
    const [selectedDCodeList, setSelectedDCodeList] = useState<any>([])

    const [DCodeForUpdate, setDCodeForUpdate] = useState<any>("")

    const [DCodeCreationPopupOpen, setDCodeCreationPopupOpen] = useState<boolean>(false)
    const [DCodeUpdatePopupOpen, setDCodeUpdatePopupOpen] = useState<boolean>(false)

    const handleDCodeCreationPopupOpen = () => {
        setDCodeCreationPopupOpen(true)
    }

    const handleDCodeCreationPopupClose = () => {
        setDCodeCreationPopupOpen(false)
    }

    const handleDCodeUpdatePopupOpen = (curDCode: any) => {
        setDCodeForUpdate(curDCode)
    }

    const handleDCodeUpdatePopupClose = () => {
        setDCodeForUpdate("")
        setDCodeUpdatePopupOpen(false)
    }

    const handleInsertingQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInsertingQuery(event.target.value)
    }

    const handleDCodeListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let curList = selectedDCodeList

        if (isSelectedDCode(event.target.name))
            curList = selectedDCodeList.filter((v: any) => v != event.target.name)
        else
            curList.push(event.target.name)

        setSelectedDCodeList([...curList])
    }

    const isSelectedDCode = (name: string) => {
        let isSelected = false

        for (let i = 0; i < selectedDCodeList.length; i++)
            if (selectedDCodeList[i] === name)
                isSelected = true

        return isSelected
    }

    useEffect(() => {
        if (DCodeCreationPopupOpen)
            return

        //TODO: DCode list api calling needed
        console.log("DCode list api calling needed")

    }, [DCodeCreationPopupOpen])

    useEffect(() => {
        if (DCodeUpdatePopupOpen)
            return

        //TODO: DCode list api calling needed
        console.log("DCode list api calling needed")

    }, [DCodeUpdatePopupOpen])

    useEffect(() => {
        if (DCodeForUpdate == undefined || DCodeForUpdate == "")
            return

        setDCodeUpdatePopupOpen(true)
    }, [DCodeForUpdate])

    useEffect(() => {
        if (searchingQuery !== "")
            alert(searchingQuery + "로 검색하는 부분~")
    }, [searchingQuery])

    useEffect(() => {
        alert("준비중인 기능입니다.")
        history.goBack()
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>마이프로틴 할인코드</BoxTitle>
                        <BoxSubTitle>{"SBBG > 크롤링 컴포넌트 > 마이프로틴 할인코드"}</BoxSubTitle>
                    </BoxDiv>
                    <BoxDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>할인코드 목록</BoxTitleKey>
                            <BoxTitleValue>
                                <TextField
                                    id="cc-search-textfield"
                                    size="small"
                                    label="할인코드 검색"
                                    fullWidth
                                    value={insertingQuery}
                                    onChange={handleInsertingQueryChange}
                                    onKeyPress={(ev) => {
                                        if (ev.key === 'Enter') {
                                            setSearchingQuery(insertingQuery)
                                            ev.preventDefault()
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={() => setSearchingQuery(insertingQuery)}
                                >검색</Button>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxDivider />
                        <BoxTitleFlexDiv>
                            <TableKeyDiv>총 {DCodeList !== undefined ? DCodeList.length : 0}개</TableKeyDiv>
                            <TableValueDiv>
                                <FlexEndDiv>
                                    <PrimaryButton
                                        value="할인코드 추가"
                                        size="medium"
                                        color="primary"
                                        onClickEvent={() => handleDCodeCreationPopupOpen()}
                                    />
                                    <BoxVirticalDivider />
                                    {
                                        selectedDCodeList == undefined || selectedDCodeList.length == 0 ?
                                            <PrimaryButton disabled={true} value="선택 삭제" size="medium" color="error" />
                                            :
                                            <PrimaryButton value="선택 삭제" size="medium" color="error" onClickEvent={() => alert(selectedDCodeList.length + "개의 선택한 상품들을 삭제~")} />
                                    }
                                </FlexEndDiv>
                            </TableValueDiv>
                        </BoxTitleFlexDiv>
                        {
                            DCodeList !== undefined && DCodeList.length !== 0 &&
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="DCodeList table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>할인코드명</TableCell>
                                                <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: 15 }}>할인율</TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {DCodeList.map((row: any) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Checkbox
                                                            checked={isSelectedDCode(row.name)}
                                                            onChange={handleDCodeListChange}
                                                            name={row.name}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">{row.name} </TableCell>
                                                    <TableCell align="left">{row.ratio}</TableCell>
                                                    <TableCell align="right">
                                                        <PrimaryButton
                                                            value="수정"
                                                            size="small"
                                                            color="success"
                                                            onClickEvent={() => {
                                                                handleDCodeUpdatePopupOpen(row)
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        }
                    </BoxDiv>
                </WrapDiv>
            </Box>
            {
                <>
                    <DCodeCreationPopup
                        open={DCodeCreationPopupOpen}
                        purpose="추가"
                        onClickClose={handleDCodeCreationPopupClose}
                        btnTextFirst="취소"
                        btnTextSecond="추가"
                    />
                </>
            }
            {
                <>
                    <DCodeCreationPopup
                        open={DCodeUpdatePopupOpen}
                        DCodeForUpdate={DCodeForUpdate}
                        purpose="수정"
                        onClickClose={handleDCodeUpdatePopupClose}
                        btnTextFirst="취소"
                        btnTextSecond="수정"
                    />
                </>
            }
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const FlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const BoxDivider = Styled.div`
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-bottom: 20px;
`
const BoxVirticalDivider = Styled.div`
    width: 20px;
`
const TableKeyDiv = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 7px;
`
const TableValueDiv = Styled.div`
`

export default SBBGMPDCode;