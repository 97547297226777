import React, { useEffect, useState, useCallback } from 'react';
import Styled from 'styled-components'
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { getToken } from 'utils/token';
import { getSchema, getRequest, postRequestWithToken, putRequestWithToken, deleteRequestWithToken, postImageRequestWithToken } from 'utils/APIRequest';

import DSBMainDrawer from 'component/drawer/DSBMainDrawer';
import PrimaryButton from 'component/button/PrimaryButton';
import ProductCategoryCreationPopup from 'component/dialog/ProductCategoryCreationPopup';
import { isMobile } from 'utils/common';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

interface UnitOptionType {
    inputValue?: string;
    unitId: number;
    name: string;
    type?: string;
}

interface ReviewTagOptionType {
    inputValue?: string;
    reviewTagId: number;
    name: string;
    description?: string;
    isFilled?: any;
}

interface AnalysisOptionType {
    inputValue?: string;
    analysisId: number;
    name: string;
}

const filterUnitOption = createFilterOptions<UnitOptionType>();
const filterReviewTagOption = createFilterOptions<ReviewTagOptionType>();
const filterAnalysisOption = createFilterOptions<AnalysisOptionType>();

const drawerWidth = 300;
const isMobileDevice = isMobile()

const DSBProductCategory = () => {
    const history = useHistory()

    const [unitList, setUnitList] = useState<any>([])
    const [reviewTagList, setReviewTagList] = useState<any>([])
    const [analysisList, setAnalysisList] = useState<any>([])

    const [categoryList, setCategoryList] = useState<any>([])
    const [categoryOpenStatusList, setCategoryOpenStatusList] = useState<any>([])
    const [selectedCategory, setSelectedCategory] = useState<any>("")

    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [isReadyToModify, setIsReadyToModify] = useState<boolean>(false)
    const [PCForUpdate, setPCForUpdate] = useState<any>(null)
    const [PC, setPC] = useState<any>(null)

    const [PCID, setPCID] = useState<number>(-1)
    const [PCName, setPCName] = useState<string>("")
    const [firstProductOption, setFirstProductOption] = useState<string>("맛")
    const [secondProductOption, setSecondProductOption] = useState<string>("")
    const [perAmount, setPerAmount] = useState<number | null>(null)
    const [perAmountUnit, setPerAmountUnit] = useState<any>(null);
    const [perAmountUnitId, setPerAmountUnitId] = useState<number | null>(null);

    const [PCReviewTagList, setPCReviewTagList] = useState<any>([])
    const [PCReviewTag, setPCReviewTag] = useState<any>(null)
    const [PCAnalysisList, setPCAnalysisList] = useState<any>([])
    const [PCAnalysis, setPCAnalysis] = useState<any>(null)

    const [PCImagePath, setPCImagePath] = useState<string>("")
    const [PCDescriptionImagePath, setPCDescriptionImagePath] = useState<string>("")
    const [PCDescriptionURL, setPCDescriptionURL] = useState<string>("")

    const [exposureStatus, setExposureStatus] = useState<string>("HOLD")

    const [parentId, setParentId] = useState<number>(-1)
    const [productCategoryCreationPopupOpen, setProductCategoryCreationPopupOpen] = useState<boolean>(false)

    const handleProductCategoryCreationPopupOpen = () => {
        setProductCategoryCreationPopupOpen(true)
    }

    const handleProductCategoryCreationPopupClose = () => {
        setProductCategoryCreationPopupOpen(false)
    }

    const handlePCNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPCName(event.target.value)
    }

    const handleFirstProductOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstProductOption(event.target.value)
    }

    const handleSecondProductOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSecondProductOption(event.target.value)
    }

    const handlePerAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const onlyNumber = event.target.value.replace(/[^0-9]/g, '')
        setPerAmount(Number(onlyNumber))
    }

    const handlePCDescriptionURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPCDescriptionURL(event.target.value)
    }

    const handleExposureStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isAllFilled) setExposureStatus(event.target.value)
        else alert("공개 상태를 변경하기 위해서는 필수항목이 채워져있어야 합니다 :)")
    }

    const {
        getRootProps: getRootPCImagePathProps,
        getInputProps: getInputPCImagePathProps,
        isDragActive: isPCImagePathDragActive,
        isDragReject: isPCImagePathDragReject,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 10 * 1024,
        multiple: false,
        onDropRejected: (rejectedFiles) => {
            if (rejectedFiles[0] !== undefined)
                alert(JSON.stringify(rejectedFiles[0].errors))
        },
        onDropAccepted: (acceptedFile) => {
            let formData = new FormData()
            formData.append('image', acceptedFile[0])
            postImage(formData, "PCImagePath")
        },
    });

    const {
        getRootProps: getRootPCDescriptionImagePathProps,
        getInputProps: getInputPCDescriptionImagePathProps,
        isDragActive: isPCDescriptionImagePathDragActive,
        isDragReject: isPCDescriptionImagePathDragReject,
    } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 10 * 10 * 1024,
        multiple: false,
        onDropRejected: (rejectedFiles) => {
            if (rejectedFiles[0] !== undefined)
                alert(JSON.stringify(rejectedFiles[0].errors))
        },
        onDropAccepted: (acceptedFile) => {
            let formData = new FormData()
            formData.append('image', acceptedFile[0])
            postImage(formData, "PCDescriptionImagePath")
        },
    });

    const postImage = async (formData: any, from: string) => {
        if (formData === undefined || !formData) return
        const curToken = getToken()
        if (curToken === '') return

        const res = await postImageRequestWithToken(
            formData,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                if (res.data.location === undefined) {
                    alert("이미지 경로를 찾을 수 없습니다. \n\n" + JSON.stringify(res.data))
                    return
                }
                if (from === "PCImagePath") {
                    setPCImagePath(res.data.location)
                } else {
                    setPCDescriptionImagePath(res.data.location)
                }
            } else if (res.status === 409) {
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getUnitList = async () => {
        const res = await getRequest('/units')
        if (res !== null) {
            if (res.status === 200) {
                setUnitList([...res.data])
            } else if (res.status === 204) {
                setUnitList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addUnit = async (unit: UnitOptionType) => {
        if (unit.name === undefined || unit.name === "") return
        if (unit.unitId === undefined || unit.unitId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const unitInfo = { name: unit.name }
        const curSchema = getSchema('/units/onlyName', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(unitInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/units/onlyName',
            unitInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getUnitList()
                const createdUnit = res.data
                setPerAmountUnit({
                    unitId: createdUnit.unitId,
                    name: createdUnit.name,
                    type: createdUnit.type
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getReviewTagList = async () => {
        const res = await getRequest('/reviewTags')
        if (res !== null) {
            if (res.status === 200) {
                setReviewTagList([...res.data])
            } else if (res.status === 204) {
                setReviewTagList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addReviewTagName = async (reviewTag: ReviewTagOptionType) => {
        if (reviewTag.name === undefined || reviewTag.name === "") return
        if (reviewTag.reviewTagId === undefined || reviewTag.reviewTagId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const reviewTagInfo = { name: reviewTag.name }
        const curSchema = getSchema('/reviewTags/onlyName', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(reviewTagInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/reviewTags/onlyName',
            reviewTagInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getReviewTagList()
                const createdReviewTag = res.data
                setPCReviewTag({
                    reviewTagId: createdReviewTag.reviewTagId,
                    name: createdReviewTag.name
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getAnalysisList = async () => {
        const res = await getRequest('/analysises')
        if (res !== null) {
            if (res.status === 200) {
                setAnalysisList([...res.data])
            } else if (res.status === 204) {
                setAnalysisList([])
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const addAnalysis = async (analysis: AnalysisOptionType) => {
        if (analysis.name === undefined || analysis.name === "") return
        if (analysis.analysisId === undefined || analysis.analysisId !== -1) return
        const curToken = getToken()
        if (curToken === '') return

        const analysisInfo = { name: analysis.name }
        const curSchema = getSchema('/analysises', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(analysisInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/analysises',
            analysisInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                getAnalysisList()
                const createdAnalysis = res.data
                setPCAnalysis({
                    analysisId: createdAnalysis.analysisId,
                    name: createdAnalysis.name
                })
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getProductCategoryList = async () => {
        const res = await getRequest('/productClassifications/structured')
        if (res !== null) {
            if (res.status === 200) {
                setCategoryList([...res.data])
                const curCategoryOpenStatusList = createCategoryOpenStatusList(res.data)
                setCategoryOpenStatusList([...curCategoryOpenStatusList])
            } else if (res.status === 204) {
                setCategoryList([])
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const getCategory = async (id: number) => {
        const res = await getRequest('/productClassifications/' + id)
        if (res !== null) {
            if (res.status === 200) {
                const curPC = res.data
                setPCForUpdate({
                    productClassificationId: curPC.productClassificationId,
                    name: curPC.name,
                    imagePath: curPC.imagePath,
                    descriptionImagePath: curPC.descriptionImagePath,
                    descriptionURL: curPC.descriptionURL,
                    firstProductOption: curPC.firstProductOption,
                    secondProductOption: curPC.secondProductOption,
                    perAmount: curPC.perAmount,
                    perAmountUnitId: curPC.perAmountUnitId,
                    reviewTagList: curPC.reviewTagList,
                    analysisList: curPC.analysisList,
                    exposureStatus: curPC.exposureStatus
                })
            } else if (res.status === 204) {
                alert("요청하신 카테고리정보가 없습니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
                setPCForUpdate(null)
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateCategory = async () => {
        if (PC.name === undefined || PC.name === "") return
        if (PC.productClassificationId === undefined || PC.productClassificationId === "" || PC.productClassificationId === -1) return
        const curToken = getToken()
        if (curToken === '') return

        const productClassificationInfo = PC
        const curSchema = getSchema('/productClassifications', 'PUT')

        if (curSchema) {
            try {
                await curSchema.validateAsync(productClassificationInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            `/productClassifications/${PC.productClassificationId}`,
            productClassificationInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                setIsReadyToModify(false)
                getCategory(PC.productClassificationId)
                getProductCategoryList()
                alert("product 카테고리를 저장했습니다 :)")
            } else if (res.status === 204) {
                alert("productClassification ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const deleteSelectedCategory = async () => {
        if (selectedCategory === undefined || selectedCategory === "") {
            alert("선택된 카테고리가 없습니다.")
            return
        }
        const productClassificationId = selectedCategory.productClassificationId
        if (productClassificationId === undefined || productClassificationId <= 0) {
            alert("선택된 카테고리의 아이디를 확인해주세요.")
            return
        }

        if (!window.confirm(`"${selectedCategory.name}" 카테고리를 삭제하시겠습니까?`)) return

        const curToken = getToken()
        if (curToken === '') return

        const res = await deleteRequestWithToken(
            '/productClassifications/' + productClassificationId,
            {},
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                getProductCategoryList()
                setSelectedCategory("")
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                    alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
            } else if (res.status === 204) {
                setSelectedCategory("")
                return
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const createNewFirstLevelCategory = () => {
        setParentId(0)
        setProductCategoryCreationPopupOpen(true)
    }

    const createNewCategory = (parentProductClassificationId: any) => {
        setParentId(parentProductClassificationId)
        setProductCategoryCreationPopupOpen(true)
    }

    const createCategoryOpenStatusList = (curCategoryList: any) => {
        if (curCategoryList === undefined || curCategoryList.length === 0) return
        let curCategoryOpenStatusList: any = []


        const categoryOpenStatus = {
            status: false,
            childList: [],
        }

        const iLen = curCategoryList.length
        for (let i = 0; i < iLen; i++) {
            curCategoryOpenStatusList.push(Object.assign({}, categoryOpenStatus))
            if (curCategoryList[i].childList.length !== 0) {
                const jLen = curCategoryList[i].childList.length
                let jCategoryOpenStatusList = []
                for (let j = 0; j < jLen; j++) {
                    jCategoryOpenStatusList.push(Object.assign({}, categoryOpenStatus))
                }
                curCategoryOpenStatusList[i].childList = jCategoryOpenStatusList
            }
        }

        for (let i = 0; i < iLen; i++) {
            if (curCategoryList[i].childList.length === 0) continue
            const jLen = curCategoryList[i].childList.length
            for (let j = 0; j < jLen; j++) {
                if (curCategoryList[i].childList[j].childList.length !== 0) {
                    const kLen = curCategoryList[i].childList[j].childList.length
                    let kCategoryOpenStatusList = []
                    for (let k = 0; k < kLen; k++) {
                        kCategoryOpenStatusList.push(Object.assign({}, categoryOpenStatus))
                    }
                    curCategoryOpenStatusList[i].childList[j].childList = kCategoryOpenStatusList
                }
            }
        }

        if (categoryOpenStatusList !== undefined && categoryOpenStatusList.length !== 0) {
            for (let i = 0; i < iLen; i++) {
                if (categoryOpenStatusList[i] === undefined) continue
                if (categoryOpenStatusList[i].status) curCategoryOpenStatusList[i].status = true

                const jLen = curCategoryOpenStatusList[i].childList.length
                for (let j = 0; j < jLen; j++) {
                    if (categoryOpenStatusList[i].childList[j] === undefined) continue
                    if (categoryOpenStatusList[i].childList[j].status) curCategoryOpenStatusList[i].childList[j].status = true
                    const kLen = curCategoryOpenStatusList[i].childList[j].childList.length
                    for (let k = 0; k < kLen; k++) {
                        if (categoryOpenStatusList[i].childList[j].childList[k] === undefined) continue
                        if (categoryOpenStatusList[i].childList[j].childList[k].status) curCategoryOpenStatusList[i].childList[j].childList[k].status = true
                    }
                }
            }
        }

        return curCategoryOpenStatusList
    }

    const getSelectedCategory = (curCategoryOpenStatusList: any) => {
        if (categoryList === undefined || categoryList.length === 0) return
        if (curCategoryOpenStatusList === undefined || curCategoryOpenStatusList.length === 0) return
        const curCategoryList = categoryList

        for (let i = 0; i < curCategoryOpenStatusList.length; i++) {
            if (curCategoryOpenStatusList[i].status) {
                for (let j = 0; j < curCategoryOpenStatusList[i].childList.length; j++) {
                    if (curCategoryOpenStatusList[i].childList[j].status) {
                        for (let k = 0; k < curCategoryOpenStatusList[i].childList[j].childList.length; k++) {
                            if (curCategoryOpenStatusList[i].childList[j].childList[k].status) {
                                return curCategoryList[i].childList[j].childList[k]
                            }
                        }
                        return curCategoryList[i].childList[j]
                    }
                }
                return curCategoryList[i]
            }
        }

        return ""
    }

    const clearChildCategoryOpenStatusList = (layerLevel: number) => {
        if (categoryOpenStatusList === undefined || categoryOpenStatusList.length === 0) return
        let curCategoryOpenStatusList = categoryOpenStatusList

        if (layerLevel === 1) {
            for (let i = 0; i < curCategoryOpenStatusList.length; i++) {
                curCategoryOpenStatusList[i].status = false
            }
        } else if (layerLevel === 2) {
            for (let i = 0; i < curCategoryOpenStatusList.length; i++) {
                for (let j = 0; j < curCategoryOpenStatusList[i].childList.length; j++) {
                    curCategoryOpenStatusList[i].childList[j].status = false
                }
            }
        } else if (layerLevel === 3) {
            for (let i = 0; i < curCategoryOpenStatusList.length; i++) {
                for (let j = 0; j < curCategoryOpenStatusList[i].childList.length; j++) {
                    for (let k = 0; k < curCategoryOpenStatusList[i].childList[j].childList.length; k++) {
                        curCategoryOpenStatusList[i].childList[j].childList[k].status = false
                    }
                }
            }
        }

        setCategoryOpenStatusList([...curCategoryOpenStatusList])
    }

    const handleCategoryOpenStatusList = (categoryOpenStatusListIndex: any, categoryOpenStatusListSecondIndex?: any, categoryOpenStatusListThirdIndex?: any) => {
        if (categoryOpenStatusList === undefined || categoryOpenStatusList.length === 0) return
        let curCategoryOpenStatusList = categoryOpenStatusList

        for (let i = 0; i < curCategoryOpenStatusList.length; i++) {
            if (i === categoryOpenStatusListIndex) {
                if (curCategoryOpenStatusList[i] === undefined) continue
                if (categoryOpenStatusListSecondIndex === undefined) {
                    curCategoryOpenStatusList[i].status = !curCategoryOpenStatusList[i].status
                    clearChildCategoryOpenStatusList(2)
                    continue
                } else {
                    for (let j = 0; j < curCategoryOpenStatusList[i].childList.length; j++) {
                        if (j === categoryOpenStatusListSecondIndex) {
                            if (curCategoryOpenStatusList[i].childList[j] === undefined) continue
                            if (categoryOpenStatusListThirdIndex === undefined) {
                                curCategoryOpenStatusList[i].childList[j].status = !curCategoryOpenStatusList[i].childList[j].status
                                clearChildCategoryOpenStatusList(3)
                                continue
                            } else {
                                for (let k = 0; k < curCategoryOpenStatusList[i].childList[j].childList.length; k++) {
                                    if (curCategoryOpenStatusList[i].childList[j].childList[k] === undefined) continue
                                    if (k === categoryOpenStatusListThirdIndex) {
                                        curCategoryOpenStatusList[i].childList[j].childList[k].status = !curCategoryOpenStatusList[i].childList[j].childList[k].status
                                    } else {
                                        curCategoryOpenStatusList[i].childList[j].childList[k].status = false
                                    }
                                }
                            }
                        } else {
                            curCategoryOpenStatusList[i].childList[j].status = false
                        }
                    }
                }
            } else {
                curCategoryOpenStatusList[i].status = false
            }
        }

        setCategoryOpenStatusList([...curCategoryOpenStatusList])
    }

    const isCategoryOpenStatusList = (categoryOpenStatusListIndex: any, categoryOpenStatusListSecondIndex?: any, categoryOpenStatusListThirdIndex?: any) => {
        if (categoryOpenStatusList === undefined || categoryOpenStatusList.length === 0) return
        let curCategoryOpenStatusList = categoryOpenStatusList

        if (categoryOpenStatusListSecondIndex === undefined) {
            if (curCategoryOpenStatusList[categoryOpenStatusListIndex] === undefined) return false
            return curCategoryOpenStatusList[categoryOpenStatusListIndex].status
        } else {
            if (categoryOpenStatusListThirdIndex === undefined) {
                if (curCategoryOpenStatusList[categoryOpenStatusListIndex].childList[categoryOpenStatusListSecondIndex] === undefined) return false
                return curCategoryOpenStatusList[categoryOpenStatusListIndex].childList[categoryOpenStatusListSecondIndex].status
            } else {
                if (curCategoryOpenStatusList[categoryOpenStatusListIndex].childList[categoryOpenStatusListSecondIndex].childList[categoryOpenStatusListThirdIndex] === undefined) return false
                return curCategoryOpenStatusList[categoryOpenStatusListIndex].childList[categoryOpenStatusListSecondIndex].childList[categoryOpenStatusListThirdIndex].status
            }
        }
    }

    const getUnitName = (curId: number) => {
        for (let i = 0; i < unitList.length; i++) {
            if (unitList[i] === undefined) continue
            if (unitList[i].unitId === curId) return unitList[i].name
        }
        return "알수없는 ID"
    }

    const initPCReviewTag = () => {
        setPCReviewTag(null)
    }

    const addPCReviewTag = () => {
        if (PCReviewTag === undefined || PCReviewTag === null) return
        if (PCReviewTag.reviewTagId === undefined || PCReviewTag.reviewTagId === -1) return

        let curPCReviewTagList = PCReviewTagList
        const curId = PCReviewTag.reviewTagId

        for (let i = 0; i < curPCReviewTagList.length; i++) {
            if (curPCReviewTagList[i] === curId) {
                alert("입력하신 항목을 이미 등록했습니다.")
                return
            }
        }

        curPCReviewTagList.push(curId)
        setPCReviewTagList([...curPCReviewTagList])
        initPCReviewTag()
    }

    const deletePCReviewTag = (curId: number) => {
        let curPCReviewTagList = PCReviewTagList

        curPCReviewTagList = PCReviewTagList.filter((v: number) => v !== curId)
        setPCReviewTagList([...curPCReviewTagList])
    }

    const getReviewTagName = (curId: number) => {
        for (let i = 0; i < reviewTagList.length; i++) {
            if (reviewTagList[i] === undefined) continue
            if (reviewTagList[i].reviewTagId === curId) return reviewTagList[i].name
        }
        return "알수없는 ID"
    }

    const initPCAnalysis = () => {
        setPCAnalysis(null)
    }

    const addPCAnalysis = () => {
        if (PCAnalysis === undefined || PCAnalysis === null) return
        if (PCAnalysis.analysisId === undefined || PCAnalysis.analysisId === -1) return

        let curPCAnalysisList = PCAnalysisList
        const curId = PCAnalysis.analysisId

        for (let i = 0; i < curPCAnalysisList.length; i++) {
            if (curPCAnalysisList[i] === curId) {
                alert("입력하신 항목을 이미 등록했습니다.")
                return
            }
        }

        curPCAnalysisList.push(curId)
        setPCAnalysisList([...curPCAnalysisList])
        initPCAnalysis()
    }

    const deletePCAnalysis = (curId: number) => {
        let curPCAnalysisList = PCAnalysisList

        curPCAnalysisList = PCAnalysisList.filter((v: number) => v !== curId)
        setPCAnalysisList([...curPCAnalysisList])
    }

    const getAnalysisName = (curId: number) => {
        for (let i = 0; i < analysisList.length; i++) {
            if (analysisList[i] === undefined) continue
            if (analysisList[i].analysisId === curId) return analysisList[i].name
        }
        return "알수없는 ID"
    }

    const clearPCInputData = () => {
        setPC(null)
        setPCName("")
        setFirstProductOption("맛")
        setSecondProductOption("")
        setPerAmount(null)
        setPerAmountUnit(null)
        setPerAmountUnitId(null)
        setPCReviewTagList([])
        setPCReviewTag(null)
        setPCAnalysisList([])
        setPCAnalysis(null)
        setPCImagePath("")
        setPCDescriptionImagePath("")
        setPCDescriptionURL("")
        setExposureStatus("HOLD")
    }

    useEffect(() => {
        if (!isAllFilled) {
            setIsReadyToModify(false)
            return
        }
        const curPC = PC
        if (PCForUpdate !== undefined && JSON.stringify(curPC) === JSON.stringify(PCForUpdate)) {
            setIsReadyToModify(false)
            return
        }
        setIsReadyToModify(true)
    }, [isAllFilled, JSON.stringify(PC)])

    useEffect(() => {
        if (PCName === undefined || PCName === "") {
            setIsAllFilled(false)
            return
        }
        if (firstProductOption === undefined || firstProductOption === "") {
            setIsAllFilled(false)
            return
        }
        if (secondProductOption === undefined || secondProductOption === "") {
            setIsAllFilled(false)
            return
        }
        if (perAmount === undefined || perAmount === null || perAmount <= 0) {
            setIsAllFilled(false)
            return
        }
        if (perAmountUnitId === undefined || perAmountUnitId === null || perAmountUnitId <= 0) {
            setIsAllFilled(false)
            return
        }
        if (PCReviewTagList === undefined || PCReviewTagList.length <= 0) {
            setIsAllFilled(false)
            return
        }
        if (PCAnalysisList === undefined || PCAnalysisList.length <= 0) {
            setIsAllFilled(false)
            return
        }


        const curPC = {
            productClassificationId: PCID,
            name: PCName,
            imagePath: PCImagePath,
            descriptionImagePath: PCDescriptionImagePath,
            descriptionURL: PCDescriptionURL,
            firstProductOption: firstProductOption,
            secondProductOption: secondProductOption,
            perAmount: perAmount,
            perAmountUnitId: perAmountUnitId,
            reviewTagList: PCReviewTagList,
            analysisList: PCAnalysisList,
            exposureStatus: exposureStatus
        }

        setPC(curPC)
        setIsAllFilled(true)
    }, [PCName, firstProductOption, secondProductOption, String(perAmount), String(perAmountUnitId), JSON.stringify(PCReviewTagList), JSON.stringify(PCAnalysisList), PCImagePath, PCDescriptionImagePath, PCDescriptionURL, exposureStatus])

    useEffect(() => {
        if (!PCAnalysis)
            return
        if (PCAnalysis.analysisId !== undefined && PCAnalysis.analysisId === -1)
            addAnalysis(PCAnalysis)
    }, [JSON.stringify(PCAnalysis)])

    useEffect(() => {
        if (!PCReviewTag)
            return
        if (PCReviewTag.reviewTagId !== undefined && PCReviewTag.reviewTagId === -1)
            addReviewTagName(PCReviewTag)
    }, [JSON.stringify(PCReviewTag)])

    useEffect(() => {
        if (!perAmountUnit)
            return
        if (perAmountUnit.unitId !== undefined && perAmountUnit.unitId === -1)
            addUnit(perAmountUnit)

        setPerAmountUnitId(perAmountUnit.unitId)
    }, [JSON.stringify(perAmountUnit)])

    useEffect(() => {
        if (productCategoryCreationPopupOpen)
            return

        getProductCategoryList()
    }, [productCategoryCreationPopupOpen])

    useEffect(() => {
        if (PCForUpdate === undefined || PCForUpdate === null) return

        setPCName(PCForUpdate.name)
        if (PCForUpdate.firstProductOption !== undefined && PCForUpdate.firstProductOption !== null)
            setFirstProductOption(PCForUpdate.firstProductOption)
        if (PCForUpdate.secondProductOption !== undefined && PCForUpdate.secondProductOption !== null)
            setSecondProductOption(PCForUpdate.secondProductOption)
        if (PCForUpdate.perAmount !== undefined && PCForUpdate.perAmount !== null)
            setPerAmount(PCForUpdate.perAmount)
        if (PCForUpdate.perAmountUnitId !== undefined && PCForUpdate.perAmountUnitId !== null && PCForUpdate.perAmountUnitId > 0)
            setPerAmountUnit({
                unitId: PCForUpdate.perAmountUnitId,
                name: getUnitName(PCForUpdate.perAmountUnitId)
            })
        if (PCForUpdate.reviewTagList !== undefined && PCForUpdate.reviewTagList !== null)
            setPCReviewTagList([...PCForUpdate.reviewTagList])
        if (PCForUpdate.analysisList !== undefined && PCForUpdate.analysisList !== null)
            setPCAnalysisList([...PCForUpdate.analysisList])
        if (PCForUpdate.imagePath !== undefined && PCForUpdate.imagePath !== null)
            setPCImagePath(PCForUpdate.imagePath)
        if (PCForUpdate.descriptionImagePath !== undefined && PCForUpdate.descriptionImagePath !== null)
            setPCDescriptionImagePath(PCForUpdate.descriptionImagePath)
        if (PCForUpdate.descriptionURL !== undefined && PCForUpdate.descriptionURL !== null)
            setPCDescriptionURL(PCForUpdate.descriptionURL)
        setExposureStatus(PCForUpdate.exposureStatus)

    }, [JSON.stringify(PCForUpdate)])

    useEffect(() => {
        if (PCID === undefined || PCID === -1) return

        clearPCInputData()
        getCategory(PCID)
    }, [String(PCID)])

    useEffect(() => {
        if (selectedCategory === undefined || selectedCategory === "") return
        if (selectedCategory.productClassificationId === undefined || selectedCategory.productClassificationId === -1) return
        setPCID(selectedCategory.productClassificationId)
    }, [selectedCategory])

    useEffect(() => {
        if (categoryOpenStatusList === undefined || categoryOpenStatusList.length === 0) return
        const curSelectedCategory = getSelectedCategory(categoryOpenStatusList)
        setSelectedCategory(curSelectedCategory)
    }, [JSON.stringify(categoryOpenStatusList)])

    useEffect(() => {
        getProductCategoryList()
        getUnitList()
        getReviewTagList()
        getAnalysisList()
    }, [])

    return (
        <>
            <DSBMainDrawer />
            <Box
                component="main"
                sx={{ maxWidth: 'lg', flexGrow: 1, p: isMobileDevice ? 1 : 4, marginTop: isMobileDevice ? 7 : 8, backgroundColor: "#FDFDFD", width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <WrapDiv>
                    <BoxDiv>
                        <BoxTitle>상품 카테고리</BoxTitle>
                        <BoxSubTitle>{"단성비 > 상품 컴포넌트 > 상품 카테고리"}</BoxSubTitle>
                    </BoxDiv>
                    <BoxContentFlexStartDiv>
                        <BoxContentDiv width="37%">
                            <BoxDiv>
                                <BoxTitleFlexDiv>
                                    <BoxTitleKey>카테고리 관리</BoxTitleKey>
                                    <BoxTitleValue>
                                        {
                                            selectedCategory !== undefined && selectedCategory !== "" ?
                                                <PrimaryButton value="삭제" size="medium" color="error" onClickEvent={() => deleteSelectedCategory()} />
                                                :
                                                <PrimaryButton disabled={true} value="삭제" size="medium" color="error" />
                                        }
                                    </BoxTitleValue>
                                </BoxTitleFlexDiv>
                                <BoxTitleFlexStartDiv>
                                    <BoxDescKey>
                                        <ListItem
                                            button
                                            sx={{
                                                borderRadius: 2,
                                                fontSize: "14px",
                                                marginTop: "2px",
                                                marginBottom: "2px",
                                                backgroundColor: "rgba(255, 77, 77, 0.2)"
                                            }}
                                        >
                                            {"sample"}
                                        </ListItem>
                                    </BoxDescKey>
                                    <BoxDescValue>
                                        {": 필수정보 미충족"}
                                    </BoxDescValue>
                                </BoxTitleFlexStartDiv>
                                <BoxTitleFlexStartDiv>
                                    <BoxDescKey>
                                        <ListItemStatusDiv>
                                            {"비공개 상태"}
                                        </ListItemStatusDiv>
                                    </BoxDescKey>
                                    <BoxDescValue>
                                        {": 공개 상태 아님"}
                                    </BoxDescValue>
                                </BoxTitleFlexStartDiv>
                                <GrayLineDivider />
                                <BoxContentDiv>
                                    <List>
                                        {
                                            categoryList !== undefined && categoryList.length !== 0 &&
                                            <>
                                                {categoryList.map((v: any, index: any) => (
                                                    <>
                                                        <ListItem
                                                            button
                                                            key={v.name}
                                                            onClick={() => handleCategoryOpenStatusList(index)}
                                                            selected={v.name === PCName && isCategoryOpenStatusList(index)}
                                                            sx={{
                                                                borderRadius: 2,
                                                                marginTop: "2px",
                                                                marginBottom: "2px",
                                                                backgroundColor: (!v.isFilled ? "rgba(255, 77, 77, 0.2)" : "transparent")
                                                            }}
                                                        >
                                                            <ListItemTextDiv isSelected={v.name === PCName && isCategoryOpenStatusList(index)}>{v.name}</ListItemTextDiv>
                                                            {
                                                                v.exposureStatus !== "OPEN" && !isCategoryOpenStatusList(index) &&
                                                                <ListItemStatusDiv>
                                                                    {"비공개 상태"}
                                                                </ListItemStatusDiv>
                                                            }
                                                            {
                                                                v.childList.length === 0 && isCategoryOpenStatusList(index) &&
                                                                <SubListItemCreationDiv
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        createNewCategory(v.productClassificationId)
                                                                    }}
                                                                >
                                                                    {"하위 카테고리 추가"}
                                                                </SubListItemCreationDiv>
                                                            }
                                                            {
                                                                v.childList.length !== 0 &&
                                                                <ListItemArrowIconDiv>
                                                                    {isCategoryOpenStatusList(index) ? <ExpandLessRoundedIcon color="inherit" fontSize="inherit" /> : <ExpandMoreRoundedIcon color="inherit" fontSize="inherit" />}
                                                                </ListItemArrowIconDiv>
                                                            }
                                                        </ListItem>
                                                        {
                                                            v.childList.length !== 0 &&
                                                            <Collapse in={isCategoryOpenStatusList(index)} timeout="auto" unmountOnExit>
                                                                <List component="div" disablePadding>
                                                                    {
                                                                        v.childList.map((v2: any, index2: any) => (
                                                                            <>
                                                                                <ListItem
                                                                                    button
                                                                                    key={v2.name}
                                                                                    onClick={() => handleCategoryOpenStatusList(index, index2)}
                                                                                    selected={v2.name === PCName && isCategoryOpenStatusList(index, index2)}
                                                                                    sx={{
                                                                                        borderRadius: 2,
                                                                                        marginTop: "2px",
                                                                                        marginBottom: "2px",
                                                                                        backgroundColor: (!v2.isFilled ? "rgba(255, 77, 77, 0.2)" : "transparent")
                                                                                    }}
                                                                                >
                                                                                    <ListItemDivider />
                                                                                    <ListItemTextDiv isSelected={v2.name === PCName && isCategoryOpenStatusList(index, index2)}>{v2.name}</ListItemTextDiv>
                                                                                    {
                                                                                        v2.exposureStatus !== "OPEN" && !isCategoryOpenStatusList(index, index2) &&
                                                                                        <ListItemStatusDiv>
                                                                                            {"비공개 상태"}
                                                                                        </ListItemStatusDiv>
                                                                                    }
                                                                                    {
                                                                                        v2.childList.length === 0 && isCategoryOpenStatusList(index, index2) &&
                                                                                        <SubListItemCreationDiv
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation()
                                                                                                createNewCategory(v2.productClassificationId)
                                                                                            }}
                                                                                        >
                                                                                            {"하위 카테고리 추가"}
                                                                                        </SubListItemCreationDiv>
                                                                                    }
                                                                                    {
                                                                                        v2.childList.length !== 0 &&
                                                                                        <SubListItemIconDiv>
                                                                                            {isCategoryOpenStatusList(index, index2) ? <ExpandLessRoundedIcon color="inherit" fontSize="inherit" /> : <ExpandMoreRoundedIcon color="inherit" fontSize="inherit" />}
                                                                                        </SubListItemIconDiv>
                                                                                    }
                                                                                </ListItem>
                                                                                {
                                                                                    v2.childList.length !== 0 &&
                                                                                    <Collapse in={isCategoryOpenStatusList(index, index2)} timeout="auto" unmountOnExit>
                                                                                        <List component="div" disablePadding>
                                                                                            {
                                                                                                v2.childList.map((v3: any, index3: any) => (
                                                                                                    <>
                                                                                                        <ListItem
                                                                                                            button
                                                                                                            key={v3.name}
                                                                                                            onClick={() => handleCategoryOpenStatusList(index, index2, index3)}
                                                                                                            selected={v3.name === PCName && isCategoryOpenStatusList(index, index2, index3)}
                                                                                                            sx={{
                                                                                                                borderRadius: 2,
                                                                                                                marginTop: "2px",
                                                                                                                marginBottom: "2px",
                                                                                                                backgroundColor: (!v3.isFilled ? "rgba(255, 77, 77, 0.2)" : "transparent")
                                                                                                            }}
                                                                                                        >
                                                                                                            <ListItemDivider />
                                                                                                            <ListItemDivider />
                                                                                                            <ListItemTextDiv isSelected={v3.name === PCName && isCategoryOpenStatusList(index, index2, index3)}>{v3.name}</ListItemTextDiv>
                                                                                                            {
                                                                                                                v3.exposureStatus !== "OPEN" && !isCategoryOpenStatusList(index, index2, index3) &&
                                                                                                                <ListItemStatusDiv>
                                                                                                                    {"비공개 상태"}
                                                                                                                </ListItemStatusDiv>
                                                                                                            }
                                                                                                        </ListItem>
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                            {
                                                                                                <ListItem
                                                                                                    button
                                                                                                    key={"newCategory"}
                                                                                                    onClick={() => createNewCategory(v2.productClassificationId)}
                                                                                                    sx={{
                                                                                                        borderRadius: 2,
                                                                                                    }}
                                                                                                >
                                                                                                    <ListItemDivider />
                                                                                                    <ListItemDivider />
                                                                                                    <ListItemCreationDiv>{"카테고리 추가"}</ListItemCreationDiv>
                                                                                                </ListItem>
                                                                                            }
                                                                                        </List>
                                                                                    </Collapse>
                                                                                }
                                                                            </>
                                                                        ))
                                                                    }
                                                                    {
                                                                        <ListItem
                                                                            button
                                                                            key={"newCategory"}
                                                                            onClick={() => createNewCategory(v.productClassificationId)}
                                                                            sx={{
                                                                                borderRadius: 2,
                                                                            }}
                                                                        >
                                                                            <ListItemDivider />
                                                                            <ListItemCreationDiv>{"카테고리 추가"}</ListItemCreationDiv>
                                                                        </ListItem>
                                                                    }
                                                                </List>
                                                                <CollapseDivider />
                                                            </Collapse>
                                                        }
                                                    </>
                                                ))}

                                            </>
                                        }
                                        <ListItem
                                            button
                                            key={"newCategory"}
                                            onClick={() => createNewFirstLevelCategory()}
                                            sx={{
                                                borderRadius: 2,
                                            }}
                                        >
                                            <ListItemCreationDiv>{"카테고리 추가"}</ListItemCreationDiv>
                                        </ListItem>
                                    </List>
                                </BoxContentDiv>
                            </BoxDiv>
                        </BoxContentDiv>
                        <BoxContentDivider width="2%" />
                        <BoxContentDiv width="61%">
                            {
                                selectedCategory !== undefined && selectedCategory !== "" &&
                                <BoxDiv>
                                    <BoxTitleFlexDiv>
                                        <BoxTitleKey>카테고리 수정</BoxTitleKey>
                                        <BoxTitleValue>
                                            {
                                                selectedCategory !== undefined && selectedCategory !== "" && isReadyToModify ?
                                                    <PrimaryButton value="저장" size="large" color="primary" onClickEvent={() => updateCategory()} />
                                                    :
                                                    <PrimaryButton disabled={true} value="저장" size="large" color="primary" />
                                            }
                                        </BoxTitleValue>
                                    </BoxTitleFlexDiv>
                                    <BoxContentDiv>
                                        <BoxContentFlexStartDiv>
                                            <BoxContentInputKeyDiv>카테고리명 *</BoxContentInputKeyDiv>
                                            <BoxContentInputValueDiv>
                                                <TextField
                                                    id="PCName"
                                                    size="small"
                                                    fullWidth
                                                    value={PCName}
                                                    onChange={handlePCNameChange}
                                                />
                                            </BoxContentInputValueDiv>
                                        </BoxContentFlexStartDiv>
                                        <BoxContentFlexStartDiv>
                                            <BoxContentInputKeyDiv>선택가능한 상품옵션 *</BoxContentInputKeyDiv>
                                            <BoxContentInputValueDiv>
                                                <TextField
                                                    id="PCFirstOpt"
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    label='상품옵션 1'
                                                    value={firstProductOption}
                                                    onChange={handleFirstProductOptionChange}
                                                >
                                                    {["맛"].map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </BoxContentInputValueDiv>
                                        </BoxContentFlexStartDiv>
                                        <BoxContentFlexStartDiv>
                                            <BoxContentInputKeyDiv></BoxContentInputKeyDiv>
                                            <BoxContentInputValueDiv>
                                                <TextField
                                                    id="PCSecondOpt"
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    label='상품옵션 2'
                                                    value={secondProductOption}
                                                    onChange={handleSecondProductOptionChange}
                                                >
                                                    {["구성", "용량"].map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </BoxContentInputValueDiv>
                                        </BoxContentFlexStartDiv>
                                        <BoxContentFlexStartDiv>
                                            <BoxContentInputFlexDiv>
                                                <BoxContentInputKeyDiv>{`{숫자+단위} 당 가격 *`}</BoxContentInputKeyDiv>
                                                <BoxContentInputValueFlexDiv>
                                                    <BoxFiftyPercentDiv>
                                                        <TextField
                                                            id="perAmount"
                                                            size="small"
                                                            fullWidth
                                                            label='숫자'
                                                            value={perAmount}
                                                            onChange={handlePerAmountChange}
                                                        />
                                                    </BoxFiftyPercentDiv>
                                                    <BoxFiftyPercentDiv>
                                                        <Autocomplete
                                                            id="perAmountUnit"
                                                            value={perAmountUnit}
                                                            onChange={(event, newValue) => {
                                                                if (newValue && newValue.inputValue) {
                                                                    setPerAmountUnit({
                                                                        unitId: -1,
                                                                        name: newValue.inputValue,
                                                                    });
                                                                } else {
                                                                    setPerAmountUnit(newValue);
                                                                }
                                                            }}
                                                            filterOptions={(options, params) => {
                                                                const filtered = filterUnitOption(options, params);

                                                                const { inputValue } = params;
                                                                const isExisting = options.some((option) => inputValue === option.name);
                                                                if (inputValue !== '' && !isExisting) {
                                                                    filtered.push({
                                                                        inputValue,
                                                                        name: `"${inputValue}" 추가!`,
                                                                        unitId: -1
                                                                    });
                                                                }

                                                                return filtered;
                                                            }}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            handleHomeEndKeys
                                                            options={unitList}
                                                            getOptionLabel={(option) => {
                                                                return option.name;
                                                            }}
                                                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                            freeSolo
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    size="small"
                                                                    fullWidth
                                                                    label='단위'
                                                                />
                                                            )}
                                                        />
                                                    </BoxFiftyPercentDiv>
                                                </BoxContentInputValueFlexDiv>
                                            </BoxContentInputFlexDiv>
                                        </BoxContentFlexStartDiv>
                                        <LargeGrayLineDivider />
                                        <BoxContentDiv>
                                            <BoxTitleFlexDiv>
                                                <BoxContentInputKeyDiv>리뷰 태그키</BoxContentInputKeyDiv>
                                            </BoxTitleFlexDiv>
                                            <BoxContentFlexDiv>
                                                <BoxFlexStartDiv>
                                                    <BoxContentInputDiv>
                                                        <Autocomplete
                                                            id="PCReviewTag"
                                                            value={PCReviewTag}
                                                            onChange={(event, newValue) => {
                                                                if (newValue && newValue.inputValue) {
                                                                    setPCReviewTag({
                                                                        reviewTagId: -1,
                                                                        name: newValue.inputValue,
                                                                    });
                                                                } else {
                                                                    setPCReviewTag(newValue);
                                                                }
                                                            }}
                                                            filterOptions={(options, params) => {
                                                                const filtered = filterReviewTagOption(options, params);

                                                                const { inputValue } = params;
                                                                const isExisting = options.some((option) => inputValue === option.name);
                                                                if (inputValue !== '' && !isExisting) {
                                                                    filtered.push({
                                                                        inputValue,
                                                                        name: `"${inputValue}" 추가!`,
                                                                        reviewTagId: -1
                                                                    });
                                                                }

                                                                return filtered;
                                                            }}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            handleHomeEndKeys
                                                            options={reviewTagList}
                                                            getOptionLabel={(option) => {
                                                                return option.name;
                                                            }}
                                                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                            freeSolo
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    size="small"
                                                                    fullWidth
                                                                    label='리뷰 태그키'
                                                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                                                />
                                                            )}
                                                            onKeyPress={(ev) => {
                                                                if (ev.key === 'Enter') {
                                                                    if (PCReviewTag !== undefined && PCReviewTag !== "") {
                                                                        addPCReviewTag()
                                                                    }
                                                                    ev.preventDefault()
                                                                }
                                                            }}
                                                        />
                                                    </BoxContentInputDiv>
                                                </BoxFlexStartDiv>
                                                <BoxFlexEndDiv>
                                                    {
                                                        PCReviewTag !== undefined && PCReviewTag !== "" ?
                                                            <PrimaryButton value="등록" size="small" color="secondary" onClickEvent={() => addPCReviewTag()} />
                                                            :
                                                            <PrimaryButton disabled={true} value="등록" size="small" color="secondary" />
                                                    }
                                                </BoxFlexEndDiv>
                                            </BoxContentFlexDiv>
                                            <BoxDownArrowDiv>
                                                <ArrowDownwardRoundedIcon fontSize="inherit" />
                                            </BoxDownArrowDiv>
                                            <BoxTitleFlexDiv>
                                                <BoxContentInputKeyDiv>등록된 리뷰 태그키 *</BoxContentInputKeyDiv>
                                            </BoxTitleFlexDiv>
                                            {
                                                PCReviewTagList.length !== 0 &&
                                                <Paper sx={{ width: '100%', mb: 2 }}>
                                                    <TableContainer>
                                                        <Table sx={{ minWidth: 500 }} aria-label="PCReviewTagList table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>리뷰 태그키 이름</TableCell>
                                                                    <TableCell align="right"></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {PCReviewTagList.map((id: any) => (
                                                                    <TableRow
                                                                        key={id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            {getReviewTagName(id)}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <PrimaryButton value="삭제" size="small" color="error" onClickEvent={() => deletePCReviewTag(id)} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            }
                                        </BoxContentDiv>
                                        <LargeGrayLineDivider />
                                        <BoxContentDiv>
                                            <BoxTitleFlexDiv>
                                                <BoxContentInputKeyDiv>상품 분석 항목</BoxContentInputKeyDiv>
                                            </BoxTitleFlexDiv>
                                            <BoxContentFlexDiv>
                                                <BoxFlexStartDiv>
                                                    <BoxContentInputDiv>
                                                        <Autocomplete
                                                            id="PCAnalysis"
                                                            value={PCAnalysis}
                                                            onChange={(event, newValue) => {
                                                                if (newValue && newValue.inputValue) {
                                                                    setPCAnalysis({
                                                                        analysisId: -1,
                                                                        name: newValue.inputValue,
                                                                    });
                                                                } else {
                                                                    setPCAnalysis(newValue);
                                                                }
                                                            }}
                                                            filterOptions={(options, params) => {
                                                                const filtered = filterAnalysisOption(options, params);

                                                                const { inputValue } = params;
                                                                const isExisting = options.some((option) => inputValue === option.name);
                                                                if (inputValue !== '' && !isExisting) {
                                                                    filtered.push({
                                                                        inputValue,
                                                                        name: `"${inputValue}" 추가!`,
                                                                        analysisId: -1
                                                                    });
                                                                }

                                                                return filtered;
                                                            }}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            handleHomeEndKeys
                                                            options={analysisList}
                                                            getOptionLabel={(option) => {
                                                                return option.name;
                                                            }}
                                                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                                            freeSolo
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    size="small"
                                                                    fullWidth
                                                                    label='상품 분석 항목'
                                                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                                                />
                                                            )}
                                                            onKeyPress={(ev) => {
                                                                if (ev.key === 'Enter') {
                                                                    if (PCAnalysis !== undefined && PCAnalysis !== "") {
                                                                        addPCAnalysis()
                                                                    }
                                                                    ev.preventDefault()
                                                                }
                                                            }}
                                                        />
                                                    </BoxContentInputDiv>
                                                </BoxFlexStartDiv>
                                                <BoxFlexEndDiv>
                                                    {
                                                        PCAnalysis !== undefined && PCAnalysis !== "" ?
                                                            <PrimaryButton value="등록" size="small" color="secondary" onClickEvent={() => addPCAnalysis()} />
                                                            :
                                                            <PrimaryButton disabled={true} value="등록" size="small" color="secondary" />
                                                    }
                                                </BoxFlexEndDiv>
                                            </BoxContentFlexDiv>
                                            <BoxDownArrowDiv>
                                                <ArrowDownwardRoundedIcon fontSize="inherit" />
                                            </BoxDownArrowDiv>
                                            <BoxTitleFlexDiv>
                                                <BoxContentInputKeyDiv>등록된 상품 분석 항목 *</BoxContentInputKeyDiv>
                                            </BoxTitleFlexDiv>
                                            {
                                                PCAnalysisList.length !== 0 &&
                                                <Paper sx={{ width: '100%', mb: 2 }}>
                                                    <TableContainer>
                                                        <Table sx={{ minWidth: 500 }} aria-label="PCAnalysisList table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>상품 분석 항목명</TableCell>
                                                                    <TableCell align="right"></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {PCAnalysisList.map((id: any) => (
                                                                    <TableRow
                                                                        key={id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            {getAnalysisName(id)}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <PrimaryButton value="삭제" size="small" color="error" onClickEvent={() => deletePCAnalysis(id)} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            }
                                        </BoxContentDiv>
                                        <LargeGrayLineDivider />
                                        <BoxContentFlexStartDiv>
                                            <>카테고리 이미지 (1개)</>
                                        </BoxContentFlexStartDiv>
                                        <BoxContentFlexStartDiv>
                                            {
                                                PCImagePath !== undefined && PCImagePath !== null && PCImagePath !== "" ?
                                                    <DropzonePreviewDiv>
                                                        <ProfileImageDiv>
                                                            <ProfileImage src={PCImagePath} alt={PCImagePath} />
                                                            <ImageCancleBtn onClick={() => setPCImagePath("")}>삭제</ImageCancleBtn>
                                                        </ProfileImageDiv>
                                                    </DropzonePreviewDiv>
                                                    :
                                                    <DropzoneDiv {...getRootPCImagePathProps()}>
                                                        <input {...getInputPCImagePathProps()} />
                                                        {
                                                            isPCImagePathDragReject ?
                                                                <DropzoneRejectDiv>
                                                                    <div>1. File Size는 10KB 이하</div>
                                                                    <div>2. 이미지 업로드는 한장만 허용</div>
                                                                    <div>3. 이미지 파일 타입 에러는 개발자에게 문의 바람</div>
                                                                </DropzoneRejectDiv>
                                                                :
                                                                <>
                                                                    {
                                                                        isPCImagePathDragActive ?
                                                                            <DropzoneHelpDiv>추가할 이미지를 여기로 드랍해주세요 :)</DropzoneHelpDiv> :
                                                                            <DropzoneHelpDiv>
                                                                                <div>이미지를 드래그 후 드랍하거나 여기를 클릭해서 추가해주세요 :)</div>
                                                                            </DropzoneHelpDiv>
                                                                    }
                                                                </>
                                                        }
                                                    </DropzoneDiv>
                                            }
                                        </BoxContentFlexStartDiv>
                                        <LargeGrayLineDivider />
                                        <BoxContentFlexStartDiv>
                                            <>카테고리 설명 이미지 (1개)</>
                                        </BoxContentFlexStartDiv>
                                        <BoxContentFlexStartDiv>
                                            <BoxContentInputKeyDiv>설명 연결 URL</BoxContentInputKeyDiv>
                                            <BoxContentInputValueDiv>
                                                <TextField
                                                    id="PCDescriptionURL"
                                                    size="small"
                                                    fullWidth
                                                    value={PCDescriptionURL}
                                                    onChange={handlePCDescriptionURLChange}
                                                />
                                            </BoxContentInputValueDiv>
                                        </BoxContentFlexStartDiv>
                                        <BoxContentFlexStartDiv>
                                            {
                                                PCDescriptionImagePath !== undefined && PCDescriptionImagePath !== null && PCDescriptionImagePath !== "" ?
                                                    <DropzonePreviewDiv>
                                                        <ProfileImageDiv>
                                                            <ProfileImage src={PCDescriptionImagePath} alt={PCDescriptionImagePath} />
                                                            <ImageCancleBtn onClick={() => setPCDescriptionImagePath("")}>삭제</ImageCancleBtn>
                                                        </ProfileImageDiv>
                                                    </DropzonePreviewDiv>
                                                    :
                                                    <DropzoneDiv {...getRootPCDescriptionImagePathProps()}>
                                                        <input {...getInputPCDescriptionImagePathProps()} />
                                                        {
                                                            isPCDescriptionImagePathDragReject ?
                                                                <DropzoneRejectDiv>
                                                                    <div>1. File Size는 100KB 이하</div>
                                                                    <div>2. 이미지 업로드는 한장만 허용</div>
                                                                    <div>3. 이미지 파일 타입 에러는 개발자에게 문의 바람</div>
                                                                </DropzoneRejectDiv>
                                                                :
                                                                <>
                                                                    {
                                                                        isPCDescriptionImagePathDragActive ?
                                                                            <DropzoneHelpDiv>추가할 이미지를 여기로 드랍해주세요 :)</DropzoneHelpDiv> :
                                                                            <DropzoneHelpDiv>
                                                                                <div>이미지를 드래그 후 드랍하거나 여기를 클릭해서 추가해주세요 :)</div>
                                                                            </DropzoneHelpDiv>
                                                                    }
                                                                </>
                                                        }
                                                    </DropzoneDiv>
                                            }
                                        </BoxContentFlexStartDiv>
                                        <LargeGrayLineDivider />
                                        <BoxContentFlexStartDiv>
                                            <BoxContentInputKeyDiv>공개 상태</BoxContentInputKeyDiv>
                                            <BoxContentInputValueDiv>
                                                <RadioGroup
                                                    row
                                                    name="exposureStatus"
                                                    value={exposureStatus}
                                                    onChange={handleExposureStatusChange}
                                                >
                                                    <FormControlLabel value="OPEN" control={<Radio />} label="공개" />
                                                    {
                                                        !isMobileDevice &&
                                                        <FormControlLabelDivider />
                                                    }
                                                    <FormControlLabel value="CLOSE" control={<Radio />} label="비공개" />
                                                    {
                                                        !isMobileDevice &&
                                                        <FormControlLabelDivider />
                                                    }
                                                    <FormControlLabel value="HOLD" control={<Radio />} label="보류" />
                                                </RadioGroup>
                                            </BoxContentInputValueDiv>
                                        </BoxContentFlexStartDiv>
                                        <BoxContentFleEndDiv>
                                            {
                                                selectedCategory !== undefined && selectedCategory !== "" && isReadyToModify ?
                                                    <PrimaryButton value="저장" size="large" color="primary" onClickEvent={() => updateCategory()} />
                                                    :
                                                    <PrimaryButton disabled={true} value="저장" size="large" color="primary" />
                                            }
                                        </BoxContentFleEndDiv>
                                    </BoxContentDiv>
                                </BoxDiv>
                            }
                        </BoxContentDiv>
                    </BoxContentFlexStartDiv>
                    <BoxFooterDiv />
                </WrapDiv>
                {
                    <>
                        <ProductCategoryCreationPopup
                            open={productCategoryCreationPopupOpen}
                            parentId={parentId}
                            onClickClose={handleProductCategoryCreationPopupClose}
                            btnTextFirst="취소"
                            btnTextSecond="추가"
                        />
                    </>
                }
            </Box>
        </>
    );
};

const WrapDiv = Styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    background: #FDFDFD;
`
const BoxDiv = Styled.div`
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
`
const BoxTitle = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
    text-align: left;
`
const BoxSubTitle = Styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
`
const BoxContentFlexStartDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-start;
`
const BoxContentFleEndDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-end;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
`
const BoxContentDivider = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
`
const BoxFooterDiv = Styled.div`
    height: 450px;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    display: flex;
    justify-content: space-between;
`
const BoxFiftyPercentDiv = Styled.div`
    width: 50%;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 170px;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 170px);
`
const BoxContentInputValueFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    width: calc(100% - 170px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleFlexStartDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    align-items: center;
`
const BoxFlexStartDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
`
const BoxFlexEndDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const BoxDescKey = Styled.div`
`
const BoxDescValue = Styled.div`
    width: fit-content;
    color: #666666;
    font-size: 14px;
`
const FormControlLabelDivider = Styled.div`
    width: 15%;
`
const BoxDownArrowDiv = Styled.div`
    font-size: 30px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
`
const GrayLineDivider = Styled.div`
    height: 0px;
    border-top: solid 1px rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.12);
    margin-bottom: 25px;
`
const LargeGrayLineDivider = Styled.div`
    height: 0px;
    border-top: solid 1px rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.12);
    margin-top: 60px;
    margin-bottom: 50px;
`
const CollapseDivider = Styled.div`
    height: 20px;
`
const ListItemDivider = Styled.div`
    width: 15%;
`
const ListItemStatusDiv = Styled.div`
    padding: 2px;
    border-radius: 8px;
    text-align: left;
    font-size: 10px;
    color: #666666;
    border: 1px dashed #ff4d4d;
    width: fit-content;
    background: rgb(255 77 77 / 10%);
`
const SubListItemCreationDiv = Styled.div`
    padding: 2px;
    border-radius: 8px;
    text-align: left;
    color: #666666;
    border: 1px dashed #327BFF;
    width: fit-content;
    background: #FFFFFF;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`
const SubListItemIconDiv = Styled.div`
    font-size: 18px;
    color: #327BFF;
`
const ListItemArrowIconDiv = Styled.div`
    font-size: 24px;
    color: #327BFF;
`
const ListItemTextDiv = Styled.div`
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    ${(props: { isSelected: any; }) => (props.isSelected ? "font-weight: 600;color: #327BFF;" : "font-weight: inherit;color: inherit;")}
`
const ListItemCreationDiv = Styled.div`
    padding: 2px;
    border-radius: 8px;
    text-align: left;
    color: #666666;
    border: 1px dashed #327BFF;
    width: fit-content;
    background: #FFFFFF;
`
const BoxContentInputDiv = Styled.div`
    width: calc(${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"} - 30px);
    padding: 0px 15px;
    font-size: 10px;
`
const DropzonePreviewDiv = Styled.div`
    width: 100%;
    height: 230px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #EBEBEB;
    border-radius: 20px;
`
const DropzoneDiv = Styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 3px #8fb7ff;
    border-radius: 20px;
`
const DropzoneHelpDiv = Styled.div`
    font-size: 25px;
    color: #8fb7ff;
`
const DropzoneRejectDiv = Styled.div`
    font-size: 25px;
    color: #fc5d6b;
`
const ProfileImageDiv = Styled.div`
    width: fit-content;
    height: 230px;
`
const ProfileImage = Styled.img`
    height: 200px;
    width: auto;
`
const ImageCancleBtn = Styled.div`
    width: 100%;
    color: #ffffff;
    background: #fc5d6b;
    border-radius: 10px;
    padding: 5px 0px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`

export default DSBProductCategory;