import React, { useEffect, useState, useCallback } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { getToken } from 'utils/token';
import { getSchema, postRequestWithToken, putRequestWithToken, postImageRequestWithToken } from 'utils/APIRequest';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

interface Props {
    open?: any,
    brandForUpdate?: any,
    purpose?: any,
    onClickClose?: any,
    btnTextFirst?: any,
    btnTextSecond?: any,
}

const BrandCreationPopup = ({ open, brandForUpdate, purpose, onClickClose, btnTextFirst, btnTextSecond }: Props) => {
    const classes = useStyles();
    const history = useHistory()

    const [popupPurpose, setPopupPurpose] = useState<any>("추가")
    
    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [brand, setBrand] = useState<any>(null)
    const [brandID, setBrandID] = useState<number>(-1)
    const [brandImagePath, setBrandImagePath] = useState<any>("")
    const [brandName, setBrandName] = useState<string | null>("")
    const [brandNameEng, setBrandNameEng] = useState<string | null>("")
    const [brandNameLangType, setBrandNameLangType] = useState<string | null>("KOR")
    const [brandDescription, setBrandDescription] = useState<string | undefined>("")

    const onDropRejected = useCallback(rejectedFiles => {
        if (rejectedFiles[0] !== undefined)
            alert(JSON.stringify(rejectedFiles[0].errors))
    }, [])

    const onDropAccepted = useCallback(acceptedFile => {
        let formData = new FormData()
        formData.append('image', acceptedFile[0])
        postImage(formData)
    }, [])

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        accept: {
            'image/*': []
        },
        minSize: 10,
        maxSize: 10 * 1024,
        multiple: false,
        onDropRejected,
        onDropAccepted,
    })

    const postImage = async (formData: any) => {
        if (formData === undefined || !formData) return
        const curToken = getToken()
        if (curToken === '') return

        const res = await postImageRequestWithToken(
            formData,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                if (res.data.location === undefined) {
                    alert("이미지 경로를 찾을 수 없습니다. \n\n" + JSON.stringify(res.data))
                    return
                }
                setBrandImagePath(res.data.location)
            } else if (res.status === 409) {
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const createBrand = async () => {
        if (brand.name === undefined || brand.name === "") return
        if (brand.nameEng === undefined || brand.nameEng === "") return
        if (brand.nameLangType === undefined || brand.nameLangType === "") return
        const curToken = getToken()
        if (curToken === '') return

        const brandInfo = {
            name: brand.name,
            nameEng: brand.nameEng,
            nameLangType: brand.nameLangType,
            imagePath: brand.imagePath,
            description: brand.description,
        }
        const curSchema = getSchema('/brands', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(brandInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/brands',
            brandInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                clearInputData()
                onClickClose()
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateBrand = async () => {
        if (brand.name === undefined || brand.name === "") return
        if (brand.nameEng === undefined || brand.nameEng === "") return
        if (brand.nameLangType === undefined || brand.nameLangType === "") return
        if (brand.brandId === undefined || brand.brandId === "" || brand.brandId === -1) return
        const curToken = getToken()
        if (curToken === '') return

        const brandInfo = {
            name: brand.name,
            nameEng: brand.nameEng,
            nameLangType: brand.nameLangType,
            imagePath: brand.imagePath,
            description: brand.description,
        }
        const curSchema = getSchema('/brands', 'PUT')

        if (curSchema) {
            try {
                await curSchema.validateAsync(brandInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            `/brands/${brand.brandId}`,
            brandInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                clearInputData()
                onClickClose()
            } else if (res.status === 204) {
                alert("brand ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const clearInputData = () => {
        setBrand(null)
        setBrandID(-1)
        setBrandName("")
        setBrandNameEng("")
        setBrandNameLangType("KOR")
        setBrandImagePath("")
        setBrandDescription("")
    }
    
    const handleBrandNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const englishNameExcluded = event.target.value.replace(/[a-zA-Z]/g, '')
        setBrandName(englishNameExcluded)
    }

    const handleBrandNameEngChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const koreanNameExcluded = event.target.value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, '')
        setBrandNameEng(koreanNameExcluded)
    }

    const handleBrandDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBrandDescription(event.target.value)
    }

    const handleBrandNameLangTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== "KOR" && event.target.value !== "ENG") return
        setBrandNameLangType(event.target.value)
    }

    useEffect(() => {
        if(brandName == undefined || brandName == "") {
            setIsAllFilled(false)
            return
        }

        if(brandNameEng == undefined || brandNameEng == "") {
            setIsAllFilled(false)
            return
        }

        if(brandNameLangType == undefined || brandNameLangType == "") {
            setIsAllFilled(false)
            return
        }

        const curBrand = {
            brandId: brandID,
            name: brandName,
            nameEng: brandNameEng,
            nameLangType: brandNameLangType,
            imagePath: brandImagePath,
            description: brandDescription,
        }

        if(brandForUpdate !== undefined && JSON.stringify(curBrand) == JSON.stringify(brandForUpdate)) {
            setIsAllFilled(false)
            return
        }

        setBrand(curBrand)
        setIsAllFilled(true)

    }, [brandName, brandNameEng, brandImagePath,  brandNameLangType, brandDescription])

    useEffect(() => {
        if(open) return
        clearInputData()
    }, [open])

    useEffect(() => {
        if(brandForUpdate !== undefined) {
            setBrandID(brandForUpdate.brandId)
            setBrandName(brandForUpdate.name)
            setBrandNameEng(brandForUpdate.nameEng)
            setBrandNameLangType(brandForUpdate.nameLangType)
            setBrandImagePath(brandForUpdate.imagePath)
            setBrandDescription(brandForUpdate.description)
        }
    }, [JSON.stringify(brandForUpdate)])
    
    useEffect(() => {
        if(purpose !== undefined)
            setPopupPurpose(purpose)
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>브랜드 프로필 {popupPurpose}</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxFlexStartDiv>
                            {
                                brandImagePath !== undefined && brandImagePath !== null && brandImagePath !== "" ?
                                    <DropzonePreviewDiv>
                                        <ProfileImageDiv>
                                            <ProfileImage src={brandImagePath} alt={brandImagePath} />
                                            <ImageCancleBtn onClick={() => setBrandImagePath("")}>삭제</ImageCancleBtn>
                                        </ProfileImageDiv>
                                    </DropzonePreviewDiv>
                                    :
                                    <DropzoneDiv {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragReject ?
                                                <DropzoneRejectDiv>
                                                    <div>1. File Size는 10KB 이하</div>
                                                    <div>2. 이미지 업로드는 한장만 허용</div>
                                                    <div>3. 이미지 파일 타입 에러는 개발자에게 문의 바람</div>
                                                </DropzoneRejectDiv>
                                                :
                                                <>
                                                    {
                                                        isDragActive ?
                                                            <DropzoneHelpDiv>추가할 이미지를 여기로 드랍해주세요 :)</DropzoneHelpDiv> :
                                                            <DropzoneHelpDiv>이미지를 드래그 후 드랍하거나 여기를 클릭해서 추가해주세요 :)</DropzoneHelpDiv>
                                                    }
                                                </>
                                        }
                                    </DropzoneDiv>
                            }
                        </BoxFlexStartDiv>
                        <BoxMarginDivider />
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>브랜드명(한글) *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="brandName"
                                                size="small"
                                                fullWidth
                                                value={brandName}
                                                onChange={handleBrandNameChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>브랜드명(영어) *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="brandNameEng"
                                                size="small"
                                                fullWidth
                                                value={brandNameEng}
                                                onChange={handleBrandNameEngChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>브랜드명 언어 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="brandNameLangType"
                                                select
                                                fullWidth
                                                size="small"
                                                label='대표 언어 선택'
                                                value={brandNameLangType}
                                                onChange={handleBrandNameLangTypeChange}
                                            >
                                                {["KOR", "ENG"].map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                        <BoxContentDiv>
                        <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>브랜드 소개</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="brandDescription"
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={brandDescription}
                                                onChange={handleBrandDescriptionChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                        </BoxContentDiv>
                        <BoxMarginDivider />
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton 
                            variant="outlined" 
                            value={btnTextFirst} 
                            size="large" 
                            color="primary" 
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                        <VerticalDivider />
                        {
                            !isAllFilled ?
                                <PrimaryButton 
                                    disabled={true} 
                                    value={btnTextSecond} 
                                    size="large" 
                                    color="primary"
                                />
                                :
                                <PrimaryButton 
                                    value={btnTextSecond} 
                                    size="large" 
                                    color="primary"
                                    onClickEvent={()=>{
                                        if(purpose == "추가")
                                            createBrand()
                                        else
                                            updateBrand()
                                    }}
                                />
                        }
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
`
const BoxContentProfileDiv = Styled.div`
    width: 100%;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 140px;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 140px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const VerticalDivider = Styled.div`
    width: 20px;
`
const BoxFlexStartDiv = Styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`
const DropzonePreviewDiv = Styled.div`
    width: 100%;
    height: 230px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #EBEBEB;
    border-radius: 20px;
`
const DropzoneDiv = Styled.div`
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 3px #8fb7ff;
    border-radius: 20px;
`
const DropzoneHelpDiv = Styled.div`
    font-size: 25px;
    color: #8fb7ff;
`
const DropzoneRejectDiv = Styled.div`
    font-size: 25px;
    color: #fc5d6b;
`
const ProfileImageDiv = Styled.div`
    width: fit-content;
    height: 230px;
`
const ProfileImage = Styled.img`
    height: 200px;
    width: auto;
`
const ImageCancleBtn = Styled.div`
    width: 100%;
    color: #ffffff;
    background: #fc5d6b;
    border-radius: 10px;
    padding: 5px 0px;
    &:hover {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 3px 3px 3px 3px rgb(0 0 0 / 20%);
    }
`

export default BrandCreationPopup