import React, { useEffect, useState } from 'react';
import Styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { getToken } from 'utils/token';
import { getSchema, postRequestWithToken, putRequestWithToken } from 'utils/APIRequest';

import PrimaryButton from 'component/button/PrimaryButton';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles({
    dialog: {
        "& .MuiPaper-rounded": {
            borderRadius: 10,
        },
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "1000px",
        }
    }
});

interface Props {
    open?: any,
    eventClassificationForUpdate?: any,
    purpose?: any,
    onClickClose?: any,
    btnTextFirst?: any,
    btnTextSecond?: any,
}

const EventClassificationCreationPopup = ({ open, eventClassificationForUpdate, purpose, onClickClose, btnTextFirst, btnTextSecond }: Props) => {
    const classes = useStyles();
    const history = useHistory()

    const [popupPurpose, setPopupPurpose] = useState<any>("추가")
    
    const [isAllFilled, setIsAllFilled] = useState<boolean>(false)
    const [eventClassification, setEventClassification] = useState<any>(null)
    const [eventClassificationID, setEventClassificationID] = useState<number>(-1)
    const [eventClassificationName, setEventClassificationName] = useState<string | null>("")

    const createEventClassification = async () => {
        if (eventClassification.name === undefined || eventClassification.name === "") return
        const curToken = getToken()
        if (curToken === '') return

        const eventClassificationInfo = { name: eventClassification.name }
        const curSchema = getSchema('/eventClassifications', 'POST')

        if (curSchema) {
            try {
                await curSchema.validateAsync(eventClassificationInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await postRequestWithToken(
            '/eventClassifications',
            eventClassificationInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 201) {
                clearInputData()
                onClickClose()
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const updateEventClassification = async () => {
        if (eventClassification.name === undefined || eventClassification.name === "") return
        if (eventClassification.eventClassificationId === undefined || eventClassification.eventClassificationId === "" || eventClassification.eventClassificationId === -1) return
        const curToken = getToken()
        if (curToken === '') return

        const eventClassificationInfo = { name: eventClassification.name }
        const curSchema = getSchema('/eventClassifications', 'PUT')

        if (curSchema) {
            try {
                await curSchema.validateAsync(eventClassificationInfo, {
                    abortEarly: false,
                    allowUnknown: true,
                    stripUnknown: true,
                })
            }
            catch (err) {
                alert("입력하신 값에 대한 검증에 실패했습니다. \n자세한 사항은 아래 에러 메세지와 함께 문의해주세요\n\n" + JSON.stringify(err))
                return
            }
        } else {
            return
        }

        const res = await putRequestWithToken(
            `/eventClassifications/${eventClassification.eventClassificationId}`,
            eventClassificationInfo,
            curToken
        )
        if (res !== null) {
            if (res.status === 200) {
                clearInputData()
                onClickClose()
            } else if (res.status === 204) {
                alert("eventClassification ID에 대한 정보가 없습니다. 다시 시도 해주세요.")
            } else if (res.status === 409) {
                if (res.data.name === "SequelizeForeignKeyConstraintError") {
                alert("다른 테이블과 관계되어있어 요청한 명령을 수행할 수 없습니다. \n\n" + res.data.message)
                    return
                }
                alert("이미 추가한 항목입니다.")
            } else if (res.status === 400 && res.data.name === "ValidationError") {
                alert(res.data.name + ": " + res.data.message)
            } else if (res.status === 401) {
                alert("계정정보를 확인하고 다시 로그인 해주세요 :)")
                history.push("/signIn")
            } else {
                alert("죄송합니다. 일시적인 서버오류입니다.\n\n" + "status code: " + (res.status) + "\n" + JSON.stringify(res.data))
            }
        }
        return
    }

    const clearInputData = () => {
        setEventClassification(null)
        setEventClassificationID(-1)
        setEventClassificationName("")
    }
    
    const handleEventClassificationNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEventClassificationName(event.target.value)
    }

    useEffect(() => {
        if(eventClassificationName == undefined || eventClassificationName == "") {
            setIsAllFilled(false)
            return
        }

        const curEventClassification = {
            eventClassificationId: eventClassificationID,
            name: eventClassificationName,
        }

        if(eventClassificationForUpdate !== undefined && JSON.stringify(curEventClassification) === JSON.stringify(eventClassificationForUpdate)) {
            setIsAllFilled(false)
            return
        }

        setEventClassification(curEventClassification)
        setIsAllFilled(true)

    }, [eventClassificationName])

    useEffect(() => {
        if(eventClassificationForUpdate !== undefined) {
            setEventClassificationID(eventClassificationForUpdate.eventClassificationId)
            setEventClassificationName(eventClassificationForUpdate.name)
        }
    }, [JSON.stringify(eventClassificationForUpdate)])

    useEffect(() => {
        if(open) return
        clearInputData()
    }, [open])
    
    useEffect(() => {
        if(purpose !== undefined)
            setPopupPurpose(purpose)
    }, [])

    return (
        <div>
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={onClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogBoxDiv>
                    <WrapDiv>
                        <BoxTitleFlexDiv>
                            <BoxTitleKey>이벤트 종류 {popupPurpose}</BoxTitleKey>
                            <BoxTitleValue>
                            </BoxTitleValue>
                        </BoxTitleFlexDiv>
                        <BoxMarginDivider />
                        <BoxContentFlexDiv>
                            <BoxContentProfileDiv>
                                <BoxContentFlexDiv>
                                    <BoxContentInputFlexDiv width="100%">
                                        <BoxContentInputKeyDiv>이벤트 종류명 *</BoxContentInputKeyDiv>
                                        <BoxContentInputValueDiv>
                                            <TextField
                                                id="eventClassificationName"
                                                size="small"
                                                fullWidth
                                                value={eventClassificationName}
                                                onChange={handleEventClassificationNameChange}
                                            />
                                        </BoxContentInputValueDiv>
                                    </BoxContentInputFlexDiv>
                                </BoxContentFlexDiv>
                            </BoxContentProfileDiv>
                        </BoxContentFlexDiv>
                    </WrapDiv>
                    <DialogBtnDiv>
                        <PrimaryButton 
                            variant="outlined" 
                            value={btnTextFirst} 
                            size="large" 
                            color="primary" 
                            onClickEvent={onClickClose !== undefined ? onClickClose : undefined}
                        />
                        <VerticalDivider />
                        {
                            !isAllFilled ?
                                <PrimaryButton 
                                    disabled={true} 
                                    value={btnTextSecond} 
                                    size="large" 
                                    color="primary"
                                />
                                :
                                <PrimaryButton 
                                    value={btnTextSecond} 
                                    size="large" 
                                    color="primary"
                                    onClickEvent={()=>{
                                        if(purpose == "추가")
                                            createEventClassification()
                                        else
                                            updateEventClassification()
                                    }}
                                />
                        }
                    </DialogBtnDiv>
                </DialogBoxDiv>
            </Dialog>
        </div>
    )
}

const WrapDiv = Styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: inline-block;
    background: #FDFDFD;
    padding: 20px;
`
const BoxContentFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
`
const BoxContentProfileDiv = Styled.div`
    width: 100%;
`
const BoxContentInputFlexDiv = Styled.div`
    width: ${(props: { width?: any; }) => props.width !== undefined ? props.width : "100%"};
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
`
const BoxContentInputKeyDiv = Styled.div`
    width: 110px;
    text-align: left;
    margin-top: 9px;
`
const BoxContentInputValueDiv = Styled.div`
    width: calc(100% - 110px);
`
const BoxTitleFlexDiv = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BoxTitleKey = Styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    margin-top: 2px;
`
const BoxTitleValue = Styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
`
const DialogBoxDiv = Styled.div`
    width: 100%;
    border-radius: 10px;
`
const DialogBtnDiv = Styled.div`
    display: flex;
    justify-content: flex-end;
    background: #FDFDFD;
    padding: 20px;
`
const VerticalDivider = Styled.div`
    width: 20px;
`
const BoxMarginDivider = Styled.div`
    width: 100%;
    height: 30px;
`

export default EventClassificationCreationPopup